import gql from 'graphql-tag';

const GET_CAMPAIGN_PAGE_DATA = gql`
  query campaignViewPageData($id: ID) {
    advertisers {
      id
      name
      margin
      agencyId
    }
    user {
      username
      margin
      additionalContactField
      staticPrice {
        TKP
        CPC
      }
      id
    }
    campaign(id: $id) {
      advertiserId
      advertiserMargin
      name
      comment
      intensity
      linkedinGoal
      campaignType
      startDate
      endDate
      linkedinTargetGroups {
        name
        id
        children {
          name
          id
        }
      }
      linkedinTargetCompanyGroups {
        name
        id
        children {
          name
          id
        }
      }
      geoTargetingType
      geoZipCodes
      geoRegionIds
      budget
      estimatedReach
      budgetRecommendation
      pricing
      status
      creativeStatus
      linkedinAges
      gender
      landingPage
      idkLandingPageYet
      linkedinProfile
      idkLinkedinProfileYet
    }
  }
`;
export default GET_CAMPAIGN_PAGE_DATA;
