import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const CampaignBudgetRecommendation = (props) => {
  const { t } = useTranslation();

  const { isReachAvailable, budgetRecommendation } = props;

  if (!isReachAvailable) {
    return (
      <Typography variant="body1">
        {t('createCampaign.budgetNotAvailableWithSelectedGeoType')}
      </Typography>
    );
  }
  const budget = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(budgetRecommendation);
  return (
    <Typography variant="body1">
      {t('createCampaign.budgetRecommendation', { data: budget })}
    </Typography>
  );
};

export default CampaignBudgetRecommendation;
