import React, { memo } from 'react';
import { GoogleMap } from '@react-google-maps/api';

const Map = memo((props) => {
  const { disabled, ...restProps } = props;
  const containerStyle = {
    width: '100%',
    height: '400px',
    filter: disabled ? 'grayscale(1)' : 'none',
  };
  const { children } = props;
  return (
    <GoogleMap {...restProps} mapContainerStyle={{ ...containerStyle }}>
      {disabled && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      )}
      {children}
    </GoogleMap>
  );
});

export default Map;
