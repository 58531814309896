import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Intensity from '../../../../../../components/Intensity';
import { LinkedinCampaignGoal } from '../../../../../../enums/campaign';
import { AppCase, AppSwitch } from '../../../../../../components/AppSwitchCase';

const useStyles = makeStyles({
  wrapper: {
    minHeight: '450px',
  },
  img: {
    display: 'block',
    maxWidth: '200px',
    width: '100%',
  },
});

const CampaignStep4 = (props) => {
  const { intensity, comment, linkedinGoal, budget } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const linkedinSpecs = t('linkedinCampaign.adSpecs', { returnObjects: true });
  const linkedinAdditionalInfo = t('linkedinCampaign.additionalInfo', {
    returnObjects: true,
  });

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{t('createCampaign.stepFour')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {/* intensity */}
          <Grid item xs={12}>
            <Intensity intensity={intensity} setIntensity={() => {}} disabled />
            <Box my={4} width="100%">
              <Divider />
            </Box>
          </Grid>

          {/* Budget */}
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('createCampaign.budget')}
            </Typography>
            <Box mt={2} width="100%" />
            <TextField
              placeholder={t('createCampaign.budgetInputPlaceholder')}
              type="number"
              value={budget}
              variant="outlined"
              fullWidth
              disabled
            />
            <Box my={4} width="100%">
              <Divider />
            </Box>
          </Grid>

          {/* comment */}
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('createCampaign.comment')}
            </Typography>
            <Box my={2} width="100%">
              <TextField
                multiline
                InputProps={{
                  style: { height: 'auto' },
                }}
                rows={4}
                value={comment}
                placeholder={t('createCampaign.commentPlaceholder')}
                variant="outlined"
                fullWidth
                disabled
              />
            </Box>
          </Grid>

          {/* linkedin ad specs */}
          <Grid item xs={12}>
            <Grid
              container
              wrap="nowrap"
              spacing={3}
              className={classes.wrapper}
            >
              <AppSwitch test={linkedinGoal}>
                <AppCase value={LinkedinCampaignGoal.Leads}>
                  <Grid container item xs={6} sm={6}>
                    <Grid item md={12} lg={6}>
                      <img
                        className={classes.img}
                        src="/campaign/linkedin-leadgen-1.png"
                        alt="linkedin ads"
                      />
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <img
                        className={classes.img}
                        src="/campaign/linkedin-leadgen-2.png"
                        alt="linkedin ads"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <Typography variant="h5" gutterBottom>
                      {t('linkedinCampaign.adSpecsTitle')}
                    </Typography>
                    {linkedinSpecs.map((i) => (
                      <Typography variant="body2" key={i} gutterBottom>
                        {i}
                      </Typography>
                    ))}
                    <br />
                    <Typography variant="h5" gutterBottom>
                      {t('linkedinCampaign.additionalInfoTitle')}
                    </Typography>
                    {linkedinAdditionalInfo.map((i) => (
                      <Typography variant="body2" key={i} gutterBottom>
                        <li> {i}</li>
                      </Typography>
                    ))}
                  </Grid>
                </AppCase>
                <AppCase value="default">
                  <Grid item xs={4} sm={3}>
                    <img
                      className={classes.img}
                      src="/campaign/linkedin-ad.png"
                      alt="linkedin ads"
                    />
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <Typography variant="h5" gutterBottom>
                      {t('linkedinCampaign.adSpecsTitle')}
                    </Typography>
                    {linkedinSpecs.map((i) => (
                      <Typography variant="body2" key={i} gutterBottom>
                        {i}
                      </Typography>
                    ))}
                  </Grid>
                </AppCase>
              </AppSwitch>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CampaignStep4;
