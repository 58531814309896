export const detailsRequiredFieldsNames = [
  'advertiser-select',
  'campaign-name',
  'goal',
  'date-picker',
  'date-picker',
];

export const targetingRequiredFieldsNames = [
  'gender-age',
  'gender-age',
  'landing-page',
  'linkedin-page',
  'target-groups',
];

export const toggleArrayItem = (arr, item) => {
  const index = arr.indexOf(item);
  if (index === -1) {
    arr.push(item);
  } else {
    arr.splice(index, 1);
  }
  return [...arr];
};

export const checkBudgetValidity = (budget, budgetRecommendation) => {
  if (!budget || budget < 2000) {
    return false;
  }
  if (budget > 100000) {
    return false;
  }
  return !(budgetRecommendation && budget > budgetRecommendation * 5);
};

export const checkIsFirstStepCompleted = (campaignState) => {
  const requiredFields = [
    !!campaignState.advertiser,
    !!campaignState.name,
    !!campaignState.linkedinGoal || campaignState.linkedinGoal === 0,
    !!campaignState.startDate,
    !!campaignState.endDate,
  ];

  let errorFieldIndex;
  const isValid = requiredFields.every((field, index) => {
    errorFieldIndex = index;
    return field;
  });

  return {
    isValid,
    firstInvalidFieldName: detailsRequiredFieldsNames[errorFieldIndex],
  };
};

export const checkIsSecondStepCompleted = (campaignState) => {
  const requiredFields = [
    !!campaignState.gender.length,
    campaignState.linkedinAges.some((age) => age),
    campaignState.landingPage || campaignState.idkLandingPageYet,
    campaignState.linkedinProfile || campaignState.idkLinkedinProfileYet,
    !!campaignState.linkedinTargetGroupsState.length,
  ];
  let errorFieldIndex;
  const isValid = requiredFields.every((field, index) => {
    errorFieldIndex = index;
    return field;
  });
  return {
    isValid,
    firstInvalidFieldName: targetingRequiredFieldsNames[errorFieldIndex],
  };
};

export const checkIsThirdStepCompleted = (campaignState) => {
  const requiredFields = [
    !!campaignState.geoZipCodes?.length,
    !!campaignState.geoRegionIds?.length,
  ];
  return {
    isValid: requiredFields.some((field) => field),
    firstInvalidFieldName: 'geo-targeting',
  };
};

export const checkIsFourthStepCompleted = (campaignState) => {
  const requiredFields = [
    !!campaignState.intensity,
    checkBudgetValidity(
      campaignState.budget,
      campaignState.budgetRecommendation
    ),
  ];
  return {
    isValid: requiredFields.every((field) => field),
    firstInvalidFieldName: 'campaign-budget',
  };
};

export const checkCampaignFormValidity = (campaignState) => {
  // check required fields
  const validationFields = [
    // campaignDetails
    checkIsFirstStepCompleted(campaignState),
    // campaignTargeting
    checkIsSecondStepCompleted(campaignState),
    // geoTargeting
    checkIsThirdStepCompleted(campaignState),
    // campaignPricing
    checkIsFourthStepCompleted(campaignState),
  ];

  let firstInvalidFieldName;
  const isValid = validationFields.every(
    ({ isValid: isFieldValid, firstInvalidFieldName: invalidFieldName }) => {
      firstInvalidFieldName = invalidFieldName;
      return isFieldValid;
    }
  );

  return { isValid, firstInvalidFieldName };
};
