import {
  CampaignAdvertisingType,
  CampaignSocialPlatformTypes,
  CampaignTypes,
} from '../campaign';

export const getBasePrice = (
  type,
  advertisingType,
  socialAdvertiserMaterialType,
  socialPlatform = []
) => {
  const advType = advertisingType || CampaignAdvertisingType.TKP;
  const basePrices = {
    banner: {
      TKP: 4.2,
      CPC: 0.9,
    },
    video: {
      TKP: 9.5,
      CPC: null,
    },
    social: {
      TKP: {
        instagram: {
          feedStatic: 5.9,
          feedVideo: 5.9,
          storyStatic: 6.4,
          storyVideo: 6.4,
        },
        facebook: {
          feedStatic: 5.7,
          feedVideo: 5.7,
          storyStatic: 6.2,
          storyVideo: 6.2,
        },
        instagramAndFacebook: {
          feedStatic: 5.8,
          feedVideo: 5.8,
          storyStatic: 6.3,
          storyVideo: 6.3,
        },
      },
      CPC: {
        instagram: {
          feedStatic: null,
          feedVideo: null,
          storyStatic: null,
          storyVideo: null,
        },
        facebook: {
          feedStatic: null,
          feedVideo: null,
          storyStatic: null,
          storyVideo: null,
        },
        instagramAndFacebook: {
          feedStatic: null,
          feedVideo: null,
          storyStatic: null,
          storyVideo: null,
        },
      },
    },
  };

  if (type === CampaignTypes.Social) {
    let socialOption = 'instagramAndFacebook';
    if (socialPlatform.includes(CampaignSocialPlatformTypes.Instagram)) {
      socialOption = CampaignSocialPlatformTypes.Instagram;
    }
    if (socialPlatform.includes(CampaignSocialPlatformTypes.Facebook)) {
      socialOption = CampaignSocialPlatformTypes.Facebook;
    }
    if (
      socialPlatform.includes(CampaignSocialPlatformTypes.Facebook) &&
      socialPlatform.includes(CampaignSocialPlatformTypes.Instagram)
    ) {
      socialOption = 'instagramAndFacebook';
    }
    return basePrices[type][advType][socialOption][
      socialAdvertiserMaterialType
    ];
  }

  return basePrices[type][advType];
};

export const getGeoTargetingPrices = (
  type,
  advertisingType,
  socialAdvertiserMaterialType
) => {
  const advType = advertisingType || CampaignAdvertisingType.TKP;
  const geoTargetingPrices = {
    banner: {
      TKP: {
        zip: 0.5,
        polygon: 1.7,
        category: 0.9,
        radius: 0.9,
      },
      CPC: {
        zip: 0.14,
        polygon: 0.5,
        category: 0.25,
        radius: 0.25,
      },
    },
    video: {
      TKP: {
        zip: 0.5,
        polygon: 1.7,
        category: 0.5,
        radius: 0.5,
      },
      CPC: {
        zip: null,
        polygon: null,
        category: null,
        radius: null,
      },
    },

    social: {
      TKP: {
        feedStatic: {
          zip: 0.5,
          polygon: 1.7,
          category: 0.9,
          radius: 0.9,
        },
        feedVideo: {
          zip: 0.5,
          polygon: 1.7,
          category: 0.9,
          radius: 0.9,
        },
        storyStatic: {
          zip: 0.5,
          polygon: 1.7,
          category: 0.9,
          radius: 0.9,
        },
        storyVideo: {
          zip: 0.5,
          polygon: 1.7,
          category: 0.9,
          radius: 0.9,
        },
      },
      CPC: {
        feedStatic: {
          zip: null,
          polygon: null,
          category: null,
          radius: null,
        },
        feedVideo: {
          zip: null,
          polygon: null,
          category: null,
          radius: null,
        },
        storyStatic: {
          zip: null,
          polygon: null,
          category: null,
          radius: null,
        },
        storyVideo: {
          zip: null,
          polygon: null,
          category: null,
          radius: null,
        },
      },
    },
  };

  if (socialAdvertiserMaterialType) {
    return geoTargetingPrices[type][advType][socialAdvertiserMaterialType];
  }
  return geoTargetingPrices[type][advType];
};

export const getWebsitesTypesPricing = (advertisingType) => {
  const advType = advertisingType || CampaignAdvertisingType.TKP;
  const websitesTypesPricing = {
    TKP: {
      userDefined: 1.05,
      twins: 0.25,
    },
    CPC: {
      userDefined: 0.3,
      twins: 0.07,
    },
  };
  return websitesTypesPricing[advType];
};

export const getTargetGroupsPrice = (advertisingType) => {
  const advType = advertisingType || CampaignAdvertisingType.TKP;
  const targetGroupsPrice = {
    TKP: 0.8,
    CPC: 0.2,
  };

  return targetGroupsPrice[advType];
};

export const creativesPrices = {
  static: 290,
  dynamic: 590,
};
