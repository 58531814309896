import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { People, Info } from '@material-ui/icons';
import Header from '../Header';
import userCan from '../../services/ACL';
import { whiteColorWithOpacity } from '../../theme';
import {
  AdvertiserIcon,
  CampaignIcon,
  SettingsIcon,
} from '../../components/CustomIcons';

const useStyles = makeStyles({
  iconSize: {
    fontSize: 20,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    color: 'inherit',
    paddingTop: 14,
    paddingBottom: 14,
    '&.Mui-selected': {
      backgroundColor: whiteColorWithOpacity,
      '&:hover': {
        backgroundColor: whiteColorWithOpacity,
      },
      '& .MuiTypography-subtitle2': {
        fontWeight: 600,
      },
    },
  },
});

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const navigation = [
    {
      name: (
        <Typography variant="subtitle2">{t('layout.advertisers')}</Typography>
      ),
      href: '/advertisers',
      icon: <AdvertiserIcon className={classes.iconSize} />,
      permission: 'see-advertiser-page',
    },
    {
      name: <Typography variant="subtitle2">{t('layout.users')}</Typography>,
      href: '/users',
      icon: <People className={classes.iconSize} />,
      permission: 'see-user-page',
    },
    {
      name: (
        <Typography variant="subtitle2">{t('layout.campaigns')}</Typography>
      ),
      href: '/campaigns',
      icon: <CampaignIcon className={classes.iconSize} />,
      permission: 'see-page',
    },
    {
      name: <Typography variant="subtitle2">{t('layout.settings')}</Typography>,
      href: '/settings',
      icon: <SettingsIcon className={classes.iconSize} />,
      permission: 'see-page',
    },
    {
      name: (
        <Typography variant="subtitle2">{t('layout.infoVideo')}</Typography>
      ),
      href: 'https://www.youtube.com/playlist?list=PLpOz2cLS_-7_oYu22hcJ7_QBHO31GuwZ0',
      listCustomOnClick: () => {
        window.open(
          'https://www.youtube.com/playlist?list=PLpOz2cLS_-7_oYu22hcJ7_QBHO31GuwZ0',
          '_blank'
        );
      },
      icon: <Info className={classes.iconSize} />,
      permission: 'see-page',
    },
    {
      name: (
        <Typography variant="subtitle2">{t('layout.adminSettings')}</Typography>
      ),
      href: '/admin-settings',
      icon: <SettingsIcon className={classes.iconSize} />,
      permission: 'see-admin-settings-page',
    },
  ];

  return (
    <>
      <Header />
      <Drawer variant="permanent">
        <Box mt={8} />
        <List>
          {navigation.map(
            ({ name, href, icon, permission, listCustomOnClick }) =>
              userCan(permission) && (
                <ListItem
                  key={href}
                  onClick={listCustomOnClick || (() => history(href))}
                  component={Button}
                  selected={location.pathname === href}
                  classes={{
                    root: classes.listItem,
                  }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{name}</ListItemText>
                </ListItem>
              )
          )}
        </List>
      </Drawer>
      <Box pl={22} pr={3} mt={3}>
        {children}
      </Box>
    </>
  );
};

export default MainLayout;
