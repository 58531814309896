import React, { useRef, useState } from 'react';
import { Box, Grid, Button, Paper } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import UploadItem from '../../components/UploadItem';
import UploadArea from '../../components/UploadArea';
import SupportedUploadFilesTypes from '../../enums/SupportedUploadFilesTypes';
import YoutubeLinkWidget from './components/YoutubeLinkWidget';
import { CREATE_UPLOAD_ATTACHMENT } from '../../common/queries';
import {
  isValidFileToUpload,
  uploadWithoutS3,
  uploadWithS3,
} from '../../services/upload-helper';

const VideoUpload = () => {
  const { campaignId } = useParams();
  const emptyYoutubeLink = {
    value: '',
  };
  const history = useNavigate();
  const { t } = useTranslation();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [youtubeLinks, setYoutubeLinks] = useState([
    {
      ...emptyYoutubeLink,
    },
  ]);
  const [progressState, setProgressState] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [createAttachment] = useMutation(CREATE_UPLOAD_ATTACHMENT);

  const onFilesSelected = (files) => {
    setFilesToUpload((oldFiles) => [...oldFiles, ...files]);
    setProgressState((oldFiles) => ({
      ...oldFiles,
      ...files?.reduce((curr, acc) => {
        return { ...acc, [curr.name]: 0 };
      }, {}),
    }));
  };

  const handleYoutubeLinksUpdate = (newYoutubeLinks) => {
    setYoutubeLinks(newYoutubeLinks);
  };

  const onRemoveItem = (index) => {
    const newFiles = [...filesToUpload];
    newFiles.splice(index, 1);
    setFilesToUpload(newFiles);

    setProgressState(
      newFiles?.reduce((curr, acc) => {
        return { ...acc, [curr.name]: 0 };
      }, {})
    );
  };

  const goToList = () => {
    history('/campaigns');
  };

  const handleUpload = async () => {
    try {
      setIsUploading(true);
      await Promise.all([
        uploadWithoutS3({
          campaignId,
          links: youtubeLinks,
          uploader: createAttachment,
        }),
        uploadWithS3({
          campaignId,
          files: filesToUpload,
          setProgressState,
          uploader: createAttachment,
        }),
      ]);
      setIsUploading(false);
      toast.success(t('toasts.videoUpload'));
      goToList();
    } catch ({ message }) {
      setIsUploading(false);
      toast.error(message);
    }
  };

  const areUploadFilesValid =
    !!filesToUpload?.length &&
    !filesToUpload?.find(
      ({ size, type }) =>
        !isValidFileToUpload({
          size,
          type,
          supportedTypes: SupportedUploadFilesTypes.Video,
        })
    );
  const areLinksValid = !youtubeLinks?.find(({ value }) => !value);
  const areFilesValid = areUploadFilesValid;

  return (
    <Paper elevation={4}>
      <Box mx="auto" mt={3} p={4} maxWidth={800}>
        <UploadArea
          onFilesSelected={onFilesSelected}
          filesToUpload={filesToUpload}
          isVideo
        />
        <Box mt={3} />
        {!!filesToUpload.length && (
          <>
            <hr />
            {filesToUpload.map((file, index) => (
              <UploadItem
                isVideo
                supportedTypes={SupportedUploadFilesTypes.Video}
                onRemoveItem={onRemoveItem}
                key={file.lastModified}
                file={file}
                index={index}
                uploadProgress={progressState?.[file.name] ?? 0}
              />
            ))}
          </>
        )}
        <YoutubeLinkWidget
          youtubeLinks={youtubeLinks}
          handleYoutubeLinksUpdate={handleYoutubeLinksUpdate}
          emptyYoutubeLink={emptyYoutubeLink}
        />
        <Grid container spacing={3} justify="space-between" alignItems="center">
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isUploading || (!areFilesValid && !areLinksValid)}
              onClick={handleUpload}
            >
              {t('uploadPage.create')}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              variant="text"
              color="secondary"
              onClick={goToList}
            >
              {t('uploadPage.skip')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default VideoUpload;
