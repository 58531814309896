import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { omit } from 'lodash';
import MaterialTable, { MTableAction, MTableToolbar } from 'material-table';
import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useMutation, useQuery } from '@apollo/client';
import AdvertiserModal from './components/AdvertiserModal';
import {
  GET_ALL_ADVERTISERS,
  CREATE_ADVERTISER,
  UPDATE_ADVERTISER,
  REMOVE_ADVERTISER,
} from './queries';
import Breadcrumb from '../../components/Breadcrumb';
import { DeleteIcon } from '../../components/CustomIcons';
import AdvDeleteDialog from './components/AdvDeleteDialog';

const useStyles = makeStyles((theme) => ({
  searchFieldStyle: {
    padding: 12,
    [theme.breakpoints.down(1000)]: {
      order: 1,
      paddingLeft: 0,
      paddingRight: 24,
    },
  },
  toolbarStyle: {
    padding: 0,
    paddingBottom: 20,
    flexWrap: 'wrap',
  },
}));

const Advertisers = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalAdvertiserOpen, setIsModalAdvertiserOpen] = useState(false);
  const [advertiserToUpdate, setAdvertiserToUpdate] = useState(undefined);
  const [isApprovingRemoveAdvertiserShow, setIsApprovingRemoveAdvertiserShow] =
    useState(false);
  const [advertiserToDelete, setAdvertiserToDelete] = useState(undefined);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleRemoveAdvertiserModal = () => {
    setIsApprovingRemoveAdvertiserShow(false);
    setIsCheckboxChecked(false);
  };
  const handleCheckbox = () => setIsCheckboxChecked(!isCheckboxChecked);
  const { data, loading } = useQuery(GET_ALL_ADVERTISERS, {
    fetchPolicy: 'network-only',
  });
  const [createAdvertiser] = useMutation(CREATE_ADVERTISER, {
    refetchQueries: ['getAdvertisers'],
  });
  const [updateAdvertiser] = useMutation(UPDATE_ADVERTISER, {
    refetchQueries: ['getAdvertisers'],
  });
  const [deleteAdvertiser] = useMutation(REMOVE_ADVERTISER, {
    refetchQueries: ['getAdvertisers'],
  });
  const isMarginDisabled = data?.user?.disabledMargin;
  const onAdvertiserCreate = async (advertiserToCreate) => {
    try {
      await createAdvertiser({
        variables: {
          advertiserInput: {
            ...advertiserToCreate,
            name: advertiserToCreate.name.trim(),
          },
        },
      });
      toast.success(t('toasts.advCreated'));
    } catch ({ message }) {
      toast.error(message);
    }
    setIsModalAdvertiserOpen(false);
  };

  const onAdvertiserEditClick = (_, advertiser) => {
    setIsModalAdvertiserOpen(true);
    setAdvertiserToUpdate(advertiser);
  };

  const onAdvertiserDeleteClick = (_, advertiser) => {
    setIsApprovingRemoveAdvertiserShow(true);
    setAdvertiserToDelete(advertiser);
  };

  const onAdvertiserUpdate = async (updatedAdvertiser) => {
    if (!advertiserToUpdate) {
      return;
    }
    const { id } = advertiserToUpdate;
    try {
      await updateAdvertiser({
        variables: {
          id,
          advertiserInput: omit(updatedAdvertiser, [
            'tableData',
            'campaigns',
            'id',
          ]),
        },
      });
      toast.success(t('toasts.advUpdated'));
    } catch ({ message }) {
      toast.error(message);
    }
    setIsModalAdvertiserOpen(false);
    setAdvertiserToUpdate(undefined);
  };

  const onAdvertiserModalClose = () => {
    setAdvertiserToUpdate(undefined);
    setIsModalAdvertiserOpen(false);
  };

  const onAdvertiserDelete = async () => {
    setIsLoading(true);
    const { id } = advertiserToDelete;
    try {
      await deleteAdvertiser({
        variables: { id, removeWithCampaigns: isCheckboxChecked },
      });
      toast.success(t('toasts.advRemoved'));
      setIsLoading(false);
    } catch ({ message }) {
      toast.error(message);
      setIsLoading(false);
    }
    setIsApprovingRemoveAdvertiserShow(false);
    setAdvertiserToDelete(undefined);
    setIsCheckboxChecked(false);
  };

  const columns = [
    {
      title: t('advertiser.name'),
      field: 'name',
    },
    {
      title: t('advertiser.campaigns'),
      render: (rowData) =>
        (rowData.campaigns && rowData.campaigns.length) || '-',
    },
  ];

  return (
    <>
      <Breadcrumb>
        <Typography color="textPrimary">{t('layout.advertisers')}</Typography>
      </Breadcrumb>

      <MaterialTable
        isLoading={loading || isLoading}
        icons={{ ResetSearch: () => <div /> }}
        columns={columns}
        data={data?.advertisers}
        title={t('layout.advertisers')}
        localization={{
          header: {
            actions: t('advertiser.actions'),
          },
          body: {
            emptyDataSourceMessage: t('advertiser.noData'),
          },
          toolbar: {
            searchPlaceholder: t('layout.search'),
          },
          pagination: {
            labelRowsSelect: t('advertiser.rows'),
          },
        }}
        options={{
          actionsColumnIndex: -1,
          searchFieldVariant: 'outlined',
          pageSize: 10,
        }}
        components={{
          // eslint-disable-next-line react/jsx-props-no-spreading
          Container: (props) => <Paper elevation={0} {...props} />,
          Toolbar: (props) => (
            <MTableToolbar
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              classes={{
                searchField: classes.searchFieldStyle,
                root: classes.toolbarStyle,
              }}
            />
          ),
          Action: (props) => {
            const {
              action: { render, ...restActionProps },
            } = props;
            return render ? (
              render(restActionProps)
            ) : (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <MTableAction {...props} />
            );
          },
        }}
        actions={[
          {
            render: (props) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Button {...props} variant="contained" color="primary">
                <AddCircleOutlineIcon style={{ fontSize: 15 }} />
                <Box ml={1} pr={3}>
                  {t('advertiser.button')}
                </Box>
              </Button>
            ),
            icon: '',
            position: 'toolbar',
            onClick: () => setIsModalAdvertiserOpen(true),
          },
          {
            icon: 'edit',
            tooltip: t('tooltips.edit'),
            onClick: onAdvertiserEditClick,
          },
          {
            icon: () => <DeleteIcon color="secondary" />,
            tooltip: t('tooltips.delete'),
            onClick: onAdvertiserDeleteClick,
          },
        ]}
      />
      {isModalAdvertiserOpen && (
        <AdvertiserModal
          isModalAdvertiserOpen={isModalAdvertiserOpen}
          advertiser={advertiserToUpdate}
          isMarginDisabled={isMarginDisabled}
          onAdvertiserCreate={onAdvertiserCreate}
          onAdvertiserUpdate={onAdvertiserUpdate}
          onClose={onAdvertiserModalClose}
        />
      )}
      <AdvDeleteDialog
        open={isApprovingRemoveAdvertiserShow}
        onClose={handleRemoveAdvertiserModal}
        onConfirm={onAdvertiserDelete}
        isChecked={isCheckboxChecked}
        isDisabled={!isCheckboxChecked}
        handleChange={handleCheckbox}
        isLoading={isLoading}
        advertiserToDelete={advertiserToDelete}
      />
    </>
  );
};

export default Advertisers;
