import XLSX from 'xlsx';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { invert } from 'lodash';
import { CampaignIntensity, LinkedinCampaignGoal } from '../../enums/campaign';
import { formatFloatToView } from '../format-helper';

const buildLinkedinAges = (ages) => {
  return ages.reduce((acc, isSelected, index) => {
    let ageString;
    if (!isSelected) {
      return acc;
    }
    switch (index) {
      case 0:
        ageString = '18-24';
        break;
      case 1:
        ageString = '25-34';
        break;
      case 2:
        ageString = '35-54';
        break;
      case 3:
        ageString = '55+';
        break;
      default:
        ageString = 'error';
    }
    acc.push(ageString);
    return acc;
  }, []);
};

const buildGender = (gender) => {
  return gender
    .map((genderType) => (genderType === 'male' ? 'Männlich' : 'Weiblich'))
    .join(', ');
};

const buildDemographics = (gender, ages) => {
  return `${buildGender(gender)} - ${buildLinkedinAges(ages)}`;
};

const getGeoTargetingRaw = (geoTargeting, geoData) => {
  if (
    geoTargeting.geoRegionIds &&
    geoTargeting.geoRegionIds.length &&
    geoData.regionsByIds
  ) {
    const regionArray = [];
    for (const region of geoData.regionsByIds) {
      regionArray.push(region.name);
    }
    return regionArray.join(', ');
  }
  if (
    geoTargeting.geoZipCodes &&
    geoTargeting.geoZipCodes.length &&
    geoData.zipsByIds
  ) {
    const zipArray = [];
    for (const zip of geoData.zipsByIds) {
      zipArray.push(zip.name);
    }
    return zipArray.join(', ');
  }
  return '';
};

const downloadLinkedinExcel = (params) => {
  const { campaign, geoData } = params;
  const formattedPrice = `${formatFloatToView(
    campaign.pricing.TKP.toFixed(2)
  )}€`;
  const campaignGoal = invert(LinkedinCampaignGoal)[campaign.linkedinGoal];
  const impressions = (campaign.budget / campaign.pricing.TKP).toFixed();
  const formattedImpressions = JSON.parse(
    JSON.stringify(Number(impressions).toLocaleString('de-DE'))
  );
  const newExcel = XLSX.utils.book_new();
  newExcel.SheetNames.push(`Campaign-${campaign.name}`);
  const excelData = [
    ['Kategorie', 'Wert'],
    ['Kampagnenname', campaign.name],
    ['Kampagnenziel', campaignGoal],
    ['Kampagnenstart', moment(campaign.startDate).format('DD.MM.YYYY')],
    ['Kampagnenende', moment(campaign.endDate).format('DD.MM.YYYY')],
    ['Kampagnentyp', 'Linkedin'],
    [
      'Demographisches Targeting',
      buildDemographics(campaign.gender, campaign.linkedinAges),
    ],
    ['Landingpage', campaign.landingPage],
    ['LinkedinProfile', campaign.linkedinProfile],
    [
      'Geo Targeting',
      getGeoTargetingRaw(
        {
          geoRegionIds: campaign.geoRegionIds,
          geoZipCodes: campaign.geoZipCodes,
        },
        geoData
      ),
    ],
    [
      'Target Groups',
      campaign.linkedinTargetGroups.map((targetGroup) => {
        return `${targetGroup.name}: ${targetGroup.children
          .map((child) => child.name)
          .join(',')}`;
      }),
    ],
    ['Werbedruck', CampaignIntensity[campaign.intensity]],
    ['Mediabudget', `${campaign.budget}€`],
    ['TKP', formattedPrice],
    ['Impressionen', formattedImpressions],
    ['Kommentar', campaign.comment],
  ];

  const excelSheet = XLSX.utils.aoa_to_sheet(excelData);
  newExcel.Sheets[`Campaign-${campaign.name}`] = excelSheet;
  const excelBuffer = XLSX.write(newExcel, {
    bookType: 'xlsx',
    type: 'binary',
  });
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  const fileType = 'application/octet-stream';
  const data = new Blob([s2ab(excelBuffer)], { type: fileType });

  saveAs(data, `Campaign-${campaign.name}.xlsx`);
};

export default downloadLinkedinExcel;
