import React, { memo, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
      {
        margin: 0,
        '-webkit-appearance': 'none',
      },
  },
}));

const CampaignBudgetInput = (props) => {
  const {
    handleBudgetChange,
    isFormSubmitted,
    budget,
    budgetRecommendation,
    disabled,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const getBudgetError = useCallback(() => {
    if (!isFormSubmitted) {
      return false;
    }
    if (!budget || budget < 2000) {
      return t('createCampaign.errorMin2000');
    }
    if (budget > 100000) {
      return t('createCampaign.errorMax100000');
    }
    if (budgetRecommendation && budget > budgetRecommendation * 10) {
      return t('createCampaign.errorRecommended10x');
    }
    return false;
  }, [budget, budgetRecommendation, isFormSubmitted]);
  // const budgetFormatted = +(new Intl.NumberFormat('de-DE').format(budget || 0));
  return (
    <TextField
      placeholder={t('createCampaign.budgetInputPlaceholder')}
      onWheelCapture={(e) => {
        // disable scroll stepper;
        e.target.blur();
      }}
      type="number"
      className={classes.root}
      error={!!getBudgetError()}
      helperText={getBudgetError()}
      value={budget}
      onChange={handleBudgetChange}
      variant="outlined"
      disabled={disabled}
      fullWidth
    />
  );
};

export default memo(CampaignBudgetInput);
