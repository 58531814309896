import React, { memo, useState, useCallback, useEffect, useRef } from 'react';
import {
  Grid,
  Box,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RegionSelector from './components/RegionSelector';
import ZipSelector from './components/ZipSelector';
import PolygonSelector from './components/PolygonSelector';
import RadiusSelector from './components/RadiusSelector';
import AppRadioButton from '../AppRadioButton';
import { PolygonIcon, RadiusIcon, StatesIcon, ZipIcon } from '../CustomIcons';
import { formatFloatToView } from '../../services/format-helper';
import { CampaignGeoTargetingType, CampaignTypes } from '../../enums/campaign';
import { CategoriesSelector } from './components/CategoriesSelector';

const GeoTargetingWidget = (props) => {
  const { t } = useTranslation();
  const [preset, setPreset] = useState(null);
  const {
    onChange,
    geoTargeting,
    disabled,
    isFormSubmitted,
    pricing,
    onMapLoaded,
    type,
    campaignGoal,
    refsMap,
  } = props;
  const geoTargetingType = geoTargeting
    ? geoTargeting?.geoTargetingType
    : CampaignGeoTargetingType.Land;

  const geoTargetingRef = useRef();

  useEffect(() => {
    refsMap.current['geo-targeting'] = geoTargetingRef;
  }, [refsMap]);

  useEffect(() => {
    if (geoTargeting && !preset) {
      setPreset({ ...geoTargeting });
    }
  }, [geoTargeting]);

  const onGeoTypeChange = useCallback(
    (newType) => {
      if (disabled) {
        return;
      }
      onChange({
        ...geoTargeting,
        geoTargetingType: newType,
      });
    },
    [onChange, disabled]
  );

  const geoTargetingChangeHandler = useCallback(
    (change) => {
      if (!disabled && change && onChange) {
        onChange({
          ...change,
          geoTargetingType,
        });
      }

      if (disabled) {
        onChange({
          ...geoTargeting,
          reach: change.reach || 0,
        });
      }
    },
    [geoTargetingType, onChange]
  );

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">{t('createCampaign.stepThree')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <AppRadioButton
                  labelText={t('geoTargeting.regionTitle')}
                  icon={StatesIcon}
                  name="geoType"
                  checked={geoTargetingType === CampaignGeoTargetingType.Land}
                  value={CampaignGeoTargetingType.Land}
                  onChange={() => {
                    onGeoTypeChange(CampaignGeoTargetingType.Land);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AppRadioButton
                  labelText={`${t('geoTargeting.zipTitle')} ${formatFloatToView(
                    pricing.geoTargeting.zip.toFixed(2)
                  )}€ ${campaignGoal}`}
                  icon={ZipIcon}
                  name="geoType"
                  checked={geoTargetingType === CampaignGeoTargetingType.Zip}
                  value={CampaignGeoTargetingType.Zip}
                  onChange={() => {
                    onGeoTypeChange(CampaignGeoTargetingType.Zip);
                  }}
                  tooltip={t('tooltips.zipTitle')}
                />
              </Grid>
              {type !== CampaignTypes.Social && (
                <Grid item xs={12} md={6}>
                  <AppRadioButton
                    labelText={`${t(
                      'geoTargeting.polygonTitle'
                    )} ${formatFloatToView(
                      pricing.geoTargeting.polygon.toFixed(2)
                    )}€ ${campaignGoal}`}
                    icon={PolygonIcon}
                    name="geoType"
                    checked={
                      geoTargetingType === CampaignGeoTargetingType.Polygon
                    }
                    value={CampaignGeoTargetingType.Polygon}
                    onChange={() => {
                      onGeoTypeChange(CampaignGeoTargetingType.Polygon);
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <AppRadioButton
                  labelText={`${t(
                    'geoTargeting.radiusTitle'
                  )} ${formatFloatToView(
                    pricing.geoTargeting.radius.toFixed(2)
                  )}€ ${campaignGoal}`}
                  icon={RadiusIcon}
                  name="geoType"
                  checked={geoTargetingType === CampaignGeoTargetingType.Radius}
                  value={CampaignGeoTargetingType.Radius}
                  onChange={() => {
                    onGeoTypeChange(CampaignGeoTargetingType.Radius);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AppRadioButton
                  labelText={`${t(
                    'geoTargeting.geoCategoryTitle'
                  )} ${formatFloatToView(
                    pricing.geoTargeting.category.toFixed(2)
                  )}€ ${campaignGoal}`}
                  icon={RadiusIcon} // TODO
                  name="geoType"
                  checked={
                    geoTargetingType === CampaignGeoTargetingType.Categories
                  }
                  value={CampaignGeoTargetingType.Categories}
                  onChange={() => {
                    onGeoTypeChange(CampaignGeoTargetingType.Categories);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Box mt={3} />
            </Grid>
            <Grid ref={geoTargetingRef} container>
              {geoTargetingType === CampaignGeoTargetingType.Land && (
                <RegionSelector
                  disabled={disabled}
                  isFormSubmitted={isFormSubmitted}
                  geoTargetingChangeHandler={geoTargetingChangeHandler}
                  regionsPreset={preset?.geoRegionIds}
                  onMapLoaded={onMapLoaded}
                />
              )}
              {geoTargetingType === CampaignGeoTargetingType.Zip && (
                <ZipSelector
                  disabled={disabled}
                  isFormSubmitted={isFormSubmitted}
                  geoTargetingChangeHandler={geoTargetingChangeHandler}
                  zipsPreset={preset?.geoZipCodes}
                  onMapLoaded={onMapLoaded}
                />
              )}
              {geoTargetingType === CampaignGeoTargetingType.Polygon && (
                <PolygonSelector
                  disabled={disabled}
                  isFormSubmitted={isFormSubmitted}
                  geoTargetingChangeHandler={geoTargetingChangeHandler}
                  polygonPreset={preset?.geoPolygons}
                  descriptionPreset={preset?.geoPolygonText}
                  onMapLoaded={onMapLoaded}
                />
              )}
              {geoTargetingType === CampaignGeoTargetingType.Radius && (
                <RadiusSelector
                  disabled={disabled}
                  isFormSubmitted={isFormSubmitted}
                  geoTargetingChangeHandler={geoTargetingChangeHandler}
                  circlesPreset={preset?.geoRadiuses}
                  descriptionPreset={preset?.geoRadiusText}
                  radiusPreset={preset?.geoRadiusMeters}
                  onMapLoaded={onMapLoaded}
                />
              )}
              {geoTargetingType === CampaignGeoTargetingType.Categories && (
                <CategoriesSelector
                  disabled={disabled}
                  isFormSubmitted={isFormSubmitted}
                  geoTargetingChangeHandler={geoTargetingChangeHandler}
                  categoriesPreset={preset?.geoCategoriesIds}
                  onMapLoaded={onMapLoaded}
                />
              )}
            </Grid>
            <Box mt={2} width="100%" />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(GeoTargetingWidget);
