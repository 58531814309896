import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Tabs, Tab } from '@material-ui/core';

import ZipCodesSettings from './components/ZipCodesSettings';
import { AppCase, AppSwitch } from '../../components/AppSwitchCase';
import RegionSettings from './components/RegionsSettings';

const AdminSettings = () => {
  const { t } = useTranslation();
  const [settingTab, setSettingTab] = useState(0);
  const handleTabChange = (_, newValue) => {
    setSettingTab(newValue);
  };
  return (
    <Box mt={1}>
      <Typography variant="h6">App {t('settings.settings')}</Typography>
      <Tabs
        value={settingTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Zip codes settings" />
        <Tab label="Region settings" />
      </Tabs>
      <AppSwitch test={settingTab}>
        <AppCase value={0}>
          <ZipCodesSettings />
        </AppCase>
        <AppCase value={1}>
          <RegionSettings />
        </AppCase>
      </AppSwitch>
    </Box>
  );
};

export default AdminSettings;
