import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { redColor } from '../../theme';
import {
  isSupportedSize,
  isSupportedType,
  getSizeInMb,
} from '../../services/upload-helper';

const useStyles = makeStyles((theme) => ({
  uploadItemContainerError: {
    backgroundColor: redColor,
    borderRadius: 5,
  },
}));
const UploadItem = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { file, onRemoveItem, index, supportedTypes, isVideo, uploadProgress } =
    props;
  const { name, size, type } = file;
  const fileSizeMb = getSizeInMb(size);
  const isSizeSupported = isSupportedSize(size);
  const isTypeSupported = isSupportedType(supportedTypes, type);
  const isValid = isSizeSupported && isTypeSupported;
  const containerClassName = !isValid ? classes.uploadItemContainerError : '';

  return (
    <Grid
      container
      component={Box}
      pb={2}
      pl={1}
      mb={1}
      className={containerClassName}
    >
      <Grid container justify="space-between" alignItems="center">
        <Typography variant="body1">
          {name} ({fileSizeMb} MB)
        </Typography>
        <IconButton
          onClick={() => {
            onRemoveItem(index);
          }}
        >
          <DeleteIcon />
        </IconButton>
        {isVideo && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </Box>
        )}
      </Grid>
      {!isSizeSupported && (
        <Typography variant="caption">{t('uploadPage.maxSize')}</Typography>
      )}
      {!isTypeSupported && !isVideo && (
        <Typography variant="caption">
          {t('uploadPage.fileTypeIsNotAllowed')}
        </Typography>
      )}
      {!isTypeSupported && isVideo && (
        <Typography variant="caption">
          {t('uploadPage.videoFileTypeIsNotAllowed')}
        </Typography>
      )}
    </Grid>
  );
};

export default UploadItem;
