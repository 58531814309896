import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

const CustomCategories = (props) => {
  const { onFormSubmit } = props;
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const handleFormSubmit = (data, e) => {
    onFormSubmit(data);
    e.target.reset();
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} style={{ marginLeft: 5 }}>
      <TextField
        fullWidth
        name="uncategorized"
        placeholder={t('createCampaign.customGroupsPlaceholder')}
        inputRef={register}
      />
    </form>
  );
};

export default CustomCategories;
