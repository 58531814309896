import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import { Close, ExpandLess, ChevronLeft } from '@material-ui/icons';

const WebsitesGeneratorListSelectedItem = (props) => {
  const {
    category,
    selected,
    total,
    websites,
    onSelectCategory,
    onSelectCategoryWebsite,
  } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { t } = useTranslation();
  const handleCollapsed = () => setIsCollapsed(!isCollapsed);

  return (
    <>
      <ListItem divider button component="li" onClick={handleCollapsed}>
        <ListItemText
          primary={
            <>
              {category || t('validationErrors.uncategorized')}
              {selected && total && (
                <>
                  {category && ` (${selected}/${total})`}
                  {!category && ` (${selected})`}
                </>
              )}
            </>
          }
        />
        <ListItemSecondaryAction>
          <IconButton onClick={() => onSelectCategory({ category })}>
            <Close />
          </IconButton>

          <IconButton onClick={handleCollapsed}>
            {isCollapsed ? <ExpandLess /> : <ChevronLeft />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      {isCollapsed && (
        <List dense disablePadding>
          {websites.map((website) => (
            <ListItem divider key={website.name}>
              <ListItemText secondary={website.name} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => onSelectCategoryWebsite({ category, website })}
                >
                  <Close />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default WebsitesGeneratorListSelectedItem;
