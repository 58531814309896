import React, { useEffect, useState } from 'react';
import { Search } from '@material-ui/icons';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import config from '../../../../configuration';

const RegionSearchInput = (props) => {
  const { onCenterChanged, disabled } = props;
  const [regionToSearch, setRegionToSearch] = useState('');

  const handleRegionSearch = async () => {
    try {
      if (!regionToSearch) {
        return;
      }
      const url = config.regionSearchUrl + regionToSearch;
      const response = await fetch(url);
      const geoData = await response.json();
      if (!geoData.length) {
        return;
      }
      const [firstResult] = geoData;
      const { lat, lon: lng } = firstResult;
      const center = {
        lng: parseFloat(lng),
        lat: parseFloat(lat),
      };
      onCenterChanged(center);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleRegionToSearchChange = (e) => {
    setRegionToSearch(e.target.value);
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter') {
        handleRegionSearch();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [regionToSearch]);
  return (
    <TextField
      fullWidth
      disabled={disabled}
      value={regionToSearch}
      variant="outlined"
      onChange={handleRegionToSearchChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton disabled={disabled} onClick={handleRegionSearch}>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default RegionSearchInput;
