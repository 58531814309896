import gql from 'graphql-tag';

export const GET_GEO_CATEGORIES = gql`
  query getGeoCategories {
    geoCategories
  }
`;

export const GET_POI_CATEGORIES = gql`
  query getPoiCategories {
    poiCategories
  }
`;

export const GET_POI_SUBCATEGORIES = gql`
  query getPoiSubCategories($category: String) {
    poiSubCategories
  }
`;

export const GET_POIS = gql`
  query getPoiSubCategories($category: String, $subcategory: String) {
    poiSubCategories
  }
`;
