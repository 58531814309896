import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';

import GET_CAMPAIGN_PAGE_DATA from './queries';
import Breadcrumb from '../../../../components/Breadcrumb';
import SpinnerBackdrop from '../../../../components/BackdropSpinner';
import CampaignInfoBoxContainer from '../../../../components/CampaignInfoBoxContainer';
import LinkedinCalculationInfoBox from './components/LinkedinCalculationInfoBox';
import CampaignStep1 from './components/CampaignStep1';
import CampaignStep2 from './components/CampaignStep2';
import CampaignStep3 from './components/CampaignStep3';
import CampaignStep4 from './components/CampaignStep4';
import CampaignActions from './components/CampaignActions';
import { CampaignStatus, CreativeStatus } from '../../../../enums/campaign';

const ViewLinkedinCampaign = () => {
  const { t } = useTranslation();
  const { campaignId } = useParams();

  const { data, loading } = useQuery(GET_CAMPAIGN_PAGE_DATA, {
    variables: { id: campaignId },
    fetchPolicy: 'network-only',
  });
  if (!data) return <SpinnerBackdrop open={loading} />;
  const { campaign, advertisers, user } = data;
  const {
    advertiserId,
    name,
    linkedinGoal,
    startDate,
    endDate,
    linkedinTargetGroups,
    linkedinTargetCompanyGroups,
    linkedinAges,
    gender,
    landingPage,
    idkLandingPageYet,
    linkedinProfile,
    idkLinkedinProfileYet,
    geoTargetingType,
    geoZipCodes,
    geoRegionIds,
    intensity,
    comment,
    budget,
    budgetRecommendation,
    estimatedReach,
    status,
    pricing,
    creativeStatus: campaignCreativeStatus,
  } = campaign;
  const advertiser = advertisers.find(({ id }) => id === advertiserId);
  const campaignStatus = CampaignStatus[status];
  const creativeStatus = CreativeStatus[campaignCreativeStatus];

  const handlePDFGeneration = () => {
    localStorage.setItem(
      'campaign-for-print',
      JSON.stringify({ campaign, advertisers })
    );
    window.open('/campaign/linkedin-view-pdf-print', '_blank');
  };

  const onPDFGenerationWithoutAdvertiserMargins = () => {
    localStorage.setItem(
      'campaign-for-print',
      JSON.stringify({ campaign, advertisers, isAgencyPrice: true })
    );
    window.open('/campaign/linkedin-view-pdf-print', '_blank');
  };

  const handleExcelGeneration = () => {
    const params = { campaign };
    localStorage.setItem('campaign-for-excel', JSON.stringify(params));
    window.open('/campaign/linkedin-excel-generator', '_blank');
  };

  return (
    <Box mt={2} ml={2}>
      <Breadcrumb>
        <Link to="/campaigns" component={RouterLink}>
          {t('campaigns.campaigns')}
        </Link>
        <Typography color="textPrimary">{name}</Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={loading} />
      <Box mt={2} />
      <Grid container wrap="nowrap" direction="row">
        <Grid
          style={{ maxWidth: '280px' }}
          item
          alignItems="flex-start"
          container
        >
          <CampaignInfoBoxContainer>
            {/* campaign calculations */}
            <Grid item>
              <LinkedinCalculationInfoBox
                estimatedReach={estimatedReach}
                budgetRecommendation={budgetRecommendation}
                pricing={pricing}
                budget={budget}
                creativeStatus={creativeStatus}
                campaignStatus={campaignStatus}
              />
            </Grid>
          </CampaignInfoBoxContainer>
        </Grid>
        <Grid style={{ maxWidth: '1100px' }} item xs>
          <form>
            <CampaignStep1
              advertiser={advertiser || ''}
              name={name}
              linkedinGoal={linkedinGoal}
              startDate={startDate}
              endDate={endDate}
            />
            <CampaignStep2
              gender={gender || []}
              linkedinAges={linkedinAges || []}
              landingPage={landingPage}
              idkLandingPageYet={idkLandingPageYet || false}
              linkedinProfile={linkedinProfile}
              idkLinkedinProfileYet={idkLinkedinProfileYet || false}
              linkedinTargetGroups={linkedinTargetGroups}
              linkedinTargetCompanyGroups={linkedinTargetCompanyGroups}
            />
            <CampaignStep3
              geoTargetingType={geoTargetingType}
              geoZipCodes={geoZipCodes || []}
              geoRegionIds={geoRegionIds || []}
            />
            <CampaignStep4
              intensity={intensity}
              comment={comment}
              linkedinGoal={linkedinGoal || 0}
              budget={budget}
            />
            <CampaignActions
              handlePDFGeneration={handlePDFGeneration}
              handleExcelGeneration={handleExcelGeneration}
              onPDFGenerationWithoutAdvertiserMargins={
                onPDFGenerationWithoutAdvertiserMargins
              }
            />
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewLinkedinCampaign;
