import React from 'react';
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Tooltip,
} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import { Info } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      backgroundColor: theme.palette.basic.lightGreyOpacity,
    },
    container: {
      position: 'relative',
    },
    tooltip: {
      position: 'absolute',
      top: 5,
      left: 310,
      cursor: 'pointer',
    },
    groupButton: {
      width: '100%',
    },
    popper: {
      zIndex: 1,
    },
  };
});

const CampaignActions = (props) => {
  const {
    isFormValid,
    isFormSubmitted,
    onSubmit,
    campaignState,
    handleCampaignState,
    disabled,
  } = props;
  const { t } = useTranslation();

  const history = useNavigate();
  const classes = useStyles();
  const { isDraft } = campaignState;

  return (
    <Paper className={classes.paper} elevation={0}>
      <Box p={2}>
        {!disabled && (
          <FormGroup className={classes.container}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDraft}
                  onChange={(event) =>
                    handleCampaignState({ isDraft: event.target.checked })
                  }
                />
              }
              label={t('createCampaign.createCampaignAsDraft')}
            />
            <Tooltip
              title={t('tooltips.createCampaignAsDraft')}
              className={classes.tooltip}
            >
              <Info fontSize="inherit" color="secondary" />
            </Tooltip>
          </FormGroup>
        )}
        <Box mt={2} />
        <Grid container spacing={6} justify="space-between">
          {!disabled && (
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={onSubmit}
                color={
                  isFormSubmitted && !isFormValid ? 'secondary' : 'primary'
                }
                variant={
                  isFormSubmitted && !isFormValid ? 'outlined' : 'contained'
                }
              >
                {isDraft
                  ? t('createCampaign.saveAsDraftButton')
                  : t('createCampaign.save')}
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Button
              color="secondary"
              onClick={() => history(-1)}
              fullWidth
              variant="outlined"
            >
              {t('common.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default CampaignActions;
