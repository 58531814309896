import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

const LinkedinProfile = (props) => {
  const {
    isFormSubmitted,
    linkedinProfile,
    idkLinkedinProfileYet,
    handleLinkedinProfileChange,
    handleIdkLinkedinProfileChange,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t('createCampaign.linkedinProfile')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          error={isFormSubmitted && !linkedinProfile && !idkLinkedinProfileYet}
          value={linkedinProfile || ''}
          onChange={handleLinkedinProfileChange}
          placeholder={t('createCampaign.linkedinProfile')}
          variant="outlined"
          fullWidth
          required={!idkLinkedinProfileYet}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={idkLinkedinProfileYet}
              onChange={handleIdkLinkedinProfileChange}
            />
          }
          label={t('createCampaign.idkLinkedinProfileYet')}
        />
      </Grid>
    </>
  );
};

export default memo(LinkedinProfile);
