import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { omit, debounce } from 'lodash';
import moment from 'moment';
import MaterialTable, { MTableAction, MTableToolbar } from 'material-table';
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  FiberManualRecord as FiberManualRecordIcon,
  RestoreFromTrash,
  Search,
} from '@material-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  GET_ALL_USERS,
  CREATE_USER,
  UPDATE_USER,
  GET_LOGO_UPLOAD_URL,
  REMOVE_USER,
} from './queries';
import UserModal from './components/UserModal';
import Breadcrumb from '../../components/Breadcrumb';
import { uploadToS3 } from '../../services/upload-helper';
import { DeleteIcon } from '../../components/CustomIcons';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { greenColor, greyColor, orangeColor, redColor } from '../../theme';
import { useQueryParams } from '../../common/hooks';
import { validateUsersQueryParams } from '../../services/query-params-validators';
import updateQueryParam from '../../services/updateQueryParam';

const useStyles = makeStyles((theme) => ({
  searchFieldStyle: {
    padding: 12,
    [theme.breakpoints.down(1042)]: {
      order: 1,
      paddingLeft: 0,
      paddingRight: 32,
    },
  },
  toolbarStyle: {
    padding: 0,
    paddingBottom: 20,
    flexWrap: 'wrap',
  },
  fullToolUser: {
    color: greenColor,
  },
  testPeriodUser: {
    color: orangeColor,
  },
  expiredUser: {
    color: redColor,
  },
}));

const Users = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search: locationSearch } = useLocation();
  const queryParams = useQueryParams(locationSearch, validateUsersQueryParams);
  const { data, loading } = useQuery(GET_ALL_USERS, {
    fetchPolicy: 'network-only',
    variables: {
      usersFilter: {
        search: queryParams.get('search'),
        archive: Boolean(queryParams.get('archive')),
        page_size: Number(queryParams.get('page_size')),
        page: Number(queryParams.get('page')),
      },
    },
  });
  const { refetch: getLogoUploadRefetch } = useQuery(GET_LOGO_UPLOAD_URL, {
    skip: true,
  });
  const [createUser] = useMutation(CREATE_USER, {
    refetchQueries: ['getUsers'],
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: ['getUsers'],
  });
  const [deleteUser] = useMutation(REMOVE_USER, {
    refetchQueries: ['getUsers'],
  });
  const [isModalUserOpen, setIsModalUserOpen] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(undefined);
  const [isApprovingRemoveUserShow, setIsApprovingRemoveUserShow] =
    useState(false);
  const [userToDelete, setUserToDelete] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleRemoveUserModal = () => {
    setIsApprovingRemoveUserShow(false);
    setIsCheckboxChecked(false);
  };
  const handleCheckbox = () => setIsCheckboxChecked(!isCheckboxChecked);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((search) => {
      const newParams = updateQueryParam(
        updateQueryParam(queryParams, 'search', search),
        'page',
        0
      );
      navigate(`?${newParams.toString()}`);
    }, 500),
    [queryParams]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncePage = useCallback(
    debounce((pageNumber, pageSize) => {
      const newParams = updateQueryParam(
        updateQueryParam(queryParams, 'page', pageNumber),
        'page_size',
        pageSize
      );
      navigate(`?${newParams.toString()}`);
    }, 500),
    [queryParams]
  );

  const columns = [
    {
      title: t('users.email'),
      field: 'email',
      render: (rowData) => {
        return (
          <Typography variant="body1" component="span">
            {rowData.email}
          </Typography>
        );
      },
    },
    {
      title: t('users.login'),
      field: 'username',
      render: (rowData) => {
        return (
          <Typography variant="body1" component="span">
            {rowData.username}
          </Typography>
        );
      },
    },
    {
      title: t('users.invoice'),
      field: 'invoiceAddress',
      render: (rowData) => {
        return (
          <Typography variant="body1" component="span">
            {rowData.invoiceAddress}
          </Typography>
        );
      },
    },
    {
      title: t('users.status'),
      field: 'testPeriodEnd',
      render: (rowData) => {
        if (!rowData.testPeriodEnd) {
          return (
            <Box display="flex">
              <FiberManualRecordIcon
                fontSize="small"
                className={classes.fullToolUser}
              />
              <Typography variant="body1" component="span">
                &nbsp;{t('users.fullToolUser')}
              </Typography>
            </Box>
          );
        }
        const isUserExpired = moment(rowData.testPeriodEnd).isBefore(
          moment().toDate()
        );
        if (isUserExpired) {
          return (
            <Box display="flex">
              <FiberManualRecordIcon
                fontSize="small"
                className={classes.expiredUser}
              />
              <Typography variant="body1" component="span">
                &nbsp;{t('users.expiredUser')}
              </Typography>
            </Box>
          );
        }
        return (
          <Box display="flex">
            <FiberManualRecordIcon
              fontSize="small"
              className={classes.testPeriodUser}
            />
            <Typography variant="body1" component="span">
              &nbsp;{t('users.testPeriodUser')}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const onUserCreate = async (userToCreate) => {
    try {
      const { logoFile } = userToCreate;
      if (logoFile) {
        const { name } = logoFile;
        const { data: logoData } = await getLogoUploadRefetch({ name });
        const { filePath, uploadUrl, fileUrl } = logoData.logoUploadInfo;
        await uploadToS3({
          filePath,
          uploadUrl,
          fileToUpload: logoFile,
        });
        userToCreate.logoUrl = fileUrl;
      }
      let object = omit(userToCreate, ['logoFile']);

      if (!object.staticTKP || !object.staticCPC) {
        object = omit(object, ['staticTKP', 'staticCPC']);
      }
      if (object.staticTKP || object.staticCPC) {
        object.staticPrice = {
          TKP: Number(object.staticTKP),
          CPC: Number(object.staticCPC),
        };
        object = omit(object, ['staticTKP', 'staticCPC']);
      }

      await createUser({
        variables: {
          userInput: {
            ...object,
            username: object?.username.trim(),
            invoiceAddress: object?.invoiceAddress.trim(),
            password: object?.password.trim(),
          },
        },
      });
      toast.success(t('toasts.userCreated'));
    } catch ({ message }) {
      toast.error(message);
    }
    setIsModalUserOpen(false);
  };

  const onUserEditClick = (_, user) => {
    setIsModalUserOpen(true);
    setUserToUpdate(user);
  };

  const onUserDeleteClick = (_, user) => {
    setIsApprovingRemoveUserShow(true);
    setUserToDelete(user);
  };

  const onUserRestore = async (_, user) => {
    setIsLoading(true);
    const { id } = user;

    try {
      const newUser = omit({ ...user, removed: false }, [
        'id',
        'tableData',
        'role',
        'logoFile',
      ]);
      await updateUser({
        variables: {
          id,
          userInput: newUser,
        },
      });
      toast.success(t('toasts.userRestored'));
      setIsLoading(false);
    } catch ({ message }) {
      toast.error(message);
      setIsLoading(false);
    }
  };

  const onUserUpdate = async (userUpdate) => {
    if (!userUpdate) {
      return;
    }
    const { id } = userToUpdate;
    const { logoFile } = userUpdate;
    if (logoFile) {
      const { name } = logoFile;
      const { data: logoData } = await getLogoUploadRefetch({ name });
      const { filePath, uploadUrl, fileUrl } = logoData.logoUploadInfo;
      await uploadToS3({
        filePath,
        uploadUrl,
        fileToUpload: logoFile,
      });
      userUpdate.logoUrl = fileUrl;
    }
    let object = omit(userUpdate, ['id', 'tableData', 'role', 'logoFile']);
    if (!object.password) {
      object = omit(object, 'password');
    }
    if (!object.staticTKP || !object.staticCPC) {
      object = omit(object, ['staticTKP', 'staticCPC']);
    }
    if (object.staticTKP || object.staticCPC) {
      object.staticPrice = {
        TKP: Number(object.staticTKP),
        CPC: Number(object.staticCPC),
      };
      object = omit(object, ['staticTKP', 'staticCPC']);
    }
    try {
      await updateUser({
        variables: {
          id,
          userInput: object,
        },
      });
      toast.success(t('toasts.userUpdated'));
    } catch ({ message }) {
      toast.error(message);
    }
    setIsModalUserOpen(false);
    setUserToUpdate(undefined);
  };

  const onUserDelete = async () => {
    setIsLoading(true);
    const { id } = userToDelete;
    try {
      await deleteUser({ variables: { id } });
      toast.success(t('toasts.userRemoved'));
      setIsLoading(false);
    } catch ({ message }) {
      toast.error(message);
      setIsLoading(false);
    }
    setIsApprovingRemoveUserShow(false);
    setUserToDelete(undefined);
    setIsCheckboxChecked(false);
  };

  const onUserModalClose = () => {
    setUserToUpdate(undefined);
    setIsModalUserOpen(false);
  };

  if (loading) {
    return <CircularProgress color="primary" size={20} />;
  }

  return (
    <>
      <Breadcrumb>
        <Typography color="textPrimary">{t('users.title')}</Typography>
      </Breadcrumb>
      <MaterialTable
        icons={{ ResetSearch: () => <div /> }}
        columns={columns}
        data={data?.users?.users}
        title={
          queryParams.get('archive') ? t('users.archive') : t('users.title')
        }
        onSearchChange={debounceSearch}
        totalCount={data?.users?.count || 0}
        page={Number(queryParams.get('page')) || 0}
        localization={{
          header: {
            actions: t('users.actions'),
          },
          body: {
            emptyDataSourceMessage: t('users.noData'),
          },
          toolbar: {
            searchPlaceholder: t('layout.search'),
          },
          pagination: {
            labelRowsSelect: t('users.rows'),
          },
        }}
        options={{
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
          searchFieldVariant: 'outlined',
          pageSize: Number(queryParams.get('page_size')) || 10,
          search: false,
        }}
        onChangePage={debouncePage}
        components={{
          // eslint-disable-next-line react/jsx-props-no-spreading
          Container: (props) => <Paper elevation={0} {...props} />,
          Toolbar: (props) => (
            <MTableToolbar
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              classes={{
                searchField: classes.searchFieldStyle,
                root: classes.toolbarStyle,
              }}
            />
          ),
          Action: (props) => {
            const { action } = props;
            const { render, ...restActionProps } = action;
            return render ? (
              render(restActionProps)
            ) : (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <MTableAction {...props} />
            );
          },
        }}
        actions={[
          {
            render: (props) => (
              <TextField
                {...props}
                defaultValue={queryParams.get('search')}
                variant="outlined"
                className={classes.searchFieldStyle}
                style={{ paddingTop: 0, paddingBottom: 0 }}
                placeholder={t('layout.search')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search
                        style={{ color: greyColor, fontSize: '1.25rem' }}
                      />
                    </InputAdornment>
                  ),
                }}
                onInput={(e) => debounceSearch(e.target.value)}
              />
            ),
            icon: '',
            position: 'toolbar',
          },
          {
            render: (props) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Button {...props} variant="contained" color="primary">
                <AddCircleOutlineIcon style={{ fontSize: 15 }} />
                <Box ml={1}>{t('users.button')}</Box>
              </Button>
            ),
            icon: '',
            onClick: () => setIsModalUserOpen(true),
            position: 'toolbar',
          },
          {
            icon: 'edit',
            tooltip: t('tooltips.edit'),
            onClick: onUserEditClick,
          },
          !queryParams.get('archive') && {
            icon: () => <DeleteIcon color="secondary" />,
            tooltip: t('tooltips.delete'),
            onClick: onUserDeleteClick,
          },
          queryParams.get('archive') && {
            icon: () => <RestoreFromTrash color="secondary" />,
            tooltip: t('tooltips.restore'),
            onClick: onUserRestore,
          },
        ]}
      />
      {!queryParams.get('archive') && (
        <Button
          onClick={() => {
            const newParams = updateQueryParam(queryParams, 'archive', true);
            navigate(`?${newParams.toString()}`);
          }}
          variant="text"
          color="primary"
        >
          {t('users.archive')}
        </Button>
      )}
      {isModalUserOpen && (
        <UserModal
          user={userToUpdate}
          open={isModalUserOpen}
          onClose={onUserModalClose}
          onUserCreate={onUserCreate}
          onUserUpdate={onUserUpdate}
        />
      )}
      <ConfirmationDialog
        open={isApprovingRemoveUserShow}
        onClose={handleRemoveUserModal}
        title={t('deleteUser.title')}
        onConfirm={onUserDelete}
        isChecked={isCheckboxChecked}
        isDisabled={!isCheckboxChecked}
        handleChange={handleCheckbox}
        isLoading={isLoading}
        checkboxText={t('deleteUser.checkbox')}
      />
    </>
  );
};

export default Users;
