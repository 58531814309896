import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Button,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';

import IntensityElement from './intensityElement';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
  },
  button: {
    textAlign: 'center',
    position: 'relative',
  },
  item: {
    textAlign: 'center',
    color: theme.palette.basic.grey,
  },
  tooltip: {
    position: 'absolute',
    top: 5,
    right: '-3px',
  },
}));

const Intensity = ({ disabled, intensity, setIntensity }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleImproveIntensity = () => {
    if (disabled) return;
    const value = intensity === 3 ? 1 : intensity + 1;
    setIntensity(value);
  };
  return (
    <div className={classes.root}>
      <Typography variant="h5">{t('intensity.title')}</Typography>

      <Grid container wrap="nowrap" alignItems="flex-end">
        <Grid item className={classes.item}>
          <Button
            onClick={() => setIntensity(1)}
            className={classes.button}
            disableRipple
          >
            {t('intensity.low')}
            <Tooltip
              title={t('tooltips.intensityLow')}
              className={classes.tooltip}
            >
              <Info fontSize="inherit" color="secondary" />
            </Tooltip>
          </Button>
        </Grid>

        <Grid item className={classes.item} xs>
          <Button
            onClick={() => setIntensity(2)}
            className={classes.button}
            disableRipple
          >
            {t('intensity.medium')}
            <Tooltip
              title={t('tooltips.intensityMedium')}
              className={classes.tooltip}
            >
              <Info fontSize="inherit" color="secondary" />
            </Tooltip>
          </Button>

          <IntensityElement
            intensity={intensity}
            onClick={handleImproveIntensity}
          />
        </Grid>

        <Grid item className={classes.item}>
          <Button
            onClick={() => setIntensity(3)}
            className={classes.button}
            disableRipple
          >
            {t('intensity.high')}
            <Tooltip
              title={t('tooltips.intensityHigh')}
              className={classes.tooltip}
            >
              <Info fontSize="inherit" color="secondary" />
            </Tooltip>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Intensity;
