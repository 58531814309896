import React from 'react';
import { List } from '@material-ui/core';

import WebsitesGeneratorListItem from './components/WebsitesGeneratorListItem';

const WebsitesGeneratorList = (props) => {
  const { categories = [], onSelectCategory, onSelectCategoryWebsite } = props;
  return (
    <List disablePadding>
      {categories.map((category) => (
        <WebsitesGeneratorListItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...category}
          onSelectCategory={onSelectCategory}
          onSelectCategoryWebsite={onSelectCategoryWebsite}
          key={category.id}
        />
      ))}
    </List>
  );
};

export default WebsitesGeneratorList;
