import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import YoutubeLinkItem from '../YoutubeLinkItem';

const YoutubeLinkWidget = (props) => {
  const { handleYoutubeLinksUpdate, youtubeLinks, emptyYoutubeLink } = props;
  const { t } = useTranslation();

  const addLink = () => {
    youtubeLinks.push({ ...emptyYoutubeLink });
    handleYoutubeLinksUpdate([...youtubeLinks]);
  };
  const removeLink = (index) => {
    youtubeLinks.splice(index, 1);
    handleYoutubeLinksUpdate([...youtubeLinks]);
  };

  const onValueChange = (value, index) => {
    youtubeLinks[index].value = value;
    handleYoutubeLinksUpdate([...youtubeLinks]);
  };

  return (
    <Grid container>
      <Grid item container alignItems="center" justify="center">
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {t('uploadPage.youtubeLinks')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2} />
      </Grid>
      <Grid item container alignItems="center" justify="center">
        {youtubeLinks.map(({ value }, index) => (
          <Grid container alignItems="center" justify="center" key={value}>
            <YoutubeLinkItem
              value={value}
              index={index}
              length={youtubeLinks.length}
              addLink={addLink}
              removeLink={removeLink}
              onValueChange={onValueChange}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Box mt={2} />
      </Grid>
    </Grid>
  );
};

export default YoutubeLinkWidget;
