import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Link } from '@material-ui/core';
import { AppCase, AppSwitch } from '../../../../../../components/AppSwitchCase';
import { CampaignSocialAdvertiserMaterialTypes } from '../../../../../../enums/campaign';

const InstagramViewNotes = (props) => {
  const {
    socialAdvertiserMaterialType = CampaignSocialAdvertiserMaterialTypes.FeedStatic,
  } = props;
  const { t } = useTranslation();

  const feedStaticNotes = t('socials.instagram.feed.static.notes', {
    returnObjects: true,
  });
  const feedStaticNotesLink = t('socials.instagram.feed.static.notesLinks', {
    returnObjects: true,
  });
  const storyStaticNotes = t('socials.instagram.story.static.notes', {
    returnObjects: true,
  });
  const storyStaticNotesLink = t('socials.instagram.story.static.notesLinks', {
    returnObjects: true,
  });

  const feedVideoNotes = t('socials.instagram.feed.video.notes', {
    returnObjects: true,
  });
  const feedVideoNotesLink = t('socials.instagram.feed.video.notesLinks', {
    returnObjects: true,
  });
  const storyVideoNotes = t('socials.instagram.story.video.notes', {
    returnObjects: true,
  });
  const storyVideoNotesLink = t('socials.instagram.story.video.notesLinks', {
    returnObjects: true,
  });

  return (
    <AppSwitch test={socialAdvertiserMaterialType}>
      <AppCase value={CampaignSocialAdvertiserMaterialTypes.FeedStatic}>
        {feedStaticNotes.map((item) => (
          <Typography gutterBottom variant="body1" key={item}>
            {item}
          </Typography>
        ))}
        {feedStaticNotesLink.map(({ text, link, info }) => (
          <Typography gutterBottom variant="body1" key={link}>
            {text}
            {link && (
              <Link href={link} target="_blank">
                {link}
              </Link>
            )}
            {info && <>{info}</>}
          </Typography>
        ))}
      </AppCase>
      <AppCase value={CampaignSocialAdvertiserMaterialTypes.FeedVideo}>
        {feedVideoNotes.map((item) => (
          <Typography gutterBottom variant="body1" key={item}>
            {item}
          </Typography>
        ))}
        {feedVideoNotesLink.map(({ text, link, info }) => (
          <Typography gutterBottom variant="body1" key={link}>
            {text}
            {link && (
              <Link href={link} target="_blank">
                {link}
              </Link>
            )}
            {info && <>{info}</>}
          </Typography>
        ))}
      </AppCase>
      <AppCase value={CampaignSocialAdvertiserMaterialTypes.StoryStatic}>
        {storyStaticNotes.map((item) => (
          <Typography gutterBottom variant="body1" key={item}>
            {item}
          </Typography>
        ))}
        {storyStaticNotesLink.map(({ text, link, info }) => (
          <Typography gutterBottom variant="body1" key={link}>
            {text}
            {link && (
              <Link href={link} target="_blank">
                {link}
              </Link>
            )}
            {info && <>{info}</>}
          </Typography>
        ))}
      </AppCase>
      <AppCase value={CampaignSocialAdvertiserMaterialTypes.StoryVideo}>
        {storyVideoNotes.map((item) => (
          <Typography gutterBottom variant="body1" key={item}>
            {item}
          </Typography>
        ))}
        {storyVideoNotesLink.map(({ text, link, info }) => (
          <Typography gutterBottom variant="body1" key={link}>
            {text}
            {link && (
              <Link href={link} target="_blank">
                {link}
              </Link>
            )}
            {info && <>{info}</>}
          </Typography>
        ))}
      </AppCase>
    </AppSwitch>
  );
};

export default InstagramViewNotes;
