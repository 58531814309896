import React, { memo } from 'react';
import { Grid, IconButton, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const YoutubeLinkItem = memo((props) => {
  const { value, index, length, addLink, removeLink, onValueChange } = props;
  const isFirst = index === 0;
  const isLast = index + 1 === length;

  return (
    <Grid item style={{ position: 'relative' }}>
      {!isFirst && (
        <IconButton
          style={{ position: 'absolute', left: '-50px' }}
          focusRipple={false}
          onClick={() => removeLink(index)}
        >
          <RemoveIcon />
        </IconButton>
      )}
      <TextField
        value={value}
        onChange={(e) => onValueChange(e.target.value, index)}
        placeholder="YouTube Link"
      />
      {isLast && (
        <IconButton
          style={{ position: 'absolute' }}
          disabled={!value}
          focusRipple={false}
          onClick={addLink}
        >
          <AddIcon />
        </IconButton>
      )}
    </Grid>
  );
});

export default YoutubeLinkItem;
