import React, { useState } from 'react';
import { Box, Grid, Button, Paper, Typography, Link } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';

import UploadItem from '../../../../../../components/UploadItem';
import UploadArea from '../../../../../../components/UploadArea';
import { CREATE_UPLOAD_ATTACHMENT } from '../../../../../../common/queries';
import SupportedUploadFilesTypes from '../../../../../../enums/SupportedUploadFilesTypes';
import {
  isValidFileToUpload,
  uploadWithS3,
} from '../../../../../../services/upload-helper';

const LinkedinBannerUpload = () => {
  const { campaignId } = useParams();
  const history = useNavigate();
  const { t } = useTranslation();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [createAttachment] = useMutation(CREATE_UPLOAD_ATTACHMENT);

  const onFilesSelected = (files) => {
    setFilesToUpload(files);
  };

  const onRemoveItem = (index) => {
    const newFiles = [...filesToUpload];
    newFiles.splice(index, 1);
    setFilesToUpload(newFiles);
  };

  const goToList = () => {
    history('/campaigns');
  };

  const handleUpload = async () => {
    try {
      setIsUploading(true);
      await uploadWithS3({
        campaignId,
        files: filesToUpload,
        uploader: createAttachment,
      });
      setIsUploading(false);
      toast.success(t('toasts.bannerUpload'));
      goToList();
    } catch ({ message }) {
      setIsUploading(false);
      toast.error(message);
    }
  };

  const areFilesValid = !filesToUpload?.find(
    ({ size, type }) =>
      !isValidFileToUpload({
        size,
        type,
        supportedTypes: SupportedUploadFilesTypes.File,
      })
  );

  return (
    <Paper elevation={4}>
      <Box mx="auto" mt={3} p={4} maxWidth={800}>
        <UploadArea
          onFilesSelected={onFilesSelected}
          filesToUpload={filesToUpload}
        />
        <Box mt={3} />
        <Grid container item justify="center" align="center">
          <Typography variant="body1">
            {t('uploadPage.listOfPossibleFormats')}&nbsp;
          </Typography>
          <Link
            variant="body1"
            target="_blank"
            href="https://docs.google.com/spreadsheets/d/1aYuZuhfIdZq8lFChDz3xYyFm3lL_zB3_0nNuekdtMrQ/edit"
          >
            AdSpecs
          </Link>
        </Grid>
        <Box mt={3} />
        {!!filesToUpload.length && (
          <>
            <hr />
            {filesToUpload.map((file, index) => (
              <UploadItem
                supportedTypes={SupportedUploadFilesTypes.File}
                onRemoveItem={onRemoveItem}
                key={file.size}
                file={file}
                index={index}
              />
            ))}
          </>
        )}
        <Grid container spacing={3} justify="space-between" alignItems="center">
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isUploading || !filesToUpload.length || !areFilesValid}
              onClick={handleUpload}
            >
              {t('uploadPage.create')}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              variant="text"
              color="secondary"
              onClick={goToList}
            >
              {t('uploadPage.skip')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default LinkedinBannerUpload;
