import React, { useEffect, useRef, useState } from 'react';
import { Grid, Box, Tabs, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Map from '../Map';
import Polygon from '../Polygon';
import config from '../../../../configuration';
import RegionSearchInput from '../RegionSearchInput';
import GeoDescriptionInput from '../GeoDescriptionInput';

const PolygonSelector = (props) => {
  const tabs = {
    text: 'text',
    map: 'map',
  };

  const {
    geoTargetingChangeHandler,
    polygonPreset,
    onMapLoaded,
    disabled,
    descriptionPreset,
  } = props;

  const options = {
    drawingControl: !disabled,
    drawingControlOptions: {
      drawingModes: ['polygon'],
    },
    polygonOptions: {
      draggable: true,
      editable: true,
    },
    drawingMode: disabled
      ? undefined
      : window.google.maps.drawing.OverlayType.POLYGON,
    fillColor: 'green',
  };

  const { t } = useTranslation();
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(config.defaultMapCenter);
  const [polygonsToRender, setPolygonsToRender] = useState([]);
  const [geoPolygonText, setGeoPolygonText] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [mapZoom, setMapZoom] = useState(6);
  const [selectedTab, setSelectedTab] = useState(tabs.map);
  const [points, setPoints] = useState([]);
  const [polygonOverlays, setPolygonOverlays] = useState([]);
  const [drawingManager] = useState(
    new window.google.maps.drawing.DrawingManager(options)
  );

  const clearPolygons = () => {
    polygonOverlays.forEach((overlay) => {
      overlay.setMap(null);
    });
  };

  useEffect(() => {
    setSelectedTab(descriptionPreset ? tabs.text : tabs.map);
    setGeoPolygonText(descriptionPreset);
  }, [descriptionPreset]);

  useEffect(() => {
    geoTargetingChangeHandler({ geoPolygonText, geoPolygons: points });
  }, [geoPolygonText]);

  useEffect(() => {
    const preset = polygonPreset || [];
    if (preset?.length) {
      setPolygonsToRender([...preset] || []);
    }
    geoTargetingChangeHandler({
      geoPolygonText: descriptionPreset,
      geoPolygons: [...preset],
    });
    setPoints([...preset]);
    if (onMapLoaded) {
      setTimeout(onMapLoaded, 2000);
    }
  }, [polygonPreset, descriptionPreset]);

  useEffect(() => {
    if (!map || disabled || !drawingManager) {
      return undefined;
    }
    const listener = window.google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (event) => {
        setPolygonOverlays([...polygonOverlays, event.overlay]);
        const currentPoints = event.overlay.getPath().getArray();
        const serializedPoints = currentPoints?.map((point) => point.toJSON());
        points.push(serializedPoints);
        setPoints(points);
        geoTargetingChangeHandler({ geoPolygonText, geoPolygons: points });
      }
    );
    return () => {
      listener.remove();
    };
  }, [map, drawingManager, polygonOverlays, points]);

  useEffect(() => {
    if (!map) {
      return;
    }
    drawingManager.setMap(map);
  }, [map]);

  return (
    <Grid container spacing={2} wrap="nowrap">
      <Grid item xs>
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={(e, val) => {
            if (val === tabs.text) {
              clearPolygons();
              setPolygonsToRender([]);
              setPoints([]);
            }
            setGeoPolygonText(null);
            geoTargetingChangeHandler({
              geoPolygonText: null,
              geoPolygons: [],
            });
            setSelectedTab(val);
          }}
        >
          <Tab
            disabled={disabled}
            value={tabs.map}
            fullWidth
            label={t('geoTargeting.map')}
          />
          <Tab
            disabled={disabled}
            value={tabs.text}
            label={t('geoTargeting.text')}
          />
        </Tabs>
        {selectedTab === tabs.map && (
          <Grid item>
            <Box mt={3} />
            <RegionSearchInput
              disabled={disabled}
              onCenterChanged={(newCenter) => {
                setCenter(newCenter);
                setMapZoom(17);
              }}
            />
          </Grid>
        )}
        {selectedTab === tabs.text && (
          <Grid item>
            <Box mt={3} />
            <GeoDescriptionInput
              disabled={disabled}
              rows={15}
              value={geoPolygonText || ''}
              onChange={(e) => {
                setGeoPolygonText(e.target.value);
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid xs={6} item>
        <Map
          disabled={selectedTab === tabs.text}
          center={center}
          zoom={mapZoom}
          onIdle={() => {
            setIsMapLoaded(true);
          }}
          onLoad={(newMap) => {
            setMap(newMap);
          }}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            disableDoubleClickZoom: true,
            draggable: true,
          }}
        >
          {isMapLoaded &&
            polygonsToRender?.map((polygon, index) => (
              <Polygon
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                options={{}}
                path={polygon}
                onLoad={(newPolygon) => {
                  polygonOverlays.push(newPolygon);
                  const myBounds = new window.google.maps.LatLngBounds();
                  newPolygon.getPath().forEach((path) => {
                    myBounds.extend(path);
                  });
                  map.fitBounds(myBounds);
                }}
              />
            ))}
        </Map>
      </Grid>
    </Grid>
  );
};

export default PolygonSelector;
