import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Slider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  makeStyles,
  Tabs,
  Tab,
} from '@material-ui/core';
import { uniqueId } from 'lodash';
import { Circle } from '@react-google-maps/api';
import { Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Map from '../Map';
import config from '../../../../configuration';
import RegionSearchInput from '../RegionSearchInput';
import GeoDescriptionInput from '../GeoDescriptionInput';

const useStyles = makeStyles({
  radiusTargetingList: {
    maxHeight: '280px',
    overflowY: 'auto',
  },
});

const RadiusSelector = (props) => {
  const defaultRadius = 3000;
  const tabs = {
    text: 'text',
    map: 'map',
  };

  const { t } = useTranslation();

  const {
    geoTargetingChangeHandler,
    circlesPreset,
    descriptionPreset,
    radiusPreset,
    onMapLoaded,
    disabled,
  } = props;
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(config.defaultMapCenter);
  const [circles, setCircles] = useState([]);
  const [geoRadiusMeters, setGeoRadiusMeters] = useState(
    radiusPreset || defaultRadius
  );
  const [activeCircleId, setActiveCircleId] = useState(null);
  const [geoRadiusText, setGeoRadiusText] = useState(null);
  const [selectedTab, setSelectedTab] = useState(tabs.map);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [mapZoom, setMapZoom] = useState(6);
  const classes = useStyles();
  const addCircle = ({ latLng }) => {
    if (disabled) {
      return;
    }
    const circle = {
      id: uniqueId(),
      lat: latLng.lat(),
      lon: latLng.lng(),
      radius: config.circleDefaultRadius,
    };
    setActiveCircleId(circle.id);
    setCircles([...circles, circle]);
  };

  const getCircleIndexById = (id) => {
    return circles.findIndex((circle) => circle.id === id);
  };

  const handleCircleDrag = (id, { latLng }) => {
    if (disabled) {
      return;
    }
    const circleIndex = getCircleIndexById(id);
    circles[circleIndex] = {
      ...circles[circleIndex],
      ...{
        lat: latLng.lat(),
        lon: latLng.lng(),
      },
    };
    setCircles([...circles]);
  };

  const removeCircleById = (id) => {
    const circleIndexToRemove = getCircleIndexById(id);
    circles.splice(circleIndexToRemove, 1);
    const [firstCircle] = circles;
    setActiveCircleId(firstCircle?.id || null);
    setCircles([...circles]);
  };

  const getActiveCircleIndex = () => {
    return circles.findIndex((circle) => circle.id === activeCircleId);
  };

  const getActiveCircle = () => {
    return circles.find((circle) => circle.id === activeCircleId);
  };

  const buildCircleText = (circle) => {
    const text = `Lat: ${circle.lat.toFixed(3)} / Lon: ${circle.lon.toFixed(
      3
    )} / Radius: ${circle.radius / 1000} km`;
    return <Typography variant="body2">{text}</Typography>;
  };

  useEffect(() => {
    setSelectedTab(descriptionPreset ? tabs.text : tabs.map);
    setGeoRadiusText(descriptionPreset);
    setGeoRadiusMeters(radiusPreset);
    geoTargetingChangeHandler({
      geoRadiusText: descriptionPreset,
      geoRadiusMeters: radiusPreset,
      geoRadiuses: [],
    });
  }, [descriptionPreset, radiusPreset]);

  useEffect(() => {
    geoTargetingChangeHandler({
      geoRadiusText: null,
      geoRadiusMeters: null,
      geoRadiuses: [...circles?.map(({ id, ...rest }) => rest)],
    });
    if (onMapLoaded) {
      setTimeout(onMapLoaded, 2000);
    }
  }, [circles]);

  useEffect(() => {
    if (geoRadiusMeters && geoRadiusText) {
      geoTargetingChangeHandler({
        geoRadiusText,
        geoRadiusMeters,
        geoRadiuses: [],
      });
    }
  }, [geoRadiusText, geoRadiusMeters]);

  useEffect(() => {
    if (!circlesPreset?.length) {
      return;
    }
    const preparedPreset = circlesPreset.map((circle) => ({
      ...circle,
      id: uniqueId(),
    }));
    setCircles(preparedPreset);
    if (!disabled) {
      const [firstCircle] = preparedPreset;
      setActiveCircleId(firstCircle.id);
    }
  }, [circlesPreset]);
  const activeCircle = getActiveCircle();

  return (
    <Grid container spacing={2} wrap="nowrap">
      <Grid item xs>
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={(e, val) => {
            setCircles([]);
            setGeoRadiusText(null);
            setGeoRadiusMeters(defaultRadius);
            geoTargetingChangeHandler({
              geoRadiusText: null,
              geoRadiusMeters: null,
              geoRadiuses: [],
            });
            setSelectedTab(val);
          }}
        >
          <Tab
            disabled={disabled}
            value={tabs.map}
            fullWidth
            label={t('geoTargeting.map')}
          />
          <Tab
            disabled={disabled}
            value={tabs.text}
            label={t('geoTargeting.text')}
          />
        </Tabs>
        {selectedTab === tabs.map && (
          <Grid item>
            <Box mt={3} />
            <RegionSearchInput
              disabled={disabled}
              onCenterChanged={(newCenter) => {
                setCenter(newCenter);
                setMapZoom(17);
              }}
            />
            {!!circles?.length && (
              <>
                <Box mt={5} />
                {!disabled && (
                  <Grid container>
                    <Grid item xs={9}>
                      <Slider
                        value={
                          (activeCircle?.radius || config.circleDefaultRadius) /
                          1000
                        }
                        onChange={(e, val) => {
                          const activeCircleIndex = getActiveCircleIndex();
                          circles[activeCircleIndex] = {
                            ...circles[activeCircleIndex],
                            radius: val * 1000,
                          };
                          setCircles([...circles]);
                        }}
                        step={0.1}
                        valueLabelDisplay="on"
                        min={0.5}
                        max={30}
                      />
                    </Grid>
                    <Grid item container xs={3} justify="center">
                      <Typography>{`${
                        activeCircle?.radius / 1000
                      } km`}</Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid container className={classes.radiusTargetingList}>
                  <List>
                    <Divider />
                    {circles?.map((circle) => {
                      return (
                        <ListItem
                          key={circle.id}
                          divider
                          button
                          selected={circle.id === activeCircleId}
                          onMouseUp={() => {
                            setActiveCircleId(circle?.id);
                          }}
                        >
                          <ListItemText>{buildCircleText(circle)}</ListItemText>
                          <ListItemIcon>
                            <IconButton
                              disabled={disabled}
                              onMouseUp={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                              onClick={(e) => {
                                removeCircleById(circle.id);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </ListItemIcon>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {selectedTab === tabs.text && (
          <>
            <Box mt={5} />
            <Grid container>
              <Grid item xs={9}>
                <Slider
                  value={geoRadiusMeters / 1000}
                  onChange={(e, val) => {
                    if (disabled) {
                      return;
                    }
                    setGeoRadiusMeters(val * 1000);
                  }}
                  step={0.1}
                  valueLabelDisplay="on"
                  min={0.5}
                  max={30}
                />
              </Grid>
              <Grid item container xs={3} justify="center">
                <Typography>{`${geoRadiusMeters / 1000} km`}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Box mt={2} />
              <GeoDescriptionInput
                disabled={disabled}
                rows={12}
                value={geoRadiusText || ''}
                onChange={(e) => {
                  setGeoRadiusText(e.target.value);
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid xs={6} item>
        <Map
          center={center}
          zoom={mapZoom}
          disabled={selectedTab === tabs.text}
          onLoad={setMap}
          onClick={addCircle}
          onIdle={() => {
            setIsMapLoaded(true);
          }}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: true,
            draggable: true,
          }}
        >
          {isMapLoaded &&
            circles?.map((circle) => {
              const isActive = activeCircleId === circle.id;
              return (
                <Circle
                  key={circle.id}
                  draggable={!disabled}
                  onLoad={(newCircle) => {
                    if (!disabled) {
                      return;
                    }
                    const bounds = map.getBounds();
                    bounds.extend(newCircle.center);
                    map.fitBounds(bounds);
                  }}
                  onDragEnd={(e) => {
                    handleCircleDrag(circle.id, e);
                  }}
                  onClick={() => {
                    setActiveCircleId(circle.id);
                  }}
                  options={{
                    fillColor: isActive ? 'orange' : 'green',
                    strokeWeight: 0,
                    draggable: !disabled,
                  }}
                  center={{
                    lng: circle.lon,
                    lat: circle.lat,
                  }}
                  radius={circle.radius}
                />
              );
            })}
        </Map>
      </Grid>
    </Grid>
  );
};

export default RadiusSelector;
