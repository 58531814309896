import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Login from './scenes/Login';
import MainLayout from './layouts/MainLayout';
import Advertisers from './scenes/Advertisers';
import Campaigns from './scenes/Campaigns';
import Users from './scenes/Users';
import history from './history';
import ProtectedRoute from './components/ProtectedRoute';
import StyledToastContainer from './components/StyledToastContainer';
import BannerUpload from './scenes/BannerUpload';
import VideoUpload from './scenes/VideoUpload';
import Settings from './scenes/Settings';
import CreateViewEditCampaign from './scenes/CreateViewEditCampaign';
import CampaignAttachments from './scenes/CampaignAttachments';
import PdfPrint from './scenes/PdfPrint';
import ExcelGenerator from './scenes/ExcelGenerator';
import AdminSettings from './scenes/AdminSettings';
import CreateLinkedinCampaign from './scenes/agencyUsers/LinkedinCampaign/CreateCampaign';
import LinkedinViewPdfPrint from './scenes/LikedinPdfPrint';
import EditLinkedinScene from './scenes/allUsers/campaigns/LinkedinCampaign/EditCampaign';
import ViewLinkedinScene from './scenes/allUsers/campaigns/LinkedinCampaign/ViewCampaign';
import LinkedinBannerUpload from './scenes/agencyUsers/LinkedinCampaign/CreateCampaign/components/BannerUpload';
import LinkedinExcelGenerator from './scenes/LinkedinExcelGenerator';

const App = () => {
  return (
    <Router history={history}>
      <Routes>
        <Route
          exact
          path="/advertisers"
          element={
            <ProtectedRoute permission="see-advertiser-page">
              <MainLayout>
                <Advertisers />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaigns"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <Campaigns />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaign/:campaignId/attachments"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <CampaignAttachments />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaign/linkedin/:campaignId"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <ViewLinkedinScene />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaign/social/:campaignId"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <CreateViewEditCampaign type="social" disabled />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaign/video/:campaignId"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <CreateViewEditCampaign type="video" disabled />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaign/banner/:campaignId"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <CreateViewEditCampaign type="banner" disabled />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/create-campaign/linkedin"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <CreateLinkedinCampaign />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/create-campaign/social"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <CreateViewEditCampaign type="social" />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/create-campaign/video"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <CreateViewEditCampaign type="video" />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/create-campaign/banner"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <CreateViewEditCampaign type="banner" />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/edit-campaign/linkedin/:campaignId"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <EditLinkedinScene />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/edit-campaign/social/:campaignId"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <CreateViewEditCampaign type="social" />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/edit-campaign/video/:campaignId"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <CreateViewEditCampaign type="video" />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/edit-campaign/banner/:campaignId"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <CreateViewEditCampaign type="banner" />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/users"
          element={
            <ProtectedRoute permission="see-user-page">
              <MainLayout>
                <Users />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/settings"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <Settings />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/admin-settings"
          element={
            <ProtectedRoute permission="see-admin-settings-page">
              <MainLayout>
                <AdminSettings />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaign/pdf-print"
          element={
            <ProtectedRoute permission="see-page">
              <PdfPrint />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaign/linkedin-view-pdf-print"
          element={
            <ProtectedRoute permission="see-page">
              <LinkedinViewPdfPrint />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaign/excel-generator"
          element={
            <ProtectedRoute permission="see-page">
              <ExcelGenerator />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaign/linkedin-excel-generator"
          element={
            <ProtectedRoute permission="see-page">
              <LinkedinExcelGenerator />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaign/upload/banner/:campaignId"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <DndProvider backend={HTML5Backend}>
                  <BannerUpload />
                </DndProvider>
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaign/upload/video/:campaignId"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <DndProvider backend={HTML5Backend}>
                  <VideoUpload />
                </DndProvider>
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/campaign/upload/linkedinBanner/:campaignId"
          element={
            <ProtectedRoute permission="see-page">
              <MainLayout>
                <DndProvider backend={HTML5Backend}>
                  <LinkedinBannerUpload />
                </DndProvider>
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route exact path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
      <StyledToastContainer />
    </Router>
  );
};

export default App;
