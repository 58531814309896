import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import AppRadioButton from '../../../../../../components/AppRadioButton';
import {
  AwarenessIcon,
  CalendarIcon,
} from '../../../../../../components/CustomIcons';
import { LinkedinCampaignGoal } from '../../../../../../enums/campaign';

const CampaignStep1 = (props) => {
  const { advertiser, name, linkedinGoal, startDate, endDate } = props;
  const { t } = useTranslation();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{t('createCampaign.stepOne')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {/* advertiser */}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.advertiser')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Select value={advertiser} disabled>
                  <MenuItem value={advertiser}>{advertiser?.name}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Box mt={3} width="100%" />
          </Grid>

          {/* campaign name */}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.campaignName')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder={t('createCampaign.campaignNamePlaceHolder')}
                variant="outlined"
                value={name}
                fullWidth
                disabled
              />
            </Grid>
            <Box mt={3} width="100%" />
          </Grid>

          {/* campaign goal */}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.campaignGoal')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppRadioButton
                icon={AwarenessIcon}
                labelText={t('createCampaign.websiteVisitors')}
                checked={linkedinGoal === LinkedinCampaignGoal.Visitors}
                value={LinkedinCampaignGoal.Visitors}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppRadioButton
                icon="mouse_icon"
                labelText={t('createCampaign.interactionWithLinkedInContent')}
                checked={linkedinGoal === LinkedinCampaignGoal.Interaction}
                value={LinkedinCampaignGoal.Interaction}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppRadioButton
                icon="contact_mail_icon"
                labelText={t('createCampaign.applicants')}
                checked={linkedinGoal === LinkedinCampaignGoal.Applicants}
                value={LinkedinCampaignGoal.Applicants}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppRadioButton
                icon="people_alt_icon"
                labelText={t('createCampaign.leadsInLinkedIn')}
                checked={linkedinGoal === LinkedinCampaignGoal.Leads}
                value={LinkedinCampaignGoal.Leads}
                disabled
              />
            </Grid>
            <Box mt={3} width="100%" />
          </Grid>

          {/* campaign duration */}
          <Grid item container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <Typography variant="body1">
                {t('createCampaign.campaignStart')}
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  format="DD.MM.YYYY"
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  margin="normal"
                  placeholder={t('createCampaign.campaignStart')}
                  value={startDate}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: 'pointer' }}
                      >
                        <CalendarIcon color="secondary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography variant="body1">
                {t('createCampaign.campaignEnd')}
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  format="DD.MM.YYYY"
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  margin="normal"
                  placeholder={t('createCampaign.campaignEnd')}
                  value={endDate}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: 'pointer' }}
                      >
                        <CalendarIcon color="secondary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Box mt={1} width="100%" />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CampaignStep1;
