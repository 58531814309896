import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  Grid,
  Typography,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Box,
  TextField,
  Paper,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Info } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';

import Intensity from '../../../../components/Intensity';
import AppRadioButton from '../../../../components/AppRadioButton';
import {
  CampaignAdvertisingType,
  CampaignCreatives,
  CampaignTypes,
} from '../../../../enums/campaign';
import CampaignBudgetRecommendation from '../CampaignBudgetRecommendation';
import CampaignCreativesModal from '../CampaignCreativesModal';
import {
  AdvMaterialIcon,
  ProtectIcon,
  UploadIcon,
} from '../../../../components/CustomIcons';
import CampaignBudgetInput from '../CampaignBudgetInput';
import CampaignSocialAdvertisingMaterials from '../CampaignSocialAdvertisingMaterials';
import { formatFloatToView } from '../../../../services/format-helper';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      backgroundColor: theme.palette.primary.main,
      height: '85px',
    },
    shieldIcon: {
      fontSize: '52px',
      color: theme.palette.basic.white,
    },
    infoText: {
      color: theme.palette.basic.white,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      position: 'relative',
    },
    tooltip: {
      position: 'absolute',
      top: 12,
      left: 308,
      cursor: 'pointer',
    },
  };
});

const CampaignPricing = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isCreativesModalOpen, setIsCreativesModalOpen] = useState(false);
  // TODO: There is a bug with onClick on AppRadioButton. onClick is called twice.
  // I suspect because of the prop name 'onClick'

  const {
    isPrint,
    handleCampaignPricing,
    disabled,
    campaignPricing,
    isFormSubmitted,
    estimatedRange,
    isReachAvailable,
    type,
    pricesPerUser,
    priceWithoutAdvMargin,
    advertiserMargin,
    showCalculation,
    socialPlatform,
    budgetRecommendation,
    campaignGoal,
    advertisingType,
    additionalContactField,
    staticPrice = {},
    refsMap,
  } = props;

  const {
    advertiserMaterial,
    comment,
    intensity,
    contactPersonEmail,
    budget,
    advertiserMaterialType,
    socialAdvertiserMaterialType,
  } = campaignPricing;

  const campaignBudgetRef = useRef();

  useEffect(() => {
    refsMap.current['campaign-budget'] = campaignBudgetRef;
  }, [refsMap]);

  const handleAdvertiserMaterialChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      const { value } = event.target;
      const payload = { advertiserMaterial: event.target.value };
      if (value !== CampaignCreatives.Order) {
        payload.advertiserMaterialType = undefined;
      }
      handleCampaignPricing(payload);
    },
    [handleCampaignPricing, disabled]
  );

  const handleAdvertiserMaterialTypeChange = useCallback(
    (event) => {
      handleCampaignPricing({ advertiserMaterialType: event.target.value });
    },
    [handleCampaignPricing]
  );

  const handleCommentChange = useCallback(
    (event) => {
      handleCampaignPricing({ comment: event.target.value });
    },
    [handleCampaignPricing]
  );

  const handleContactPersonEmailChange = useCallback(
    (event) => {
      handleCampaignPricing({ contactPersonEmail: event.target.value });
    },
    [handleCampaignPricing]
  );

  const handleIntensityChange = useCallback(
    (value) => {
      if (disabled) {
        return;
      }
      handleCampaignPricing({ intensity: value });
    },
    [handleCampaignPricing, disabled]
  );

  const handleBudgetChange = useCallback(
    (event) => {
      const strValue = event.target.value.toString();
      const value = strValue
        .replace(/^[,.]|[^\d|,.]/g, '')
        .replace(/([,.])(\d{0,2}).*/g, '$1$2');
      const budgetNumber = value ? Number(value) : undefined;
      handleCampaignPricing({ budget: budgetNumber });
    },
    [handleCampaignPricing]
  );

  const handleMaterialModalOnClose = useCallback(() => {
    setIsCreativesModalOpen(false);
  }, [setIsCreativesModalOpen]);

  let impressions = (budget / pricesPerUser.price).toFixed();
  if (staticPrice) {
    const staticPrices = {
      CPC: staticPrice.CPC,
      TKP: staticPrice.TKP / 1000,
    };
    impressions = (budget / staticPrices[campaignGoal]).toFixed();
  }

  // todo olo fix this ugly formatting
  const formattedImpressions = JSON.parse(
    JSON.stringify(Number(impressions).toLocaleString('de-DE'))
  );

  // todo olo fix this ugly formatting
  const formattedEstimatedRange = JSON.parse(
    JSON.stringify(estimatedRange.toLocaleString('de-DE'))
  );

  let price = priceWithoutAdvMargin
    ? pricesPerUser.viewPriceWithoutAdvMargin
    : pricesPerUser.viewPrice;
  if (staticPrice && !priceWithoutAdvMargin) {
    price = staticPrice[campaignGoal];
  }
  let budgetWithoutAdvMargin = budget / (advertiserMargin / 100 + 1);

  if (staticPrice) {
    budgetWithoutAdvMargin = impressions * pricesPerUser.priceWithoutAdvMargin;
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{t('createCampaign.stepFour')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {/* reach (reach user estimated range) only for pdf view */}
          {showCalculation && (
            <Grid item xs={12}>
              {isReachAvailable && (
                <Typography variant="body1">
                  {t('createCampaign.estimatedRange', {
                    data: formattedEstimatedRange,
                  })}
                </Typography>
              )}
              {!isReachAvailable && (
                <Typography variant="body1">
                  {t('createCampaign.reachNotAvailableWithSelectedGeoType')}
                </Typography>
              )}

              <Box mt={2} mb={4} width="100%">
                <Divider />
              </Box>
            </Grid>
          )}

          {/* intensity */}
          <Grid item xs={12}>
            <Intensity
              intensity={intensity}
              setIntensity={handleIntensityChange}
            />
            <Box my={4} width="100%">
              <Divider />
            </Box>
          </Grid>

          {/* budget recommendation only for pdf view */}
          {showCalculation && (
            <Grid item xs={12}>
              <CampaignBudgetRecommendation
                isReachAvailable={isReachAvailable}
                budgetRecommendation={budgetRecommendation}
              />
              <Box my={4} width="100%">
                <Divider />
              </Box>
            </Grid>
          )}

          {/* budget */}
          <Grid ref={campaignBudgetRef} item xs={12}>
            <Typography variant="body1">
              {t('createCampaign.budget')}
            </Typography>
            <Box mt={2} width="100%" />
            <CampaignBudgetInput
              handleBudgetChange={handleBudgetChange}
              disabled={disabled}
              budget={
                priceWithoutAdvMargin
                  ? budgetWithoutAdvMargin.toFixed(2)
                  : budget
              }
              isFormSubmitted={isFormSubmitted}
              budgetRecommendation={budgetRecommendation}
            />
            <Box my={4} width="100%">
              <Divider />
            </Box>
          </Grid>

          <Grid item container xs={12}>
            {/* CPC and TKP  only for pdf view */}
            {showCalculation && (
              <Grid item container spacing={4} xs={12}>
                <Grid item>
                  {(!budget || !price) && (
                    <Typography variant="body1" gutterBottom>
                      {t('createCampaign.notAvailable')}
                    </Typography>
                  )}
                  {!!budget && !!price && (
                    <>
                      {advertisingType === CampaignAdvertisingType.CPC && (
                        <Typography variant="body1" gutterBottom>
                          {t('createCampaign.clicks', {
                            impressions: formattedImpressions,
                          })}
                        </Typography>
                      )}
                      {advertisingType === CampaignAdvertisingType.TKP && (
                        <Typography variant="body1" gutterBottom>
                          {t('createCampaign.impressions', {
                            impressions: formattedImpressions,
                          })}
                        </Typography>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="body1" gutterBottom>
                    {`${campaignGoal}: ${formatFloatToView(price.toFixed(2))}€`}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {/* this banner is for 'banner' and 'video' campaigns */}
            {type === CampaignTypes.Banner ||
              (type === CampaignTypes.Video && (
                <Grid item xs={12}>
                  <Box mt={2} width="100%" />
                  <Paper className={classes.paper} elevation={1}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Box p={2}>
                          <ProtectIcon className={classes.shieldIcon} />
                        </Box>
                      </Grid>
                      <Grid item xs={10}>
                        <Box className={classes.infoText}>
                          <Typography variant="body1">
                            {t('createCampaign.protectedInformationMessage1')}
                          </Typography>
                          <Tooltip
                            title={t('tooltips.brandSafety')}
                            className={classes.tooltip}
                          >
                            <Info fontSize="inherit" color="inherit" />
                          </Tooltip>
                          <Typography variant="body2">
                            {t('createCampaign.protectedInformationMessage2')}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Box my={4} width="100%">
                    <Divider />
                  </Box>
                </Grid>
              ))}
          </Grid>

          {/* advertising materials for banner campaign */}
          {type === CampaignTypes.Banner && (
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('createCampaign.advertisingMaterials')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppRadioButton
                  error={isFormSubmitted && !advertiserMaterial}
                  icon={UploadIcon}
                  labelText={t('createCampaign.ownMaterials')}
                  checked={advertiserMaterial === CampaignCreatives.Own}
                  onChange={handleAdvertiserMaterialChange}
                  value={CampaignCreatives.Own}
                  tooltip={t('tooltips.ownMaterials')}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppRadioButton
                  error={
                    isFormSubmitted &&
                    advertiserMaterial === CampaignCreatives.Order &&
                    !advertiserMaterialType
                  }
                  icon={AdvMaterialIcon}
                  labelText={t('createCampaign.orderMaterials')}
                  checked={advertiserMaterial === CampaignCreatives.Order}
                  onChange={handleAdvertiserMaterialChange}
                  value={CampaignCreatives.Order}
                  tooltip={t('tooltips.orderMaterials')}
                  onClick={() => {
                    // TODO: move out of render block
                    if (disabled) {
                      return;
                    }
                    setIsCreativesModalOpen(true);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppRadioButton
                  error={isFormSubmitted && !advertiserMaterial}
                  icon="watch_later"
                  labelText={t('createCampaign.decideLater')}
                  checked={advertiserMaterial === CampaignCreatives.Later}
                  onChange={handleAdvertiserMaterialChange}
                  value={CampaignCreatives.Later}
                  tooltip={t('tooltips.decideLater')}
                />
              </Grid>
              <Grid item xs={12}>
                <Box my={4} width="100%">
                  <Divider />
                </Box>
              </Grid>
            </Grid>
          )}

          {/* advertising materials for social campaign */}
          {type === CampaignTypes.Social && (
            <CampaignSocialAdvertisingMaterials
              isPrint={isPrint}
              disabled={disabled}
              handleCampaignPricing={handleCampaignPricing}
              socialAdvertiserMaterialType={socialAdvertiserMaterialType}
              socialPlatform={socialPlatform}
            />
          )}

          {/* comment */}
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('createCampaign.comment')}
            </Typography>
            <Box my={2} width="100%">
              <TextField
                multiline
                InputProps={{
                  style: {
                    height: 'auto',
                  },
                }}
                rows={4}
                value={comment || ''}
                onChange={handleCommentChange}
                placeholder={t('createCampaign.commentPlaceholder')}
                variant="outlined"
                disabled={disabled}
                fullWidth
              />
            </Box>
          </Grid>

          {/* additionalContactField only for special customer */}
          {additionalContactField && (
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.additionalContactField')}
              </Typography>
              <Box my={2} width="100%">
                <TextField
                  value={contactPersonEmail || ''}
                  onChange={handleContactPersonEmailChange}
                  placeholder={t('createCampaign.email')}
                  variant="outlined"
                  disabled={disabled}
                  fullWidth
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
      <CampaignCreativesModal
        isOpen={isCreativesModalOpen}
        onClose={handleMaterialModalOnClose}
        onMaterialTypeChange={handleAdvertiserMaterialTypeChange}
        materialType={advertiserMaterialType}
      />
    </Accordion>
  );
};

export default memo(CampaignPricing);
