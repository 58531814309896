import React, { memo, useState, useCallback, useMemo } from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { ChevronLeft, Close, ExpandLess } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import GeoCategoryListItem from '../GeoCategoryListItem';

const ExpandedList = ({ disabled, categoryItems, removeSelectedPlace }) => {
  return (
    <List dense disablePadding>
      {categoryItems.map((item) => {
        const { id } = item;
        return (
          <GeoCategoryListItem
            key={id}
            item={item}
            disabled={disabled}
            togglePlaceCheck={removeSelectedPlace}
            checked
          />
        );
      })}
    </List>
  );
};

const CollapsedItem = ({
  category,
  disabled,
  removeSelectedCategory,
  removeSelectedPlace,
  categoryItems,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = useCallback(() => {
    setIsExpanded((oldIsExpanded) => !oldIsExpanded);
  }, [setIsExpanded]);

  const removeSelectedCategoryCallback = useCallback(() => {
    removeSelectedCategory(category);
  }, [removeSelectedCategory, category]);

  return (
    <>
      <ListItem divider button component="li" onClick={toggleExpand}>
        <ListItemText primary={category} />
        <ListItemSecondaryAction>
          {!disabled && (
            <IconButton onClick={removeSelectedCategoryCallback}>
              <Close />
            </IconButton>
          )}
          <IconButton onClick={toggleExpand}>
            {isExpanded ? <ExpandLess /> : <ChevronLeft />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      {isExpanded && (
        <ExpandedList
          removeSelectedPlace={removeSelectedPlace}
          disabled={disabled}
          categoryItems={categoryItems}
        />
      )}
    </>
  );
};

export const GeoCategoriesSelectedList = (props) => {
  const {
    geoCategories,
    removeSelectedPlace,
    removeSelectedCategory,
    checkedPlaces,
    disabled,
  } = props;
  const { t } = useTranslation();

  const groupedByCategory = Object.keys(checkedPlaces)?.reduce((acc, id) => {
    if (!checkedPlaces[id]) {
      return acc;
    }
    const item = geoCategories[id];
    const { subCategory } = item;
    acc[subCategory] = acc[subCategory] || [];
    acc[subCategory].push(item);
    return acc;
  }, {});

  const categories = Object.keys(groupedByCategory);

  return (
    <List disablePadding>
      <ListItem divider selected>
        <ListItemText primary={t('geoTargeting.selected')} />
      </ListItem>
      {categories?.map((category) => {
        return (
          <React.Fragment key={category}>
            <CollapsedItem
              category={category}
              disabled={disabled}
              removeSelectedCategory={removeSelectedCategory}
              categoryItems={groupedByCategory[category]}
              removeSelectedPlace={removeSelectedPlace}
            />
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default memo(GeoCategoriesSelectedList);
