import gql from 'graphql-tag';

export const GET_REGIONS = gql`
  query getRegions {
    regions {
      id
      lat
      lon
      population
      name
      is_country
    }
  }
`;

export const GET_REGION = gql`
  query getRegion($regionId: ID!) {
    region(regionId: $regionId) {
      id
      points
    }
  }
`;

export const GET_REGION_BY_NAME = gql`
  query regionByName($name: String!) {
    regionByName(name: $name) {
      name
      points
      lat
      lon
      population
      zoom
      id
    }
  }
`;

export const UPDATE_REGION = gql`
  mutation updateRegion($regionInput: UpdateRegionInput!) {
    updateRegion(regionInput: $regionInput) {
      id
      name
    }
  }
`;
