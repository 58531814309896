import './index.css';

import React from 'react';
import { Grid } from '@material-ui/core';
import CampaignDetails from '../CreateViewEditCampaign/components/CampaignDetails';
import CampaignTargeting from '../CreateViewEditCampaign/components/CampaignTargeting';
import GeoTargetingWidget from '../../components/GeoTargeting';
import CampaignPricing from '../CreateViewEditCampaign/components/CampaignPricing';
import {
  daysCount,
  getBudgetRecommendation,
  getPricePerUser,
  getPricesWithMargin,
  getReach,
  isReachAvailable,
} from '../CreateViewEditCampaign/services/calculation';
import { CampaignAdvertisingType } from '../../enums/campaign';

const PdfPrint = () => {
  const {
    campaign,
    agency,
    priceWithoutAdvMargin,
    savedCampaign,
    staticPrice,
  } = JSON.parse(localStorage.getItem('campaign-for-print'));
  const {
    campaignDetails = {},
    campaignTargeting,
    geoTargeting,
    campaignPricing,
  } = campaign;

  const calculatedPricesWithMargin = getPricesWithMargin(
    campaignDetails?.advertiser?.margin,
    agency?.margin,
    campaignDetails.type,
    campaignDetails.advertisingType,
    campaignPricing.socialAdvertiserMaterialType
  );
  const pricing = savedCampaign?.pricing || calculatedPricesWithMargin;

  const estimatedRange = getReach(campaign);

  const campaignGoal =
    campaign.campaignDetails.advertisingType || CampaignAdvertisingType.TKP;

  const calculatedPricePerUser = getPricePerUser({
    campaignValue: campaign,
    agency,
  });
  const savedPricePerUser = savedCampaign?.pricesPerUser;
  const pricePerUser = savedPricePerUser
    ? savedPricePerUser[campaignGoal]
    : calculatedPricePerUser;

  const reach = getReach(campaign);
  const days = daysCount(campaignDetails.startDate, campaignDetails.endDate);
  const budgetRecommendation = getBudgetRecommendation(
    reach,
    campaignPricing.intensity,
    pricePerUser.price,
    days,
    campaignDetails.advertisingType
  );

  const onMapLoaded = () => {
    window.onafterprint = () => {
      window.close();
    };
    window.print();
  };
  return (
    <Grid item xs id="pdf-container">
      {priceWithoutAdvMargin && (
        <img
          style={{ height: '150px' }}
          src={`${process.env.PUBLIC_URL}/twins-digital-logo.png`}
          alt="logo"
        />
      )}
      <CampaignDetails
        advertisers={[campaignDetails?.advertiser]}
        handleCampaignDetails={() => {}}
        type={campaignDetails?.type}
        campaignDetails={campaignDetails}
        isFormSubmitted={false}
        disabled
      />
      <div style={{ pageBreakAfter: 'always' }} />
      <CampaignTargeting
        handleCampaignTargeting={() => {}}
        pricing={pricing}
        campaignTargeting={campaignTargeting}
        campaignDetails={campaignDetails}
        isFormSubmitted={false}
        campaignGoal={campaignGoal}
        disabled
      />
      <div style={{ pageBreakAfter: 'always' }} />
      <GeoTargetingWidget
        onMapLoaded={onMapLoaded}
        pricing={pricing}
        onChange={() => {}}
        geoTargeting={geoTargeting}
        isFormSubmitted={false}
        campaignGoal={campaignGoal}
        disabled
      />
      <div style={{ pageBreakAfter: 'always' }} />
      <CampaignPricing
        staticPrice={staticPrice}
        handleCampaignPricing={() => {}}
        campaignPricing={campaignPricing}
        estimatedRange={estimatedRange}
        isPrint
        isReachAvailable={isReachAvailable(geoTargeting.geoTargetingType)}
        type={campaignDetails.type}
        pricesPerUser={pricePerUser}
        priceWithoutAdvMargin={priceWithoutAdvMargin}
        advertiserMargin={campaignDetails?.advertiser?.margin}
        budgetRecommendation={budgetRecommendation}
        socialPlatform={campaignDetails.socialPlatform}
        showCalculation
        isFormSubmitted={false}
        campaignGoal={campaignGoal}
        advertisingType={campaignDetails.advertisingType}
        disabled
      />
    </Grid>
  );
};

export default PdfPrint;
