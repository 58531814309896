import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, makeStyles, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      backgroundColor: theme.palette.basic.lightGreyOpacity,
    },
    container: {
      position: 'relative',
    },
    tooltip: {
      position: 'absolute',
      top: 5,
      left: 310,
      cursor: 'pointer',
    },
    groupButton: {
      width: '100%',
    },
    popper: {
      zIndex: 1,
    },
  };
});

const CampaignActions = (props) => {
  const { onAdminSubmit } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useNavigate();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Box p={2}>
        <Grid container spacing={6} justify="space-between">
          <Grid item xs={12} sm={6}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={onAdminSubmit}
            >
              {t('common.save')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              color="secondary"
              onClick={() => history(-1)}
              fullWidth
              variant="outlined"
            >
              {t('common.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default CampaignActions;
