import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { InputBase, FormHelperText } from '@material-ui/core';

const WebsitesGeneratorForm = memo(({ onFormSubmit = () => {} }) => {
  const { t } = useTranslation();
  const { errors, register, handleSubmit } = useForm();
  const handleFormSubmit = (data, e) => {
    onFormSubmit(data);
    e.target.reset();
  };
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <InputBase
        fullWidth
        size="small"
        autoComplete="off"
        error={errors?.uncategorized}
        placeholder={t('websitesGenerator.formPlaceholer')}
        {...register('uncategorized', {
          required: t('validationErrors.formRequired'),
        })}
      />
      <FormHelperText color="error">
        {errors?.uncategorized?.message}
      </FormHelperText>
    </form>
  );
});

export default WebsitesGeneratorForm;
