import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './index.css';
import { ApolloProvider } from '@apollo/client';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ThemeProvider } from '@material-ui/core';
import moment from 'moment';
import { LoadScript } from '@react-google-maps/api';
import commonEn from './lang/en/index.json';
import commonDe from './lang/de/index.json';
import theme from './theme';
import 'moment/locale/de';
import { buildApolloClient, getApolloClient } from './services/apollo-helper';
import App from './App';
import config from './configuration';

moment.locale('de');
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: commonEn,
    },
    de: {
      translation: commonDe,
    },
  },
  lng: 'de',
  fallbackLng: 'en',
});

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: 'https://da498770b3224110a5ad0473b506ac08@o4504118027026432.ingest.sentry.io/4504139842453504',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const appInit = async () => {
  buildApolloClient();
  const client = getApolloClient();
  ReactDOM.render(
    <React.StrictMode>
      <LoadScript
        libraries={config.googleMapsLibraries}
        googleMapsApiKey={config.googleMapsApiKey}
      >
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </ThemeProvider>
      </LoadScript>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

appInit();
