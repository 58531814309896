import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import Map from '../../../../components/GeoTargeting/components/Map';
import Polygon from '../../../../components/GeoTargeting/components/Polygon';
import {
  UPDATE_REGION,
  GET_REGION_BY_NAME,
} from '../../../../components/GeoTargeting/components/RegionSelector/queries';
import SpinnerBackdrop from '../../../../components/BackdropSpinner';

const RegionSettings = () => {
  const { refetch: fetchRegion } = useQuery(GET_REGION_BY_NAME, { skip: true });
  const [createRegion] = useMutation(UPDATE_REGION);
  const [availableRegion, setAvailableRegion] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [optionalCoordinates, setOptionalCoordinates] = useState([]);
  const [regionToUpdate, setRegionToUpdate] = useState(undefined);
  const [selectedPointsArray, setSelectedPointsArray] = useState(undefined);
  const { register, handleSubmit, errors } = useForm();
  const {
    register: newRegionRegister,
    handleSubmit: newRegionSubmit,
    reset: newRegionReset,
  } = useForm();

  const uploadRegion = async () => {
    try {
      setLoading(true);
      const selectedPoints = optionalCoordinates[selectedPointsArray].map(
        ([lng, lat]) => {
          return { lng, lat };
        }
      );
      const {
        data: { updateRegion },
      } = await createRegion({
        variables: {
          regionInput: { ...regionToUpdate, points: selectedPoints },
        },
      });
      setLoading(false);
      newRegionReset();
      setOptionalCoordinates([]);
      toast.success(`region ${updateRegion.name} updated`);
    } catch ({ message }) {
      setLoading(false);
      toast.error(message);
    }
  };

  const onSubmit = async ({ regionName: name }) => {
    setAvailableRegion(undefined);
    try {
      setLoading(true);
      const { data: region } = await fetchRegion({ name });
      if (!region?.regionByName) {
        setLoading(false);
        return toast.error('region not found');
      }

      toast.success(`region ${name} founded in DB`);
      setLoading(false);
      setAvailableRegion(region.regionByName);
    } catch ({ message }) {
      setLoading(false);
      toast.error(message);
    }
    return false;
  };

  const onAddRegionSubmit = async ({ regionName, population }) => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search.php?q=${regionName}&polygon_geojson=1&format=json`
      );
      const data = await response.json();
      const region = data.length ? data[0] : {};
      const ResPolygonType = data.filter((res) => {
        const correctTypes = ['Polygon', 'MultiPolygon'];
        return res?.geojson && correctTypes.includes(res.geojson.type);
      });
      const coordinates = ResPolygonType.map((res) => {
        if (res.geojson.type === 'MultiPolygon') {
          return res.geojson.coordinates.sort(
            (a, b) => b[0].length - a[0].length
          )[0][0];
        }
        return res.geojson.coordinates[0];
      }).sort((a, b) => b.length - a.length);
      setOptionalCoordinates(coordinates);

      const newRegionToUpdate = {
        name: regionName,
        id: String(region.osm_id),
        lat: Number(region.lat),
        lon: Number(region.lon),
        population: Number(population),
      };
      setLoading(false);
      setRegionToUpdate(newRegionToUpdate);
    } catch ({ message }) {
      setLoading(false);
      toast.error(message);
    }
  };

  return (
    <>
      <SpinnerBackdrop open={loading} />
      <Paper component={Box} p={4} mt={3}>
        <form id="settings" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                check region availability
              </Typography>
              <TextField
                required
                variant="outlined"
                error={!!errors?.regionName}
                placeholder="region name"
                {...register('regionName', {
                  required: true,
                })}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={3} />
          <Grid container spacing={3} justify="flex-start">
            <Grid item xs={12} md={3}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                form="settings"
              >
                Check
              </Button>
            </Grid>
          </Grid>
        </form>
        {availableRegion && (
          <Grid container spacing={3} justify="flex-start">
            <Grid item xs={6} md={6}>
              <table>
                {Object.keys(availableRegion).map((key) => {
                  if (key === 'points' || key === 'zoom') return undefined;
                  return (
                    <tr key={key}>
                      <th>{key}</th>
                      <td>{String(availableRegion[key])}</td>
                    </tr>
                  );
                })}
              </table>
            </Grid>
            <Grid item xs={6} md={6}>
              <Map
                center={{
                  lat: +availableRegion.lat,
                  lng: +availableRegion.lon,
                }}
                zoom={availableRegion.zoom || 5}
                options={{
                  mapTypeControl: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                  disableDoubleClickZoom: true,
                  draggable: true,
                }}
              >
                <Polygon options={{}} path={availableRegion.points} />
                ))
              </Map>
            </Grid>
          </Grid>
        )}
      </Paper>
      <Paper component={Box} p={4} mt={3}>
        <form
          id="settingsRegionUpload"
          onSubmit={newRegionSubmit(onAddRegionSubmit)}
        >
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">add region by name</Typography>
              <TextField
                required
                variant="outlined"
                placeholder="region name"
                {...newRegionRegister('regionName', {
                  required: true,
                })}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">add population</Typography>
              <TextField
                required
                variant="outlined"
                type="number"
                placeholder="population"
                {...newRegionRegister('population', {
                  required: true,
                })}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={3} />
          {!!optionalCoordinates.length && (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {optionalCoordinates.map((coords, i) => {
                  return (
                    <Button
                      fullWidth
                      key={coords}
                      style={{ marginBottom: 5 }}
                      variant={
                        selectedPointsArray === i ? 'contained' : 'outlined'
                      }
                      color={
                        selectedPointsArray === i ? 'primary' : 'secondary'
                      }
                      onClick={() => {
                        setSelectedPointsArray(i);
                      }}
                      type="button"
                    >
                      #{i + 1} Available Points: {coords.length}
                    </Button>
                  );
                })}
              </Grid>
              {selectedPointsArray >= 0 && (
                <Grid item xs={6} md={6}>
                  <Map
                    center={{
                      lat: regionToUpdate.lat,
                      lng: regionToUpdate.lon,
                    }}
                    zoom={5}
                    options={{
                      mapTypeControl: false,
                      streetViewControl: false,
                      fullscreenControl: false,
                      disableDoubleClickZoom: true,
                      draggable: true,
                    }}
                  >
                    <Polygon
                      options={{}}
                      path={optionalCoordinates[selectedPointsArray].map(
                        ([lng, lat]) => {
                          return { lng, lat };
                        }
                      )}
                    />
                    ))
                  </Map>
                </Grid>
              )}
            </Grid>
          )}
          <Box mt={3} />
          <Grid container spacing={3} justify="flex-start">
            <Grid item xs={4} md={4}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                form="settingsRegionUpload"
              >
                get region data
              </Button>
            </Grid>
            <Grid item xs={4} md={4}>
              <Button
                fullWidth
                color="primary"
                disabled={!selectedPointsArray && selectedPointsArray !== 0}
                variant="contained"
                onClick={uploadRegion}
                form="settingsRegionUpload"
              >
                upload/update region
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default RegionSettings;
