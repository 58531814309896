import gql from 'graphql-tag';

export const GET_CAMPAIGN_PAGE_DATA = gql`
  query campaignViewPageData($id: ID) {
    advertisers {
      id
      name
      margin
      agencyId
    }
    user {
      username
      margin
      additionalContactField
      staticPrice {
        TKP
        CPC
      }
      id
    }
    campaign(id: $id) {
      advertiserId
      name
      comment
      intensity
      linkedinGoal
      campaignType
      startDate
      endDate
      linkedinTargetGroups {
        name
        id
        children {
          id
          name
        }
      }
      linkedinTargetCompanyGroups {
        name
        id
        children {
          name
          id
        }
      }
      geoTargetingType
      geoZipCodes
      geoRegionIds
      budget
      status
      creativeStatus
      linkedinAges
      gender
      landingPage
      idkLandingPageYet
      linkedinProfile
      idkLinkedinProfileYet
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign(
    $campaignId: ID!
    $campaignInput: UpdateCampaignInput!
    $additionalInfo: CampaignAdditionalInfoInput!
  ) {
    updateCampaign(
      campaignId: $campaignId
      campaignInput: $campaignInput
      additionalInfo: $additionalInfo
    ) {
      id
      name
    }
  }
`;
