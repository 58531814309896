import React from 'react';
import { AppCase, AppSwitch } from '../../../../../components/AppSwitchCase';
import { getRole } from '../../../../../services/JWT-Auth';
import UserRoles from '../../../../../enums/UserRoles';
import UserEditLinkedinCampaign from '../../../../agencyUsers/LinkedinCampaign/EditCampaign';
import AdminEditLinkedinCampaign from '../../../../adminUsers/LinkedinCampaign/EditCampaign';

const EditLinkedinScene = () => {
  return (
    <AppSwitch test={getRole()}>
      <AppCase value={UserRoles.Admin}>
        <AdminEditLinkedinCampaign />
      </AppCase>
      <AppCase value={UserRoles.User}>
        <UserEditLinkedinCampaign />
      </AppCase>
    </AppSwitch>
  );
};

export default EditLinkedinScene;
