import gql from 'graphql-tag';

export default gql`
  query {
    websites {
      id
      category
      websites
    }
    customWebsites {
      id
      category
      websites {
        name
        categories
        relevance
        isForStudent
        isForGraduate
        isForProspectiveStudents
        isForTrainee
        isForPupils
        isForForeignAffinity
        isAdsAvailable
      }
    }
    relevanceFilter
  }
`;
