import React, { memo } from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Paper,
  Tooltip,
  Radio,
  Typography,
  Box,
  Icon,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';

const StyledPaper = styled(Paper)(({ theme }) => ({
  '&.active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.basic.white,
  },
  '&.error': {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    border: '1px solid',
    boxShadow: 'none',
    boxSizing: 'border-box',
    height: '72px',
  },
}));

const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  height: '72px',
  minWidth: '120px',
  cursor: 'pointer',
}));

const StyledLabelContainer = styled(Box)(({ theme }) => ({
  margin: 'auto',
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.basic.grey,
  '&.active': {
    color: theme.palette.basic.white,
  },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.active': {
    color: theme.palette.basic.white,
  },
}));

const StyledIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '80px',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  color: theme.palette.basic.grey,
  margin: 0,
  position: 'absolute',
  top: 6,
  left: 6,
  '&.active': {
    color: theme.palette.basic.white,
  },
}));

const AppRadioButton = (props) => {
  const {
    onChange,
    checked,
    value,
    required,
    name,
    icon,
    labelText,
    tooltip,
    error,
    onClick,
    disabled,
    interactiveTooltip,
  } = props;

  return (
    <StyledPaper
      onClick={onClick}
      elevation={2}
      className={`${checked ? 'active' : ''} ${error ? 'error' : ''}`}
    >
      <StyledLabel>
        <StyledIconContainer>
          <StyledIcon className={checked ? 'active' : ''} fontSize="large">
            {icon}
          </StyledIcon>
          {tooltip && (
            <StyledTooltip
              interactive={interactiveTooltip}
              className={checked ? 'active' : ''}
              title={tooltip}
            >
              <Info fontSize="inherit" />
            </StyledTooltip>
          )}
        </StyledIconContainer>
        <StyledLabelContainer>
          <Typography>{labelText}</Typography>
        </StyledLabelContainer>
        <Box>
          <StyledRadio
            className={checked ? 'active' : ''}
            color="primary"
            required={required}
            name={name}
            checked={checked}
            onChange={onChange}
            value={value}
            disabled={disabled}
          />
        </Box>
      </StyledLabel>
    </StyledPaper>
  );
};

export default memo(AppRadioButton);
