import React, { memo, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
      {
        margin: 0,
        '-webkit-appearance': 'none',
      },
  },
}));

const LinkedinBudgetInput = (props) => {
  const { handleCampaignState, isFormSubmitted, budget, budgetRecommendation } =
    props;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleBudgetChange = useCallback(
    (event) => {
      const strValue = event.target.value.toString();
      const value = strValue
        .replace(/^[,.]|[^\d|,.]/g, '')
        .replace(/([,.])(\d{0,2}).*/g, '$1$2');
      const budgetNumber = value ? Number(value) : undefined;
      handleCampaignState({ budget: budgetNumber });
    },
    [handleCampaignState]
  );

  const getBudgetError = useCallback(() => {
    if (!isFormSubmitted) {
      return false;
    }
    if (!budget || budget < 2000) {
      return t('createCampaign.errorMin2000');
    }
    if (budget > 100000) {
      return t('createCampaign.errorMax100000');
    }
    if (budgetRecommendation && budget > budgetRecommendation * 5) {
      return t('createCampaign.errorRecommended5x');
    }
    return false;
  }, [budget, isFormSubmitted, budgetRecommendation, t]);

  return (
    <TextField
      placeholder={t('createCampaign.budgetInputPlaceholder')}
      onWheelCapture={(e) => {
        // disable scroll stepper;
        e.target.blur();
      }}
      type="number"
      className={classes.root}
      error={!!getBudgetError()}
      helperText={getBudgetError()}
      value={budget}
      onChange={handleBudgetChange}
      variant="outlined"
      fullWidth
    />
  );
};

export default memo(LinkedinBudgetInput);
