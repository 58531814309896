import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppLabeledCheckbox from '../AppLabeledCheckbox';

const useStyles = makeStyles({
  dialogActionsStyle: {
    padding: '8px 24px',
  },
  dialogContentStyle: {
    paddingTop: 0,
  },
});

const ConfirmationDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    title,
    subtitle,
    checkboxText,
    open,
    onClose,
    onConfirm,
    isChecked,
    isDisabled,
    handleChange,
    isLoading,
    hideCheckbox,
  } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <Box px={3} width={444}>
        <Box mt={4} />
        <DialogTitle>{title}</DialogTitle>
        {!hideCheckbox && (
          <>
            <DialogContent className={classes.dialogContentStyle}>
              <DialogContentText variant="subtitle1" color="secondary">
                {subtitle}
              </DialogContentText>
              <Box mt={3} />
              <AppLabeledCheckbox
                checked={isChecked}
                label={checkboxText}
                onChange={handleChange}
              />
            </DialogContent>
            <Box mt={2} />
          </>
        )}
        <DialogActions className={classes.dialogActionsStyle}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                disabled={isDisabled && !hideCheckbox}
                onClick={onConfirm}
                variant="contained"
                color="primary"
                fullWidth
              >
                {t('deleteAdvertiser.yes')}
                {isLoading && <CircularProgress color="inherit" size={20} />}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2} />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={onClose} color="secondary" fullWidth>
                {t('deleteAdvertiser.no')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        <Box mt={3} />
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
