export default {
  maximumUploadSizeBites: 160000,
  maximumUploadSizeBitesVideo: 137438953472,
  googleMapsApiKey: 'AIzaSyCPoK3dpjQ0NyMLn6ykIVYO2hNyFmqjstw',
  googleMapsLibraries: ['geometry', 'drawing'],
  regionSearchUrl:
    'https://nominatim.openstreetmap.org/search?addressdetails=0&format=json&polygon_geojson=1&q=',
  defaultMapCenter: { lat: 51.0834196, lng: 10 },
  circleDefaultRadius: 3000,
  zipsPerRequest: 50,
  zoomToFetchZipsFrom: 9,
};
