export const CampaignStatus = {
  0: 'Review',
  1: 'Live',
  2: 'Rejected',
  3: 'Ended',
  4: 'Draft',
  5: 'Canceled',
};

export const CreativeStatus = {
  0: 'Review',
  1: 'Approved',
  2: 'Rejected',
};

export const CampaignCreatives = {
  Own: 'own',
  Later: 'later',
  Order: 'order',
};

export const CampaignTypes = {
  Video: 'video',
  Banner: 'banner',
  Social: 'social',
  Linkedin: 'linkedin',
};

export const CampaignSocialAdvertiserMaterialTypes = {
  FeedStatic: 'feedStatic',
  FeedVideo: 'feedVideo',
  StoryStatic: 'storyStatic',
  StoryVideo: 'storyVideo',
};

export const CampaignCreativesTypes = {
  Static: 'static',
  Animation: 'animation',
};

export const CampaignSocialPlatformTypes = {
  Instagram: 'instagram',
  Facebook: 'facebook',
};

export const CampaignDevices = {
  Mobile: 'mobile',
  Desktop: 'desktop',
  Tablet: 'tablet',
  SmartTv: 'smartTv',
};

export const CampaignGenders = {
  Male: 'male',
  Female: 'female',
};

export const CampaignAges = {
  age1825: 0,
  age2635: 1,
  age3645: 2,
  age4655: 3,
  age5665: 4,
  age66: 5,
};

export const CampaignAgesForLinkedin = {
  age1824: 0,
  age2534: 1,
  age3554: 2,
  age55: 3,
};

export const CampaignGoal = {
  Awareness: 0,
  Convince: 1,
  SalesAndLeads: 2,
};

export const LinkedinCampaignGoal = {
  Visitors: 0,
  Interaction: 1,
  Applicants: 2,
  Leads: 3,
};

export const CampaignAdvertisingType = {
  TKP: 'TKP',
  CPC: 'CPC',
};

export const CampaignGeoTargetingType = {
  Land: 0,
  Zip: 1,
  Polygon: 2,
  Radius: 3,
  Categories: 4,
};

export const CampaignWebsitesTargetingType = {
  User: 0,
  Twins: 1,
  NoWebsites: 2,
};

export const CampaignIntensity = {
  1: 'Gering',
  2: 'Mittel',
  3: 'Hoch',
};

export const TARGET_GROUPS = [
  {
    name: 'Automobil',
    percentage: [
      0.094594594594595, 0.154054054054054, 0.094594594594595, 0.07027027027027,
      0.037837837837838, 0.017297297297297,
    ],
  },
  {
    name: 'Bildung',
    percentage: [
      0.105405405405405, 0.135135135135135, 0.072972972972973,
      0.043243243243243, 0.020810810810811, 0.009189189189189,
    ],
  },
  {
    name: 'Bücher und Literatur',
    percentage: [
      0.07027027027027, 0.1, 0.056756756756757, 0.035135135135135,
      0.017027027027027, 0.008108108108108,
    ],
  },
  {
    name: 'Business und Märkte',
    percentage: [
      0.110810810810811, 0.191891891891892, 0.127027027027027,
      0.089189189189189, 0.048648648648649, 0.019189189189189,
    ],
  },
  {
    name: 'Shopping',
    percentage: [
      0.156756756756757, 0.254054054054054, 0.162162162162162,
      0.113513513513514, 0.064864864864865, 0.02972972972973,
    ],
  },
  {
    name: 'Events und Attraktionen',
    percentage: [
      0.137837837837838, 0.202702702702703, 0.116216216216216,
      0.072972972972973, 0.037837837837838, 0.018108108108108,
    ],
  },
  {
    name: 'Essen und Trinken',
    percentage: [
      0.137837837837838, 0.227027027027027, 0.143243243243243, 0.1,
      0.054054054054054, 0.025135135135135,
    ],
  },
  {
    name: 'Familie und Beziehungen',
    percentage: [
      0.127027027027027, 0.202702702702703, 0.124324324324324,
      0.078378378378378, 0.040540540540541, 0.018378378378378,
    ],
  },
  {
    name: 'Eltern',
    percentage: [
      0.000259459459459, 0.010810810810811, 0.015135135135135,
      0.021891891891892, 0.018378378378378, 0.00972972972973,
    ],
  },
  {
    name: 'Filme',
    percentage: [
      0.145945945945946, 0.227027027027027, 0.135135135135135,
      0.089189189189189, 0.045945945945946, 0.020540540540541,
    ],
  },
  {
    name: 'Gesunder Lifestyle',
    percentage: [
      0.043243243243243, 0.062162162162162, 0.02972972972973, 0.015675675675676,
      0.006756756756757, 0.003243243243243,
    ],
  },
  {
    name: 'Haus und Garten',
    percentage: [
      0.086486486486487, 0.162162162162162, 0.105405405405405, 0.07027027027027,
      0.037837837837838, 0.017027027027027,
    ],
  },
  {
    name: 'Haustiere',
    percentage: [
      0.105405405405405, 0.148648648648649, 0.086486486486487,
      0.062162162162162, 0.032432432432433, 0.014864864864865,
    ],
  },
  {
    name: 'Immobilien',
    percentage: [
      0.043243243243243, 0.083783783783784, 0.056756756756757,
      0.037837837837838, 0.02, 0.00972972972973,
    ],
  },
  {
    name: 'Kunst',
    percentage: [
      0.083783783783784, 0.113513513513514, 0.056756756756757, 0.02972972972973,
      0.013243243243243, 0.005945945945946,
    ],
  },
  {
    name: 'Karriere',
    percentage: [
      0.026216216216216, 0.032432432432433, 0.017027027027027, 0.00972972972973,
      0.003513513513514, 0.00127027027027,
    ],
  },
  {
    name: 'Medizin und Gesundheit',
    percentage: [
      0.05945945945946, 0.089189189189189, 0.051351351351351, 0.032432432432433,
      0.017567567567568, 0.008648648648649,
    ],
  },
  {
    name: 'Musik und Audio',
    percentage: [
      0.156756756756757, 0.251351351351351, 0.154054054054054,
      0.108108108108108, 0.05945945945946, 0.027027027027027,
    ],
  },
  {
    name: 'News und Politik',
    percentage: [
      0.035135135135135, 0.083783783783784, 0.056756756756757,
      0.037837837837838, 0.020540540540541, 0.01,
    ],
  },
  {
    name: 'Persönliche Finanzen',
    percentage: [
      0.051351351351351, 0.097297297297297, 0.062162162162162,
      0.040540540540541, 0.022162162162162, 0.01,
    ],
  },
  {
    name: 'Pop Kultur',
    percentage: [
      0.07027027027027, 0.067567567567568, 0.02972972972973, 0.015945945945946,
      0.007837837837838, 0.004594594594595,
    ],
  },
  {
    name: 'Reisen',
    percentage: [
      0.127027027027027, 0.205405405405405, 0.12972972972973, 0.091891891891892,
      0.051351351351351, 0.024054054054054,
    ],
  },
  {
    name: 'Religion und Spiritualität',
    percentage: [
      0.072972972972973, 0.102702702702703, 0.062162162162162,
      0.040540540540541, 0.020810810810811, 0.009459459459459,
    ],
  },
  {
    name: 'Sport',
    percentage: [
      0.148648648648649, 0.243243243243243, 0.151351351351351,
      0.105405405405405, 0.056756756756757, 0.026756756756757,
    ],
  },
  {
    name: 'Studierende',
    percentage: [
      0.078378378378378, 0.078378378378378, 0.02972972972973, 0.014864864864865,
      0.005675675675676, 0.002972972972973,
    ],
  },
  {
    name: 'Style und Mode',
    percentage: [
      0.156756756756757, 0.256756756756757, 0.162162162162162,
      0.113513513513514, 0.064864864864865, 0.02972972972973,
    ],
  },
  {
    name: 'Technologie und Computer',
    percentage: [
      0.143243243243243, 0.22972972972973, 0.143243243243243, 0.1,
      0.054054054054054, 0.025675675675676,
    ],
  },
  {
    name: 'TV',
    percentage: [
      0.105405405405405, 0.154054054054054, 0.081081081081081,
      0.045945945945946, 0.021351351351351, 0.01,
    ],
  },
  {
    name: 'Videospiele',
    percentage: [
      0.108108108108108, 0.154054054054054, 0.083783783783784,
      0.048648648648649, 0.024594594594595, 0.011621621621622,
    ],
  },
  {
    name: 'Wissenschaft',
    percentage: [
      0.025405405405405, 0.032432432432433, 0.016216216216216, 0.00972972972973,
      0.004594594594595, 0.002351351351351,
    ],
  },
  {
    name: 'Museum',
    percentage: [
      0.027027027027027, 0.037837837837838, 0.021891891891892,
      0.014054054054054, 0.007297297297297, 0.004054054054054,
    ],
  },
];
