import gql from 'graphql-tag';

export const GET_ALL_USERS = gql`
  query getUsers($usersFilter: UsersFilter) {
    users(usersFilter: $usersFilter) {
      users {
        id
        email
        username
        invoiceAddress
        logoUrl
        margin
        role
        testPeriodEnd
        disabledEmailNotifications
        staticPrice {
          TKP
          CPC
        }
        disabledMargin
        removed
      }
      count
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($userInput: CreateUserInput!) {
    createUser(userInput: $userInput) {
      id
      email
      username
      invoiceAddress
      logoUrl
      margin
      role
      testPeriodEnd
      disabledMargin
      disabledEmailNotifications
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $userInput: UpdateUserInput!) {
    updateUser(userId: $id, userInput: $userInput) {
      id
      email
      username
      invoiceAddress
      logoUrl
      margin
      role
      testPeriodEnd
      disabledMargin
      disabledEmailNotifications
    }
  }
`;

export const GET_LOGO_UPLOAD_URL = gql`
  query getLogoUploadUrl($name: String!) {
    logoUploadInfo(name: $name) {
      uploadUrl
      filePath
      fileUrl
    }
  }
`;

export const REMOVE_USER = gql`
  mutation removeUser($id: ID!) {
    removeUser(userId: $id) {
      id
      username
    }
  }
`;
