export default function updateQueryParam(
  oldURLSearchParams,
  paramName,
  paramValue
) {
  const newParams = new URLSearchParams(oldURLSearchParams);
  if (paramValue === undefined) newParams.delete(paramName);
  else if (newParams.has(paramName)) {
    newParams.set(paramName, paramValue);
  } else {
    newParams.append(paramName, paramValue);
  }
  return newParams;
}
