import React, { memo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

const LinkedinCalculationInfoBox = (props) => {
  const { estimatedReach, budgetRecommendation, budget, pricing } = props;
  const { t } = useTranslation();
  // todo olo fix this ugly formatting
  const formattedEstimatedRange = JSON.parse(
    JSON.stringify(estimatedReach.toLocaleString('de-DE'))
  );
  const recommendedBudget = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(budgetRecommendation);

  const getEstimatedRange = (estimatedRange) => {
    if (estimatedRange !== '0') {
      return capitalize(estimatedRange);
    }
    return t('campaignCalculationBox.notAvailable');
  };
  const impressions = ((1000 * budget) / pricing.TKP).toFixed();
  const formattedImpressions = JSON.parse(
    JSON.stringify(Number(impressions).toLocaleString('de-DE'))
  );
  return (
    <Grid container alignItems="flex-start" justify="center" direction="column">
      <Box ml={3}>
        <Grid item container direction="column" alignItems="flex-start">
          <Grid xs={12} item>
            <Typography variant="subtitle1">
              {t('campaignCalculationBox.reach')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography>
              {getEstimatedRange(formattedEstimatedRange)}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={2} />

        <Grid item container direction="column" alignItems="flex-start">
          <Grid xs={12} item>
            <Typography variant="subtitle1">
              {t('campaignCalculationBox.budgetRecommendation')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            {!budgetRecommendation && (
              <Typography>{t('createCampaign.notAvailable')}</Typography>
            )}
            {!!budgetRecommendation && (
              <Typography>{recommendedBudget}</Typography>
            )}
          </Grid>
        </Grid>
        <Box mt={2} />

        <Grid item container direction="column" alignItems="flex-start">
          <Grid xs={12} item>
            <Typography variant="subtitle1">
              {t('campaignCalculationBox.impressions')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            {(!budget || !pricing.TKP) && (
              <Typography>{t('createCampaign.notAvailable')}</Typography>
            )}

            {!!budget && !!pricing.TKP && (
              <Typography>{formattedImpressions}</Typography>
            )}
          </Grid>
        </Grid>
        <Box mt={2} />

        <Grid item container direction="column" alignItems="flex-start">
          <Grid xs={12} item>
            <Typography variant="subtitle1">TKP</Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography>
              {new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
              }).format(Number(pricing.TKP.toFixed(2)))}
            </Typography>
          </Grid>
        </Grid>

        <Box mt={2} />

        <Grid item container direction="column" alignItems="flex-start">
          <Grid xs={12} item>
            <Typography variant="subtitle1">
              {t('campaignInfoBox.type')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography>Linkedin</Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default memo(LinkedinCalculationInfoBox);
