import React, { memo, useState } from 'react';
import { Box, Divider, Grid } from '@material-ui/core';

import AudienceTargetingListItems from './components/AudienceTargetingList';
import AudienceTargetingListSubItems from './components/AudienceTargetingListSubItems';
import AudienceTargetingListItemsSelected from './components/AudienceTargetingListSelected';
import CustomCategories from './components/AudienceTargetingCustomCategories';

const AudienceTargeting = (props) => {
  const {
    categoriesState,
    onSelectCategory,
    handleSelectSubCategory,
    isPdfView,
    handleCustomCategories,
  } = props;
  const [categoryShown, setCategoryShown] = useState(0);
  const handleCategoriesShown = (index) => setCategoryShown(index);
  const isCustomCategoriesShown =
    categoryShown ===
    categoriesState.findIndex((el) => el.name === 'User input');

  return (
    <Grid container>
      <Grid item xs={12}>
        <AudienceTargetingListItemsSelected
          categoriesState={categoriesState}
          handleSelectSubCategory={handleSelectSubCategory}
        />
        <Box mt={1} />
      </Grid>
      {!isPdfView && (
        <>
          <Grid item xs={6}>
            <AudienceTargetingListItems
              categories={categoriesState}
              onSelectCategory={onSelectCategory}
              handleCategoriesShown={handleCategoriesShown}
              categoryShown={categoryShown}
            />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs="auto" style={{ flexGrow: 1 }}>
            <AudienceTargetingListSubItems
              categoryIndex={categoryShown}
              onSelectSubCategory={handleSelectSubCategory}
            >
              {categoriesState[categoryShown].children}
            </AudienceTargetingListSubItems>
            {isCustomCategoriesShown && (
              <CustomCategories onFormSubmit={handleCustomCategories} />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default memo(AudienceTargeting);
