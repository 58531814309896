import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Info } from '@material-ui/icons';
import classNames from 'classnames';

import { FemaleIcon, MaleIcon } from '../../../../../../components/CustomIcons';
import AppCheckboxButton from '../../../../../../components/AppCheckboxButton';
import AppLabeledCheckbox from '../../../../../../components/AppLabeledCheckbox';

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  root: {
    '& .MuiFormControlLabel-root': {
      minWidth: '100px',
    },
  },
});

const CampaignStep2 = (props) => {
  const {
    linkedinTargetGroups,
    linkedinTargetCompanyGroups,
    linkedinAges,
    gender,
    landingPage,
    idkLandingPageYet,
    linkedinProfile,
    idkLinkedinProfileYet,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{t('createCampaign.stepTwo')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {/* landing page */}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.landingPage')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={landingPage}
                placeholder={t('createCampaign.landingPage')}
                variant="outlined"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={idkLandingPageYet} />
                }
                label={t('createCampaign.idkLandingPageYet')}
              />
            </Grid>
            <Box mt={3} width="100%" />
          </Grid>

          {/* linkedin company profile */}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.linkedinProfile')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={linkedinProfile}
                placeholder={t('createCampaign.linkedinProfile')}
                variant="outlined"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={idkLinkedinProfileYet} />
                }
                label={t('createCampaign.idkLinkedinProfileYet')}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box my={2} width="100%">
              <Divider />
            </Box>
          </Grid>

          {/* targetGroups */}
          {linkedinTargetGroups && (
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('createCampaign.companyTargetGroups')}
                  <Tooltip
                    title={t('tooltips.companyTargetGroups')}
                    className={classes.tooltip}
                  >
                    <Info color="secondary" fontSize="inherit" />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {linkedinTargetGroups.map(({ name, children }) => (
                  <Grid item xs={12} key={name}>
                    <Typography>{name}</Typography>
                    {children.map(({ name: childName }) => (
                      <Chip
                        color="primary"
                        size="small"
                        label={childName}
                        key={childName}
                        style={{ marginRight: 5, marginBottom: 5 }}
                      />
                    ))}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box my={2} width="100%">
              <Divider />
            </Box>
          </Grid>

          {/* gender/age */}
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Box mt={2} width="100%" />
            </Grid>
            <Grid item xs={12} md={4} className={classes.container}>
              <Typography variant="body1">
                {t('createCampaign.gender')}
                <Tooltip title={t('tooltips.gender')}>
                  <Info color="secondary" fontSize="inherit" />
                </Tooltip>
              </Typography>
              <Box mt={2} width="100%" />
              <Grid container spacing={2}>
                <Grid item>
                  <AppCheckboxButton
                    icon={MaleIcon}
                    labelText={t('createCampaign.male')}
                    name="male"
                    checked={gender.includes('male')}
                  />
                </Grid>
                <Grid item>
                  <AppCheckboxButton
                    icon={FemaleIcon}
                    labelText={t('createCampaign.female')}
                    name="female"
                    checked={gender.includes('female')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" gutterBottom>
                {t('createCampaign.demographicTargeting')}
              </Typography>
              <FormGroup>
                <Grid container className={classNames(classes.root)}>
                  <Grid item xs={12}>
                    <AppLabeledCheckbox
                      checked={Boolean(linkedinAges[0])}
                      label="18-24"
                      name="age1824"
                      value={1}
                    />
                    <AppLabeledCheckbox
                      checked={Boolean(linkedinAges[1])}
                      label="25-34"
                      name="age2534"
                      value={1}
                    />
                    <AppLabeledCheckbox
                      checked={Boolean(linkedinAges[2])}
                      label="35-54"
                      name="age3554"
                      value={1}
                    />
                    <AppLabeledCheckbox
                      checked={Boolean(linkedinAges[3])}
                      label="55+"
                      name="age55"
                      value={1}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </Grid>
            <Box mt={3} width="100%" />
          </Grid>

          <Grid item xs={12}>
            <Box my={2} width="100%">
              <Divider />
            </Box>
          </Grid>

          {/* targetCompanyGroups */}
          {linkedinTargetCompanyGroups && (
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('createCampaign.userTargetGroups')}
                  <Tooltip
                    title={t('tooltips.userTargetGroups')}
                    className={classes.tooltip}
                  >
                    <Info color="secondary" fontSize="inherit" />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {linkedinTargetCompanyGroups.map(({ name, children }) => (
                  <Grid item xs={12} key={name}>
                    <Typography>{name}</Typography>
                    {children.map(({ name: childName }) => (
                      <Chip
                        color="primary"
                        size="small"
                        label={childName}
                        key={childName}
                        style={{ marginRight: 5, marginBottom: 5 }}
                      />
                    ))}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CampaignStep2;
