import React, { useMemo, useState } from 'react';
import { List, ListSubheader, Grid, TextField, Box } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import WebsitesCustomGeneratorListItem from './components/WebsitesCustomGeneratorListItem';

const WebsitesCustomGeneratorList = (props) => {
  const [filter] = useState({});
  const { t } = useTranslation();

  const {
    websites = [],
    categories = [],
    onSelectCustomWebsite,
    relevanceFilter,
    onCategoryChanged,
    onTargetingChange,
    onRelevanceChange,
    handleCustomWebsitesCheckbox,
    isCustomWebsitesChecked,
    onSelectCustomWebsites,
  } = props;

  const targetingOptions = useMemo(
    () => [
      {
        value: 'isForStudent',
        label: t('websitesGenerator.isForStudent'),
      },
      {
        value: 'isForGraduate',
        label: t('websitesGenerator.isForGraduate'),
      },
      {
        value: 'isForProspectiveStudents',
        label: t('websitesGenerator.isForProspectiveStudents'),
      },
      {
        value: 'isForTrainee',
        label: t('websitesGenerator.isForTrainee'),
      },
      {
        value: 'isForPupils',
        label: t('websitesGenerator.isForPupils'),
      },
      {
        value: 'isForForeignAffinity',
        label: t('websitesGenerator.isForForeignAffinity'),
      },
    ],
    []
  );

  const subheader = (
    <ListSubheader>
      <Grid container spacing={1}>
        <Grid item xs={6} lg={4}>
          <Autocomplete
            multiple
            options={categories}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(_, newCategories) => {
              onCategoryChanged(newCategories);
            }}
            value={filter.categories}
            renderOption={(option) => option}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                placeholder={t('websitesGenerator.categoryLabel')}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Autocomplete
            multiple
            options={targetingOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            onChange={(_, targeting) => {
              onTargetingChange(targeting);
            }}
            value={filter.targeting}
            renderOption={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('websitesGenerator.targetingLabel')}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Autocomplete
            multiple
            options={relevanceFilter}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(_, relevance) => {
              onRelevanceChange(relevance);
            }}
            value={filter.relevance}
            renderOption={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('websitesGenerator.relevanceLabel')}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box mt={2} />
    </ListSubheader>
  );
  return (
    <List subheader={subheader} disablePadding>
      <WebsitesCustomGeneratorListItem
        websites={websites}
        onSelectCustomWebsite={onSelectCustomWebsite}
        handleCheckbox={handleCustomWebsitesCheckbox}
        checked={isCustomWebsitesChecked}
        onSelectCustomWebsites={onSelectCustomWebsites}
      />
    </List>
  );
};

export default WebsitesCustomGeneratorList;
