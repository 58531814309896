import React, { useCallback, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MaterialTable, { MTableToolbar, MTableAction } from 'material-table';
import {
  Box,
  Button,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  Paper,
  MenuItem,
  Link as AbsoluteLink,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import {
  FiberManualRecord as FiberManualRecordIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  Search,
} from '@material-ui/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { invert, debounce } from 'lodash';

import {
  COPY_CAMPAIGN,
  GET_ALL_CAMPAIGNS,
  REMOVE_CAMPAIGN,
  GET_ALL_USERS,
  GET_ADVERTISERS,
  GET_USER,
} from './queries';
import {
  CampaignStatus,
  CampaignTypes,
  CreativeStatus,
} from '../../enums/campaign';
import {
  greenColor,
  greyColor,
  lightGreyColor,
  orangeColor,
  redColor,
  turquiseColor,
} from '../../theme';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import CampaignTypeModal from '../../components/CampaignTypeModal';
import Breadcrumb from '../../components/Breadcrumb';
import userCan from '../../services/ACL';
import { CopyIcon, DeleteIcon, SeeIcon } from '../../components/CustomIcons';
import CampaignsAgencyDropdown from './components/CampaignsAgencyDropdown';
import { isAdmin } from '../../services/JWT-Auth';
import UserRoles from '../../enums/UserRoles';
import { useQueryParams } from '../../common/hooks';
import updateQueryParam from '../../services/updateQueryParam';
import { validateCampaignQueryParams } from '../../services/query-params-validators';

const useStyles = makeStyles((theme) => ({
  searchFieldStyle: {
    padding: 12,
    [theme.breakpoints.down(955)]: {
      order: 1,
      paddingRight: 81,
      paddingLeft: 0,
      width: 220,
    },
  },
  toolbarStyle: {
    padding: 0,
    paddingBottom: 20,
    fontSize: 12,
    flexWrap: 'wrap',
    [theme.breakpoints.down(1124)]: {
      alignItems: 'flex-end',
    },
    [theme.breakpoints.down(955)]: {
      alignItems: 'self-end',
    },
  },
  spacerStyle: {
    flex: '1 1 0',
  },
  flexStyle: {
    [theme.breakpoints.down(1124)]: {
      flexDirection: 'column',
    },
  },
  linkStyle: {
    color: turquiseColor,
    textDecoration: 'none',
  },
  Review: {
    color: orangeColor,
  },
  Approved: {
    color: greenColor,
  },
  Rejected: {
    color: redColor,
  },
  Live: {
    color: greenColor,
  },
  Draft: {
    color: lightGreyColor,
  },
  Canceled: {
    color: greyColor,
  },
  Ended: {
    color: greyColor,
  },
  buttonStyle: {
    marginTop: 12,
    marginBottom: 12,
  },
  dropdown: {
    width: 230,
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
  },
}));

const Campaigns = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isApprovingRemoveCampaignShow, setIsApprovingRemoveCampaignShow] =
    useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(undefined);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCampaignTypeModalOpen, setIsCampaignTypeModalOpen] = useState(false);
  const { search: locationSearch } = useLocation();
  const queryParams = useQueryParams(
    locationSearch,
    validateCampaignQueryParams
  );
  const { data, loading } = useQuery(GET_ALL_CAMPAIGNS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        advertiserId: queryParams.get('advertiserId'),
        agencyId: queryParams.get('agencyId'),
        search: queryParams.get('search'),
        page_size: Number(queryParams.get('page_size')),
        page: Number(queryParams.get('page')),
      },
    },
  });
  const { data: advertisersData } = useQuery(GET_ADVERTISERS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const { data: userData } = useQuery(GET_USER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const { data: usersData } = useQuery(GET_ALL_USERS, {
    fetchPolicy: 'network-only',
    skip: !isAdmin(),
    variables: {
      usersFilter: { all: true },
    },
  });
  const [deleteCampaign] = useMutation(REMOVE_CAMPAIGN, {
    refetchQueries: ['getCampaignsPage'],
  });
  const [copyCampaign] = useMutation(COPY_CAMPAIGN, {
    refetchQueries: ['getCampaignsPage'],
  });

  const users = usersData?.users?.users || [];
  const agencies = users.filter(({ role }) => role === UserRoles.User);
  const agency = agencies.find(({ id }) => queryParams.get('agencyId') === id);

  const dropdownAdvertisers = agency
    ? advertisersData?.advertisers?.filter(
        (advertiser) => advertiser.agencyId === agency.id
      )
    : advertisersData?.advertisers;

  const handleRemoveCampaignModal = () => {
    setIsApprovingRemoveCampaignShow(false);
    setIsCheckboxChecked(false);
  };

  const handleCheckbox = () => setIsCheckboxChecked(!isCheckboxChecked);

  const handleAdvertiserChange = (_, advertiser) => {
    const advertiserId = advertiser.props.value;
    const newParams = updateQueryParam(
      updateQueryParam(queryParams, 'advertiserId', advertiserId),
      'page',
      0
    );
    navigate(`?${newParams.toString()}`);
  };

  const handleAgencyChange = ({ target: { value } }) => {
    const newParams = updateQueryParam(
      updateQueryParam(queryParams, 'agencyId', value?.id),
      'page',
      0
    );
    navigate(`?${newParams.toString()}`);
  };

  const onCampaignDelete = async (campaign) => {
    setIsLoading(true);
    let id;
    if (campaignToDelete) {
      id = campaignToDelete.id;
    } else {
      id = campaign.id;
    }
    try {
      await deleteCampaign({ variables: { id } });
      toast.success(t('toasts.campaignRemoved'));
      setIsLoading(false);
    } catch ({ message }) {
      toast.error(message);
      setIsLoading(false);
    }
    setIsApprovingRemoveCampaignShow(false);
    setCampaignToDelete(undefined);
    setIsCheckboxChecked(false);
  };

  const onCampaignDeleteClick = (campaign) => {
    setIsApprovingRemoveCampaignShow(true);
    setCampaignToDelete(campaign);
  };

  const handleCampaignTypeModalConfirm = (type) => {
    navigate({
      pathname: `/create-campaign/${type}`,
    });
    setIsCampaignTypeModalOpen(false);
  };

  const handleCopyCampaign = async (_, rowData) => {
    const { id } = rowData;
    try {
      await copyCampaign({ variables: { id } });
      toast.success(t('toasts.campaignCopied'));
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const handleCreateCampaignModal = () => {
    if (!dropdownAdvertisers?.length) {
      toast.error(t('validationErrors.requiredAdvertiser'));
      return;
    }
    setIsCampaignTypeModalOpen(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((search) => {
      const newParams = updateQueryParam(
        updateQueryParam(queryParams, 'search', search),
        'page',
        0
      );
      navigate(`?${newParams.toString()}`);
    }, 500),
    [queryParams]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncePage = useCallback(
    debounce((pageNumber, pageSize) => {
      const newParams = updateQueryParam(
        updateQueryParam(queryParams, 'page', pageNumber),
        'page_size',
        pageSize
      );
      navigate(`?${newParams.toString()}`);
    }, 500),
    [queryParams]
  );

  const columns = [
    {
      title: t('campaigns.campaign'),
      field: 'name',
      // TODO:
      render: (rowData) => (
        <>
          <Link
            to={`/campaign/${rowData.campaignType || CampaignTypes.Banner}/${
              rowData.id
            }`}
            className={classes.linkStyle}
          >
            {rowData.name}
          </Link>
          <Typography variant="body1" color="secondary">
            #4-22-{rowData.sequence}
          </Typography>
        </>
      ),
    },
    {
      title: t('campaigns.adv_status'),
      field: 'creativeStatus',
      render: (rowData) => (
        <Box display="flex">
          <FiberManualRecordIcon
            fontSize="small"
            className={classes[CreativeStatus[rowData.creativeStatus]]}
          />
          <Typography variant="body2" component="span">
            {t(`creativeStatuses.${CreativeStatus[rowData.creativeStatus]}`)}
          </Typography>
        </Box>
      ),
    },
    {
      title: t('campaigns.camp_status'),
      field: 'status',
      render: (rowData) => {
        return (
          <Box display="flex">
            <FiberManualRecordIcon
              fontSize="small"
              className={classes[CampaignStatus[rowData.status]]}
            />
            <Typography variant="body2" component="span">
              {t(`campaignStatuses.${CampaignStatus[rowData.status]}`)}
            </Typography>
          </Box>
        );
        // }
      },
    },
    {
      title: t('campaigns.adv_materials'),
      render: ({ id }) => (
        <Link to={`/campaign/${id}/attachments`} className={classes.linkStyle}>
          {t('campaigns.adv_materials')}
        </Link>
      ),
    },
    {
      title: t('campaigns.report'),
      field: 'reportLink',
      render: (rowData) => {
        if (rowData.reportLink) {
          return (
            <AbsoluteLink
              href={
                rowData.reportLink.startsWith('http')
                  ? rowData.reportLink
                  : `https://${rowData.reportLink}`
              }
              target="_blank"
              className={classes.linkStyle}
            >
              {t('campaigns.report')}
            </AbsoluteLink>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      title: t('campaigns.contactPerson'),
      field: 'contactPersonEmail',
      hidden: !userData?.user?.additionalContactField,
      render: (rowData) => {
        return (
          <Typography variant="body2" component="span">
            {rowData.contactPersonEmail || '-'}
          </Typography>
        );
      },
    },
  ];

  const checkDeleteConfirmation = (campaign = {}) => {
    const isCampaignLive =
      campaign?.status === Number(invert(CampaignStatus).Live);
    return !isCampaignLive;
  };

  return (
    <>
      <Breadcrumb>
        <Typography color="textPrimary">{t('campaigns.campaigns')}</Typography>
      </Breadcrumb>

      <MaterialTable
        isLoading={loading || isLoading}
        icons={{ ResetSearch: () => <div /> }}
        columns={columns}
        data={data?.campaigns?.campaigns}
        onSearchChange={debounceSearch}
        onChangePage={debouncePage}
        totalCount={data?.campaigns?.count || 0}
        page={Number(queryParams.get('page')) || 0}
        title={
          <Box display="flex" className={classes.flexStyle}>
            <Box mr={2} mt={2}>
              <Typography variant="h6">{t('campaigns.campaigns')}</Typography>
            </Box>
            {isAdmin() && (
              <CampaignsAgencyDropdown
                className={classes.dropdown}
                agencies={agencies}
                onChange={handleAgencyChange}
                value={agency}
              />
            )}
            {dropdownAdvertisers && (
              <FormControl variant="outlined" className={classes.dropdown}>
                <InputLabel>{t('campaigns.selectAdv')}</InputLabel>
                <Select
                  defaultValue={undefined}
                  onChange={handleAdvertiserChange}
                  value={queryParams.get('advertiserId') || undefined}
                  label={t('campaigns.selectAdv')}
                >
                  <MenuItem value={undefined} key={undefined}>
                    {t('campaigns.allAdv')}
                  </MenuItem>
                  {dropdownAdvertisers?.map((advertiser) => (
                    <MenuItem value={advertiser.id} key={advertiser.id}>
                      {advertiser.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        }
        localization={{
          header: {
            actions: t('campaigns.action'),
          },
          body: {
            emptyDataSourceMessage: t('campaigns.noData'),
          },
          toolbar: {
            searchPlaceholder: t('layout.search'),
          },
          pagination: {
            labelRowsSelect: t('campaigns.rows'),
          },
        }}
        options={{
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
          searchFieldVariant: 'outlined',
          pageSize: Number(queryParams.get('page_size')) || 10,
          pageSizeOptions: [5, 10, 20],
          search: false,
        }}
        actions={[
          {
            icon: () => <SeeIcon color="secondary" />,
            tooltip: t('tooltips.see'),
            onClick: (_, rowData) =>
              navigate(
                `/campaign/${rowData.campaignType || CampaignTypes.Banner}/${
                  rowData.id
                }`
              ),
          },
          {
            icon: () => <CopyIcon color="secondary" />,
            tooltip: t('tooltips.copy'),
            onClick: handleCopyCampaign,
          },
          (rowData) => {
            return {
              icon: 'edit',
              tooltip: t('tooltips.edit'),
              onClick: (_, clickedRowData) => {
                return navigate(
                  `/edit-campaign/${clickedRowData.campaignType}/${clickedRowData.id}`
                );
              },
              disabled: rowData.status !== Number(invert(CampaignStatus).Draft),
            };
          },
          {
            icon: () => <DeleteIcon color="secondary" />,
            tooltip: t('tooltips.delete'),
            onClick: (_, rowData) =>
              rowData.status === Number(invert(CampaignStatus).Draft)
                ? onCampaignDelete(rowData)
                : onCampaignDeleteClick(rowData),
          },
          {
            render: (props) =>
              userCan('see-advertiser-page') && (
                <Button
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  variant="contained"
                  color="primary"
                  className={classes.buttonStyle}
                >
                  <AddCircleOutlineIcon style={{ fontSize: 15 }} />
                  <Box ml={1}>{t('campaigns.button')}</Box>
                </Button>
              ),
            icon: '',
            position: 'toolbar',
            onClick: handleCreateCampaignModal,
          },
          {
            render: (props) => (
              <TextField
                {...props}
                defaultValue={queryParams.get('search')}
                variant="outlined"
                className={classes.searchFieldStyle}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginBottom: '12px',
                  marginTop: '12px',
                }}
                placeholder={t('layout.search')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search
                        style={{
                          color: greyColor,
                          fontSize: '1.25rem',
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                onInput={(e) => debounceSearch(e.target.value)}
              />
            ),
            icon: '',
            position: 'toolbar',
          },
        ]}
        components={{
          // eslint-disable-next-line react/jsx-props-no-spreading
          Container: (props) => <Paper elevation={0} {...props} />,
          Toolbar: (props) => (
            <MTableToolbar
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              classes={{
                spacer: classes.spacerStyle,
                searchField: classes.searchFieldStyle,
                root: classes.toolbarStyle,
              }}
            />
          ),
          Action: (props) => {
            const {
              action: { render, ...restActionProps },
            } = props;
            return render ? (
              render(restActionProps)
            ) : (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <MTableAction {...props} />
            );
          },
        }}
      />
      <ConfirmationDialog
        open={isApprovingRemoveCampaignShow}
        onClose={handleRemoveCampaignModal}
        title={t('deleteCampaign.title')}
        checkboxText={t('deleteCampaign.checkbox')}
        onConfirm={onCampaignDelete}
        isChecked={isCheckboxChecked}
        isDisabled={!isCheckboxChecked}
        hideCheckbox={checkDeleteConfirmation(campaignToDelete)}
        handleChange={handleCheckbox}
        isLoading={isLoading}
      />
      <CampaignTypeModal
        open={isCampaignTypeModalOpen}
        onClose={() => setIsCampaignTypeModalOpen(false)}
        onConfirm={handleCampaignTypeModalConfirm}
      />
    </>
  );
};

export default Campaigns;
