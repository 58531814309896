export default {
  Video: ['video/mp4'],
  File: [
    'image/png',
    'image/jpeg',
    'image/svg+xml',
    'application/zip',
    'application/zip-compressed',
    'application/x-zip-compressed',
  ],
};
