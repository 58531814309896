import {
  getBasePrice,
  getGeoTargetingPrices,
  getTargetGroupsPrice,
  getWebsitesTypesPricing,
} from '../../../../enums/prices';
import {
  CampaignAdvertisingType,
  CampaignGeoTargetingType,
  CampaignTypes,
  CampaignWebsitesTargetingType,
  TARGET_GROUPS,
} from '../../../../enums/campaign';
import {
  ageCategories,
  femalesPercentage,
  getDesktopUsers,
  getMobileUsers,
  getSmartTv,
  getTabletUsers,
  malesPercentage,
  minTgOverlap,
  tgOverlap,
} from '../../../../enums/statictics';

//   startDate/endDate => isoDate;
export const daysCount = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return 0;
  }

  const startTimeStamp = new Date(startDate);
  const endTimeStamp = new Date(endDate);

  return (
    Math.round(
      (endTimeStamp.getTime() - startTimeStamp.getTime()) /
        (1000 * 60 * 60 * 24)
    ) + 1
  );
};

export const getPricesWithMargin = (
  advMargin = 0,
  agencyMargin = 1,
  type,
  advertisingType,
  socialAdvertiserMaterialType,
  socialPlatform
) => {
  const advertiserMargin = 1 + advMargin / 100;
  const margin = advertiserMargin * agencyMargin;
  const geoTargetingPrices = getGeoTargetingPrices(
    type,
    advertisingType,
    socialAdvertiserMaterialType
  );
  const websitesTypesPricing = getWebsitesTypesPricing(advertisingType);
  const targetGroupsPrice = getTargetGroupsPrice(advertisingType);

  return {
    base:
      getBasePrice(
        type,
        advertisingType,
        socialAdvertiserMaterialType,
        socialPlatform
      ) * margin,
    geoTargeting: {
      zip: geoTargetingPrices.zip * margin,
      polygon: geoTargetingPrices.polygon * margin,
      radius: geoTargetingPrices.radius * margin,
      category: geoTargetingPrices.category * margin,
    },
    websites: {
      userDefined: websitesTypesPricing.userDefined * margin,
      twins: websitesTypesPricing.twins * margin,
    },
    targetGroups: targetGroupsPrice * margin,
  };
};

export const getPricesPerUser = (params) => {
  const { campaignValue, agency } = params;
  const { margin: agencyMargin } = agency || {};
  const { campaignDetails, geoTargeting, campaignTargeting, campaignPricing } =
    campaignValue;
  const { advertiser, type, socialPlatform } = campaignDetails;
  const { geoTargetingType } = geoTargeting;
  const { websitesTargeting, targetGroups } = campaignTargeting;
  const { socialAdvertiserMaterialType } = campaignPricing;

  const advMargin = advertiser?.margin;
  // TKP pricing start
  const TKPPricing = getPricesWithMargin(
    advMargin,
    agencyMargin,
    type,
    CampaignAdvertisingType.TKP,
    socialAdvertiserMaterialType,
    socialPlatform
  );
  const TKPPricingWithoutAdvMargin = getPricesWithMargin(
    undefined,
    agencyMargin,
    type,
    CampaignAdvertisingType.TKP,
    socialAdvertiserMaterialType,
    socialPlatform
  );
  const TKPPriceWithoutMargins = getPricesWithMargin(
    undefined,
    undefined,
    type,
    CampaignAdvertisingType.TKP,
    socialAdvertiserMaterialType,
    socialPlatform
  );
  // TKP pricing ends

  // CPC pricing start
  const CPCPricing = getPricesWithMargin(
    advMargin,
    agencyMargin,
    type,
    CampaignAdvertisingType.CPC,
    socialAdvertiserMaterialType,
    socialPlatform
  );
  const CPCPricingWithoutAdvMargin = getPricesWithMargin(
    undefined,
    agencyMargin,
    type,
    CampaignAdvertisingType.CPC,
    socialAdvertiserMaterialType,
    socialPlatform
  );
  const CPCPriceWithoutMargins = getPricesWithMargin(
    undefined,
    undefined,
    type,
    CampaignAdvertisingType.CPC,
    socialAdvertiserMaterialType,
    socialPlatform
  );
  // CPC pricing ends

  const calculatePrice = (pricing) => {
    return (
      pricing.base +
      (geoTargetingType === CampaignGeoTargetingType.Zip
        ? pricing.geoTargeting.zip
        : 0) +
      (geoTargetingType === CampaignGeoTargetingType.Polygon
        ? pricing.geoTargeting.polygon
        : 0) +
      (geoTargetingType === CampaignGeoTargetingType.Radius
        ? pricing.geoTargeting.radius
        : 0) +
      (geoTargetingType === CampaignGeoTargetingType.Categories
        ? pricing.geoTargeting.category
        : 0) +
      (websitesTargeting === CampaignWebsitesTargetingType.User
        ? pricing.websites.userDefined
        : 0) +
      (websitesTargeting === CampaignWebsitesTargetingType.Twins
        ? pricing.websites.twins
        : 0) +
      (targetGroups.length ? pricing.targetGroups : 0)
    );
  };

  const advTypePricing = {
    CPC: {
      viewPrice: calculatePrice(CPCPricing),
      viewPriceWithoutAdvMargin: calculatePrice(CPCPricingWithoutAdvMargin),
      viewPriceWithoutMargins: calculatePrice(CPCPriceWithoutMargins),
      price: calculatePrice(CPCPricing),
      priceWithoutAdvMargin: calculatePrice(CPCPricingWithoutAdvMargin),
      priceWithoutMargins: calculatePrice(CPCPriceWithoutMargins),
    },
    TKP: {
      viewPrice: calculatePrice(TKPPricing),
      viewPriceWithoutAdvMargin: calculatePrice(TKPPricingWithoutAdvMargin),
      viewPriceWithoutMargins: calculatePrice(TKPPriceWithoutMargins),
      price: calculatePrice(TKPPricing) / 1000,
      priceWithoutAdvMargin: calculatePrice(TKPPricingWithoutAdvMargin) / 1000,
      priceWithoutMargins: calculatePrice(TKPPriceWithoutMargins) / 1000,
    },
  };
  return advTypePricing;
};
export const getPricePerUser = (params) => {
  const { campaignValue } = params;
  const { campaignDetails } = campaignValue;
  const { advertisingType } = campaignDetails;
  const advType = advertisingType || CampaignAdvertisingType.TKP;
  const prices = getPricesPerUser(params);

  return prices[advType];
};

export const getAgesPercent = (ages) => {
  const result = ages.reduce((acc, ageSelected, index) => {
    if (!ageSelected) {
      return acc;
    }
    return acc + ageCategories[index];
  }, 0);
  return Math.min(result, 1);
};

export const getTargetAgeCategoriesPercent = (ages, targetGroups, type) => {
  const selectedTargetGroups = targetGroups.map((targetGroup) =>
    TARGET_GROUPS.find((tg) => tg.name === targetGroup)
  );

  const targetGroupsPercentages = selectedTargetGroups.map((tg) => {
    return ages.reduce((acc, ageSelected, index) => {
      if (ageSelected) {
        return acc + tg.percentage[index];
      }
      return acc;
    }, 0);
  });

  targetGroupsPercentages.sort((a, b) => b - a);

  const targetGroupsPercentage = targetGroupsPercentages.reduce(
    (totalPercentage, curr, tgIndex) => {
      const overlapFactor = tgOverlap[tgIndex] || minTgOverlap;
      const percentagePerTGWithOverlapFactor = curr * overlapFactor;
      return percentagePerTGWithOverlapFactor + totalPercentage;
    },
    0
  );

  if (type === CampaignTypes.Social) {
    const socialCoefficientCorrection = targetGroupsPercentage * 1.13;
    return Math.min(socialCoefficientCorrection, 1);
  }

  return Math.min(targetGroupsPercentage, 1);
};

export const getReach = (campaignValue) => {
  const { campaignDetails, geoTargeting, campaignTargeting } = campaignValue;
  const { reach } = geoTargeting;
  const { gender, devices, websitesTargeting, targetGroups, ages } =
    campaignTargeting;
  const { type } = campaignDetails;

  // age serialisation
  const isForMale = gender.includes('male');
  const isForFemale = gender.includes('female');

  // devices serialisation
  const isForDesktop = devices.includes('desktop');
  const isForMobile = devices.includes('mobile');
  const isForTablet = devices.includes('tablet');
  const isForSmartTv = devices.includes('smartTv');

  const genderPercentage =
    (isForMale ? 1 : 0) * malesPercentage +
    (isForFemale ? 1 : 0) * femalesPercentage;

  const devicesPercentage =
    (isForDesktop ? 1 : 0) * getDesktopUsers(type) +
    (isForMobile ? 1 : 0) * getMobileUsers(type) +
    (isForTablet ? 1 : 0) * getTabletUsers(type) +
    (isForSmartTv ? 1 : 0) * getSmartTv(type);

  const websiteTypePercentage =
    websitesTargeting === CampaignWebsitesTargetingType.User ? 0.5 : 1;
  const populationAgePercentage = getAgesPercent(ages);
  const categoryAgesPercentage = getTargetAgeCategoriesPercent(
    ages,
    targetGroups,
    type
  );

  const reachIgnoringAge =
    reach * genderPercentage * devicesPercentage * websiteTypePercentage;
  const reachWithPopulationAge = reachIgnoringAge * populationAgePercentage;
  const reachWithCategoryAge = reachIgnoringAge * categoryAgesPercentage;

  const totalReach = targetGroups.length
    ? reachWithCategoryAge
    : reachWithPopulationAge;

  return Math.round(Math.min(totalReach, reachWithPopulationAge));
};

export const isReachAvailable = (geoTargetingType) => {
  return !(
    geoTargetingType === CampaignGeoTargetingType.Polygon ||
    geoTargetingType === CampaignGeoTargetingType.Radius
  );
};
export const budgetMaxLimit = 100000;

export const getBudgetRecommendation = (
  reach,
  intensity,
  pricePerUser,
  days,
  advertisingType
) => {
  const recommendation =
    reach *
    (intensity * 0.2) *
    pricePerUser *
    days *
    (advertisingType === CampaignAdvertisingType.TKP ||
    // eslint-disable-next-line eqeqeq
    advertisingType == undefined
      ? 1
      : 0.001);
  return recommendation > budgetMaxLimit ? budgetMaxLimit : recommendation;
};
