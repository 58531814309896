import { min } from 'lodash';
// linkedin calculation data start //
export const linkedinUsersPercentage = 0.15;
export const linkedinBasePrice = 19;

export const linkedinAgeCategories = [
  0.2192021043, 0.5611573871, 0.201665936, 0.02192021043,
];

export const linkedinGenderCategories = {
  male: 0.56,
  female: 0.44,
};

export const linkedinBudgetMaxLimit = 100000;
// reachPercentages:
export const linkedinTargetGroupsItems = [
  {
    id: 'ausbildung',
    name: 'Ausbildung',
    value: 'Ausbildung',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'ausbildungXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 9.287925697,
        isSelected: false,
        reachPercentages: 0.0002279701885,
      },
      {
        id: 'ausbildungXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001753616835,
      },
      {
        id: 'ausbildungXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.002279701885,
      },
      {
        id: 'ausbildungXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.004471722929,
      },
      {
        id: 'ausbildungXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.002367382727,
      },
      {
        id: 'ausbildungXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.002455063569,
      },
      {
        id: 'ausbildungXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.005523893029,
      },
      {
        id: 'ausbildungXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.003068829461,
      },
      {
        id: 'ausbildungXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001402893468,
      },
    ],
  },
  {
    id: 'baugewerbe',
    name: 'Baugewerbe',
    value: 'Baugewerbe',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'baugewerbeXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 5.85618977,
        isSelected: false,
        reachPercentages: 0.00007189829022,
      },
      {
        id: 'baugewerbeXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001753616835,
      },
      {
        id: 'baugewerbeXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001315212626,
      },
      {
        id: 'baugewerbeXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001052170101,
      },
      {
        id: 'baugewerbeXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.0009644892591,
      },
      {
        id: 'baugewerbeXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1.076555024,
        isSelected: false,
        reachPercentages: 0.0006313020605,
      },
      {
        id: 'baugewerbeXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001139850943,
      },
      {
        id: 'baugewerbeXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1.101591187,
        isSelected: false,
        reachPercentages: 0.000657606313,
      },
      {
        id: 'baugewerbeXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001402893468,
      },
    ],
  },
  {
    id: 'design',
    name: 'Design',
    value: 'Design',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'designXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 2.059496568,
        isSelected: false,
        reachPercentages: 0.0007715914073,
      },
      {
        id: 'designXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1.052631579,
        isSelected: false,
        reachPercentages: 0.001402893468,
      },
      {
        id: 'designXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001139850943,
      },
      {
        id: 'designXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1.076555024,
        isSelected: false,
        reachPercentages: 0.0815,
      },
      {
        id: 'designXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1.578947368,
        isSelected: false,
        reachPercentages: 0.0004647084612,
      },
      {
        id: 'designXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1.973684211,
        isSelected: false,
        reachPercentages: 0.0002104340202,
      },
      {
        id: 'designXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 2.368421053,
        isSelected: false,
        reachPercentages: 0.0001841297676,
      },
      {
        id: 'designXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 5.157894737,
        isSelected: false,
        reachPercentages: 0.00003945637878,
      },
      {
        id: 'designXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 3.643724696,
        isSelected: false,
        reachPercentages: 0.0001052170101,
      },
    ],
  },
  {
    id: 'dienstleistungen_für_Unternehmen',
    name: 'Dienstleistungen für Unternehmen',
    value: 'Dienstleistungen für Unternehmen',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'dienstleistungen_für_UnternehmenXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001665935993,
      },
      {
        id: 'dienstleistungen_für_UnternehmenXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.007189829022,
      },
      {
        id: 'dienstleistungen_für_UnternehmenXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.005699254713,
      },
      {
        id: 'dienstleistungen_für_UnternehmenXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.003857957036,
      },
      {
        id: 'dienstleistungen_für_UnternehmenXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.002192021043,
      },
      {
        id: 'dienstleistungen_für_UnternehmenXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001665935993,
      },
      {
        id: 'dienstleistungen_für_UnternehmenXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.002718106094,
      },
      {
        id: 'dienstleistungen_für_UnternehmenXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.00149057431,
      },
      {
        id: 'dienstleistungen_für_UnternehmenXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.004471722929,
      },
    ],
  },
  {
    id: 'einzelhandel',
    name: 'Einzelhandel',
    value: 'Einzelhandel',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'einzelhandelXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 4.148606811,
        isSelected: false,
        reachPercentages: 0.00009644892591,
      },
      {
        id: 'einzelhandelXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001402893468,
      },
      {
        id: 'einzelhandelXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001315212626,
      },
      {
        id: 'einzelhandelXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.0008768084174,
      },
      {
        id: 'einzelhandelXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1.155327343,
        isSelected: false,
        reachPercentages: 0.0007277509864,
      },
      {
        id: 'einzelhandelXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1.480263158,
        isSelected: false,
        reachPercentages: 0.0006137658922,
      },
      {
        id: 'einzelhandelXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001841297676,
      },
      {
        id: 'einzelhandelXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1.315789474,
        isSelected: false,
        reachPercentages: 0.000657606313,
      },
      {
        id: 'einzelhandelXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.005523893029,
      },
    ],
  },
  {
    id: 'energie_and_bergbau',
    name: 'Energie & Bergbau',
    value: 'Energie & Bergbau',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'energie_and_bergbauXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 10.47368421,
        isSelected: false,
        reachPercentages: 0,
      },
      {
        id: 'energie_and_bergbauXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1.246537396,
        isSelected: false,
        reachPercentages: 0.000604997808,
      },
      {
        id: 'energie_and_bergbauXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.0006751424814,
      },
      {
        id: 'energie_and_bergbauXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1.280227596,
        isSelected: false,
        reachPercentages: 0.0007540552389,
      },
      {
        id: 'energie_and_bergbauXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1.127819549,
        isSelected: false,
        reachPercentages: 0.0006839105655,
      },
      {
        id: 'energie_and_bergbauXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 13.53383459,
        isSelected: false,
        reachPercentages: 0.0004822446295,
      },
      {
        id: 'energie_and_bergbauXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.00149057431,
      },
      {
        id: 'energie_and_bergbauXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1.578947368,
        isSelected: false,
        reachPercentages: 0.0005962297238,
      },
      {
        id: 'energie_and_bergbauXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.003331871986,
      },
    ],
  },
  {
    id: 'fertigung',
    name: 'Fertigung',
    value: 'Fertigung',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'fertigungXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 1.821862348,
        isSelected: false,
        reachPercentages: 0.0002367382727,
      },
      {
        id: 'fertigungXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.003507233669,
      },
      {
        id: 'fertigungXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.005699254713,
      },
      {
        id: 'fertigungXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.006839105655,
      },
      {
        id: 'fertigungXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.00604997808,
      },
      {
        id: 'fertigungXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.004208680403,
      },
      {
        id: 'fertigungXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.009644892591,
      },
      {
        id: 'fertigungXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.004120999562,
      },
      {
        id: 'fertigungXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.03331871986,
      },
    ],
  },
  {
    id: 'finanzwesen',
    name: 'Finanzwesen',
    value: 'Finanzwesen',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'finanzwesenXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 2.960526316,
        isSelected: false,
        reachPercentages: 0.0001578255151,
      },
      {
        id: 'finanzwesenXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001841297676,
      },
      {
        id: 'finanzwesenXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.002192021043,
      },
      {
        id: 'finanzwesenXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001665935993,
      },
      {
        id: 'finanzwesenXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001402893468,
      },
      {
        id: 'finanzwesenXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001402893468,
      },
      {
        id: 'finanzwesenXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.004384042087,
      },
      {
        id: 'finanzwesenXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001841297676,
      },
      {
        id: 'finanzwesenXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.008417360807,
      },
    ],
  },
  {
    id: 'freizeit_and_reisen',
    name: 'Freizeit & Reisen',
    value: 'Freizeit & Reisen',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'freizeit_and_reisenXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 3.157894737,
        isSelected: false,
        reachPercentages: 0.0001315212626,
      },
      {
        id: 'freizeit_and_reisenXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001578255151,
      },
      {
        id: 'freizeit_and_reisenXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.00149057431,
      },
      {
        id: 'freizeit_and_reisenXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001139850943,
      },
      {
        id: 'freizeit_and_reisenXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.0008680403332,
      },
      {
        id: 'freizeit_and_reisenXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1.214574899,
        isSelected: false,
        reachPercentages: 0.0005786935555,
      },
      {
        id: 'freizeit_and_reisenXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001052170101,
      },
      {
        id: 'freizeit_and_reisenXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1.480263158,
        isSelected: false,
        reachPercentages: 0.0005874616396,
      },
      {
        id: 'freizeit_and_reisenXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.002718106094,
      },
    ],
  },
  {
    id: 'geisteswissenschaften',
    name: 'Geisteswissenschaften',
    value: 'Geisteswissenschaften',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'geisteswissenschaftenXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 2.631578947,
        isSelected: false,
        reachPercentages: 0.0005699254713,
      },
      {
        id: 'geisteswissenschaftenXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1.894736842,
        isSelected: false,
        reachPercentages: 0.0005085488821,
      },
      {
        id: 'geisteswissenschaftenXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 2.786377709,
        isSelected: false,
        reachPercentages: 0.0002718106094,
      },
      {
        id: 'geisteswissenschaftenXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 3.859649123,
        isSelected: false,
        reachPercentages: 0.0001665935993,
      },
      {
        id: 'geisteswissenschaftenXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 4.833512352,
        isSelected: false,
        reachPercentages: 0.00008768084174,
      },
      {
        id: 'geisteswissenschaftenXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 61.40350877,
        isSelected: false,
        reachPercentages: 0.00005173169662,
      },
      {
        id: 'geisteswissenschaftenXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 2.274204029,
        isSelected: false,
        reachPercentages: 0.00007365190706,
      },
      {
        id: 'geisteswissenschaftenXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 10.47368421,
        isSelected: false,
        reachPercentages: 0,
      },
      {
        id: 'geisteswissenschaftenXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 3.846153846,
        isSelected: false,
        reachPercentages: 0.00003857957036,
      },
    ],
  },
  {
    id: 'gesundheitspflege',
    name: 'Gesundheitspflege',
    value: 'Gesundheitspflege',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'gesundheitspflegeXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 2.786377709,
        isSelected: false,
        reachPercentages: 0.0002104340202,
      },
      {
        id: 'gesundheitspflegeXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.002367382727,
      },
      {
        id: 'gesundheitspflegeXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.002367382727,
      },
      {
        id: 'gesundheitspflegeXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001753616835,
      },
      {
        id: 'gesundheitspflegeXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001227531784,
      },
      {
        id: 'gesundheitspflegeXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001052170101,
      },
      {
        id: 'gesundheitspflegeXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.002981148619,
      },
      {
        id: 'gesundheitspflegeXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001928978518,
      },
      {
        id: 'gesundheitspflegeXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.007365190706,
      },
    ],
  },
  {
    id: 'hardware_and_netzwerk',
    name: 'Hardware & Netzwerk',
    value: 'Hardware & Netzwerk',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'hardware_and_netzwerkXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 2.786377709,
        isSelected: false,
        reachPercentages: 0.0007803594915,
      },
      {
        id: 'hardware_and_netzwerkXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1.821862348,
        isSelected: false,
        reachPercentages: 0.0003770276195,
      },
      {
        id: 'hardware_and_netzwerkXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1.353383459,
        isSelected: false,
        reachPercentages: 0.0006926786497,
      },
      {
        id: 'hardware_and_netzwerkXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1.127819549,
        isSelected: false,
        reachPercentages: 0.0008768084174,
      },
      {
        id: 'hardware_and_netzwerkXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1.246537396,
        isSelected: false,
        reachPercentages: 0.0005786935555,
      },
      {
        id: 'hardware_and_netzwerkXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1.894736842,
        isSelected: false,
        reachPercentages: 0.0004647084612,
      },
      {
        id: 'hardware_and_netzwerkXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1.101591187,
        isSelected: false,
        reachPercentages: 0.0009644892591,
      },
      {
        id: 'hardware_and_netzwerkXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 2.493074792,
        isSelected: false,
        reachPercentages: 0.000403331872,
      },
      {
        id: 'hardware_and_netzwerkXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.004120999562,
      },
    ],
  },
  {
    id: 'immobilien',
    name: 'Immobilien',
    value: 'Immobilien',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'immobilienXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 4.94610019,
        isSelected: false,
        reachPercentages: 0.00006663743972,
      },
      {
        id: 'immobilienXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001139850943,
      },
      {
        id: 'immobilienXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001052170101,
      },
      {
        id: 'immobilienXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1.029748284,
        isSelected: false,
        reachPercentages: 0.0006225339763,
      },
      {
        id: 'immobilienXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1.246537396,
        isSelected: false,
        reachPercentages: 0.0004647084612,
      },
      {
        id: 'immobilienXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1.633393829,
        isSelected: false,
        reachPercentages: 0.0003068829461,
      },
      {
        id: 'immobilienXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1.528013582,
        isSelected: false,
        reachPercentages: 0.0004471722929,
      },
      {
        id: 'immobilienXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 3.947368421,
        isSelected: false,
        reachPercentages: 0.0001227531784,
      },
      {
        id: 'immobilienXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1.39318885448916,
        isSelected: false,
        reachPercentages: 0.0003770276195,
      },
    ],
  },
  {
    id: 'juristische_dienstleistungen',
    name: 'Juristische Dienstleistungen',
    value: 'Juristische Dienstleistungen',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'juristische_dienstleistungenXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 3.383458647,
        isSelected: false,
        reachPercentages: 0.0002279701885,
      },
      {
        id: 'juristische_dienstleistungenXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1.184210526,
        isSelected: false,
        reachPercentages: 0.0008417360807,
      },
      {
        id: 'juristische_dienstleistungenXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.0008768084174,
      },
      {
        id: 'juristische_dienstleistungenXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.0007014467339,
      },
      {
        id: 'juristische_dienstleistungenXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1.15532734274711,
        isSelected: false,
        reachPercentages: 0.0008417360807,
      },
      {
        id: 'juristische_dienstleistungenXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1.184210526,
        isSelected: false,
        reachPercentages: 0.0004120999562,
      },
      {
        id: 'juristische_dienstleistungenXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.0007189829022,
      },
      {
        id: 'juristische_dienstleistungenXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1.31578947368421,
        isSelected: false,
        reachPercentages: 0.0002279701885,
      },
      {
        id: 'juristische_dienstleistungenXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 2.493074792,
        isSelected: false,
        reachPercentages: 0.0001139850943,
      },
    ],
  },
  {
    id: 'konsumgüter',
    name: 'Konsumgüter',
    value: 'Konsumgüter',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'konsumgüterXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 2.786377709,
        isSelected: false,
        reachPercentages: 0,
      },
      {
        id: 'konsumgüterXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.0001578255151,
      },
      {
        id: 'konsumgüterXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001578255151,
      },
      {
        id: 'konsumgüterXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001928978518,
      },
      {
        id: 'konsumgüterXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001665935993,
      },
      {
        id: 'konsumgüterXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001052170101,
      },
      {
        id: 'konsumgüterXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.0008768084174,
      },
      {
        id: 'konsumgüterXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1.05263157894737,
        isSelected: false,
        reachPercentages: 0.00201665936,
      },
      {
        id: 'konsumgüterXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001139850943,
      },
    ],
  },
  {
    id: 'landwirtschaft',
    name: 'Landwirtschaft',
    value: 'Landwirtschaft',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'landwirtschaftXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 8.014354067,
        isSelected: false,
        reachPercentages: 0.005786935555,
      },
      {
        id: 'landwirtschaftXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 3.383458647,
        isSelected: false,
        reachPercentages: 0.0002104340202,
      },
      {
        id: 'landwirtschaftXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 4.30622009569378,
        isSelected: false,
        reachPercentages: 0.0001753616835,
      },
      {
        id: 'landwirtschaftXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 3.673245614,
        isSelected: false,
        reachPercentages: 0.00009644892591,
      },
      {
        id: 'landwirtschaftXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 4.306220096,
        isSelected: false,
        reachPercentages: 0.0001139850943,
      },
      {
        id: 'landwirtschaftXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 5.93031875463306,
        isSelected: false,
        reachPercentages: 0.00008768084174,
      },
      {
        id: 'landwirtschaftXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 4.631578947,
        isSelected: false,
        reachPercentages: 0.00005523893029,
      },
      {
        id: 'landwirtschaftXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 4.114832536,
        isSelected: false,
        reachPercentages: 0.0001052170101,
      },
      {
        id: 'landwirtschaftXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 3.377192982,
        isSelected: false,
        reachPercentages: 0.0001052170101,
      },
    ],
  },
  {
    id: 'medien_and_kommunikation',
    name: 'Medien & Kommunikation',
    value: 'Medien & Kommunikation',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'medien_and_kommunikationXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 1.393188854,
        isSelected: false,
        reachPercentages: 0.0001227531784,
      },
      {
        id: 'medien_and_kommunikationXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.0008768084174,
      },
      {
        id: 'medien_and_kommunikationXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.003244191144,
      },
      {
        id: 'medien_and_kommunikationXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.004647084612,
      },
      {
        id: 'medien_and_kommunikationXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.002981148619,
      },
      {
        id: 'medien_and_kommunikationXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001753616835,
      },
      {
        id: 'medien_and_kommunikationXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001052170101,
      },
      {
        id: 'medien_and_kommunikationXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1.691729323,
        isSelected: false,
        reachPercentages: 0.00149057431,
      },
      {
        id: 'medien_and_kommunikationXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1.214574899,
        isSelected: false,
        reachPercentages: 0.0004384042087,
      },
    ],
  },
  {
    id: 'nonprofit',
    name: 'Nonprofit',
    value: 'Nonprofit',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'nonprofitXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 2.786377709,
        isSelected: false,
        reachPercentages: 0.0001665935993,
      },
      {
        id: 'nonprofitXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001578255151,
      },
      {
        id: 'nonprofitXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001578255151,
      },
      {
        id: 'nonprofitXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001052170101,
      },
      {
        id: 'nonprofitXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1.393188854,
        isSelected: false,
        reachPercentages: 0.0006926786497,
      },
      {
        id: 'nonprofitXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1.973684211,
        isSelected: false,
        reachPercentages: 0.0003156510302,
      },
      {
        id: 'nonprofitXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1.435406699,
        isSelected: false,
        reachPercentages: 0.000657606313,
      },
      {
        id: 'nonprofitXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 3.15789473684211,
        isSelected: false,
        reachPercentages: 0.0001578255151,
      },
      {
        id: 'nonprofitXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1.052631579,
        isSelected: false,
        reachPercentages: 0.0009644892591,
      },
    ],
  },
  {
    id: 'software_and_it_services',
    name: 'Software & IT-Services',
    value: 'Software & IT-Services',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'software_and_it_servicesXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 1.214574899,
        isSelected: false,
        reachPercentages: 0.001402893468,
      },
      {
        id: 'software_and_it_servicesXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.004296361245,
      },
      {
        id: 'software_and_it_servicesXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.007978956598,
      },
      {
        id: 'software_and_it_servicesXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.006926786497,
      },
      {
        id: 'software_and_it_servicesXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.004120999562,
      },
      {
        id: 'software_and_it_servicesXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.002805786936,
      },
      {
        id: 'software_and_it_servicesXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.005085488821,
      },
      {
        id: 'software_and_it_servicesXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001753616835,
      },
      {
        id: 'software_and_it_servicesXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.01052170101,
      },
    ],
  },
  {
    id: 'transportwesen_and_logistik',
    name: 'Transportwesen & Logistik',
    value: 'Transportwesen & Logistik',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'transportwesen_and_logistikXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 4.499151104,
        isSelected: false,
        reachPercentages: 0.00005260850504,
      },
      {
        id: 'transportwesen_and_logistikXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1.101591187,
        isSelected: false,
        reachPercentages: 0.0007277509864,
      },
      {
        id: 'transportwesen_and_logistikXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.0009644892591,
      },
      {
        id: 'transportwesen_and_logistikXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.0008241999123,
      },
      {
        id: 'transportwesen_and_logistikXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1.029748284,
        isSelected: false,
        reachPercentages: 0.0006926786497,
      },
      {
        id: 'transportwesen_and_logistikXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1.127819549,
        isSelected: false,
        reachPercentages: 0.0005786935555,
      },
      {
        id: 'transportwesen_and_logistikXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001227531784,
      },
      {
        id: 'transportwesen_and_logistikXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1.480263158,
        isSelected: false,
        reachPercentages: 0.0004997807979,
      },
      {
        id: 'transportwesen_and_logistikXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.005436212188,
      },
    ],
  },
  {
    id: 'unterhaltung',
    name: 'Unterhaltung',
    value: 'Unterhaltung',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'unterhaltungXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 1.435406699,
        isSelected: false,
        reachPercentages: 0.001227531784,
      },
      {
        id: 'unterhaltungXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001402893468,
      },
      {
        id: 'unterhaltungXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001753616835,
      },
      {
        id: 'unterhaltungXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1,
        isSelected: false,
        reachPercentages: 0.001139850943,
      },
      {
        id: 'unterhaltungXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1.528013582,
        isSelected: false,
        reachPercentages: 0.0006400701447,
      },
      {
        id: 'unterhaltungXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 1.48026315789474,
        isSelected: false,
        reachPercentages: 0.000455940377,
      },
      {
        id: 'unterhaltungXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1.007838746,
        isSelected: false,
        reachPercentages: 0.001665935993,
      },
      {
        id: 'unterhaltungXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 2.25563909774436,
        isSelected: false,
        reachPercentages: 0.0003594914511,
      },
      {
        id: 'unterhaltungXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1.82186234817814,
        isSelected: false,
        reachPercentages: 0.0008768084174,
      },
    ],
  },
  {
    id: 'wellness_and_fitness',
    name: 'Wellness & Fitness',
    value: 'Wellness & Fitness',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'wellness_and_fitnessXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 3.157894737,
        isSelected: false,
        reachPercentages: 0.000254274441,
      },
      {
        id: 'wellness_and_fitnessXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1.393188854,
        isSelected: false,
        reachPercentages: 0.0007891275756,
      },
      {
        id: 'wellness_and_fitnessXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1.315789474,
        isSelected: false,
        reachPercentages: 0.0006663743972,
      },
      {
        id: 'wellness_and_fitnessXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1.75438596491228,
        isSelected: false,
        reachPercentages: 0.0004734765454,
      },
      {
        id: 'wellness_and_fitnessXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 2.059496568,
        isSelected: false,
        reachPercentages: 0.000254274441,
      },
      {
        id: 'wellness_and_fitnessXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 2.63157894736842,
        isSelected: false,
        reachPercentages: 0.0001928978518,
      },
      {
        id: 'wellness_and_fitnessXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1.894736842,
        isSelected: false,
        reachPercentages: 0.0004384042087,
      },
      {
        id: 'wellness_and_fitnessXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 4.927211646,
        isSelected: false,
        reachPercentages: 0.00005786935555,
      },
      {
        id: 'wellness_and_fitnessXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 4.11483253588517,
        isSelected: false,
        reachPercentages: 0.000201665936,
      },
    ],
  },
  {
    id: 'öffentliche_sicherheit',
    name: 'Öffentliche Sicherheit',
    value: 'Öffentliche Sicherheit',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'öffentliche_sicherheitXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 10.47368421,
        isSelected: false,
        reachPercentages: 0,
      },
      {
        id: 'öffentliche_sicherheitXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 4.09356725146199,
        isSelected: false,
        reachPercentages: 0.00006488382288,
      },
      {
        id: 'öffentliche_sicherheitXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 3.801169591,
        isSelected: false,
        reachPercentages: 0.00004471722929,
      },
      {
        id: 'öffentliche_sicherheitXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 3.28947368421053,
        isSelected: false,
        reachPercentages: 0.00003244191144,
      },
      {
        id: 'öffentliche_sicherheitXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 4.055220017,
        isSelected: false,
        reachPercentages: 0.00005436212188,
      },
      {
        id: 'öffentliche_sicherheitXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 3.383458647,
        isSelected: false,
        reachPercentages: 0,
      },
      {
        id: 'öffentliche_sicherheitXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 4.21052631578947,
        isSelected: false,
        reachPercentages: 0.0001139850943,
      },
      {
        id: 'öffentliche_sicherheitXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 2.105263158,
        isSelected: false,
        reachPercentages: 0.00005874616396,
      },
      {
        id: 'öffentliche_sicherheitXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1.05263157894737,
        isSelected: false,
        reachPercentages: 0.001315212626,
      },
    ],
  },
  {
    id: 'öffentliche_verwaltung',
    name: 'Öffentliche Verwaltung',
    value: 'Öffentliche Verwaltung',
    isSelected: false,
    priceMultiplier: 1,
    children: [
      {
        id: 'öffentliche_verwaltungXXXEin-Personen-Unternehmen',
        name: 'Ein-Personen-Unternehmen',
        value: 'Ein-Personen-Unternehmen',
        priceMultiplier: 3.629764065,
        isSelected: false,
        reachPercentages: 0.0001227531784,
      },
      {
        id: 'öffentliche_verwaltungXXX2-10',
        name: '2-10 Mitarbeiter',
        value: '2-10 Mitarbeiter',
        priceMultiplier: 1.82186234817814,
        isSelected: false,
        reachPercentages: 0.0005173169662,
      },
      {
        id: 'öffentliche_verwaltungXXX11-50',
        name: '11-50 Mitarbeiter',
        value: '11-50 Mitarbeiter',
        priceMultiplier: 1.528013582,
        isSelected: false,
        reachPercentages: 0.0007978956598,
      },
      {
        id: 'öffentliche_verwaltungXXX51-200',
        name: '51-200 Mitarbeiter',
        value: '51-200 Mitarbeiter',
        priceMultiplier: 1.435406699,
        isSelected: false,
        reachPercentages: 0.0007715914073,
      },
      {
        id: 'öffentliche_verwaltungXXX201-500',
        name: '201-500 Mitarbeiter',
        value: '201-500 Mitarbeiter',
        priceMultiplier: 1.754385965,
        isSelected: false,
        reachPercentages: 0.0005260850504,
      },
      {
        id: 'öffentliche_verwaltungXXX501-1000',
        name: '501-1000 Mitarbeiter',
        value: '501-1000 Mitarbeiter',
        priceMultiplier: 2.493074792,
        isSelected: false,
        reachPercentages: 0.0003594914511,
      },
      {
        id: 'öffentliche_verwaltungXXX1001-5000',
        name: '1001-5000 Mitarbeiter',
        value: '1001-5000 Mitarbeiter',
        priceMultiplier: 1.82186234817814,
        isSelected: false,
        reachPercentages: 0.0007365190706,
      },
      {
        id: 'öffentliche_verwaltungXXX5001-10000',
        name: '5001-10000 Mitarbeiter',
        value: '5001-10000 Mitarbeiter',
        priceMultiplier: 1.89473684210526,
        isSelected: false,
        reachPercentages: 0.0006137658922,
      },
      {
        id: 'öffentliche_verwaltungXXX10000+',
        name: '10000+ Mitarbeiter',
        value: '10000+ Mitarbeiter',
        priceMultiplier: 1.28022759601707,
        isSelected: false,
        reachPercentages: 0.01402893468,
      },
    ],
  },
];

export const linkedinCompanyTargetGroupsStateItems = [
  {
    id: 'bachelor',
    name: 'Bachelor',
    value: 'bachelor',
    isSelected: false,
    children: [
      {
        id: 'bachelor_unbezahlt',
        name: 'Unbezahlt',
        value: 'unbezahlt',
        isSelected: false,
        ageMultiplier: [2.322654462, 24.34210526, 5.263157895, 10.52631579],
      },
      {
        id: 'bachelor_praktikum/lehre',
        name: 'Praktikum/Lehre',
        value: 'praktikum/lehre',
        isSelected: false,
        ageMultiplier: [2.493074792, 2.255639098, 13.93188854, 10.52631579],
      },
      {
        id: 'bachelor_berufseinsteiger',
        name: 'Berufseinsteiger',
        value: 'berufseinsteiger',
        isSelected: false,
        ageMultiplier: [2.059496568, 1.528013582, 2.368421053, 10.29748284],
      },
      {
        id: 'bachelor_berufserfahren',
        name: 'Berufserfahren',
        value: 'berufserfahren',
        isSelected: false,
        ageMultiplier: [2.493074792, 1.633393829, 2.493074792, 8.937437934],
      },
      {
        id: 'bachelor_manager',
        name: 'Manager',
        value: 'manager',
        isSelected: false,
        ageMultiplier: [5.921052632, 2.255639098, 2.786377709, 8.937437934],
      },
      {
        id: 'bachelor_director',
        name: 'Director',
        value: 'director',
        isSelected: false,
        ageMultiplier: [8.771929825, 2.368421053, 2.960526316, 14.35406699],
      },
      {
        id: 'bachelor_vice_president',
        name: 'Vice President',
        value: 'vice_president',
        isSelected: false,
        ageMultiplier: [13.93188854, 3.157894737, 3.643724696, 15.28013582],
      },
      {
        id: 'bachelor_geschäftsführung',
        name: 'Geschäftsführung',
        value: 'geschäftsführung',
        isSelected: false,
        ageMultiplier: [20.36613272, 4.736842105, 6.40113798, 14.80263158],
      },
      {
        id: 'bachelor_unternehmensinhaber',
        name: 'Unternehmensinhaber',
        value: 'unternehmensinhaber',
        isSelected: false,
        ageMultiplier: [11.27819549, 3.383458647, 4.986149584, 21.53110048],
      },
      {
        id: 'bachelor_partner',
        name: 'Partner',
        value: 'partner',
        isSelected: false,
        ageMultiplier: [23.26869806, 5.996002665, 6.965944272, 16.33393829],
      },
    ],
  },
  {
    id: 'master',
    name: 'Master',
    value: 'master',
    isSelected: false,
    children: [
      {
        id: 'master_unbezahlt',
        name: 'Unbezahlt',
        value: 'unbezahlt',
        isSelected: false,
        ageMultiplier: [10.47368421, 12.55060729, 22.16066482, 27.86377709],
      },
      {
        id: 'master_praktikum/lehre',
        name: 'Praktikum/Lehre',
        value: 'praktikum/lehre',
        isSelected: false,
        ageMultiplier: [2.368421053, 2.631578947, 13.93188854, 6.315789474],
      },
      {
        id: 'master_berufseinsteiger',
        name: 'Berufseinsteiger',
        value: 'berufseinsteiger',
        isSelected: false,
        ageMultiplier: [2.153110048, 1.578947368, 2.059496568, 5.039193729],
      },
      {
        id: 'master_berufserfahren',
        name: 'Berufserfahren',
        value: 'berufserfahren',
        isSelected: false,
        ageMultiplier: [2.631578947, 1.633393829, 1.973684211, 3.643724696],
      },
      {
        id: 'master_manager',
        name: 'Manager',
        value: 'manager',
        isSelected: false,
        ageMultiplier: [5.996002665, 2.255639098, 2.493074792, 7.06991359],
      },
      {
        id: 'master_director',
        name: 'Director',
        value: 'director',
        isSelected: false,
        ageMultiplier: [8.612440191, 2.368421053, 2.368421053, 6.40113798],
      },
      {
        id: 'master_vice_president',
        name: 'Vice President',
        value: 'vice_president',
        isSelected: false,
        ageMultiplier: [11.27819549, 3.383458647, 2.493074792, 5.444646098],
      },
      {
        id: 'master_geschäftsführung',
        name: 'Geschäftsführung',
        value: 'geschäftsführung',
        isSelected: false,
        ageMultiplier: [14.35406699, 4.736842105, 3.157894737, 10.07838746],
      },
      {
        id: 'master_unternehmensinhaber',
        name: 'Unternehmensinhaber',
        value: 'unternehmensinhaber',
        isSelected: false,
        ageMultiplier: [10.52631579, 3.643724696, 2.786377709, 7.518796992],
      },
      {
        id: 'master_partner',
        name: 'Partner',
        value: 'partner',
        isSelected: false,
        ageMultiplier: [18.94736842, 5.847953216, 3.383458647, 9.667024705],
      },
    ],
  },
  {
    id: 'diplom',
    name: 'Diplom',
    value: 'diplom',
    isSelected: false,
    children: [
      {
        id: 'diplom_unbezahlt',
        name: 'Unbezahlt',
        value: 'unbezahlt',
        isSelected: false,
        ageMultiplier: [2.236842105, 14.35406699, 24.45820433, 14.35406699],
      },
      {
        id: 'diplom_praktikum/lehre',
        name: 'Praktikum/Lehre',
        value: 'praktikum/lehre',
        isSelected: false,
        ageMultiplier: [2.255639098, 2.255639098, 12.46537396, 6.315789474],
      },
      {
        id: 'diplom_berufseinsteiger',
        name: 'Berufseinsteiger',
        value: 'berufseinsteiger',
        isSelected: false,
        ageMultiplier: [1.973684211, 1.528013582, 1.973684211, 4.306220096],
      },
      {
        id: 'diplom_berufserfahren',
        name: 'Berufserfahren',
        value: 'berufserfahren',
        isSelected: false,
        ageMultiplier: [2.631578947, 1.633393829, 2.059496568, 3.643724696],
      },
      {
        id: 'diplom_manager',
        name: 'Manager',
        value: 'manager',
        isSelected: false,
        ageMultiplier: [5.996002665, 2.255639098, 2.493074792, 6.488824802],
      },
      {
        id: 'diplom_director',
        name: 'Director',
        value: 'director',
        isSelected: false,
        ageMultiplier: [9.868421053, 2.493074792, 2.368421053, 6.315789474],
      },
      {
        id: 'diplom_vice_president',
        name: 'Vice President',
        value: 'vice_president',
        isSelected: false,
        ageMultiplier: [13.93188854, 3.383458647, 2.631578947, 5.639097744],
      },
      {
        id: 'diplom_geschäftsführung',
        name: 'Geschäftsführung',
        value: 'geschäftsführung',
        isSelected: false,
        ageMultiplier: [14.35406699, 4.833512352, 3.157894737, 10.29748284],
      },
      {
        id: 'diplom_unternehmensinhaber',
        name: 'Unternehmensinhaber',
        value: 'unternehmensinhaber',
        isSelected: false,
        ageMultiplier: [9.868421053, 3.643724696, 2.786377709, 7.287449393],
      },
      {
        id: 'diplom_partner',
        name: 'Partner',
        value: 'partner',
        isSelected: false,
        ageMultiplier: [17.54385965, 5.847953216, 3.643724696, 10.52631579],
      },
    ],
  },
  {
    id: 'berufsausbildung',
    name: 'Berufsausbildung',
    value: 'berufsausbildung',
    isSelected: false,
    children: [
      {
        id: 'berufsausbildung_unbezahlt',
        name: 'Unbezahlt',
        value: 'unbezahlt',
        isSelected: false,
        ageMultiplier: [10.92733898, 2.236842105, 42.10526316, 43.06220096],
      },
      {
        id: 'berufsausbildung_praktikum/lehre',
        name: 'Praktikum/Lehre',
        value: 'praktikum/lehre',
        isSelected: false,
        ageMultiplier: [6.40113798, 5.263157895, 27.86377709, 6.315789474],
      },
      {
        id: 'berufsausbildung_berufseinsteiger',
        name: 'Berufseinsteiger',
        value: 'berufseinsteiger',
        isSelected: false,
        ageMultiplier: [2.786377709, 1.973684211, 2.255639098, 6.766917293],
      },
      {
        id: 'berufsausbildung_berufserfahren',
        name: 'Berufserfahren',
        value: 'berufserfahren',
        isSelected: false,
        ageMultiplier: [3.947368421, 2.255639098, 2.493074792, 6.151742994],
      },
      {
        id: 'berufsausbildung_manager',
        name: 'Manager',
        value: 'manager',
        isSelected: false,
        ageMultiplier: [9.868421053, 2.786377709, 2.786377709, 9.287925697],
      },
      {
        id: 'berufsausbildung_director',
        name: 'Director',
        value: 'director',
        isSelected: false,
        ageMultiplier: [18.21862348, 4.306220096, 2.960526316, 12.14574899],
      },
      {
        id: 'berufsausbildung_vice_president',
        name: 'Vice President',
        value: 'vice_president',
        isSelected: false,
        ageMultiplier: [24.34210526, 6.766917293, 3.947368421, 10.76555024],
      },
      {
        id: 'berufsausbildung_geschäftsführung',
        name: 'Geschäftsführung',
        value: 'geschäftsführung',
        isSelected: false,
        ageMultiplier: [18.42105263, 11.27819549, 7.518796992, 23.42105263],
      },
      {
        id: 'berufsausbildung_unternehmensinhaber',
        name: 'Unternehmensinhaber',
        value: 'unternehmensinhaber',
        isSelected: false,
        ageMultiplier: [19.19504644, 7.401315789, 5.444646098, 13.15789474],
      },
      {
        id: 'berufsausbildung_partner',
        name: 'Partner',
        value: 'partner',
        isSelected: false,
        ageMultiplier: [18.42105263, 13.53383459, 8.458646617, 23.68421053],
      },
    ],
  },
  {
    id: 'doktor',
    name: 'Doktor',
    value: 'doktor',
    isSelected: false,
    children: [
      {
        id: 'doktor_unbezahlt',
        name: 'Unbezahlt',
        value: 'unbezahlt',
        isSelected: false,
        ageMultiplier: [19.73684211, 3.646616541, 8.947368421, 37.59398496],
      },
      {
        id: 'doktor_praktikum/lehre',
        name: 'Praktikum/Lehre',
        value: 'praktikum/lehre',
        isSelected: false,
        ageMultiplier: [19.73684211, 11.01591187, 8.947368421, 15.78947368],
      },
      {
        id: 'doktor_berufseinsteiger',
        name: 'Berufseinsteiger',
        value: 'berufseinsteiger',
        isSelected: false,
        ageMultiplier: [19.73684211, 3.643724696, 4.306220096, 17.54385965],
      },
      {
        id: 'doktor_berufserfahren',
        name: 'Berufserfahren',
        value: 'berufserfahren',
        isSelected: false,
        ageMultiplier: [20.59496568, 3.383458647, 3.157894737, 11.01591187],
      },
      {
        id: 'doktor_manager',
        name: 'Manager',
        value: 'manager',
        isSelected: false,
        ageMultiplier: [18.42105263, 7.287449393, 5.205320995, 17.54385965],
      },
      {
        id: 'doktor_director',
        name: 'Director',
        value: 'director',
        isSelected: false,
        ageMultiplier: [18.42105263, 7.518796992, 4.883342377, 14.80263158],
      },
      {
        id: 'doktor_vice_president',
        name: 'Vice President',
        value: 'vice_president',
        isSelected: false,
        ageMultiplier: [18.42105263, 11.55327343, 6.072874494, 15.28013582],
      },
      {
        id: 'doktor_geschäftsführung',
        name: 'Geschäftsführung',
        value: 'geschäftsführung',
        isSelected: false,
        ageMultiplier: [18.42105263, 13.93188854, 8.771929825, 19.73684211],
      },
      {
        id: 'doktor_unternehmensinhaber',
        name: 'Unternehmensinhaber',
        value: 'unternehmensinhaber',
        isSelected: false,
        ageMultiplier: [18.42105263, 12.46537396, 8.937437934, 18.21862348],
      },
      {
        id: 'doktor_partner',
        name: 'Partner',
        value: 'partner',
        isSelected: false,
        ageMultiplier: [18.42105263, 21.53110048, 8.937437934, 20.59496568],
      },
    ],
  },
  {
    id: 'schulabschluss',
    name: 'Schulabschluss',
    value: 'schulabschluss',
    isSelected: false,
    children: [
      {
        id: 'schulabschluss_unbezahlt',
        name: 'Unbezahlt',
        value: 'unbezahlt',
        isSelected: false,
        ageMultiplier: [5.263157895, 10.52631579, 3.049535604, 13.15789474],
      },
      {
        id: 'schulabschluss_praktikum/lehre',
        name: 'Praktikum/Lehre',
        value: 'praktikum/lehre',
        isSelected: false,
        ageMultiplier: [5.996002665, 3.157894737, 17.54385965, 13.15789474],
      },
      {
        id: 'schulabschluss_berufseinsteiger',
        name: 'Berufseinsteiger',
        value: 'berufseinsteiger',
        isSelected: false,
        ageMultiplier: [4.934210526, 2.153110048, 2.960526316, 9.109311741],
      },
      {
        id: 'schulabschluss_berufserfahren',
        name: 'Berufserfahren',
        value: 'berufserfahren',
        isSelected: false,
        ageMultiplier: [7.894736842, 2.631578947, 2.786377709, 8.458646617],
      },
      {
        id: 'schulabschluss_manager',
        name: 'Manager',
        value: 'manager',
        isSelected: false,
        ageMultiplier: [19.73684211, 5.639097744, 4.306220096, 12.14574899],
      },
      {
        id: 'schulabschluss_director',
        name: 'Director',
        value: 'director',
        isSelected: false,
        ageMultiplier: [10.52631579, 8.166969147, 5.38277512, 13.15789474],
      },
      {
        id: 'schulabschluss_vice_president',
        name: 'Vice President',
        value: 'vice_president',
        isSelected: false,
        ageMultiplier: [10.52631579, 12.80227596, 7.765314927, 13.93188854],
      },
      {
        id: 'schulabschluss_geschäftsführung',
        name: 'Geschäftsführung',
        value: 'geschäftsführung',
        isSelected: false,
        ageMultiplier: [10.52631579, 16.91729323, 11.84210526, 18.21862348],
      },
      {
        id: 'schulabschluss_unternehmensinhaber',
        name: 'Unternehmensinhaber',
        value: 'unternehmensinhaber',
        isSelected: false,
        ageMultiplier: [26.97368421, 9.868421053, 8.612440191, 15.78947368],
      },
      {
        id: 'schulabschluss_partner',
        name: 'Partner',
        value: 'partner',
        isSelected: false,
        ageMultiplier: [10.52631579, 19.73684211, 14.80263158, 20.59496568],
      },
    ],
  },
];

// linkedin calculation data end //

export const linkedinDaysCount = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return 0;
  }

  const startTimeStamp = new Date(startDate);
  const endTimeStamp = new Date(endDate);

  return (
    Math.round(
      (endTimeStamp.getTime() - startTimeStamp.getTime()) /
        (1000 * 60 * 60 * 24)
    ) + 1
  );
};

const getLinkedinAgesPercent = (linkedinAges) => {
  const result = linkedinAges.reduce((acc, ageSelected, index) => {
    if (!ageSelected) {
      return acc;
    }
    return acc + linkedinAgeCategories[index];
  }, 0);
  return Math.min(result, 1);
};

const getLinkedinGenderPercent = (gender) => {
  const result = gender.reduce((acc, option) => {
    return acc + linkedinGenderCategories[option];
  }, 0);
  return Math.min(result, 1);
};

const getLinkedinTargetingPercent = (linkedinTargetGroupsState) => {
  const percentages = linkedinTargetGroupsState.reduce((acc, group) => {
    if (group.isSelected) {
      const childrenPercentages = group.children.reduce(
        (accumulator, childGroup) => {
          if (childGroup.isSelected) {
            // eslint-disable-next-line no-return-assign, no-param-reassign
            return (accumulator += childGroup.reachPercentages);
          }
          return accumulator;
        },
        0
      );
      // eslint-disable-next-line no-param-reassign
      acc += childrenPercentages;
    }
    return acc;
  }, 0);
  return percentages || 1;
};

const getLinkedinTargetingMultiplier = (linkedinTargetGroupsState) => {
  let avr = 0;
  const multiplier = linkedinTargetGroupsState.reduce((acc, group) => {
    if (group.isSelected) {
      let avrSubCat = 0;
      avr += 1;
      const childrenMultiplier = group.children.reduce(
        (accumulator, childGroup) => {
          if (childGroup.isSelected) {
            avrSubCat += 1;
            // eslint-disable-next-line no-param-reassign
            accumulator += childGroup.priceMultiplier;
            return accumulator;
          }
          return accumulator;
        },
        0
      );
      const avrMultiplier = childrenMultiplier / avrSubCat;
      // eslint-disable-next-line no-param-reassign
      acc += avrMultiplier;
    }
    return acc;
  }, 0);
  return multiplier / avr || 1;
};

const getLinkedinTargetingCompanyMultiplier = (
  linkedinTargetGroupsState,
  linkedinAges
) => {
  const multiplier = linkedinTargetGroupsState.reduce((acc, group) => {
    if (group.isSelected) {
      const childrenMultiplier = group.children.reduce(
        (accumulator, childGroup) => {
          if (childGroup.isSelected) {
            const selectedMultiplier = childGroup.ageMultiplier.filter(
              (_, index) => linkedinAges[index]
            );
            accumulator.push(min(selectedMultiplier));
          }
          return accumulator;
        },
        []
      );
      acc.push(min(childrenMultiplier));
    }
    return acc;
  }, []);
  return min(multiplier) || 1;
};

export const getEstimatedReach = (reachEstimationDependencies) => {
  const { reach, gender, linkedinAges, linkedinTargetGroupsState } =
    reachEstimationDependencies;
  const genderPercentages = getLinkedinGenderPercent(gender);
  const agePercentages = getLinkedinAgesPercent(linkedinAges);
  const targetGroupsPercentages = getLinkedinTargetingPercent(
    linkedinTargetGroupsState
  );
  const estimatedReach =
    reach *
    genderPercentages *
    agePercentages *
    targetGroupsPercentages *
    linkedinUsersPercentage;
  return Math.round(Math.min(reach, estimatedReach));
};

export const getLinkedinCampaignPrice = (pricingDependencies) => {
  const {
    advertiser,
    user,
    linkedinTargetGroupsState,
    linkedinCompanyTargetGroupsState,
    linkedinAges,
  } = pricingDependencies;
  const agencyMargin = user?.margin || 1;
  const advMargin = advertiser?.margin || 0;
  const advertiserMargin = 1 + advMargin / 100;
  const marginSum = advertiserMargin * agencyMargin;
  const targetGroupsMultiplier = getLinkedinTargetingMultiplier(
    linkedinTargetGroupsState
  );
  const targetCompanyGroupsMultiplier = getLinkedinTargetingCompanyMultiplier(
    linkedinCompanyTargetGroupsState,
    linkedinAges
  );

  const TKP =
    marginSum *
    linkedinBasePrice *
    targetGroupsMultiplier *
    targetCompanyGroupsMultiplier;
  return { TKP };
};

export const getLinkedinBudgetRecommendation = (
  budgetRecommendationDependencies
) => {
  const {
    startDate,
    endDate,
    estimatedReach,
    intensity,
    advertiser,
    user,
    linkedinTargetGroupsState,
    linkedinCompanyTargetGroupsState,
    linkedinAges,
  } = budgetRecommendationDependencies;
  const days = linkedinDaysCount(startDate, endDate);
  const pricePerUser = getLinkedinCampaignPrice({
    advertiser,
    user,
    linkedinTargetGroupsState,
    linkedinCompanyTargetGroupsState,
    linkedinAges,
  }).TKP;
  const recommendation =
    estimatedReach * (intensity * 0.2) * pricePerUser * days * 0.001;
  return recommendation > linkedinBudgetMaxLimit
    ? linkedinBudgetMaxLimit
    : recommendation;
};
