import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const CampaignsAgencyDropdown = ({
  agencies = [],
  onChange,
  value,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <FormControl variant="outlined" className={className}>
      <InputLabel>{t('campaigns.selectAgency')}</InputLabel>
      <Select
        value={value || ''}
        onChange={onChange}
        label={t('campaigns.selectAgency')}
      >
        <MenuItem>{t('campaigns.allAgencies')}</MenuItem>
        {agencies.map((i) => (
          <MenuItem key={i.id} value={i}>
            {i.username}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CampaignsAgencyDropdown;
