import React, { memo } from 'react';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Grid,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';

const GeoCategoryListItem = (props) => {
  const { item, togglePlaceCheck, checked, disabled } = props;
  const { description, id, street, zipCode, city } = item;
  const descriptionTemplate = (
    <Grid container>
      <Grid item xs={12}>
        {description}
      </Grid>
      <Grid item xs={12}>
        {street}
      </Grid>
      <Grid item xs={12}>
        {zipCode} {city}
      </Grid>
    </Grid>
  );
  return (
    <ListItem divider>
      <ListItemText secondary={descriptionTemplate} />
      {!disabled && (
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => {
              togglePlaceCheck(id);
            }}
          >
            <Check style={{ color: checked ? green[500] : 'inherit' }} />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default memo(GeoCategoryListItem);
