import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  formatFloatToSubmit,
  formatFloatToView,
} from '../../../../services/format-helper';

const useStyles = makeStyles({
  dialogActionsStyle: {
    padding: '8px 24px',
  },
});

const AdvertiserModal = (props) => {
  const classes = useStyles();
  const {
    isModalAdvertiserOpen,
    advertiser,
    onAdvertiserCreate,
    onAdvertiserUpdate,
    onClose,
    isMarginDisabled,
  } = props;

  const { t } = useTranslation();
  const isEditing = !!advertiser?.id;
  const defaultValues = isEditing ? advertiser : undefined;
  const { register, handleSubmit } = useForm({ defaultValues });
  if (defaultValues)
    defaultValues.margin = formatFloatToView(defaultValues.margin.toString());

  const onSubmit = async (data) => {
    if (!isMarginDisabled) {
      data.margin = formatFloatToSubmit(data.margin);
    }
    const action = isEditing ? onAdvertiserUpdate : onAdvertiserCreate;
    await action(data);
  };

  return (
    <Dialog open={isModalAdvertiserOpen} onClose={onClose}>
      <Box px={3} pt={4} pb={3} width={444}>
        <DialogTitle>
          {isEditing
            ? t('advertiserModal.editAdvertiser')
            : t('advertiserModal.newAdvertiser')}
        </DialogTitle>
        <Box mt={2} />
        <DialogContent>
          <form id="new-advertiser" onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="subtitle2">
              {t('advertiserModal.name')}
            </Typography>
            <TextField
              autoFocus
              required
              placeholder={t('advertiserModal.name')}
              {...register('name', {
                required: true,
              })}
              variant="outlined"
              fullWidth
            />

            {Boolean(!isMarginDisabled) && (
              <>
                <Box mt={6} />
                <Typography variant="subtitle2">
                  {t('advertiserModal.margin')}
                </Typography>
                <TextField
                  /* required */
                  placeholder={t('advertiserModal.margin')}
                  {...register('margin', {
                    /* required: true, */
                  })}
                  variant="outlined"
                  fullWidth
                />
              </>
            )}
          </form>
        </DialogContent>
        <Box mt={4} />
        <DialogActions className={classes.dialogActionsStyle}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                form="new-advertiser"
                fullWidth
              >
                {t('advertiserModal.save')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2} />
            </Grid>
            <Grid item xs={12}>
              <Button color="secondary" onClick={onClose} fullWidth>
                {t('advertiserModal.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AdvertiserModal;
