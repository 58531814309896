import React, { useCallback, memo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Grid,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Box,
  TextField,
  Checkbox,
  FormControl,
  Tooltip,
  makeStyles,
  Divider,
  FormControlLabel,
  Link,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Info } from '@material-ui/icons';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import AppCheckboxButton from '../../../../components/AppCheckboxButton';
import AppRadioButton from '../../../../components/AppRadioButton';
import WebsitesGenerator from '../../../../components/WebsitesGenerator';
import {
  CampaignWebsitesTargetingType,
  CampaignAges,
  TARGET_GROUPS,
  CampaignDevices,
  CampaignTypes,
} from '../../../../enums/campaign';
import {
  AGOFSiteIcon,
  DesktopIcon,
  FemaleIcon,
  KeineSiteIcon,
  MaleIcon,
  MobileIcon,
  TabletIcon,
  WebsiteIcon,
} from '../../../../components/CustomIcons';
import AgeTargeting from './components/AgeTargeting';
import { formatFloatToView } from '../../../../services/format-helper';
import { toggleArrayItem } from '../../services/campaignStateHelper';
import { whiteColor } from '../../../../theme';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  tooltip: {
    position: 'absolute',
    top: '-10px',
    left: 94,
    cursor: 'pointer',
  },
  checkbox: {
    marginRight: 8,
  },
});

const CampaignTargeting = (props) => {
  const {
    handleCampaignTargeting,
    campaignTargeting,
    campaignDetails,
    disabled,
    isFormSubmitted,
    pricing,
    campaignGoal,
    refsMap,
  } = props;
  const [isWebsitesOpen, setIsWebsitesOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const devicesRef = useRef();
  const genderAgeRef = useRef();
  const landingPageRef = useRef();

  useEffect(() => {
    // eslint-disable-next-line dot-notation
    refsMap.current['devices'] = devicesRef;
    refsMap.current['gender-age'] = genderAgeRef;
    refsMap.current['landing-page'] = landingPageRef;
  }, [refsMap]);

  const handleWebsitesChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      const websitesTargeting = parseInt(event.target.value, 10);
      if (
        websitesTargeting === CampaignWebsitesTargetingType.Twins ||
        websitesTargeting === CampaignWebsitesTargetingType.NoWebsites
      ) {
        handleCampaignTargeting({
          websitesIncludeList: [],
          customWebsitesIncludeList: [],
        });
      }
      handleCampaignTargeting({ websitesTargeting });
    },
    [handleCampaignTargeting]
  );

  const handleLandingPageChange = useCallback(
    (event) => {
      handleCampaignTargeting({ landingPage: event.target.value });
    },
    [handleCampaignTargeting]
  );

  const handleIdkLandingPageChange = useCallback(
    (e) => {
      if (disabled) {
        return;
      }
      handleCampaignTargeting({ idkLandingPageYet: e.target.checked });
    },
    [handleCampaignTargeting]
  );

  const handleAgeChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      const { name, checked } = event.target;
      const updatedAges = [...campaignTargeting.ages];
      updatedAges[CampaignAges[name]] = Number(checked);
      handleCampaignTargeting({ ages: updatedAges });
    },
    [handleCampaignTargeting, campaignTargeting]
  );

  const handleDevicesChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      const { name } = event.target;
      const updatedDevices = toggleArrayItem(campaignTargeting.devices, name);
      handleCampaignTargeting({ devices: updatedDevices });
    },
    [handleCampaignTargeting, campaignTargeting]
  );

  const handleGenderChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      const { name } = event.target;
      const updatedGender = toggleArrayItem(campaignTargeting.gender, name);
      handleCampaignTargeting({ gender: updatedGender });
    },
    [handleCampaignTargeting, campaignTargeting]
  );

  const handleTargetGroupChange = useCallback(
    (event, newValue) => {
      if (disabled) {
        return;
      }
      handleCampaignTargeting({ targetGroups: newValue });
    },
    [handleCampaignTargeting, campaignTargeting]
  );

  const handleWebsitesIncludeListChange = useCallback(
    (websitesIncludeListData) => {
      if (disabled) {
        return;
      }
      handleCampaignTargeting({
        ...websitesIncludeListData,
      });
      setIsWebsitesOpen(false);
    },
    [handleCampaignTargeting]
  );

  const handleWebsiteModal = useCallback(() => {
    if (disabled) {
      return;
    }
    setIsWebsitesOpen(true);
  }, [setIsWebsitesOpen, disabled]);
  const {
    websitesTargeting,
    websitesIncludeList,
    landingPage,
    idkLandingPageYet,
    ages,
    devices,
    gender,
    targetGroups,
    customWebsitesIncludeList,
  } = campaignTargeting;
  const { type } = campaignDetails;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{t('createCampaign.stepTwo')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {/* devices */}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.devices')}
              </Typography>
            </Grid>
            <Grid ref={devicesRef} item container spacing={2}>
              <Grid item>
                <AppCheckboxButton
                  error={isFormSubmitted && !devices.length}
                  icon={DesktopIcon}
                  labelText={t('createCampaign.desktop')}
                  name={CampaignDevices.Desktop}
                  checked={devices.includes(CampaignDevices.Desktop)}
                  checkboxHandler={handleDevicesChange}
                  tooltip={t('tooltips.desktop')}
                />
              </Grid>
              <Grid item>
                <AppCheckboxButton
                  error={isFormSubmitted && !devices.length}
                  icon={MobileIcon}
                  labelText={t('createCampaign.mobile')}
                  name={CampaignDevices.Mobile}
                  checked={devices.includes(CampaignDevices.Mobile)}
                  checkboxHandler={handleDevicesChange}
                  tooltip={t('tooltips.mobile')}
                />
              </Grid>
              <Grid item>
                <AppCheckboxButton
                  error={isFormSubmitted && !devices.length}
                  icon={TabletIcon}
                  labelText={t('createCampaign.tablet')}
                  name={CampaignDevices.Tablet}
                  checked={devices.includes(CampaignDevices.Tablet)}
                  checkboxHandler={handleDevicesChange}
                  tooltip={t('tooltips.tablet')}
                />
              </Grid>
              {type === CampaignTypes.Video && (
                <Grid item>
                  <AppCheckboxButton
                    error={isFormSubmitted && !devices.length}
                    icon="tv"
                    labelText={t('createCampaign.smartTv')}
                    name={CampaignDevices.SmartTv}
                    checked={devices.includes(CampaignDevices.SmartTv)}
                    checkboxHandler={handleDevicesChange}
                  />
                </Grid>
              )}
            </Grid>
            <Box mt={4} width="100%" />
          </Grid>

          {/* gender/age */}
          <Grid ref={genderAgeRef} container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Box mt={2} width="100%" />
            </Grid>
            <Grid item xs={12} md={4} className={classes.container}>
              <Typography variant="body1">
                {t('createCampaign.gender')}
              </Typography>
              <Tooltip title={t('tooltips.gender')} className={classes.tooltip}>
                <Info color="secondary" fontSize="inherit" />
              </Tooltip>
              <Box mt={2} width="100%" />
              <Grid container spacing={2}>
                <Grid item>
                  <AppCheckboxButton
                    error={isFormSubmitted && !gender.length}
                    icon={MaleIcon}
                    labelText={t('createCampaign.male')}
                    name="male"
                    checked={gender.includes('male')}
                    checkboxHandler={handleGenderChange}
                  />
                </Grid>
                <Grid item>
                  <AppCheckboxButton
                    error={isFormSubmitted && !gender.length}
                    icon={FemaleIcon}
                    labelText={t('createCampaign.female')}
                    name="female"
                    checked={gender.includes('female')}
                    checkboxHandler={handleGenderChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <AgeTargeting
                isFormSubmitted={isFormSubmitted}
                ages={ages}
                handleAgeChange={handleAgeChange}
              />
            </Grid>
            <Box mt={3} width="100%" />
          </Grid>

          {/* landing page */}
          <Grid ref={landingPageRef} item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.landingPage')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={isFormSubmitted && !landingPage && !idkLandingPageYet}
                value={landingPage || ''}
                onChange={handleLandingPageChange}
                placeholder={t('createCampaign.landingPage')}
                variant="outlined"
                disabled={disabled}
                fullWidth
                required={!idkLandingPageYet}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={idkLandingPageYet}
                    onChange={handleIdkLandingPageChange}
                  />
                }
                label={t('createCampaign.idkLandingPageYet')}
              />
            </Grid>
            <Grid item xs={12}>
              <Box mt={2} mb={4} width="100%">
                <Divider />
              </Box>
            </Grid>
          </Grid>

          {/* websites */}
          {type === CampaignTypes.Banner && (
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('createCampaign.websites')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppRadioButton
                  error={
                    isFormSubmitted &&
                    !websitesTargeting &&
                    websitesTargeting !== 0
                  }
                  icon={AGOFSiteIcon}
                  labelText={`${t(
                    'createCampaign.TopAGOFSitelist'
                  )} ${formatFloatToView(
                    pricing.websites.twins.toFixed(2)
                  )}€ ${campaignGoal}`}
                  checked={
                    websitesTargeting === CampaignWebsitesTargetingType.Twins
                  }
                  onChange={handleWebsitesChange}
                  value={CampaignWebsitesTargetingType.Twins}
                  tooltip={
                    <Link
                      href="https://docs.google.com/spreadsheets/d/142jhGXS1SatF9JxL-fKKZ0rD9Soly4MMXmG_lzN3dUM/edit?usp=sharing"
                      target="_blank"
                      style={{ color: whiteColor }}
                    >
                      {t('tooltips.TopAGOFSitelist')}
                    </Link>
                  }
                  interactiveTooltip
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppRadioButton
                  onClick={handleWebsiteModal}
                  error={
                    isFormSubmitted &&
                    !websitesTargeting &&
                    websitesTargeting !== 0
                  }
                  icon={WebsiteIcon}
                  labelText={`${t(
                    'createCampaign.customSites'
                  )} ${formatFloatToView(
                    pricing.websites.userDefined.toFixed(2)
                  )}€ ${campaignGoal}`}
                  checked={
                    websitesTargeting === CampaignWebsitesTargetingType.User
                  }
                  onChange={handleWebsitesChange}
                  value={CampaignWebsitesTargetingType.User}
                  tooltip={t('tooltips.customSites')}
                />
                <WebsitesGenerator
                  open={isWebsitesOpen}
                  categories={websitesIncludeList}
                  customWebsitesPreset={customWebsitesIncludeList}
                  onEmitData={handleWebsitesIncludeListChange}
                  onClose={() => {
                    setIsWebsitesOpen(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppRadioButton
                  error={
                    isFormSubmitted &&
                    !websitesTargeting &&
                    websitesTargeting !== 0
                  }
                  icon={KeineSiteIcon}
                  labelText={t('createCampaign.noSiteList')}
                  checked={
                    websitesTargeting ===
                    CampaignWebsitesTargetingType.NoWebsites
                  }
                  onChange={handleWebsitesChange}
                  value={CampaignWebsitesTargetingType.NoWebsites}
                  tooltip={t('tooltips.noSiteList')}
                />
              </Grid>
              <Grid item xs={12}>
                <Box mt={2} mb={4} width="100%">
                  <Divider />
                </Box>
              </Grid>
            </Grid>
          )}

          {/* targetGroups */}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {`${t('createCampaign.targetGroups')} (${formatFloatToView(
                  pricing.targetGroups.toFixed(2)
                )}€ ${campaignGoal})`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={TARGET_GROUPS.map(({ name }) => name)}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  onChange={handleTargetGroupChange}
                  value={targetGroups}
                  disabled={disabled}
                  renderOption={(option) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes.checkbox}
                        checked={targetGroups.includes(option)}
                        value={option}
                      />
                      {option}
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      variant="outlined"
                      placeholder={t('createCampaign.targetGroups')}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Box mt={2} width="100%" />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(CampaignTargeting);
