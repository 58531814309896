import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Check, ExpandLess, ChevronLeft } from '@material-ui/icons';

const WebsitesGeneratorListItem = (props) => {
  const {
    category,
    checked,
    websites,
    onSelectCategory,
    onSelectCategoryWebsite,
  } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleCollapsed = () => setIsCollapsed(!isCollapsed);
  return (
    <>
      <ListItem divider button component="li" onClick={handleCollapsed}>
        <ListItemText primary={category} />
        <ListItemSecondaryAction>
          <IconButton onClick={() => onSelectCategory({ category })}>
            <Check style={{ color: checked ? green[500] : 'inherit' }} />
          </IconButton>

          <IconButton onClick={handleCollapsed}>
            {isCollapsed ? <ExpandLess /> : <ChevronLeft />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      {isCollapsed && (
        <List dense disablePadding>
          {websites.map((website) => (
            <ListItem divider key={website.name}>
              <ListItemText secondary={website.name} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => onSelectCategoryWebsite({ category, website })}
                >
                  <Check
                    style={{ color: website.checked ? green[500] : 'inherit' }}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default WebsitesGeneratorListItem;
