import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Grid,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Box,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputAdornment,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import AppRadioButton from '../../../../components/AppRadioButton';
import {
  CampaignAdvertisingType,
  CampaignGoal,
  CampaignSocialPlatformTypes,
  CampaignTypes,
} from '../../../../enums/campaign';
import {
  AwarenessIcon,
  CalendarIcon,
  ConvinceIcon,
  SalesAndLeadsIcon,
} from '../../../../components/CustomIcons';
import AppLabeledCheckbox from '../../../../components/AppLabeledCheckbox';
import { toggleArrayItem } from '../../services/campaignStateHelper';

const CampaignDetails = (props) => {
  const {
    advertisers = [],
    handleCampaignDetails,
    campaignDetails,
    disabled,
    isFormSubmitted,
    type,
    refsMap,
  } = props;
  const { t } = useTranslation();

  const selectAdvertiserRef = useRef();
  const campaignNameRef = useRef();
  const datePickerRef = useRef();
  const platformRef = useRef();

  useEffect(() => {
    refsMap.current['advertiser-select'] = selectAdvertiserRef;
    refsMap.current['campaign-name'] = campaignNameRef;
    refsMap.current['date-picker'] = datePickerRef;
    // eslint-disable-next-line dot-notation
    refsMap.current['platform'] = platformRef;
  }, [refsMap]);

  const {
    name,
    goal,
    startDate,
    endDate,
    advertiser,
    socialPlatform,
    instagramProfile,
    facebookProfile,
    advertisingType,
  } = campaignDetails;

  const campaignGoalInfobox = {
    awareness: {
      social: t('tooltips.awareness.social'),
      video: t('tooltips.awareness.video'),
    },
    convince: {
      social: t('tooltips.convince.social'),
      video: t('tooltips.convince.video'),
    },
    salesLeads: {
      social: t('tooltips.salesLeads.social'),
      video: t('tooltips.salesLeads.video'),
    },
  };

  const handleGoalChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      const newGoal = parseInt(event.target.value, 10);
      handleCampaignDetails({ goal: newGoal });
    },
    [handleCampaignDetails, disabled]
  );

  const handleAdvertisingTypeChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      handleCampaignDetails({ advertisingType: event.target.value });
    },
    [handleCampaignDetails, disabled]
  );

  const handleNameChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      handleCampaignDetails({ name: event.target.value });
    },
    [handleCampaignDetails, disabled]
  );

  const handleAdvertiserChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      handleCampaignDetails({ advertiser: event.target.value });
    },
    [handleCampaignDetails, disabled]
  );

  const handleStartDateChange = useCallback(
    (newStartDate) => {
      if (disabled) {
        return;
      }
      handleCampaignDetails({ startDate: newStartDate.toDate() });
    },
    [handleCampaignDetails, disabled]
  );

  const handleEndDateChange = useCallback(
    (newEndDate) => {
      if (disabled) {
        return;
      }
      handleCampaignDetails({ endDate: newEndDate.toDate() });
    },
    [handleCampaignDetails, disabled]
  );

  const handleSocialPlatformChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      const { name: targetName } = event.target;
      const updatedSocialPlatform = toggleArrayItem(socialPlatform, targetName);
      const payload = { socialPlatform: updatedSocialPlatform };

      if (!socialPlatform.includes(CampaignSocialPlatformTypes.Instagram)) {
        payload.instagramProfile = '';
      }

      if (!socialPlatform.includes(CampaignSocialPlatformTypes.Facebook)) {
        payload.facebookProfile = '';
      }

      handleCampaignDetails(payload);
    },
    [handleCampaignDetails, socialPlatform, disabled]
  );

  const handleInstagramProfileChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      handleCampaignDetails({ instagramProfile: event.target.value });
    },
    [handleCampaignDetails, disabled]
  );

  const handleFacebookProfileChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      handleCampaignDetails({ facebookProfile: event.target.value });
    },
    [handleCampaignDetails, disabled]
  );

  const currentDate = moment();
  const minAllowedDate = disabled
    ? undefined
    : moment(currentDate).add(3, 'days');

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{t('createCampaign.stepOne')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {/* advertiser */}
          <Grid item container spacing={2}>
            <Grid ref={selectAdvertiserRef} item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.advertiser')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  error={isFormSubmitted && !advertiser}
                  placeholder={t('createCampaign.selectAdvertiser')}
                  labelId="advertiser-select-label"
                  id="advertiser-select"
                  displayEmpty
                  value={advertiser || ''}
                  onChange={handleAdvertiserChange}
                  disabled={disabled}
                >
                  <MenuItem value="" disabled>
                    {t('createCampaign.selectAdvertiserPlaceHolder')}
                  </MenuItem>
                  {advertisers.map((item, i) => (
                    <MenuItem value={item} key={item?.id || i}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Box mt={3} width="100%" />
          </Grid>

          {/* campaign name */}
          <Grid item container spacing={2}>
            <Grid ref={campaignNameRef} item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.campaignName')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={isFormSubmitted && !name}
                value={name}
                onChange={handleNameChange}
                placeholder={t('createCampaign.campaignNamePlaceHolder')}
                variant="outlined"
                disabled={disabled}
                fullWidth
              />
            </Grid>
            <Box mt={3} width="100%" />
          </Grid>

          {/* social platform link (only for social campaigns) */}
          {type === CampaignTypes.Social && (
            <>
              <Grid ref={platformRef} item container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {t('createCampaign.campaignSocialPlatform')}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <AppLabeledCheckbox
                    checked={socialPlatform.includes(
                      CampaignSocialPlatformTypes.Instagram
                    )}
                    error={isFormSubmitted && !socialPlatform.length}
                    label="Instagram"
                    name={CampaignSocialPlatformTypes.Instagram}
                    onChange={handleSocialPlatformChange}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    value={instagramProfile}
                    onChange={handleInstagramProfileChange}
                    placeholder={t('createCampaign.addPlatformProfile')}
                    variant="outlined"
                    disabled={
                      disabled ||
                      !socialPlatform.includes(
                        CampaignSocialPlatformTypes.Instagram
                      )
                    }
                    fullWidth
                  />
                </Grid>
                <Box mt={3} width="100%" />
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <AppLabeledCheckbox
                    checked={socialPlatform.includes(
                      CampaignSocialPlatformTypes.Facebook
                    )}
                    error={isFormSubmitted && !socialPlatform.length}
                    label="Facebook"
                    name={CampaignSocialPlatformTypes.Facebook}
                    onChange={handleSocialPlatformChange}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    value={facebookProfile}
                    onChange={handleFacebookProfileChange}
                    placeholder={t('createCampaign.addPlatformProfile')}
                    variant="outlined"
                    disabled={
                      disabled ||
                      !socialPlatform.includes(
                        CampaignSocialPlatformTypes.Facebook
                      )
                    }
                    fullWidth
                  />
                </Grid>
                <Box mt={4} width="100%" />
              </Grid>
            </>
          )}

          {/* campaign goal (only for video and social campaigns) */}
          {type !== CampaignTypes.Banner && (
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('createCampaign.campaignGoal')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppRadioButton
                  error={isFormSubmitted && !goal && goal !== 0}
                  required
                  icon={AwarenessIcon}
                  labelText={t('createCampaign.increaseAwareness')}
                  checked={goal === CampaignGoal.Awareness}
                  onChange={handleGoalChange}
                  value={CampaignGoal.Awareness}
                  tooltip={campaignGoalInfobox.awareness[type]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppRadioButton
                  error={isFormSubmitted && !goal && goal !== 0}
                  icon={ConvinceIcon}
                  labelText={t('createCampaign.convince')}
                  checked={goal === CampaignGoal.Convince}
                  onChange={handleGoalChange}
                  value={CampaignGoal.Convince}
                  tooltip={campaignGoalInfobox.convince[type]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppRadioButton
                  error={isFormSubmitted && !goal && goal !== 0}
                  icon={SalesAndLeadsIcon}
                  labelText={t('createCampaign.salesLeads')}
                  checked={goal === CampaignGoal.SalesAndLeads}
                  onChange={handleGoalChange}
                  value={CampaignGoal.SalesAndLeads}
                  tooltip={campaignGoalInfobox.salesLeads[type]}
                />
              </Grid>
              <Box mt={3} width="100%" />
            </Grid>
          )}

          {/* CPC and TKP options (only for banner campaigns) */}
          {type === CampaignTypes.Banner && (
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t('createCampaign.advertisingType')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <AppRadioButton
                  error={isFormSubmitted && !advertisingType}
                  required
                  icon="visibility"
                  labelText={t('createCampaign.TKPOption')}
                  checked={advertisingType === CampaignAdvertisingType.TKP}
                  onChange={handleAdvertisingTypeChange}
                  value={CampaignAdvertisingType.TKP}
                  tooltip={t('tooltips.TKP')}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <AppRadioButton
                  error={isFormSubmitted && !advertisingType}
                  required
                  icon="mouse"
                  labelText={t('createCampaign.CPCOption')}
                  checked={advertisingType === CampaignAdvertisingType.CPC}
                  onChange={handleAdvertisingTypeChange}
                  value={CampaignAdvertisingType.CPC}
                  tooltip={t('tooltips.CPC')}
                />
              </Grid>
              <Box mt={3} width="100%" />
            </Grid>
          )}

          {/* campaign duration */}
          <Grid item container spacing={2}>
            <Grid ref={datePickerRef} item xs={12} md={6} lg={4}>
              <Typography variant="body1">
                {t('createCampaign.campaignStart')}
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  error={isFormSubmitted && !startDate}
                  format="DD.MM.YYYY"
                  minDate={minAllowedDate}
                  maxDate={endDate || undefined}
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  margin="normal"
                  placeholder={t('createCampaign.campaignStart')}
                  value={startDate}
                  onChange={handleStartDateChange}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: 'pointer' }}
                      >
                        <CalendarIcon color="secondary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography variant="body1">
                {t('createCampaign.campaignEnd')}
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  error={isFormSubmitted && !endDate}
                  format="DD.MM.YYYY"
                  minDate={startDate || minAllowedDate}
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  margin="normal"
                  placeholder={t('createCampaign.campaignEnd')}
                  value={endDate}
                  onChange={handleEndDateChange}
                  disabled={disabled}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: 'pointer' }}
                      >
                        <CalendarIcon color="secondary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Box mt={1} width="100%" />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(CampaignDetails);
