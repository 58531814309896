/* eslint-disable camelcase */
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export function useQueryParams(search, validator) {
  return React.useMemo(() => {
    const newSearchParams = new URLSearchParams(search);
    return validator?.(newSearchParams) || newSearchParams;
  }, [search, validator]);
}
