import React from 'react';
import {
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Breadcrumb from '../../components/Breadcrumb';

import GET_CAMPAIGN_ATTACHMENTS from './queries';

const useStyles = makeStyles({ link: { wordBreak: 'break-word' } });

const CampaignAttachments = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { campaignId } = useParams();
  const { loading, data } = useQuery(GET_CAMPAIGN_ATTACHMENTS, {
    variables: { id: campaignId },
    fetchPolicy: 'network-only',
  });
  const campaignAttachments = data?.campaignAttachments || [];

  if (loading) {
    return <CircularProgress color="primary" />;
  }

  return (
    <>
      <Breadcrumb>
        <Link to="/campaigns" component={RouterLink}>
          {t('campaigns.campaigns')}
        </Link>
        <Typography color="textPrimary">
          {t('campaignAttachments.title')}
        </Typography>
      </Breadcrumb>

      <Typography variant="h6" gutterBottom>
        {t('campaignAttachments.title')}
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>{t('common.url')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaignAttachments?.map(({ id, imageUrl, image }, i) => (
              <TableRow key={id}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>
                  <Link
                    href={imageUrl || image}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={classes.link}
                  >
                    {imageUrl || image}
                  </Link>
                </TableCell>
              </TableRow>
            ))}

            {!campaignAttachments?.length && (
              <TableRow>
                <TableCell />
                <TableCell>
                  <Typography variant="body1">
                    {t('campaignAttachments.emptyAttachments')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CampaignAttachments;
