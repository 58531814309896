/* eslint-disable import/no-named-as-default */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Box, CircularProgress, Divider, Grid } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GET_GEO_CATEGORIES } from './queries';
import GeoCategoriesSelectedList from './components/GeoCategoriesSelectedList';
import Map from '../Map';
import config from '../../../../configuration';
import GeoCategoryFilters from './components/GeoCategoryFilters';
import { GeoCategoriesList } from './components/GeoCategoriesList';

export const CategoriesSelector = (props) => {
  const { t } = useTranslation();
  const { geoTargetingChangeHandler, categoriesPreset, disabled, onMapLoaded } =
    props;
  const { data, loading } = useQuery(GET_GEO_CATEGORIES);
  const [subCategoryOpenStates, setSubCategoryOpenStates] = useState({});
  const [popupId, setPopupId] = useState(null);
  const [subCategoryCheckedStates, setSubCategoryCheckedStates] = useState({});
  const [placesCheckedStates, setPlacesCheckedStates] = useState({});
  const [categoryFilter, setCategoryFilter] = useState('');
  const [textFilter, setTextFilter] = useState();
  const geoCategoriesResponse = data?.geoCategories || [];

  const { geoCategories, allCategories } = useMemo(() => {
    const defaultGeoCategoriesInfo = {
      geoCategories: {},
      allCategories: [],
    };
    if (!geoCategoriesResponse.length) {
      return defaultGeoCategoriesInfo;
    }

    const { geoCategories: newGeoCategories, allCategories: newAllCategories } =
      geoCategoriesResponse.reduce(
        (acc, place) => {
          const { id, category } = place;
          acc.geoCategories[id] = place;
          acc.allCategories[category] = category;
          return acc;
        },
        {
          geoCategories: {},
          allCategories: {},
        }
      );
    return {
      geoCategories: newGeoCategories || {},
      allCategories: Object.keys(newAllCategories) || [],
    };
  }, [geoCategoriesResponse]);

  /**
   * Apply text and category filters
   */
  const applyFilters = useCallback(() => {
    if (!textFilter && !categoryFilter) {
      return geoCategories;
    }
    const filteredCategories = Object.keys(geoCategories).reduce((acc, id) => {
      let push;
      const { category, city, zipCode } = geoCategories[id];
      if (!categoryFilter || categoryFilter === category) {
        push = true;
      } else {
        return acc;
      }
      const isCity = ~city
        ?.toLowerCase()
        ?.search(textFilter?.toLowerCase()?.trim());
      const isZipCode = ~zipCode
        ?.toLowerCase()
        ?.search(textFilter?.toLowerCase()?.trim());
      if (!textFilter || isCity || isZipCode) {
        push = true;
      } else {
        return acc;
      }
      if (push) {
        acc[id] = geoCategories[id];
      }
      return acc;
    }, {});
    return filteredCategories;
  }, [geoCategories, textFilter, categoryFilter]);

  /**
   * Builds render tree
   * @type {{}}
   */
  const renderTree = useMemo(() => {
    const categories = applyFilters();
    const newRenderTree = Object.keys(categories)?.reduce((acc, id) => {
      const { subCategory } = categories[id];
      if (!acc[subCategory]) {
        acc[subCategory] = {
          checked: subCategoryCheckedStates[subCategory],
          places: [],
          name: subCategory,
          isOpen: subCategoryOpenStates[subCategory],
        };
      }
      acc[subCategory].places.push(categories[id]);
      return acc;
    }, {});
    return newRenderTree;
  }, [
    subCategoryCheckedStates,
    subCategoryOpenStates,
    applyFilters,
    placesCheckedStates,
  ]);

  /**
   * Set preset checked states
   */
  useEffect(() => {
    if (!categoriesPreset?.length) {
      return;
    }
    const checkedStates = categoriesPreset.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});
    setPlacesCheckedStates(checkedStates);
    if (onMapLoaded) {
      setTimeout(onMapLoaded, 2000);
    }
  }, [categoriesPreset]);

  /**
   * General on change
   */
  useEffect(() => {
    geoTargetingChangeHandler({
      geoCategoriesIds: Object.keys(placesCheckedStates || {}).filter(
        (id) => placesCheckedStates[id]
      ),
    });
    setPopupId(null);
  }, [placesCheckedStates]);

  const toggleSubCategoryOpen = (subCategoryName) => {
    const isOpen = subCategoryOpenStates[subCategoryName];
    setSubCategoryOpenStates({
      ...subCategoryOpenStates,
      [subCategoryName]: !isOpen,
    });
  };

  const toggleSubCategoryCheck = (subCategoryName, places) => {
    const isChecked = !subCategoryCheckedStates[subCategoryName];
    setSubCategoryCheckedStates({
      ...subCategoryCheckedStates,
      [subCategoryName]: isChecked,
    });
    const newPlacesCheckedState = places.reduce((acc, { id }) => {
      acc[id] = isChecked;
      return acc;
    }, {});
    setPlacesCheckedStates({
      ...placesCheckedStates,
      ...newPlacesCheckedState,
    });
  };

  const togglePlaceCheck = (id) => {
    setPlacesCheckedStates({
      ...placesCheckedStates,
      [id]: !placesCheckedStates[id],
    });
  };

  const removeSelectedCategory = useCallback(
    (subCategory) => {
      const newCheckedStates = Object.keys(geoCategories)?.reduce((acc, id) => {
        if (subCategory !== geoCategories[id].subCategory) {
          return acc;
        }
        acc[id] = false;
        return acc;
      }, {});
      setPlacesCheckedStates((oldPlacesCheckedStates) => ({
        ...oldPlacesCheckedStates,
        ...newCheckedStates,
      }));
      setSubCategoryCheckedStates((oldSubCategoryCheckedStates) => ({
        ...oldSubCategoryCheckedStates,
        [subCategory]: false,
      }));
    },
    [geoCategories]
  );

  const removeSelectedPlace = useCallback(
    (placeId) => {
      const newPlacesCheckedStates = Object.keys(geoCategories)?.reduce(
        (acc, id) => {
          if (placeId !== id) {
            return acc;
          }
          acc[id] = false;
          return acc;
        },
        {}
      );
      setPlacesCheckedStates((oldPlacesCheckedStates) => ({
        ...oldPlacesCheckedStates,
        ...newPlacesCheckedStates,
      }));
    },
    [geoCategories]
  );

  if (!Object.keys(geoCategories)?.length && !loading) {
    return t('createCampaign.noCategories');
  }

  if (loading) {
    return <CircularProgress color="primary" size={20} />;
  }

  return (
    <>
      <Grid container>
        {!disabled && (
          <>
            <Grid container>
              <Grid container item xs={6} spacing={1} wrap="nowrap">
                <GeoCategoryFilters
                  textFilter={textFilter}
                  categoryFilter={categoryFilter}
                  allCategories={allCategories}
                  onCategoryFilterChanged={(category) => {
                    setCategoryFilter(category);
                  }}
                  onTextFilterChanged={(newTextFilter) => {
                    setTextFilter(newTextFilter);
                  }}
                />
              </Grid>
            </Grid>
            <Box mt={2} width="100%" />
          </>
        )}
        <Divider width="100%" />
        <Grid item xs={6} style={{ maxHeight: 391, overflow: 'scroll' }}>
          <GeoCategoriesList
            disabled={disabled}
            renderTree={renderTree}
            toggleSubCategoryOpen={toggleSubCategoryOpen}
            toggleSubCategoryCheck={toggleSubCategoryCheck}
            placesCheckedStates={placesCheckedStates}
            togglePlaceCheck={togglePlaceCheck}
          />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs style={{ maxHeight: 391, overflow: 'scroll' }}>
          <GeoCategoriesSelectedList
            disabled={disabled}
            geoCategories={geoCategories}
            checkedPlaces={placesCheckedStates}
            removeSelectedPlace={removeSelectedPlace}
            removeSelectedCategory={removeSelectedCategory}
          />
        </Grid>
      </Grid>
      <Divider width="100%" />
      <Grid container style={{ minHeight: 300 }}>
        <Divider orientation="horizontal" flexItem />
        <Box mt={3} width="100%" />
        <Grid container item xs={12} justify="center" alignItems="center">
          <Map
            center={config.defaultMapCenter}
            zoom={6}
            options={{
              disableDefaultUI: true,
              disableDoubleClickZoom: true,
              zoomControl: true,
            }}
          >
            {Object.keys(placesCheckedStates).map((id) => {
              const { zipCode, city, street, description } = geoCategories[id];
              return (
                placesCheckedStates[id] && (
                  <Marker
                    key={id}
                    onClick={() => {
                      setPopupId(id);
                    }}
                    position={{
                      lat: geoCategories[id].lat,
                      lng: geoCategories[id].lng,
                    }}
                  >
                    {popupId === id && (
                      <InfoWindow
                        open={popupId === id}
                        position={{
                          lat: geoCategories[id].lat,
                          lng: geoCategories[id].lng,
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            {description}
                          </Grid>
                          <Grid item xs={12}>
                            {street}
                          </Grid>
                          <Grid item xs={12}>
                            {zipCode} {city}
                          </Grid>
                        </Grid>
                      </InfoWindow>
                    )}
                  </Marker>
                )
              );
            })}
          </Map>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(CategoriesSelector);
