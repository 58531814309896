import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(-3),
    top: theme.spacing(10),
    position: 'sticky',
  },
}));

const CampaignInfoBoxContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      alignItems="flex-start"
    >
      {children}
    </Grid>
  );
};

export default memo(CampaignInfoBoxContainer);
