import gql from 'graphql-tag';

const GET_CAMPAIGN_ATTACHMENTS = gql`
  query getCampaignAttachments($id: ID!) {
    campaignAttachments(campaignId: $id) {
      id
      campaignId
      imageUrl
      image
      created_at
      updated_at
    }
  }
`;

export default GET_CAMPAIGN_ATTACHMENTS;
