import React from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    height: '441px',
    overflow: 'auto',
    padding: 0,
  },
}));

const AudienceTargetingListSubItems = (props) => {
  const { children, categoryIndex, onSelectSubCategory } = props;
  const classes = useStyles();

  return (
    <List className={classes.listContainer}>
      {children.map(
        ({ id: subcategoryId, name, isSelected }, subcategoryIndex) => (
          <ListItem divider key={subcategoryId}>
            <ListItemText secondary={name} />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() =>
                  onSelectSubCategory({ categoryIndex, subcategoryIndex })
                }
              >
                <Check style={{ color: isSelected ? 'green' : 'inherit' }} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )
      )}
    </List>
  );
};

export default AudienceTargetingListSubItems;
