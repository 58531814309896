import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import AppCheckboxButton from '../../../../../../../../components/AppCheckboxButton';
import {
  FemaleIcon,
  MaleIcon,
} from '../../../../../../../../components/CustomIcons';

const useStyles = makeStyles({
  tooltip: {
    position: 'absolute',
    top: '-10px',
    left: 94,
    cursor: 'pointer',
  },
});

const GenderTargeting = (props) => {
  const { isFormSubmitted, gender, handleGenderChange } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Typography variant="body1">
        {t('createCampaign.gender')}
        <Tooltip title={t('tooltips.gender')}>
          <Info color="secondary" fontSize="inherit" />
        </Tooltip>
      </Typography>
      <Box mt={2} width="100%" />
      <Grid container spacing={2}>
        <Grid item>
          <AppCheckboxButton
            error={isFormSubmitted && !gender.length}
            icon={MaleIcon}
            labelText={t('createCampaign.male')}
            name="male"
            checked={gender.includes('male')}
            checkboxHandler={handleGenderChange}
          />
        </Grid>
        <Grid item>
          <AppCheckboxButton
            error={isFormSubmitted && !gender.length}
            icon={FemaleIcon}
            labelText={t('createCampaign.female')}
            name="female"
            checked={gender.includes('female')}
            checkboxHandler={handleGenderChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default memo(GenderTargeting);
