import React, { memo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import AppLabeledCheckbox from '../../../../../../components/AppLabeledCheckbox';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControlLabel-root': {
      minWidth: '100px',
    },
  },
  error: {},
}));

const AgeTargeting = (props) => {
  const { handleAgeChange, ages, isFormSubmitted } = props;
  const error = isFormSubmitted && !ages.some((age) => age);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="body1" gutterBottom>
        {t('createCampaign.demographicTargeting')}
      </Typography>
      <FormGroup>
        <Grid
          container
          className={classNames(classes.root, {
            error,
          })}
        >
          <Grid item xs={12}>
            <AppLabeledCheckbox
              checked={Boolean(ages[0])}
              label="18-25"
              name="age1825"
              value={1}
              onChange={handleAgeChange}
              error={error}
            />

            <AppLabeledCheckbox
              checked={Boolean(ages[1])}
              label="26-35"
              name="age2635"
              value={1}
              onChange={handleAgeChange}
              error={error}
            />

            <AppLabeledCheckbox
              checked={Boolean(ages[2])}
              label="36-45"
              name="age3645"
              value={1}
              onChange={handleAgeChange}
              error={error}
            />
          </Grid>

          <Grid item xs={12}>
            <AppLabeledCheckbox
              checked={Boolean(ages[3])}
              label="46-55"
              name="age4655"
              value={1}
              onChange={handleAgeChange}
              error={error}
            />

            <AppLabeledCheckbox
              checked={Boolean(ages[4])}
              label="56-65"
              name="age5665"
              value={1}
              onChange={handleAgeChange}
              error={error}
            />

            <AppLabeledCheckbox
              checked={Boolean(ages[5])}
              label="66+"
              name="age66"
              value={1}
              onChange={handleAgeChange}
              error={error}
            />
          </Grid>
        </Grid>
      </FormGroup>
    </>
  );
};

export default memo(AgeTargeting);
