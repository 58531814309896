import XLSX from 'xlsx';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { invert } from 'lodash';
import {
  CampaignAdvertisingType,
  CampaignGoal,
  CampaignIntensity,
} from '../../enums/campaign';
import { formatFloatToView } from '../format-helper';
import { getPricePerUser } from '../../scenes/CreateViewEditCampaign/services/calculation';

const buildAges = (ages) =>
  ages.reduce((acc, isSelected, index) => {
    let ageString;
    if (!isSelected) {
      return acc;
    }
    switch (index) {
      case 0:
        ageString = '18-25';
        break;
      case 1:
        ageString = '26-35';
        break;
      case 2:
        ageString = '36-45';
        break;
      case 3:
        ageString = '46-55';
        break;
      case 4:
        ageString = '56-65';
        break;
      case 5:
        ageString = '66+';
        break;
      default:
        ageString = '66+';
        break;
    }
    acc.push(ageString);
    return acc;
  }, []);

const websitesList = (websites) => {
  return [...websites.map((i) => i.websites)].flat(1);
};

const customWebsitesList = (customWebsites) =>
  customWebsites.map(({ name }) => name);

const excelWebsites = (targeting, websites, customWebsites) => {
  let exelWebsites;
  switch (targeting) {
    case 0:
      exelWebsites = [
        websitesList(websites),
        customWebsitesList(customWebsites),
      ]
        .flat(1)
        .join(', ');
      break;
    case 1:
      exelWebsites = 'Top AGOF';
      break;
    case 2:
      exelWebsites = 'Keine Sitelist';
      break;
    default:
      exelWebsites = 'Keine Sitelist';
      break;
  }
  return exelWebsites;
};

const buildGender = (gender) => {
  return gender
    .map((genderType) => (genderType === 'male' ? 'Männlich' : 'Weiblich'))
    .join(', ');
};

const buildDemographics = (gender, ages) =>
  `${buildGender(gender)} - ${buildAges(ages)}`;

const getGeoTargetingRaw = (geoTargeting, geoData) => {
  if (geoTargeting.geoRadiusText) {
    return geoTargeting.geoRadiusText;
  }
  if (geoTargeting.geoPolygonText) {
    return geoTargeting.geoPolygonText;
  }
  if (geoTargeting.geoPolygons && geoTargeting.geoPolygons.length) {
    return 'Polygon Targeting';
  }
  if (geoTargeting.geoRadiuses && geoTargeting.geoRadiuses.length) {
    return 'Radius Targeting';
  }
  if (
    geoTargeting.geoRegionIds &&
    geoTargeting.geoRegionIds.length &&
    geoData.regionsByIds
  ) {
    const regionArray = [];
    for (const region of geoData.regionsByIds) {
      regionArray.push(region.name);
    }
    return regionArray.join(', ');
  }
  if (
    geoTargeting.geoZipCodes &&
    geoTargeting.geoZipCodes.length &&
    geoData.zipsByIds
  ) {
    const zipArray = [];
    for (const zip of geoData.zipsByIds) {
      zipArray.push(zip.name);
    }
    return zipArray.join(', ');
  }
  return '';
};

const downloadExcel = (params, savedCampaign, staticPrice) => {
  const { campaign, agency, geoData } = params;
  const { campaignDetails, campaignTargeting, geoTargeting, campaignPricing } =
    campaign;
  const { name, advertisingType, goal, startDate, endDate, type } =
    campaignDetails;
  const {
    websitesTargeting,
    websitesIncludeList,
    customWebsitesIncludeList,
    devices,
    gender,
    ages,
    landingPage,
    targetGroups,
  } = campaignTargeting;
  const { budget, intensity, comment } = campaignPricing;

  const calculatedPricePerUser = getPricePerUser({
    campaignValue: campaign,
    agency,
  });

  const savedPricePerUser = savedCampaign?.pricesPerUser;
  const priceGoal = advertisingType || CampaignAdvertisingType.TKP;
  const pricePerUser = savedPricePerUser
    ? savedPricePerUser[priceGoal]
    : calculatedPricePerUser;
  let impressions = (budget / pricePerUser.price).toFixed();

  if (staticPrice) {
    const staticPrices = {
      CPC: staticPrice.CPC,
      TKP: staticPrice.TKP / 1000,
    };
    impressions = (budget / staticPrices[priceGoal]).toFixed();
  }

  const formattedImpressions = Number(impressions).toLocaleString('de-DE');
  let price = pricePerUser.viewPrice;
  if (staticPrice) {
    price = staticPrice[advertisingType];
  }
  const formattedPrice = `${formatFloatToView(price.toFixed(2))}€`;
  let campaignGoal;
  if (goal) campaignGoal = invert(CampaignGoal)[goal];
  else if (advertisingType === CampaignAdvertisingType.TKP)
    campaignGoal = 'Reichweitenoptimierte Kampagnen';
  else campaignGoal = 'Klickoptimierte Kampagnen';

  const newExcel = XLSX.utils.book_new();
  newExcel.SheetNames.push(`Campaign-${name}`);
  const excelData = [
    ['Kategorie', 'Wert'],
    ['Kampagnenname', name],
    ['Kampagnenziel', campaignGoal],
    ['Kampagnenstart', moment(startDate).format('DD.MM.YYYY')],
    ['Kampagnenende', moment(endDate).format('DD.MM.YYYY')],
    ['Kampagnentyp', type],
    ['Geräte', devices.join(', ')],
    ['Demographisches Targeting', buildDemographics(gender, ages)],
    ['Geo Targeting', getGeoTargetingRaw(geoTargeting, geoData)],
    ['Landingpage', landingPage],
    [
      'Websites',
      excelWebsites(
        websitesTargeting,
        websitesIncludeList,
        customWebsitesIncludeList
      ),
    ],
    ['Zielgruppen', targetGroups.join(', ')],
    ['Werbedruck', CampaignIntensity[intensity]],
    ['Mediabudget', `${budget}€`],
    [
      advertisingType === CampaignAdvertisingType.TKP ? 'TKP' : 'CPC',
      formattedPrice,
    ],
    [
      advertisingType === CampaignAdvertisingType.TKP
        ? 'Impressionen'
        : 'Klicks',
      formattedImpressions,
    ],
    ['Kommentar', comment],
  ];

  const excelSheet = XLSX.utils.aoa_to_sheet(excelData);
  newExcel.Sheets[`Campaign-${name}`] = excelSheet;
  const excelBuffer = XLSX.write(newExcel, {
    bookType: 'xlsx',
    type: 'binary',
  });
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  const fileType = 'application/octet-stream';
  const data = new Blob([s2ab(excelBuffer)], { type: fileType });

  saveAs(data, `Campaign-${name}.xlsx`);
};

export default downloadExcel;
