import { getRole } from '../JWT-Auth';
import UserRoles from '../../enums/UserRoles';

const actions = {
  'see-page': [UserRoles.User, UserRoles.Admin],
  'see-user-page': [UserRoles.Admin],
  'see-admin-settings-page': [UserRoles.Admin],
  'see-advertiser-page': [UserRoles.User],
};

export default (action) => {
  const currentRole = getRole();
  return Boolean(actions[action]?.includes(currentRole));
};
