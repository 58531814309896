import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Box, TextField, Tooltip } from '@material-ui/core';
import { chunk, debounce, uniqBy } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { InfoBox } from '@react-google-maps/api';
import Map from '../Map';
import {
  GET_NEAREST_ZIPS,
  GET_ZIPS_BY_NAME,
  GET_ZIPS_POINTS,
  GET_ZIPS_BY_IDS,
  GET_ZIPS_IN_NAMES,
} from './queries';
import Polygon from '../Polygon';
import PopulationCounter from '../PopulationCounter';
import {
  getCenterByPoints,
  getZoomByPoints,
  mergePoints,
} from '../../../../services/geo-location-helper';
import config from '../../../../configuration';

const ZipSelector = (props) => {
  const { t } = useTranslation();
  const {
    geoTargetingChangeHandler = () => {},
    zipsPreset,
    isFormSubmitted,
    onMapLoaded,
    disabled,
  } = props;
  const [selectedZips, setSelectedZips] = useState([]);
  const [zipsInNames, setZipsInNames] = useState([]);
  const [population, setPopulation] = useState(0);
  const [isPointsLoading, setIsPointsLoading] = useState(false);
  const [renderPolygons, setRenderPolygons] = useState(false);
  const [isCopyPaste, setIsCopyPaste] = useState(false);
  const [applyRecenter, setApplyRecenter] = useState(false);
  const [map, setMap] = useState(null);
  const [coordinatesSearch, setCoordinatesSearch] = useState(null);
  const [isPolygonTooltipOpen, setIsPolygonTooltipOpen] = useState(null);
  const [hoveredZipPolygon, setHoveredZipPolygon] = useState(null);
  const [zoom, setZoom] = useState(6);
  const [zipIdsToFetchPoints, setZipIdsToFetchPoints] = useState([]);
  const [zipsNameToSearch, setZipsNameToSearch] = useState(null);
  const [center, setCenter] = useState(config.defaultMapCenter);
  const [zipsCache, setZipsCache] = useState({});

  const { data: zipsByNameData, loading: isZipsByNameLoading } = useQuery(
    GET_ZIPS_BY_NAME,
    {
      skip: !zipsNameToSearch,
      variables: {
        name: zipsNameToSearch,
      },
    }
  );
  const { data: zipsInNamesData, loading: isZipsInNamesLoading } = useQuery(
    GET_ZIPS_IN_NAMES,
    {
      skip: !zipsInNames?.length,
      variables: {
        names: zipsInNames,
      },
    }
  );

  const { data: zipsPresetData } = useQuery(GET_ZIPS_BY_IDS, {
    skip: !zipsPreset?.length,
    variables: {
      ids: zipsPreset,
    },
  });
  const { data: nearestZipsData } = useQuery(GET_NEAREST_ZIPS, {
    skip: !coordinatesSearch,
    variables: {
      search: coordinatesSearch,
    },
  });
  const { refetch: refetchZipPoints } = useQuery(GET_ZIPS_POINTS, {
    skip: true,
    variables: {
      ids: zipIdsToFetchPoints,
    },
  });

  const nearestZips = nearestZipsData?.nearestZips;
  const zipsPresetResponse = zipsPresetData?.zipsByIds || [];
  const zipsInNamesResponse = zipsInNamesData?.zipsInNames || [];

  const zipOptions = useMemo(() => {
    return (
      zipsByNameData?.zipsByName?.filter(
        ({ id }) =>
          !selectedZips?.find(({ id: selectedZipId }) => id === selectedZipId)
      ) || []
    );
  }, [zipsByNameData?.zipsByName, selectedZips]);

  const fetchAndStorePoints = async (newZipIdsToFetchPoints) => {
    setIsPointsLoading(true);
    const zipsRequests = [];
    const idsChunk = chunk(newZipIdsToFetchPoints, config.zipsPerRequest);
    idsChunk.forEach((ids) => {
      zipsRequests.push(
        refetchZipPoints({
          ids,
        })
      );
    });
    const responses = await Promise.all(zipsRequests);
    const newZipsCache = responses
      .map(({ data }) => data.zipsWithPoints)
      .reduce((acc, zipsWithPoints) => [...acc, ...zipsWithPoints], [])
      .reduce((acc, zip) => ({ ...acc, [zip.id]: { ...zip } }), {});
    setZipsCache({ ...zipsCache, ...newZipsCache });
    setIsPointsLoading(false);
    if (onMapLoaded) {
      setTimeout(onMapLoaded, 1000);
    }
  };
  useEffect(() => {
    if (!zipIdsToFetchPoints?.length || isPointsLoading) {
      return;
    }
    fetchAndStorePoints(zipIdsToFetchPoints);
    setZipIdsToFetchPoints([]);
  }, [zipIdsToFetchPoints]);

  useEffect(() => {
    if (zipsInNamesResponse.length) {
      const selectedUniqueZips = uniqBy(
        [...selectedZips, ...zipsInNamesResponse],
        ({ id }) => id
      );
      setSelectedZips(selectedUniqueZips);
      setZipsInNames([]);
      setApplyRecenter(true);
    }
  }, [zipsInNamesResponse]);

  const handleZipsToFetchPoints = (zipsToFetch) => {
    const idsToFetch = zipsToFetch
      ?.filter(({ id }) => !zipsCache[id])
      .map(({ id }) => id);
    if (!idsToFetch?.length) {
      return;
    }
    setZipIdsToFetchPoints(idsToFetch);
  };

  useEffect(() => {
    const newPopulation =
      selectedZips?.reduce((acc, zip) => acc + zip.population, 0) || 0;
    geoTargetingChangeHandler({
      reach: newPopulation,
      geoZipCodes: selectedZips?.map(({ id }) => id) || null,
    });
    setPopulation(newPopulation);
    handleZipsToFetchPoints(selectedZips);
  }, [selectedZips]);

  useEffect(() => {
    if (!zipsPresetResponse?.length) {
      return;
    }
    setSelectedZips(zipsPresetResponse);
    setApplyRecenter(true);
  }, [zipsPresetResponse]);

  useEffect(() => {
    if (disabled) {
      return;
    }
    handleZipsToFetchPoints(nearestZips);
  }, [nearestZips]);

  useEffect(() => {
    if (!isPointsLoading && applyRecenter) {
      const currentPoints = selectedZips
        .map(({ id }) => zipsCache[id]?.points)
        .filter((item) => item);
      const allPoints = mergePoints(currentPoints);
      const newZoom = getZoomByPoints(allPoints);
      const newCenter = getCenterByPoints({
        defaultCenter: config.defaultMapCenter,
        points: allPoints,
      });
      setZoom(newZoom);
      setCenter(newCenter);
      setApplyRecenter(false);
    }
  }, [isPointsLoading]);

  const onMapChanged = useCallback(
    debounce(() => {
      if (disabled) {
        return;
      }
      const newRenderPolygons = map?.getZoom() > config.zoomToFetchZipsFrom;
      setRenderPolygons(newRenderPolygons);
      if (!map) {
        return;
      }
      if (!newRenderPolygons) {
        return;
      }
      const newCenter = map.getCenter();
      const bounds = map.getBounds();
      const start = bounds.getNorthEast();
      const end = bounds.getSouthWest();
      const distStart =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          newCenter,
          start
        );
      const distEnd =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          newCenter,
          end
        );
      const dist = distEnd + distStart;
      setIsPolygonTooltipOpen(false);
      setCoordinatesSearch({
        coordinates: [newCenter.lng(), newCenter.lat()],
        maxDistance: dist * 0.5,
      });
    }, 500),
    [map]
  );

  const onPolygonMouseOut = () => {
    setHoveredZipPolygon(null);
  };
  const onPolygonMouseOver = (e, zip) => {
    setHoveredZipPolygon(zip);
  };
  const onPolygonClick = (e, zip) => {
    if (disabled) {
      return;
    }
    const selectedPolyIndex = selectedZips.findIndex(({ id }) => id === zip.id);
    const isPolySelected = ~selectedPolyIndex;
    if (isPolySelected) {
      selectedZips.splice(selectedPolyIndex, 1);
    } else {
      selectedZips.push(zip);
    }
    setSelectedZips([...selectedZips]);
    setApplyRecenter(true);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs>
            <Autocomplete
              multiple
              disabled={disabled}
              loadingText={t('common.loading')}
              noOptionsText={t('common.noOptions')}
              loading={isZipsByNameLoading || isZipsInNamesLoading}
              autoComplete={false}
              value={selectedZips}
              options={[...zipOptions] || []}
              getOptionLabel={(option) => option.name}
              onChange={(e, newSelectedZips) => {
                setSelectedZips(newSelectedZips);
                setApplyRecenter(true);
              }}
              filterSelectedOptions
              renderInput={({ InputProps, ...params }) => {
                return (
                  <TextField
                    multiline
                    variant="outlined"
                    error={isFormSubmitted && !selectedZips?.length}
                    onKeyDown={(e) => {
                      if ((e.metaKey || e.ctrlKey) && e.key === 'v') {
                        setIsCopyPaste(true);
                      }
                    }}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (isCopyPaste) {
                        setZipsInNames(
                          value.split(',')?.map((item) => item.trim()) || []
                        );
                        setIsCopyPaste(false);
                      } else {
                        setZipsNameToSearch(value);
                      }
                    }}
                    InputProps={{
                      ...InputProps,
                      style: {
                        height: 'auto',
                        padding: '4px 4px 5px 4px',
                      },
                    }}
                    {...params}
                    placeholder={t('geoTargeting.zipPlaceholder')}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Box mt={3} />
        <Grid container>
          <PopulationCounter population={population} />
        </Grid>
      </Grid>
      <Grid xs={6} item>
        <Map
          center={center}
          zoom={zoom}
          onCenterChanged={onMapChanged}
          onZoomChanged={onMapChanged}
          onLoad={setMap}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            disableDoubleClickZoom: true,
          }}
        >
          <>
            {selectedZips?.map(({ id }) => {
              return (
                <Polygon
                  key={id}
                  path={zipsCache[id]?.points}
                  onClick={(e) => {
                    onPolygonClick(e, zipsCache[id]);
                  }}
                />
              );
            })}
            {renderPolygons &&
              Object.keys(zipsCache)?.map((id) => {
                return (
                  <Polygon
                    options={{
                      fillOpacity: 0.1,
                      strokeOpacity: 0.2,
                      strokeWeight: 2,
                      strokeColor: 'green',
                      fillColor: 'green',
                    }}
                    onClick={(e) => {
                      onPolygonClick(e, zipsCache[id]);
                    }}
                    onMouseOut={(e) => {
                      onPolygonMouseOut(e, zipsCache[id]);
                    }}
                    onMouseOver={(e) => {
                      onPolygonMouseOver(e, zipsCache[id]);
                    }}
                    key={id}
                    path={zipsCache[id]?.points}
                  />
                );
              })}
            {hoveredZipPolygon && (
              <InfoBox
                options={{
                  infoBox: null,
                }}
                onDomReady={() => {
                  setIsPolygonTooltipOpen(true);
                }}
                onLoad={(box) => {
                  box.setVisible(false);
                }}
                onUnmount={() => {
                  setIsPolygonTooltipOpen(false);
                }}
                position={{
                  lat: hoveredZipPolygon.lat,
                  lng: hoveredZipPolygon.lon,
                }}
              >
                <Tooltip
                  placement="top"
                  open={isPolygonTooltipOpen}
                  title={hoveredZipPolygon.name}
                  arrow
                >
                  <div />
                </Tooltip>
              </InfoBox>
            )}
          </>
        </Map>
      </Grid>
    </Grid>
  );
};

export default ZipSelector;
