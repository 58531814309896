import React from 'react';
import { Grid } from '@material-ui/core';

import AudienceTargetingListItemSelected from '../AudienceTargetingListItemSelected';

const AudienceTargetingListItemsSelected = (props) => {
  const { categoriesState, handleSelectSubCategory } = props;

  const categoriesSelected = categoriesState.reduce(
    (acc, el, categoryIndex) => {
      if (el.isSelected) {
        const children = el.children?.reduce(
          (childAcc, childEl, subcategoryIndex) => {
            if (childEl.isSelected)
              return [
                ...childAcc,
                { name: childEl.name, id: childEl.id, subcategoryIndex },
              ];
            return childAcc;
          },
          []
        );
        return [...acc, { name: el.name, id: el.id, children, categoryIndex }];
      }
      return acc;
    },
    []
  );

  return (
    <Grid container>
      {categoriesSelected.map(({ name, children, id, categoryIndex }) => (
        <AudienceTargetingListItemSelected
          name={name}
          key={id}
          categoryIndex={categoryIndex}
          onRemoveSubCategory={handleSelectSubCategory}
        >
          {children}
        </AudienceTargetingListItemSelected>
      ))}
    </Grid>
  );
};

export default AudienceTargetingListItemsSelected;
