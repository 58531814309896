import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Breadcrumbs } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';

const Breadcrumb = ({ children }) => {
  const [domNode, setDomNode] = useState();
  const breadcrumbNode = document.getElementById('breadcrumb');

  useEffect(() => {
    setDomNode(breadcrumbNode);
  }, [breadcrumbNode]);

  return domNode
    ? createPortal(
        <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
          {children}
        </Breadcrumbs>,
        domNode
      )
    : null;
};

export default Breadcrumb;
