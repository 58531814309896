import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import userCan from '../../services/ACL';
import { isLoggedIn } from '../../services/JWT-Auth';

const ProtectedRoute = (props) => {
  const { permission, children } = props;
  if (!isLoggedIn()) {
    return <Navigate to="/login" />;
  }
  return userCan(permission) ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
