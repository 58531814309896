import config from '../../configuration';
import SupportedUploadFilesTypes from '../../enums/SupportedUploadFilesTypes';

export const uploadToS3 = async (params) => {
  const { filePath, fileToUpload, uploadUrl, setProgressState } = params;
  /* const fd = new FormData(); */
  /* fd.append(filePath, fileToUpload); */

  const xhr = new XMLHttpRequest();
  await new Promise((resolve) => {
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const uploadProgressValue = Math.min(
          100 * (event.loaded / event.total),
          100
        );
        if (setProgressState) {
          setProgressState((oldState) => ({
            ...oldState,
            [fileToUpload.name]: uploadProgressValue,
          }));
        }
      }
    });
    xhr.addEventListener('loadend', () => {
      resolve(xhr.readyState === 4 && xhr.status === 200);
    });
    xhr.open('PUT', uploadUrl, true);
    xhr.setRequestHeader('Content-Type', fileToUpload.type);
    xhr.send(fileToUpload);
  });
};

export const uploadWithS3 = async (params) => {
  const { files, uploader, campaignId, setProgressState } = params;
  for (const file of files) {
    const { name: image } = file;
    const createdAttachmentResponse = await uploader({
      variables: {
        attachment: { campaignId, image },
      },
    });
    const createdAttachment =
      createdAttachmentResponse?.data?.createCampaignAttachment;
    const { originalName, uploadUrl, filePath } = createdAttachment;
    const currentFileToUpload = files.find(({ name }) => name === originalName);
    await uploadToS3({
      uploadUrl,
      filePath,
      fileToUpload: currentFileToUpload,
      setProgressState,
    });
  }
};

export const uploadWithoutS3 = async (params) => {
  const { links, uploader, campaignId } = params;
  for (const link of links) {
    const { value } = link;
    if (value) {
      await uploader({
        variables: {
          attachment: { campaignId, image: value },
          skipS3: true,
        },
      });
    }
  }
};

export const getSizeInMb = (size) => (size / 1024 / 1024).toFixed(2);

export const isSupportedSize = (
  size,
  type = SupportedUploadFilesTypes.File
) => {
  return size <= type === SupportedUploadFilesTypes.File
    ? config.maximumUploadSizeBites
    : config.maximumUploadSizeBitesVideo;
};

export const isSupportedType = (supportedTypes, type) => {
  return !!~supportedTypes.indexOf(type);
};

export const isValidFileToUpload = (params) => {
  const { supportedTypes, size, type } = params;
  return isSupportedSize(size, type) && isSupportedType(supportedTypes, type);
};
