import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

const CustomCategories = (props) => {
  const { onFormSubmit } = props;
  const [inputState, setInputState] = useState('');
  const { t } = useTranslation();
  const handleFormSubmit = (data) => {
    onFormSubmit({ uncategorized: data });
    setInputState('');
  };

  return (
    <TextField
      fullWidth
      name="uncategorized"
      value={inputState}
      placeholder={t('createCampaign.customGroupsPlaceholder')}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleFormSubmit(inputState);
        }
      }}
      onChange={(e) => setInputState(e.target.value)}
      style={{ marginLeft: 5 }}
    />
  );
};

export default CustomCategories;
