import gql from 'graphql-tag';

export const GET_CAMPAIGN_PAGE_DATA = gql`
  query campaignViewPageData {
    advertisers {
      id
      name
      margin
      agencyId
    }
    user {
      username
      margin
      additionalContactField
      staticPrice {
        TKP
        CPC
      }
      id
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign(
    $campaignInput: CreateCampaignInput!
    $additionalInfo: CampaignAdditionalInfoInput!
  ) {
    createCampaign(
      campaignInput: $campaignInput
      additionalInfo: $additionalInfo
    ) {
      id
      name
    }
  }
`;
