import React, { useRef } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { turquiseColor } from '../../theme';

const useStyles = makeStyles((theme) => ({
  uploadArea: {
    borderRadius: 20,
    border: `${turquiseColor} 4px dashed`,
  },
  uploadIcon: {
    fontSize: 200,
  },
}));
const UploadArea = (props) => {
  const { isVideo, onFilesSelected } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const fileUploadInput = useRef(null);
  const [_, drop] = useDrop({
    accept: NativeTypes.FILE,
    drop: (dragObj) => {
      const { files } = dragObj;
      onFilesSelected(Array.isArray(files) ? files : [files]);
    },
  });

  const onFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    onFilesSelected(files);
  };

  const handleUploadClick = () => {
    const fileInput = fileUploadInput.current;
    fileInput.value = '';
    fileInput.click();
    onFilesSelected([]);
  };

  return (
    <Grid
      container
      ref={drop}
      className={classes.uploadArea}
      justify="center"
      align="center"
      onClick={handleUploadClick}
    >
      <Box p={3}>
        <CloudUploadIcon color="primary" className={classes.uploadIcon} />
        <Typography variant="body1">{t('uploadPage.clickHere')}</Typography>
        <Box mt={2} />
        <Typography variant="body1">{t('uploadPage.ifYouSkip')}</Typography>
        {isVideo && (
          <>
            <Typography variant="body1">
              {t('uploadPage.videoMustBe')}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              {t('uploadPage.eitherVideoOrLink')}
            </Typography>
          </>
        )}
      </Box>
      <Box visibility="hidden">
        <input
          ref={fileUploadInput}
          onChange={onFileInputChange}
          type="file"
          multiple
        />
      </Box>
    </Grid>
  );
};

export default UploadArea;
