import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  Box,
  Grid,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { invert } from 'lodash';
import AppLabeledCheckbox from '../../../../components/AppLabeledCheckbox';
import { CampaignStatus } from '../../../../enums/campaign';

const useStyles = makeStyles({
  dialogActionsStyle: {
    padding: '8px 24px',
  },
  dialogContentStyle: {
    paddingTop: 0,
  },
});

const AdvDeleteDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    open,
    onClose,
    onConfirm,
    isChecked,
    isDisabled,
    handleChange,
    isLoading,
    advertiserToDelete = {},
  } = props;
  const { campaigns: advCampaigns } = advertiserToDelete;
  const campaigns = advCampaigns || [];
  const activeCampaigns = campaigns.filter(({ status }) => {
    return status === Number(invert(CampaignStatus).Live);
  });
  return (
    <Dialog open={open} onClose={onClose}>
      <Box px={3} width={444}>
        <Box mt={4} />
        <DialogTitle>{t('deleteAdvertiser.title')}</DialogTitle>
        <DialogContent className={classes.dialogContentStyle}>
          <DialogContentText variant="subtitle1" color="secondary">
            {t('deleteAdvertiser.subtitle')}
          </DialogContentText>
          {!!activeCampaigns.length && (
            <>
              <Box mt={3} />
              <AppLabeledCheckbox
                checked={isChecked}
                label={t('deleteAdvertiser.checkbox', {
                  activeCampaigns: activeCampaigns.length,
                })}
                onChange={handleChange}
              />
            </>
          )}
        </DialogContent>
        <Box mt={2} />
        <DialogActions className={classes.dialogActionsStyle}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                disabled={isDisabled && !!activeCampaigns.length}
                onClick={onConfirm}
                variant="contained"
                color="primary"
                fullWidth
              >
                {t('deleteAdvertiser.yes')}
                {isLoading && <CircularProgress color="inherit" size={20} />}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2} />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={onClose} color="secondary" fullWidth>
                {t('deleteAdvertiser.no')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        <Box mt={3} />
      </Box>
    </Dialog>
  );
};

export default AdvDeleteDialog;
