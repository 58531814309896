import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { Grid, Radio, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  checkIsFirstStepCompleted,
  checkIsSecondStepCompleted,
  checkIsThirdStepCompleted,
  checkIsFourthStepCompleted,
} from '../../../../../../services/linkedin/state-helper';

const useStyles = makeStyles((theme) => ({
  root: {
    top: theme.spacing(10),
    position: 'sticky',
    '& .MuiStep-root': {
      cursor: 'default',
    },
    '& .MuiStep-root.MuiStep-vertical.error + .MuiStepConnector-vertical .MuiStepConnector-lineVertical':
      {
        borderColor: theme.palette.error.main,
      },
    '& .MuiStep-root.MuiStep-vertical.error .MuiIconButton-label': {
      color: theme.palette.error.main,
    },
    '& .MuiStep-completed': {
      '& .MuiStepLabel-completed': {
        color: theme.palette.primary.main,
      },
      '& + .MuiStepConnector-vertical': {
        '& .MuiStepConnector-lineVertical': {
          borderColor: theme.palette.primary.main,
        },
      },
    },
    '& .MuiStepConnector-lineVertical': {
      borderLeftStyle: 'dashed',
      marginLeft: 2,
    },
  },
  stepText: {
    whiteSpace: 'nowrap',
  },
  radioButton: {
    cursor: 'default',
    color: theme.palette.basic.grey,
    padding: 0,
    '& svg': {
      height: 30,
      width: 30,
    },
  },
}));

const CampaignStepper = (props) => {
  const { isFormSubmitted, headerText, campaignState } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const isFirstStepCompleted = checkIsFirstStepCompleted(campaignState);
  const isSecondStepCompleted = checkIsSecondStepCompleted(campaignState);
  const isThirdStepCompleted = checkIsThirdStepCompleted(campaignState);
  const isFourthStepCompleted = checkIsFourthStepCompleted(campaignState);

  return (
    <Grid
      container
      alignItems="flex-start"
      justify="center"
      direction="column"
      className={classes.root}
    >
      <Grid item>
        {headerText && (
          <Typography variant="h5" align="center">
            {headerText}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Stepper activeStep={-1} orientation="vertical" nonLinear>
          <Step
            className={classNames({
              error: isFormSubmitted && !isFirstStepCompleted,
            })}
            completed={isFirstStepCompleted}
          >
            <StepLabel
              error={isFormSubmitted && !isFirstStepCompleted}
              icon={
                <Radio
                  className={classes.radioButton}
                  color="primary"
                  disableRipple
                  checked={isFirstStepCompleted}
                />
              }
            >
              <b className={classes.stepText}>{t('createCampaign.stepOne')}</b>
            </StepLabel>
            <StepContent />
          </Step>
          <Step
            className={classNames({
              error: isFormSubmitted && !isSecondStepCompleted,
            })}
            completed={isSecondStepCompleted}
          >
            <StepLabel
              error={isFormSubmitted && !isSecondStepCompleted}
              icon={
                <Radio
                  className={classes.radioButton}
                  color="primary"
                  disableRipple
                  checked={isSecondStepCompleted}
                />
              }
            >
              <b className={classes.stepText}>{t('createCampaign.stepTwo')}</b>
            </StepLabel>
            <StepContent />
          </Step>
          <Step
            className={classNames({
              error: isFormSubmitted && !isThirdStepCompleted,
            })}
            completed={isThirdStepCompleted}
          >
            <StepLabel
              error={isFormSubmitted && !isThirdStepCompleted}
              icon={
                <Radio
                  className={classes.radioButton}
                  color="primary"
                  disableRipple
                  checked={isThirdStepCompleted}
                />
              }
            >
              <b className={classes.stepText}>
                {t('createCampaign.stepThree')}
              </b>
            </StepLabel>
            <StepContent />
          </Step>
          <Step
            className={classNames({
              error: isFormSubmitted && !isFourthStepCompleted,
            })}
            completed={isFourthStepCompleted}
          >
            <StepLabel
              error={isFormSubmitted && !isFourthStepCompleted}
              icon={
                <Radio
                  className={classes.radioButton}
                  color="primary"
                  disableRipple
                  checked={isFourthStepCompleted}
                />
              }
            >
              <b className={classes.stepText}>{t('createCampaign.stepFour')}</b>
            </StepLabel>
            <StepContent />
          </Step>
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default memo(CampaignStepper);
