import React, { memo } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    backgroundColor: 'rgb(255 255 255 / 40%)',
  },
}));

const SpinnerBackdrop = ({ open }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} invisible open={open}>
      <CircularProgress size={120} />
    </Backdrop>
  );
};

export default memo(SpinnerBackdrop);
