import React, { memo } from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { Check, ChevronLeft, ExpandLess } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { FixedSizeList } from 'react-window';
import GeoCategoryListItem from '../GeoCategoryListItem';

export const GeoCategoriesList = (props) => {
  const {
    renderTree,
    toggleSubCategoryOpen,
    toggleSubCategoryCheck,
    placesCheckedStates,
    togglePlaceCheck,
    disabled,
  } = props;

  return (
    <List disablePadding>
      {Object.keys(renderTree).map((subCategory) => {
        const { name, places, isOpen, checked } = renderTree[subCategory];
        return (
          <React.Fragment key={name}>
            <ListItem
              divider
              button
              component="li"
              onClick={() => {
                toggleSubCategoryOpen(name);
              }}
            >
              <ListItemText primary={name} />
              <ListItemSecondaryAction>
                {!disabled && (
                  <IconButton
                    onClick={() => {
                      toggleSubCategoryCheck(subCategory, places);
                    }}
                  >
                    <Check
                      style={{ color: checked ? green[500] : 'inherit' }}
                    />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => {
                    toggleSubCategoryOpen(name);
                  }}
                >
                  {isOpen ? <ExpandLess /> : <ChevronLeft />}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            {/* <div>
                {(isOpen && places?.length) && <FixedSizeList height={360} itemSize={170} itemCount={50} useIsScrolling>
                  {({ index, style, key }) => {
                    const item = places[index];
                    return (
                      <div key={key} style={style}>
                        <GeoCategoryListItem
                          key={item.id}
                          disabled={disabled}
                          checked={placesCheckedStates[item.id]}
                          item={item}
                          togglePlaceCheck={togglePlaceCheck}
                        />
                      </div>);
                  }}
                </FixedSizeList>}
              </div> */}

            <List dense disablePadding>
              {isOpen &&
                places?.map((item) => {
                  const { id } = item;
                  return (
                    <GeoCategoryListItem
                      disabled={disabled}
                      checked={placesCheckedStates[id]}
                      key={id}
                      item={item}
                      togglePlaceCheck={togglePlaceCheck}
                    />
                  );
                })}
            </List>
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default memo(GeoCategoriesList);
