import React from 'react';

export const AppSwitch = (props) => {
  const { test, children } = props;
  const matchedCase = children.find((child) => {
    return child.props.value === test;
  });
  const defaultCase = children.find((child) => {
    return child.props.value === 'default';
  });
  return matchedCase || defaultCase || null;
};

export const AppCase = ({ children, value }) => {
  return children;
};

// ex
// <AppSwitch test={true}>
//   <AppCase value={true}>
//     <h1>value is true</h1>
//   </AppCase>
//   <AppCase value={false}>
//     <h1>value is false</h1>
//   </AppCase>
// </AppSwitch>
