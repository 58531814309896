import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  Box,
  Grid,
} from '@material-ui/core';
import { CampaignTypes } from '../../enums/campaign';

const useStyles = makeStyles({
  dialogActionsStyle: {
    padding: '12px 24px 24px',
  },
  cursorStyle: {
    cursor: 'pointer',
  },
  overflowStyle: {
    overflowY: 'inherit',
  },
});

const CampaignTypeModal = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleConfirm = (type) => onConfirm(type);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Box maxWidth={800} py={1}>
        <DialogContent className={classes.overflowStyle}>
          <Grid container spacing={4} display="flex">
            <Grid
              item
              xs={3}
              onClick={() => handleConfirm(CampaignTypes.Banner)}
            >
              <Box mb={3}>
                <img
                  src="/campaign-type-banner.svg"
                  alt="banner"
                  width="100%"
                  className={classes.cursorStyle}
                />
              </Box>
              <Typography variant="h5" gutterBottom>
                {t('campaignModal.bannerTitle')}
              </Typography>
              <Typography variant="body1" color="secondary" gutterBottom>
                {t('campaignModal.bannerText')}
              </Typography>
              <Button
                variant="body1"
                className={classes.cursorStyle}
                onClick={() => handleConfirm(CampaignTypes.Banner)}
              >
                {t('campaignModal.continue')}
              </Button>
            </Grid>
            <Grid
              item
              xs={3}
              onClick={() => handleConfirm(CampaignTypes.Social)}
            >
              <Box mb={3}>
                <img
                  src="/campaign-type-social.svg"
                  alt="social"
                  width="100%"
                  className={classes.cursorStyle}
                />
              </Box>
              <Typography variant="h5" gutterBottom>
                {t('campaignModal.socialTitle')}
              </Typography>
              <Typography variant="body1" color="secondary" gutterBottom>
                {t('campaignModal.socialText')}
              </Typography>
              <Button
                variant="body1"
                className={classes.cursorStyle}
                onClick={() => handleConfirm(CampaignTypes.Social)}
              >
                {t('campaignModal.continue')}
              </Button>
            </Grid>
            <Grid
              item
              xs={3}
              onClick={() => handleConfirm(CampaignTypes.Video)}
            >
              <Box mb={3}>
                <img
                  src="/campaign-type-youtube.svg"
                  alt="video"
                  width="100%"
                  className={classes.cursorStyle}
                />
              </Box>
              <Typography variant="h5" gutterBottom>
                {t('campaignModal.videoTitle')}
              </Typography>
              <Typography variant="body1" color="secondary" gutterBottom>
                {t('campaignModal.videoText')}
              </Typography>
              <Button
                variant="body1"
                className={classes.cursorStyle}
                onClick={() => handleConfirm(CampaignTypes.Video)}
              >
                {t('campaignModal.continue')}
              </Button>
            </Grid>
            <Grid
              item
              xs={3}
              onClick={() => handleConfirm(CampaignTypes.Linkedin)}
            >
              <Box mb={3}>
                <img
                  src="/campaign-type-linkedin.svg"
                  alt="linkedin"
                  width="100%"
                  className={classes.cursorStyle}
                />
              </Box>
              <Typography variant="h5" gutterBottom>
                {t('campaignModal.linkedinTitle')}
              </Typography>
              <Typography variant="body1" color="secondary" gutterBottom>
                {t('campaignModal.linkedinText')}
              </Typography>
              <Button
                variant="body1"
                className={classes.cursorStyle}
                onClick={() => handleConfirm(CampaignTypes.Linkedin)}
              >
                {t('campaignModal.continue')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActionsStyle}>
          <Button color="secondary" onClick={onClose}>
            {t('common.cancel')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CampaignTypeModal;
