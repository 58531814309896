import React from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { ChevronRight, Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    height: '441px',
    overflow: 'auto',
    padding: 0,
  },
}));

const AudienceTargetingListItems = (props) => {
  const { categories, onSelectCategory, handleCategoriesShown, categoryShown } =
    props;
  const classes = useStyles();

  return (
    <List className={classes.listContainer}>
      {categories.map(({ name, children, isSelected, id }, categoryIndex) => (
        <ListItem
          divider
          key={id}
          onClick={() => handleCategoriesShown(categoryIndex)}
          style={{
            backgroundColor:
              categoryIndex === categoryShown ? '#F9F9FA' : 'inherit',
          }}
        >
          <ListItemText primary={name} />
          <ListItemSecondaryAction>
            <IconButton onClick={() => onSelectCategory({ categoryIndex })}>
              <Check style={{ color: isSelected ? 'green' : 'inherit' }} />
            </IconButton>
            <IconButton onClick={() => handleCategoriesShown(categoryIndex)}>
              <ChevronRight />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default AudienceTargetingListItems;
