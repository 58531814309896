import { ApolloClient, InMemoryCache } from '@apollo/client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';
// eslint-disable-next-line import/no-cycle
import { getAccessToken, logout } from '../JWT-Auth';

let apolloClient;

export const extractApolloError = (apolloError) => {
  if (!apolloError) {
    return [];
  }
  return apolloError.graphQLErrors;
};

const buildHttpLink = () => {
  return new HttpLink({
    uri: process.env.REACT_APP_API_URL || 'http://127.0.0.1:1337/api/graphql',
    headers: {
      'client-name': 'Yippin',
      'client-version': '1.0.0',
    },
  });
};

const buildErrorLink = () => {
  return onError((error) => {
    if (!error?.graphQLErrors) logout();
    const [graphqlError] = error.graphQLErrors;
    if (graphqlError.extensions.code === 'UNAUTHENTICATED') {
      logout();
    }
  });
};

const setAuthContext = setContext(() => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return undefined;
  }
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
});

export const buildApolloClient = () => {
  const httpLink = buildHttpLink();
  const errorLink = buildErrorLink();

  const link = ApolloLink.from([errorLink, setAuthContext, httpLink]);

  const cache = new InMemoryCache({ addTypename: false });
  apolloClient = new ApolloClient({
    cache,
    link,
  });
};

export const getApolloClient = () => {
  return apolloClient;
};
