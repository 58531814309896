import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: { cursor: 'pointer' },
  svg: { display: 'block' },
});

const IntensityElement = ({ intensity, onClick }) => {
  const classes = useStyles();
  let rotateDeg = 0;
  if (intensity === 1) rotateDeg = -100;
  else if (intensity === 3) rotateDeg = 100;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={classes.root} onClick={onClick}>
      <svg
        fill="none"
        viewBox="0 -10 208 119"
        className={classes.svg}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            id="a"
            x1="208"
            y1="104"
            x2="0"
            y2="104"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#14A3AE" />
            <stop offset=".479" stopColor="#14A3AE" stopOpacity=".7" />
            <stop offset="1" stopColor="#14A3AE" stopOpacity=".32" />
          </linearGradient>
          <filter
            id="b"
            x="0"
            y="35.968"
            width="68.774"
            height="100.032"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="6" />
            <feGaussianBlur stdDeviation="8" />
            <feColorMatrix values="0 0 0 0 0.0784314 0 0 0 0 0.639216 0 0 0 0 0.682353 0 0 0 0.3 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
        <path
          d="M0 104a104 104 0 01208 0h-33.28a70.72 70.72 0 10-141.44 0H0z"
          fill="url(#a)"
        />
        <g
          style={{
            transition: 'all 0.25s ease',
            transform: `translateY(-5px) rotate(${rotateDeg}deg)`,
            transformOrigin: '50% 87px',
          }}
        >
          <rect
            x="102.324"
            width="3.356"
            height="43.614"
            rx="1.839"
            fill="#E0607E"
          />
          <g
            filter="url(#b)"
            fill="#fff"
            transform="translate(72.63) scale(.91228)"
          >
            <path d="M52.774 95.613c0 10.155-8.232 18.387-18.387 18.387C24.232 114 16 105.768 16 95.613c0-10.155 8.232-18.387 18.387-18.387 10.155 0 18.387 8.232 18.387 18.387z" />
            <path d="M29.628 49.483a4.98 4.98 0 019.518 0l13.628 44.291H16l13.628-44.291z" />
          </g>
        </g>
      </svg>
    </div>
  );
};
export default IntensityElement;
