import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';

import { ExpandMore } from '@material-ui/icons';
import { invert } from 'lodash';
import { toast } from 'react-toastify';
import GET_CAMPAIGN_PAGE_DATA from './queries';
import Breadcrumb from '../../../../components/Breadcrumb';
import SpinnerBackdrop from '../../../../components/BackdropSpinner';
import CampaignInfoBoxContainer from '../../../../components/CampaignInfoBoxContainer';
import LinkedinCalculationInfoBox from './components/LinkedinCalculationInfoBox';
import CampaignStep1 from './components/CampaignStep1';
import CampaignStep2 from './components/CampaignStep2';
import CampaignStep3 from './components/CampaignStep3';
import CampaignStep4 from './components/CampaignStep4';
import CampaignActions from './components/CampaignActions';
import AdminCampaignActions from '../../../CreateViewEditCampaign/components/AdminCampaignActions';
import { CampaignStatus, CreativeStatus } from '../../../../enums/campaign';
import { UPDATE_CAMPAIGN_STATUSES } from '../../../CreateViewEditCampaign/queries';

const ViewLinkedinCampaign = () => {
  const { t } = useTranslation();
  const { campaignId } = useParams();
  const [statusesToUpdate, setStatusesToUpdate] = useState(null);

  const history = useNavigate();

  // todo on view campaign price data should be from BE side only (not calculated dynamically as on create page!!!);
  const { data, loading } = useQuery(GET_CAMPAIGN_PAGE_DATA, {
    variables: { id: campaignId },
    fetchPolicy: 'network-only',
  });

  const [updateCampaignStatuses] = useMutation(UPDATE_CAMPAIGN_STATUSES, {
    refetchQueries: ['campaignViewPageData'],
  });

  if (!data) return <SpinnerBackdrop open={loading} />; // todo check
  const { campaign, advertisers } = data;
  const {
    advertiserId,
    name,
    linkedinGoal,
    startDate,
    endDate,
    linkedinTargetGroups,
    linkedinTargetCompanyGroups,
    linkedinAges,
    gender,
    landingPage,
    idkLandingPageYet,
    linkedinProfile,
    idkLinkedinProfileYet,
    geoTargetingType,
    geoZipCodes,
    geoRegionIds,
    intensity,
    comment,
    budget,
    budgetRecommendation,
    estimatedReach,
    reportLink,
    status,
    pricing,
    creativeStatus: campaignCreativeStatus,
  } = campaign;
  const [advertiser] = advertisers.filter(({ id }) => id === advertiserId);
  const campaignStatus = CampaignStatus[status];
  const creativeStatus = CreativeStatus[campaignCreativeStatus];

  const handlePDFGeneration = () => {
    localStorage.setItem(
      'campaign-for-print',
      JSON.stringify({ campaign, advertisers })
    );
    window.open('/campaign/linkedin-view-pdf-print', '_blank');
  };

  const handlePDFGenerationWithoutAdvertiserMargins = () => {
    localStorage.setItem(
      'campaign-for-print',
      JSON.stringify({ campaign, advertisers, isAgencyPrice: true })
    );
    window.open('/campaign/linkedin-view-pdf-print', '_blank');
  };

  const handleExcelGeneration = () => {
    const params = { campaign };
    localStorage.setItem('campaign-for-excel', JSON.stringify(params));
    window.open('/campaign/linkedin-excel-generator', '_blank');
  };

  const onAdminSubmit = async () => {
    try {
      await updateCampaignStatuses({
        variables: {
          id: campaignId,
          campaignInput: statusesToUpdate,
        },
      });
      toast.success(t('toasts.campaignUpdated'));
    } catch ({ message }) {
      toast.error(message);
    }
    history(-1);
  };

  const onCampaignStatusesChange = (newStatuses) => {
    const {
      status: newStatus,
      reportLink: newReportingLink,
      creativeStatus: newCreativeStatus,
    } = newStatuses;
    const statusesToSend = {
      status: Number(invert(CampaignStatus)[newStatus]),
      creativeStatus: Number(invert(CreativeStatus)[newCreativeStatus]),
      reportLink: newReportingLink,
    };
    setStatusesToUpdate(statusesToSend);
  };

  return (
    <Box mt={2} ml={2}>
      <Breadcrumb>
        <Link to="/campaigns" component={RouterLink}>
          {t('campaigns.campaigns')}
        </Link>
        <Typography color="textPrimary">{name}</Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={loading} />
      <Box mt={2} />
      <Grid container wrap="nowrap" direction="row">
        <Grid
          style={{ maxWidth: '280px' }}
          item
          alignItems="flex-start"
          container
        >
          <CampaignInfoBoxContainer>
            {/* campaign calculations */}
            <Grid item>
              <LinkedinCalculationInfoBox
                estimatedReach={estimatedReach}
                budgetRecommendation={budgetRecommendation}
                pricing={pricing}
                budget={budget}
                creativeStatus={creativeStatus}
                campaignStatus={campaignStatus}
              />
            </Grid>
          </CampaignInfoBoxContainer>
        </Grid>
        <Grid style={{ maxWidth: '1100px' }} item xs>
          <form>
            <CampaignStep1
              advertiser={advertiser || ''}
              name={name}
              linkedinGoal={linkedinGoal}
              startDate={startDate}
              endDate={endDate}
            />
            <CampaignStep2
              gender={gender || []}
              linkedinAges={linkedinAges || []}
              landingPage={landingPage}
              idkLandingPageYet={idkLandingPageYet || false}
              linkedinProfile={linkedinProfile}
              idkLinkedinProfileYet={idkLinkedinProfileYet || false}
              linkedinTargetGroups={linkedinTargetGroups}
              linkedinTargetCompanyGroups={linkedinTargetCompanyGroups}
            />
            <CampaignStep3
              geoTargetingType={geoTargetingType}
              geoZipCodes={geoZipCodes || []}
              geoRegionIds={geoRegionIds || []}
            />
            <CampaignStep4
              intensity={intensity}
              comment={comment}
              linkedinGoal={linkedinGoal || 0}
              budget={budget}
            />
            <Accordion defaultExpanded>
              <AccordionSummary id="admin-actions" expandIcon={<ExpandMore />}>
                <Typography variant="h6">Admin</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AdminCampaignActions
                  report={reportLink}
                  campaignStatus={campaignStatus}
                  creativeStatus={creativeStatus}
                  onChange={onCampaignStatusesChange}
                />
              </AccordionDetails>
            </Accordion>
            <CampaignActions
              handlePDFGeneration={handlePDFGeneration}
              onAdminSubmit={onAdminSubmit}
              handleExcelGeneration={handleExcelGeneration}
              onPDFGenerationWithoutAdvertiserMargins={
                handlePDFGenerationWithoutAdvertiserMargins
              }
            />
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewLinkedinCampaign;
