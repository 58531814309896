import React, { memo } from 'react';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const PopulationCounter = memo((props) => {
  const { t } = useTranslation();
  const { population } = props;
  return (
    <TextField
      value={t('geoTargeting.population', {
        population: population.toLocaleString('de-DE'),
      })}
      disabled
      fullWidth
      variant="standard"
    />
  );
});

export default PopulationCounter;
