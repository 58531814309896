import React, { memo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Grid,
  Select,
  MenuItem,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Map from '../Map';
import { GET_REGION, GET_REGIONS } from './queries';
import Polygon from '../Polygon';
import PopulationCounter from '../PopulationCounter';
import {
  getCenterByPoints,
  mergePoints,
} from '../../../../services/geo-location-helper';
import config from '../../../../configuration';

const RegionSelector = (props) => {
  const {
    geoTargetingChangeHandler,
    regionsPreset,
    isFormSubmitted,
    onMapLoaded,
    disabled,
  } = props;
  const { t } = useTranslation();
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [population, setPopulation] = useState(0);
  const [center, setCenter] = useState(config.defaultMapCenter);
  const [points, setPoints] = useState({});
  const [isPointsLoading, setIsPointsLoading] = useState(true);
  const { data: regionsData, loading } = useQuery(GET_REGIONS);
  const { refetch: fetchRegion } = useQuery(GET_REGION, { skip: true });
  const regions = regionsData?.regions;

  const handleRegionChange = (e) => {
    setSelectedRegions([...e.target.value]);
  };
  const loadPoints = async () => {
    let newPoints = {};
    for (const region of selectedRegions) {
      if (!points[region.id]) {
        setIsPointsLoading(true);
        const { data: regionData } = await fetchRegion({
          regionId: region.id,
        });
        const { region: regionWithPoints } = regionData;
        newPoints = {
          ...newPoints,
          [region.id]: regionWithPoints.points,
        };
      }
    }
    if (Object.keys(newPoints).length) {
      setPoints({ ...points, ...newPoints });
    }
    setIsPointsLoading(false);
  };

  const calculateCenter = () => {
    const allPoints = mergePoints(points);
    const newCenter = getCenterByPoints({
      defaultCenter: config.defaultMapCenter,
      points: allPoints,
    });
    setCenter(newCenter);
  };

  useEffect(() => {
    if (regionsPreset && regions) {
      const newSelectedRegions = regionsPreset.map((id) =>
        regions.find((region) => region.id === id)
      );
      setSelectedRegions(newSelectedRegions.filter((r) => !!r));
    }
  }, [regionsPreset, regions]);

  useEffect(() => {
    calculateCenter();
    if (Object.keys(points).length) {
      setTimeout(onMapLoaded, 1000);
    }
  }, [points, selectedRegions]);

  useEffect(() => {
    const newPopulation =
      selectedRegions?.reduce((acc, region) => acc + region.population, 0) || 0;
    if (geoTargetingChangeHandler) {
      geoTargetingChangeHandler({
        reach: newPopulation,
        geoRegionIds: selectedRegions?.map((region) => region.id) || null,
      });
    }
    setPopulation(newPopulation);
    loadPoints();
  }, [selectedRegions]);

  if (loading) {
    return (
      <Grid container justify="center" alignItems="center">
        <CircularProgress color="primary" size={20} />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs>
            <Select
              multiple
              displayEmpty
              renderValue={(value) => {
                return value?.length === 0
                  ? t('geoTargeting.regionPlaceholder')
                  : value.map(({ name }) => name).join(', ');
              }}
              error={isFormSubmitted && !selectedRegions?.length}
              disabled={disabled}
              value={selectedRegions}
              fullWidth
              variant="outlined"
              onChange={handleRegionChange}
            >
              {isPointsLoading && (
                <CircularProgress color="primary" size={20} />
              )}
              {!isPointsLoading &&
                regions?.map((region) => (
                  <MenuItem
                    disabled={
                      (selectedRegions.find((reg) => reg.id === '1') &&
                        !region.is_country) ||
                      (selectedRegions.find((reg) => !reg.is_country) &&
                        region.id === '1')
                    }
                    key={region.id}
                    value={region}
                  >
                    {region.name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
        <Box mt={3} />
        <Grid container>
          <PopulationCounter population={population} />
        </Grid>
      </Grid>
      <Grid xs={6} item>
        <Map
          center={center}
          zoom={6}
          options={{
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            zoomControl: true,
          }}
        >
          {selectedRegions?.map((region) => (
            <Polygon key={region.id} path={points[region.id]} />
          ))}
        </Map>
      </Grid>
    </Grid>
  );
};

export default memo(RegionSelector);
