import React, { memo } from 'react';
import { styled } from '@material-ui/core/styles';
import { Paper, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import { Info } from '@material-ui/icons';

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: '112px',
  width: '122px',
  '&.active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.basic.white,
  },
  '&.error': {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    border: '1px solid',
    boxShadow: 'none',
    boxSizing: 'border-box',
  },
}));

const StyledLabel = styled('label')(({ theme }) => ({
  height: '72px',
  minWidth: '120px',
  cursor: 'pointer',
}));

const StyledLabelContainer = styled(Box)(({ theme }) => ({
  margin: 'auto',
  textAlign: 'center',
  marginTop: '8px',
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.basic.grey,
  '&.active': {
    color: theme.palette.basic.white,
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.active': {
    color: theme.palette.basic.white,
  },
}));

const StyledIconContainer = styled(Box)(({ theme }) => ({
  marginTop: '-15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));

const StyledCheckboxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  color: theme.palette.basic.grey,
  position: 'absolute',
  top: '-23px',
  left: 5,
  '&.active': {
    color: theme.palette.basic.white,
  },
}));

const AppCheckboxButton = (props) => {
  const { name, icon, labelText, tooltip, error, checked, checkboxHandler } =
    props;

  return (
    <StyledPaper
      elevation={2}
      className={`${checked ? 'active' : ''} ${error ? 'error' : ''}`}
    >
      <StyledLabel>
        <StyledCheckboxContainer>
          <StyledCheckbox
            className={checked ? 'active' : ''}
            color="primary"
            name={name}
            checked={checked}
            onChange={checkboxHandler}
          />
        </StyledCheckboxContainer>
        <StyledIconContainer>
          <StyledIcon className={checked ? 'active' : ''} fontSize="large">
            {icon}
          </StyledIcon>
          {tooltip && (
            <StyledTooltip className={checked ? 'active' : ''} title={tooltip}>
              <Info fontSize="inherit" />
            </StyledTooltip>
          )}
        </StyledIconContainer>
        <StyledLabelContainer>
          <Typography>{labelText}</Typography>
        </StyledLabelContainer>
      </StyledLabel>
    </StyledPaper>
  );
};

export default memo(AppCheckboxButton);
