import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Paper,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useTranslation } from 'react-i18next';
import LOGIN from './queries';
import { extractApolloError } from '../../services/apollo-helper';
import { isAdmin, isLoggedIn, storeAccessToken } from '../../services/JWT-Auth';
import { turquiseColor } from '../../theme';
import { InvisibleIcon, SeeIcon } from '../../components/CustomIcons';

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  imageStyle: {
    height: 75,
    marginLeft: '-9px',
  },
  linkStyle: {
    color: turquiseColor,
    textDecoration: 'none',
  },
});

const Login = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState(null);
  const { errors, register, handleSubmit } = useForm();
  const [login] = useMutation(LOGIN);
  const history = useNavigate();
  const adminEmail =
    process.env.adminEmail || 'campaignformula@twins-digital.com';

  const onSubmit = async (form) => {
    const { email, password } = form;
    try {
      setError(null);
      setIsLoading(true);
      const { data } = await login({
        variables: { email: email.trim(), password: password.trim() },
      });
      storeAccessToken(data.login.token);
      setIsLoading(false);
      setLoggedIn(true);
    } catch (submitError) {
      const apolloError = extractApolloError(submitError);
      if (apolloError && apolloError.length) {
        setError(apolloError[0].message);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (loggedIn || isLoggedIn())
      history(isAdmin() ? '/users' : '/advertisers');
  }, [loggedIn, history]);

  const handleClickShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Box component={Paper} p={6} m="auto">
        <Grid container>
          {/* <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" className={classes.imageStyle} /> */}
        </Grid>
        <Box mt={10} />
        <Grid container>
          <Typography variant="h4" gutterBottom>
            {t('login.signIn')}
          </Typography>
        </Grid>
        <Box mt={1} />
        <Grid container>
          <Typography variant="subtitle1" gutterBottom>
            {t('login.details')}
          </Typography>
        </Grid>
        <Box mt={6} />
        <form id="login" onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs>
            <TextField
              variant="outlined"
              fullWidth
              placeholder={t('login.email')}
              error={!!errors?.email}
              helperText={errors?.email?.message}
              {...register('email', {
                required: t('validationErrors.formRequired'),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: t('validationErrors.invalidEmailFormat'),
                },
              })}
              autoFocus
            />
          </Grid>
          <Box mt={3} />
          <Grid item xs container justifyContent="flex-end">
            <Typography variant="body2">
              <a
                className={classes.linkStyle}
                href={`mailto:${adminEmail}?subject=${t(
                  'login.emails.forgotPassword.subject'
                )}&body=${t('login.emails.forgotPassword.body')}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('login.forgotPassword')}
              </a>
            </Typography>
          </Grid>
          <Box mt={1} />
          <Grid item xs>
            <TextField
              variant="outlined"
              fullWidth
              placeholder={t('login.password')}
              error={!!error || !!errors?.password}
              helperText={error || errors?.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {isPasswordShown ? <SeeIcon /> : <InvisibleIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register('password', {
                required: t('validationErrors.formRequired'),
              })}
              type={isPasswordShown ? 'text' : 'password'}
              autoComplete="current-password"
            />
          </Grid>
        </form>
        <Box mt={4} />
        <Grid item xs>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={isRememberMeChecked}
                onChange={({ target }) =>
                  setIsRememberMeChecked(target.checked)
                }
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
              />
            }
            label={t('login.rememberPassword')}
          />
        </Grid>
        <Box mt={3} />
        <Grid item>
          <Button
            type="submit"
            form="login"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {t('login.login')}
            {isLoading && <CircularProgress color="primary" size={20} />}
          </Button>
        </Grid>
        <Box mt={3} />
        <Grid item>
          <Typography variant="body1" component="span" gutterBottom>
            {t('login.dontHaveAnAccount')}
          </Typography>
          <Typography component="span" gutterBottom>
            <a
              className={classes.linkStyle}
              href={`mailto:${adminEmail}?subject=${t(
                'login.emails.signup.subject'
              )}&body=${t('login.emails.signup.subject')}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('login.signUp')}
            </a>
          </Typography>
        </Grid>
      </Box>
      <Typography variant="body1" color="secondary">
        Copyright © Campaign formula 2021
      </Typography>
      <Box mt={4} />
    </Grid>
  );
};

export default Login;
