import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Grid,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Box,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputAdornment,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import {
  AwarenessIcon,
  CalendarIcon,
} from '../../../../../../components/CustomIcons';
import AppRadioButton from '../../../../../../components/AppRadioButton';
import { LinkedinCampaignGoal } from '../../../../../../enums/campaign';

const CampaignStep1 = (props) => {
  const {
    advertisers = [],
    handleCampaignState,
    isFormSubmitted,
    advertiser,
    name,
    linkedinGoal,
    startDate,
    endDate,
    refsMap,
  } = props;
  const { t } = useTranslation();

  const selectAdvertiserRef = useRef();
  const campaignNameRef = useRef();
  const datePickerRef = useRef();

  useEffect(() => {
    refsMap.current['advertiser-select'] = selectAdvertiserRef;
    refsMap.current['campaign-name'] = campaignNameRef;
    refsMap.current['date-picker'] = datePickerRef;
    // eslint-disable-next-line dot-notation
  }, [refsMap]);

  const currentDate = moment();
  const minAllowedDate = moment(currentDate).add(3, 'days');

  const handleNameChange = useCallback(
    (event) => {
      handleCampaignState({ name: event.target.value });
    },
    [handleCampaignState]
  );

  const handleGoalChange = useCallback(
    (event) => {
      const linkedinGoalInt = parseInt(event.target.value, 10);
      handleCampaignState({ linkedinGoal: linkedinGoalInt });
    },
    [handleCampaignState]
  );

  const handleAdvertiserChange = useCallback(
    (event) => {
      handleCampaignState({ advertiser: event.target.value });
    },
    [handleCampaignState]
  );

  const handleStartDateChange = useCallback(
    (newStartDate) => {
      handleCampaignState({ startDate: newStartDate.toDate() });
    },
    [handleCampaignState]
  );

  const handleEndDateChange = useCallback(
    (newEndDate) => {
      handleCampaignState({ endDate: newEndDate.toDate() });
    },
    [handleCampaignState]
  );

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{t('createCampaign.stepOne')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {/* advertiser */}
          <Grid item container spacing={2}>
            <Grid ref={selectAdvertiserRef} item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.advertiser')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  error={isFormSubmitted && !advertiser}
                  placeholder={t('createCampaign.selectAdvertiser')}
                  labelId="advertiser-select-label"
                  id="advertiser-select"
                  displayEmpty
                  value={advertiser || ''}
                  onChange={handleAdvertiserChange}
                >
                  <MenuItem value="" disabled>
                    {t('createCampaign.selectAdvertiserPlaceHolder')}
                  </MenuItem>
                  {advertisers.map((item, i) => (
                    <MenuItem value={item} key={item?.id || i}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Box mt={3} width="100%" />
          </Grid>

          {/* campaign name */}
          <Grid item container spacing={2}>
            <Grid ref={campaignNameRef} item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.campaignName')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={isFormSubmitted && !name}
                onChange={handleNameChange}
                placeholder={t('createCampaign.campaignNamePlaceHolder')}
                variant="outlined"
                value={name}
                fullWidth
              />
            </Grid>
            <Box mt={3} width="100%" />
          </Grid>

          {/* campaign goal */}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.campaignGoal')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppRadioButton
                error={isFormSubmitted && !linkedinGoal && linkedinGoal !== 0}
                required
                icon={AwarenessIcon}
                labelText={t('createCampaign.websiteVisitors')}
                checked={linkedinGoal === LinkedinCampaignGoal.Visitors}
                value={LinkedinCampaignGoal.Visitors}
                onChange={handleGoalChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppRadioButton
                error={isFormSubmitted && !linkedinGoal && linkedinGoal !== 0}
                icon="mouse_icon"
                labelText={t('createCampaign.interactionWithLinkedInContent')}
                checked={linkedinGoal === LinkedinCampaignGoal.Interaction}
                value={LinkedinCampaignGoal.Interaction}
                onChange={handleGoalChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppRadioButton
                error={isFormSubmitted && !linkedinGoal && linkedinGoal !== 0}
                icon="contact_mail_icon"
                labelText={t('createCampaign.applicants')}
                checked={linkedinGoal === LinkedinCampaignGoal.Applicants}
                value={LinkedinCampaignGoal.Applicants}
                onChange={handleGoalChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <AppRadioButton
                error={isFormSubmitted && !linkedinGoal && linkedinGoal !== 0}
                icon="people_alt_icon"
                labelText={t('createCampaign.leadsInLinkedIn')}
                checked={linkedinGoal === LinkedinCampaignGoal.Leads}
                value={LinkedinCampaignGoal.Leads}
                onChange={handleGoalChange}
              />
            </Grid>
            <Box mt={3} width="100%" />
          </Grid>

          {/* campaign duration */}
          <Grid item container spacing={2}>
            <Grid ref={datePickerRef} item xs={12} md={6} lg={4}>
              <Typography variant="body1">
                {t('createCampaign.campaignStart')}
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  error={isFormSubmitted && !startDate}
                  format="DD.MM.YYYY"
                  minDate={minAllowedDate}
                  maxDate={endDate || undefined}
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  margin="normal"
                  placeholder={t('createCampaign.campaignStart')}
                  value={startDate}
                  onChange={handleStartDateChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: 'pointer' }}
                      >
                        <CalendarIcon color="secondary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography variant="body1">
                {t('createCampaign.campaignEnd')}
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  error={isFormSubmitted && !endDate}
                  format="DD.MM.YYYY"
                  minDate={startDate || minAllowedDate}
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  margin="normal"
                  placeholder={t('createCampaign.campaignEnd')}
                  value={endDate}
                  onChange={handleEndDateChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: 'pointer' }}
                      >
                        <CalendarIcon color="secondary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Box mt={1} width="100%" />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(CampaignStep1);
