import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';

import GET_GEO_INFO_BY_IDS from './queries';
import downloadExcel from '../../services/excel-helper';

const ExcelGenerator = () => {
  const { campaign, agency, savedCampaign, staticPrice } = JSON.parse(
    localStorage.getItem('campaign-for-excel')
  );
  const { geoTargeting } = campaign;
  const skipQuery =
    !geoTargeting.geoRegionIds.length && !geoTargeting.geoZipCodes.length;
  const { data: geoData, loading } = useQuery(GET_GEO_INFO_BY_IDS, {
    variables: {
      regionIds: geoTargeting.geoRegionIds,
      zipIds: geoTargeting.geoZipCodes,
    },
    skip: skipQuery,
  });

  useEffect(() => {
    if (skipQuery || (!loading && geoData)) {
      downloadExcel({ campaign, agency, geoData }, savedCampaign, staticPrice);
    }
  }, [skipQuery, loading, geoData]);

  if (!skipQuery && loading) {
    return <CircularProgress color="primary" size={20} />;
  }

  return <></>;
};

export default ExcelGenerator;
