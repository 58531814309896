import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  TextField,
  Button,
  Input,
  Radio,
  Grid,
  Avatar,
  Box,
  makeStyles,
  Typography,
  InputAdornment,
  Checkbox,
} from '@material-ui/core';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import UserRoles from '../../../../enums/UserRoles';
import {
  formatFloatToView,
  formatFloatToSubmit,
} from '../../../../services/format-helper';
import { CalendarIcon } from '../../../../components/CustomIcons';

const useStyles = makeStyles({
  dialogActionsStyle: {
    padding: '8px 24px',
  },
});

const UserModal = (props) => {
  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  const { open, onClose, user, onUserCreate, onUserUpdate } = props;
  const { t } = useTranslation();
  const isEditing = !!user?.id;
  const defaultValues = isEditing
    ? {
        ...user,
        isTestPeriodEnabled: !!user.testPeriodEnd,
        staticTKP: user.staticPrice ? user.staticPrice.TKP : undefined,
        staticCPC: user.staticPrice ? user.staticPrice.CPC : undefined,
      }
    : {
        role: UserRoles.User.toString(),
        margin: 1,
        testPeriodEnd: moment().add(3, 'month'),
        isTestPeriodEnabled: true,
      };

  const { register, errors, handleSubmit, watch, setValue } = useForm({
    defaultValues,
  });

  if (defaultValues) {
    defaultValues.margin = formatFloatToView(defaultValues.margin.toString());
  }

  const handleFormSubmit = async (form) => {
    const data = omit(form, 'isTestPeriodEnabled');

    const [logoFile] = data.logoFile;
    const action = isEditing ? onUserUpdate : onUserCreate;
    await action({
      ...data,
      logoFile,
      margin: data.margin ? formatFloatToSubmit(data.margin) : undefined,
      role: Number(data.role),
      testPeriodEnd: form.isTestPeriodEnabled
        ? data.testPeriodEnd || moment().toDate()
        : null,
    });
  };

  const onDateChange = (date) => {
    setValue('testPeriodEnd', date.toDate());
  };

  const onTestPeriodChange = ({ target: { checked } }) => {
    setValue('isTestPeriodEnabled', checked);
  };

  const onDisabledMarginChange = ({ target: { checked } }) => {
    setValue('disabledMargin', checked);
  };

  const onDisabledEmailNotificationsChange = ({ target: { checked } }) => {
    setValue('disabledEmailNotifications', checked);
  };

  useEffect(() => {
    register('testPeriodEnd');
    register('isTestPeriodEnabled', { value: true });
    register('disabledMargin');
    register('disabledEmailNotifications');
  }, []);

  const [
    role,
    logoFile,
    testPeriodEnd,
    disabledMargin,
    disabledEmailNotifications,
    isTestPeriodEnabled,
  ] = watch([
    'role',
    'logoFile',
    'testPeriodEnd',
    'disabledMargin',
    'disabledEmailNotifications',
    'isTestPeriodEnabled',
  ]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box px={3} py={4} width={444}>
        <DialogTitle>
          {isEditing ? t('userModal.editUser') : t('userModal.newUser')}
        </DialogTitle>
        <DialogContent>
          <form id="user-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {t('userModal.email')}
                </Typography>
                <TextField
                  fullWidth
                  error={errors?.email}
                  helperText={errors?.email?.message}
                  placeholder={t('userModal.email')}
                  variant="outlined"
                  {...register('email', {
                    required: t('validationErrors.formRequired'),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t('validationErrors.invalidEmailFormat'),
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {t('userModal.username')}
                </Typography>
                <TextField
                  fullWidth
                  error={errors?.username}
                  helperText={errors?.username?.message}
                  placeholder={t('userModal.username')}
                  variant="outlined"
                  {...register('username', {
                    required: t('validationErrors.formRequired'),
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {t('userModal.invoice')}
                </Typography>
                <TextField
                  fullWidth
                  error={errors?.invoiceAddress}
                  placeholder={t('userModal.invoice')}
                  variant="outlined"
                  {...register('invoiceAddress')}
                />
              </Grid>
              {!isEditing && (
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel>{t('userModal.role')}</FormLabel>
                    <RadioGroup
                      name="role"
                      defaultValue={defaultValues.role}
                      row
                    >
                      <FormControlLabel
                        name="role"
                        value={UserRoles.User.toString()}
                        label={t('userModal.userAccount')}
                        control={<Radio {...register('role')} />}
                      />
                      <FormControlLabel
                        name="role"
                        value={UserRoles.Admin.toString()}
                        label={t('userModal.adminAccount')}
                        control={<Radio {...register('role')} />}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
              <Grid item pb={0} xs={12}>
                <FormControlLabel
                  checked={isTestPeriodEnabled}
                  control={
                    <Checkbox
                      color="primary"
                      name="isTestPeriodEnabled"
                      onChange={onTestPeriodChange}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  label={t('userModal.testPeriod') + isTestPeriodEnabled}
                />
              </Grid>
              <Grid item pb={0} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="disabledMargin"
                      checked={disabledMargin}
                      onChange={onDisabledMarginChange}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  label={t('userModal.disabledMargin')}
                />
              </Grid>
              <Grid item pb={0} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="disabledEmailNotifications"
                      checked={disabledEmailNotifications}
                      onChange={onDisabledEmailNotificationsChange}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  label={t('userModal.disabledEmailNotifications')}
                />
              </Grid>
              {isTestPeriodEnabled && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    {t('userModal.testPeriodEnd') + isTestPeriodEnabled}
                  </Typography>
                  <MuiPickersUtilsProvider m={0} utils={MomentUtils}>
                    <DatePicker
                      name="testPeriodEnd"
                      format="DD.MM.YYYY"
                      minDate={moment().subtract(1, 'day')}
                      autoOk
                      error={
                        isEditing &&
                        moment(testPeriodEnd).isBefore(moment().toDate())
                      }
                      minDateMessage=""
                      fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      margin="none"
                      placeholder={t('userModal.testPeriodEnd')}
                      disabled={!isTestPeriodEnabled}
                      value={moment(testPeriodEnd)}
                      onChange={onDateChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ cursor: 'pointer' }}
                          >
                            <CalendarIcon color="secondary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}

              {Number(role) === UserRoles.User && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {t('userModal.margin')}
                    </Typography>
                    <TextField
                      fullWidth
                      error={errors?.margin}
                      helperText={errors?.margin?.message}
                      placeholder={t('userModal.margin')}
                      variant="outlined"
                      {...register('margin', {
                        required: t('validationErrors.formRequired'),
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Static Price TKP
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder={t('userModal.TKP')}
                      variant="outlined"
                      {...register('staticTKP')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Static Price CPC
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder={t('userModal.TKP')}
                      variant="outlined"
                      {...register('staticCPC')}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {t('userModal.password')}
                </Typography>
                <TextField
                  fullWidth
                  type="password"
                  error={errors?.password}
                  helperText={errors?.password?.message}
                  placeholder={t('userModal.password')}
                  variant="outlined"
                  {...register('password', {
                    required: !isEditing
                      ? t('validationErrors.formRequired')
                      : false,
                  })}
                />
              </Grid>
              <Grid item>
                <Box mt={1} mb={1}>
                  <Button variant="outlined" size="large" component="label">
                    {t('userModal.logo')}
                    <Input
                      type="file"
                      {...register('logoFile')}
                      style={{ display: 'none' }}
                    />
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box mt={1} mb={1}>
                  {(logoFile?.length || user?.logoUrl) && (
                    <Avatar
                      variant="square"
                      src={
                        (logoFile?.length &&
                          URL.createObjectURL(logoFile[0])) ||
                        user?.logoUrl
                      }
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogActionsStyle}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button fullWidth color="primary" onClick={onClose}>
                {t('userModal.cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                onClick={handleSubmit(handleFormSubmit)}
                form="user-form"
                color="primary"
                variant="contained"
              >
                {t('userModal.save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UserModal;
