export const getCoordinateByKey = (params) => {
  const { key, points } = params;
  const sortedPoints = points?.sort((a, b) => a[key] - b[key]);
  const min = sortedPoints[0][key];
  const max = sortedPoints.reverse()[0][key];
  return (min + max) / 2;
};

export const getCenterByPoints = (params) => {
  const { points, defaultCenter } = params;
  if (!points.length) {
    return defaultCenter;
  }
  return {
    lng: getCoordinateByKey({
      points,
      key: 'lng',
    }),
    lat: getCoordinateByKey({
      points,
      key: 'lat',
    }),
  };
};

export const mergePoints = (points) => {
  return (
    Object.keys(points)?.reduce((acc, id) => acc.concat(points[id]), []) || []
  );
};

export const getZoomByPoints = (points) => {
  if (points.length === 0) {
    return 6;
  }

  const minLgn = points.sort((a, b) => (a.lng > b.lng ? 1 : -1))[0].lng;
  const maxLgn = points.sort((a, b) => (a.lng < b.lng ? 1 : -1))[0].lng;

  const minLat = points.sort((a, b) => (a.lat > b.lat ? 1 : -1))[0].lat;
  const maxLat = points.sort((a, b) => (a.lat < b.lat ? 1 : -1))[0].lat;

  const baseDeg = Math.max((maxLat - minLat) * 1.3, maxLgn - minLgn);
  if (baseDeg < 0.17) {
    return 12;
  } else if (baseDeg < 0.33) {
    return 11;
  } else if (baseDeg < 0.7) {
    return 10;
  } else if (baseDeg < 1.3) {
    return 9;
  } else if (baseDeg < 2.7) {
    return 8;
  } else if (baseDeg < 4) {
    return 7;
  } else {
    return 6;
  }
};
