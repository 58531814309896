import React, { memo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { CampaignAdvertisingType } from '../../../../enums/campaign';

const CampaignCalculationInfoBox = (props) => {
  const {
    estimatedRange,
    pricePerUser = {},
    budgetRecommendation,
    budget,
    isViewed,
    campaignGoal,
    staticPrice,
  } = props;
  const { t } = useTranslation();
  // todo olo fix this ugly formatting
  const formattedEstimatedRange = JSON.parse(
    JSON.stringify(estimatedRange.toLocaleString('de-DE'))
  );
  const recommendedBudget = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(budgetRecommendation);
  let impressions = (budget / pricePerUser.price).toFixed();
  if (staticPrice) {
    const staticPrices = {
      CPC: staticPrice.CPC,
      TKP: staticPrice.TKP / 1000,
    };
    impressions = (budget / staticPrices[campaignGoal]).toFixed();
  }
  // todo olo fix this ugly formatting
  const formattedImpressions = JSON.parse(
    JSON.stringify(Number(impressions).toLocaleString('de-DE'))
  );
  const getEstimatedRange = (newEstimatedRange) => {
    if (newEstimatedRange !== '0') {
      return capitalize(newEstimatedRange);
    }
    return t('campaignCalculationBox.notAvailable');
  };

  return (
    <Grid container alignItems="flex-start" justify="center" direction="column">
      {/* todo: add view toggle */}
      {!isViewed && (
        <>
          <Grid item>
            <Typography variant="h5">
              {t('campaignCalculationBox.details')}
            </Typography>
          </Grid>
          <Box mt={3} />
        </>
      )}
      <Box ml={3}>
        <Grid item container direction="column" alignItems="flex-start">
          <Grid xs={12} item>
            <Typography variant="subtitle1">
              {t('campaignCalculationBox.reach')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography>
              {getEstimatedRange(formattedEstimatedRange)}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={2} />

        <Grid item container direction="column" alignItems="flex-start">
          <Grid xs={12} item>
            <Typography variant="subtitle1">
              {t('campaignCalculationBox.budgetRecommendation')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            {!budgetRecommendation && (
              <Typography>{t('createCampaign.notAvailable')}</Typography>
            )}
            {!!budgetRecommendation && (
              <Typography>{recommendedBudget}</Typography>
            )}
          </Grid>
        </Grid>
        <Box mt={2} />

        <Grid item container direction="column" alignItems="flex-start">
          <Grid xs={12} item>
            {campaignGoal === CampaignAdvertisingType.TKP && (
              <Typography variant="subtitle1">
                {t('campaignCalculationBox.impressions')}
              </Typography>
            )}
            {campaignGoal === CampaignAdvertisingType.CPC && (
              <Typography variant="subtitle1">
                {t('campaignCalculationBox.clicks')}
              </Typography>
            )}
          </Grid>
          <Grid xs={12} item>
            {(!budget || !pricePerUser.price) && (
              <Typography>{t('createCampaign.notAvailable')}</Typography>
            )}

            {!!budget && !!pricePerUser.price && (
              <Typography>{formattedImpressions}</Typography>
            )}
          </Grid>
        </Grid>
        <Box mt={2} />
        {!staticPrice && (
          <>
            <Grid item container direction="column" alignItems="flex-start">
              <Grid xs={12} item>
                <Typography variant="subtitle1">{campaignGoal}</Typography>
              </Grid>
              <Grid xs={12} item>
                <Typography>
                  {new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(Number(pricePerUser.viewPrice.toFixed(2)))}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        {staticPrice && (
          <>
            <Grid item container direction="column" alignItems="flex-start">
              <Grid xs={12} item>
                <Typography variant="subtitle1">
                  {t('createCampaign.purchasePrice')} ({campaignGoal})
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <Typography>
                  {new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(
                    Number(pricePerUser.viewPriceWithoutAdvMargin.toFixed(2))
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Box mt={2} />
            <Grid item container direction="column" alignItems="flex-start">
              <Grid xs={12} item>
                <Typography variant="subtitle1">
                  {t('createCampaign.sellingPrice')} ({campaignGoal})
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <Typography>
                  {new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(Number(staticPrice[campaignGoal].toFixed(2)))}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default memo(CampaignCalculationInfoBox);
