import gql from 'graphql-tag';

const GET_GEO_INFO_BY_IDS = gql`
  query getGeoInfoByIds($regionIds: [String], $zipIds: [String]) {
    regionsByIds(ids: $regionIds) {
      name
      id
    }
    zipsByIds(ids: $zipIds) {
      name
      id
    }
  }
`;

export default GET_GEO_INFO_BY_IDS;
