import gql from 'graphql-tag';

export const CREATE_UPLOAD_ATTACHMENT = gql`
  mutation createUploadAttachment(
    $attachment: CreateCampaignAttachmentInput!
    $skipS3: Boolean
  ) {
    createCampaignAttachment(attachment: $attachment, skipS3: $skipS3) {
      originalName
      uploadUrl
      filePath
    }
  }
`;

export const GET_USER = gql`
  query getUser {
    user {
      id
      email
      username
      invoiceAddress
      logoUrl
      margin
    }
  }
`;
