import { decode } from 'jsonwebtoken';
import history from '../../history';
import UserRoles from '../../enums/UserRoles';
// eslint-disable-next-line import/no-cycle
import { getApolloClient } from '../apollo-helper';

export const getAccessToken = () => {
  const accessToken = localStorage.getItem('accessToken') || null;
  return accessToken;
};

export const getUserId = () => {
  const accessToken = getAccessToken();
  const { id } = decode(accessToken);
  return id;
};

// TODO (remove me later)
export const setSelectedAdvertiserInfo = (advertiser) => {
  localStorage.setItem('advertiserInfo', JSON.stringify(advertiser));
};

export const getSelectedAdvertiserInfo = () => {
  const advertiser = JSON.parse(localStorage.getItem('advertiserInfo')) || {};
  return advertiser;
};

export const storeAccessToken = (accessToken) => {
  localStorage.setItem('accessToken', accessToken);
};

export const clearAccessToken = () => {
  localStorage.removeItem('accessToken');
};

export const clearSelectedAdvertiser = () => {
  localStorage.removeItem('advertiserInfo');
};

export const logout = () => {
  const client = getApolloClient();
  client.clearStore();
  clearAccessToken();
  clearSelectedAdvertiser();
  history.go('/login');
};

export const getRole = () => {
  const accessToken = getAccessToken();
  const decoded = decode(accessToken);
  if (!decoded) logout();
  return decoded.role;
};

export const isAdmin = () => getRole() === UserRoles.Admin;

export const isLoggedIn = () => !!getAccessToken();
