import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const WebsitesGeneratorCustomListSelectedItem = (props) => {
  const { removeCustomWebsite, websites } = props;
  const { t } = useTranslation();
  return (
    <List dense disablePadding>
      <ListItem divider component="li">
        <ListItemText primary={t('websitesGenerator.specialWebsites')} />
      </ListItem>
      {websites.map((website) => {
        return (
          <ListItem divider key={website.name}>
            <ListItemText secondary={website.name} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => removeCustomWebsite(website)}>
                <Close />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default WebsitesGeneratorCustomListSelectedItem;
