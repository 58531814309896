import React, { useCallback, memo, useState, useEffect, useRef } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';

import AppRadioButton from '../../../../../../components/AppRadioButton';
import { StatesIcon, ZipIcon } from '../../../../../../components/CustomIcons';
import { CampaignGeoTargetingType } from '../../../../../../enums/campaign';
import RegionSelector from '../../../../../../components/GeoTargeting/components/RegionSelector';
import ZipSelector from '../../../../../../components/GeoTargeting/components/ZipSelector';

const CampaignStep3 = (props) => {
  const { t } = useTranslation();
  const {
    isFormSubmitted,
    geoTargetingType,
    geoRegionIds,
    geoZipCodes,
    handleCampaignState,
    onMapLoaded,
    refsMap,
  } = props;
  const [zipsPreset, setZipsPreset] = useState(null);
  const [regionsPreset, setRegionsPreset] = useState(null);

  const geoTargetingRef = useRef();

  useEffect(() => {
    refsMap.current['geo-targeting'] = geoTargetingRef;
  }, [refsMap]);

  useEffect(() => {
    if (geoRegionIds.length && !regionsPreset) {
      setRegionsPreset(geoRegionIds);
    }
    if (geoZipCodes.length && !zipsPreset) {
      setZipsPreset(geoZipCodes);
    }
  }, [geoRegionIds, geoZipCodes, zipsPreset, regionsPreset]);

  const handleGeoTypeChange = useCallback(
    (event) => {
      const type = parseInt(event.target.value, 10);
      handleCampaignState({
        geoTargetingType: type,
        geoRegionIds: [],
        geoZipCodes: [],
        reach: 0,
      });
    },
    [handleCampaignState]
  );

  const handleGeoRegionChange = useCallback(
    (payload) => {
      handleCampaignState({
        geoRegionIds: payload.geoRegionIds,
        geoZipCodes: [],
        reach: payload.reach,
      });
    },
    [handleCampaignState]
  );

  const handleGeoZipChange = useCallback(
    (payload) => {
      handleCampaignState({
        geoZipCodes: payload.geoZipCodes,
        geoRegionIds: [],
        reach: payload.reach,
      });
    },
    [handleCampaignState]
  );

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{t('createCampaign.stepThree')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <AppRadioButton
                labelText={t('geoTargeting.regionTitle')}
                icon={StatesIcon}
                name="geoType"
                checked={geoTargetingType === CampaignGeoTargetingType.Land}
                value={CampaignGeoTargetingType.Land}
                onChange={handleGeoTypeChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AppRadioButton
                labelText={`${t('geoTargeting.zipTitle')}`}
                icon={ZipIcon}
                name="geoType"
                checked={geoTargetingType === CampaignGeoTargetingType.Zip}
                value={CampaignGeoTargetingType.Zip}
                onChange={handleGeoTypeChange}
                tooltip={t('tooltips.zipTitle')}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Box mt={3} />
          </Grid>
          <Grid ref={geoTargetingRef} container>
            {geoTargetingType === CampaignGeoTargetingType.Land && (
              <RegionSelector
                isFormSubmitted={isFormSubmitted}
                geoTargetingChangeHandler={handleGeoRegionChange}
                onMapLoaded={onMapLoaded}
                regionsPreset={regionsPreset}
              />
            )}
            {geoTargetingType === CampaignGeoTargetingType.Zip && (
              <ZipSelector
                isFormSubmitted={isFormSubmitted}
                geoTargetingChangeHandler={handleGeoZipChange}
                onMapLoaded={onMapLoaded}
                zipsPreset={zipsPreset}
              />
            )}
          </Grid>
          <Box mt={2} width="100%" />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(CampaignStep3);
