import React from 'react';

import { AppCase, AppSwitch } from '../../../../../components/AppSwitchCase';
import { getRole } from '../../../../../services/JWT-Auth';
import UserRoles from '../../../../../enums/UserRoles';
import AdminViewLinkedinCampaign from '../../../../adminUsers/LinkedinCampaign/ViewCampaign';
import UserViewLinkedinCampaign from '../../../../agencyUsers/LinkedinCampaign/ViewCampaign';

const ViewLinkedinScene = () => {
  return (
    <AppSwitch test={getRole()}>
      <AppCase value={UserRoles.Admin}>
        <AdminViewLinkedinCampaign />
      </AppCase>
      <AppCase value={UserRoles.User}>
        <UserViewLinkedinCampaign />
      </AppCase>
    </AppSwitch>
  );
};

export default ViewLinkedinScene;
