import './index.css';
import React from 'react';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import MomentUtils from '@date-io/moment';
import { Info } from '@material-ui/icons';
import classNames from 'classnames';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { formatFloatToView } from '../../services/format-helper';
import Intensity from '../../components/Intensity';
import LinkedinBudgetInput from '../agencyUsers/LinkedinCampaign/CreateCampaign/components/CampaignStep4/components/LinkedinBudgetInput';
import LinkedinAdSpecs from '../agencyUsers/LinkedinCampaign/CreateCampaign/components/CampaignStep4/components/LinkedinAdSpecs';
import AppRadioButton from '../../components/AppRadioButton';
import {
  AwarenessIcon,
  CalendarIcon,
  FemaleIcon,
  MaleIcon,
  StatesIcon,
  ZipIcon,
} from '../../components/CustomIcons';
import {
  CampaignGeoTargetingType,
  LinkedinCampaignGoal,
} from '../../enums/campaign';

import AppCheckboxButton from '../../components/AppCheckboxButton';
import AppLabeledCheckbox from '../../components/AppLabeledCheckbox';
import RegionSelector from '../../components/GeoTargeting/components/RegionSelector';
import ZipSelector from '../../components/GeoTargeting/components/ZipSelector';

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  root: {
    '& .MuiFormControlLabel-root': {
      minWidth: '100px',
    },
  },
});

const LinkedinPdfPrint = () => {
  const { campaign, advertisers, isAgencyPrice } = JSON.parse(
    localStorage.getItem('campaign-for-print')
  );
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    advertiserId,
    name,
    linkedinGoal,
    startDate,
    endDate,
    linkedinAges,
    gender,
    landingPage,
    idkLandingPageYet,
    linkedinProfile,
    idkLinkedinProfileYet,
    geoTargetingType,
    linkedinTargetGroups,
    linkedinTargetCompanyGroups,
    geoZipCodes,
    geoRegionIds,
    intensity,
    comment,
    budget: campaignBudget,
    pricing: campaignPricing,
    advertiserMargin,
  } = campaign;
  const advertiser = advertisers.find(({ id }) => id === advertiserId);
  let budget = campaignBudget;
  const pricing = campaignPricing;

  if (isAgencyPrice) {
    const agencyBudget = (campaignBudget * 100) / (100 + advertiserMargin);
    budget = Number(agencyBudget.toFixed(2));
    const agencyPrice = (pricing.TKP * 100) / (100 + advertiserMargin);
    pricing.TKP = Number(agencyPrice.toFixed(2));
  }

  const impressions = (budget / pricing.TKP).toFixed();
  const formattedImpressions = JSON.parse(
    JSON.stringify(Number(impressions).toLocaleString('de-DE'))
  );

  const onMapLoaded = () => {
    window.onafterprint = () => {
      window.close();
    };
    window.print();
  };

  return (
    <Grid item xs id="pdf-container">
      <img
        style={{ height: '150px' }}
        src={`${process.env.PUBLIC_URL}/twins-digital-logo.png`}
        alt="logo"
      />
      <Box mt={2} width="100%" />

      {/* step 1 */}
      <Typography variant="h6">{t('createCampaign.stepOne')}</Typography>
      <Grid container>
        {/* advertiser */}
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('createCampaign.advertiser')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Select value={advertiser} disabled>
                <MenuItem value={advertiser}>{advertiser?.name}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Box mt={3} width="100%" />
        </Grid>

        {/* campaign name */}
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('createCampaign.campaignName')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder={t('createCampaign.campaignNamePlaceHolder')}
              variant="outlined"
              value={name}
              fullWidth
              disabled
            />
          </Grid>
          <Box mt={3} width="100%" />
        </Grid>

        {/* campaign goal */}
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('createCampaign.campaignGoal')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <AppRadioButton
              icon={AwarenessIcon}
              labelText={t('createCampaign.websiteVisitors')}
              checked={linkedinGoal === LinkedinCampaignGoal.Visitors}
              value={LinkedinCampaignGoal.Visitors}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <AppRadioButton
              icon="mouse_icon"
              labelText={t('createCampaign.interactionWithLinkedInContent')}
              checked={linkedinGoal === LinkedinCampaignGoal.Interaction}
              value={LinkedinCampaignGoal.Interaction}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <AppRadioButton
              icon="contact_mail_icon"
              labelText={t('createCampaign.applicants')}
              checked={linkedinGoal === LinkedinCampaignGoal.Applicants}
              value={LinkedinCampaignGoal.Applicants}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <AppRadioButton
              icon="people_alt_icon"
              labelText={t('createCampaign.leadsInLinkedIn')}
              checked={linkedinGoal === LinkedinCampaignGoal.Leads}
              value={LinkedinCampaignGoal.Leads}
              disabled
            />
          </Grid>
          <Box mt={3} width="100%" />
        </Grid>

        {/* campaign duration */}
        <Grid item container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant="body1">
              {t('createCampaign.campaignStart')}
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                format="DD.MM.YYYY"
                autoOk
                fullWidth
                variant="inline"
                inputVariant="outlined"
                margin="normal"
                placeholder={t('createCampaign.campaignStart')}
                value={startDate}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ cursor: 'pointer' }}
                    >
                      <CalendarIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant="body1">
              {t('createCampaign.campaignEnd')}
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                format="DD.MM.YYYY"
                autoOk
                fullWidth
                variant="inline"
                inputVariant="outlined"
                margin="normal"
                placeholder={t('createCampaign.campaignEnd')}
                value={endDate}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ cursor: 'pointer' }}
                    >
                      <CalendarIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Box mt={1} width="100%" />
        </Grid>
      </Grid>
      <div style={{ pageBreakAfter: 'always' }} />

      {/* step 2 */}
      <Typography variant="h6">{t('createCampaign.stepTwo')}</Typography>
      <Box mt={2} width="100%" />
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography variant="body1" gutterBottom>
            {t('createCampaign.demographicTargeting')}
          </Typography>
          <FormGroup>
            <Grid container className={classNames(classes.root)}>
              <Grid item xs={12}>
                <AppLabeledCheckbox
                  checked={Boolean(linkedinAges[0])}
                  label="18-24"
                  name="age1824"
                  value={1}
                />
                <AppLabeledCheckbox
                  checked={Boolean(linkedinAges[1])}
                  label="25-34"
                  name="age2534"
                  value={1}
                />
                <AppLabeledCheckbox
                  checked={Boolean(linkedinAges[2])}
                  label="35-54"
                  name="age3554"
                  value={1}
                />
                <AppLabeledCheckbox
                  checked={Boolean(linkedinAges[3])}
                  label="55+"
                  name="age55"
                  value={1}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </Grid>
        <Box mt={3} width="100%" />

        {/* landing page */}
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('createCampaign.landingPage')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={landingPage}
              placeholder={t('createCampaign.landingPage')}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox color="primary" checked={idkLandingPageYet} />}
              label={t('createCampaign.idkLandingPageYet')}
            />
          </Grid>
          <Box mt={3} width="100%" />
        </Grid>

        {/* linkedin company profile */}
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('createCampaign.linkedinProfile')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={linkedinProfile}
              placeholder={t('createCampaign.linkedinProfile')}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={idkLinkedinProfileYet} />
              }
              label={t('createCampaign.idkLinkedinProfileYet')}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box my={2} width="100%">
            <Divider />
          </Box>
        </Grid>

        {/* targetGroups */}
        {linkedinTargetGroups && (
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.companyTargetGroups')}
                <Tooltip
                  title={t('tooltips.companyTargetGroups')}
                  className={classes.tooltip}
                >
                  <Info color="secondary" fontSize="inherit" />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {linkedinTargetGroups.map(({ name: groupName, children }) => (
                <Grid item xs={12} key={groupName}>
                  <Typography>{groupName}</Typography>
                  {children.map(({ name: childName }) => (
                    <Chip
                      color="primary"
                      size="small"
                      label={childName}
                      key={childName}
                      style={{ marginRight: 5, marginBottom: 5 }}
                    />
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {/* gender/age */}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Box mt={2} width="100%" />
          </Grid>
          <Grid item xs={12} md={4} className={classes.container}>
            <Typography variant="body1">
              {t('createCampaign.gender')}
            </Typography>
            <Tooltip title={t('tooltips.gender')} className={classes.tooltip}>
              <Info color="secondary" fontSize="inherit" />
            </Tooltip>
            <Box mt={2} width="100%" />
            <Grid container spacing={2}>
              <Grid item>
                <AppCheckboxButton
                  icon={MaleIcon}
                  labelText={t('createCampaign.male')}
                  name="male"
                  checked={gender.includes('male')}
                />
              </Grid>
              <Grid item>
                <AppCheckboxButton
                  icon={FemaleIcon}
                  labelText={t('createCampaign.female')}
                  name="female"
                  checked={gender.includes('female')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={3} width="100%" />

        {/* targetCompanyGroups */}
        {linkedinTargetCompanyGroups && (
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.userTargetGroups')}
                <Tooltip
                  title={t('tooltips.userTargetGroups')}
                  className={classes.tooltip}
                >
                  <Info color="secondary" fontSize="inherit" />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {linkedinTargetCompanyGroups.map(
                ({ name: groupName, children }) => (
                  <Grid item xs={12} key={groupName}>
                    <Typography>{groupName}</Typography>
                    {children.map(({ name: childName }) => (
                      <Chip
                        color="primary"
                        size="small"
                        label={childName}
                        key={childName}
                        style={{ marginRight: 5, marginBottom: 5 }}
                      />
                    ))}
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        )}
      </Grid>

      <div style={{ pageBreakAfter: 'always' }} />

      {/* step 3 */}
      <Typography variant="h6">{t('createCampaign.stepThree')}</Typography>
      <Box mt={2} width="100%" />
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <AppRadioButton
              labelText={t('geoTargeting.regionTitle')}
              icon={StatesIcon}
              name="geoType"
              checked={geoTargetingType === CampaignGeoTargetingType.Land}
              value={CampaignGeoTargetingType.Land}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AppRadioButton
              labelText={`${t('geoTargeting.zipTitle')}`}
              icon={ZipIcon}
              name="geoType"
              checked={geoTargetingType === CampaignGeoTargetingType.Zip}
              value={CampaignGeoTargetingType.Zip}
              tooltip={t('tooltips.zipTitle')}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Box mt={3} />
        </Grid>
        <Grid container>
          {geoTargetingType === CampaignGeoTargetingType.Land && (
            <RegionSelector
              onMapLoaded={onMapLoaded}
              regionsPreset={geoRegionIds}
              disabled
            />
          )}
          {geoTargetingType === CampaignGeoTargetingType.Zip && (
            <ZipSelector
              onMapLoaded={onMapLoaded}
              zipsPreset={geoZipCodes}
              disabled
            />
          )}
        </Grid>
        <Box mt={2} width="100%" />
      </Grid>
      <div style={{ pageBreakAfter: 'always' }} />

      {/* step 4 */}
      <Typography variant="h6">{t('createCampaign.stepFour')}</Typography>
      <Grid container>
        {/* intensity */}
        <Grid item xs={12}>
          <Intensity intensity={intensity} />
          <Box my={4} width="100%">
            <Divider />
          </Box>
        </Grid>

        {/* Budget */}
        <Grid item xs={12}>
          <Typography variant="body1">{t('createCampaign.budget')}</Typography>
          <Box mt={2} width="100%" />
          <LinkedinBudgetInput budget={budget} />
          <Box my={4} width="100%">
            <Divider />
          </Box>
        </Grid>

        {/* Impressions  */}
        <Grid item xs={12}>
          {(!budget || !pricing.TKP) && (
            <Typography variant="body1" gutterBottom>
              {t('createCampaign.notAvailable')}
            </Typography>
          )}
          {!!budget && !!pricing.TKP && (
            <Typography variant="body1" gutterBottom>
              {t('createCampaign.impressions', {
                impressions: formattedImpressions,
              })}
            </Typography>
          )}
        </Grid>

        {/* TKP  */}
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            {`TKP: ${formatFloatToView(pricing.TKP.toFixed(2))}€`}
          </Typography>
        </Grid>

        <Box my={4} width="100%">
          <Divider />
        </Box>

        {/* comment */}
        <Grid item xs={12}>
          <Typography variant="body1">{t('createCampaign.comment')}</Typography>
          <Box my={2} width="100%">
            <TextField
              multiline
              InputProps={{
                style: {
                  height: 'auto',
                },
              }}
              rows={4}
              value={comment}
              placeholder={t('createCampaign.commentPlaceholder')}
              variant="outlined"
              fullWidth
            />
          </Box>
        </Grid>
      </Grid>
      <div style={{ pageBreakAfter: 'always' }} />
      {/* linkedin ad specs */}
      <Grid item xs={12}>
        <LinkedinAdSpecs linkedinGoal={linkedinGoal} />
      </Grid>
    </Grid>
  );
};

export default LinkedinPdfPrint;
