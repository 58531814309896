import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  Grid,
  Button,
} from '@material-ui/core';

import AppRadioButton from '../../../../components/AppRadioButton';
import { CampaignCreativesTypes } from '../../../../enums/campaign';

const CampaignCreativesModal = (props) => {
  const { isOpen, materialType, onClose, onMaterialTypeChange } = props;

  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <Box maxWidth={800} py={1}>
        <DialogTitle>{t('createCampaign.materialTypeModalHeader')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} display="flex" direction="column">
            <Grid item xs={12}>
              <AppRadioButton
                error={false}
                icon="library_books"
                labelText={t('createCampaign.staticMaterialLabel')}
                checked={materialType === CampaignCreativesTypes.Static}
                onChange={onMaterialTypeChange}
                value={CampaignCreativesTypes.Static}
              />
            </Grid>

            <Grid item xs={12}>
              <AppRadioButton
                error={false}
                icon="library_books"
                labelText={t('createCampaign.animatedMaterialLabel')}
                checked={materialType === CampaignCreativesTypes.Animation}
                onChange={onMaterialTypeChange}
                value={CampaignCreativesTypes.Animation}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions disableSpacing>
          <Box m={2} fullWidth>
            <Grid container justify="center" direction="row">
              <Grid item l={12}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={onClose}
                >
                  {t('advertiserModal.save')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CampaignCreativesModal;
