import React, { memo } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { capitalize, invert } from 'lodash';

import { CampaignStatus, CreativeStatus } from '../../../../enums/campaign';

const CampaignInfoBox = (props) => {
  const { campaign } = props;
  const { status, creativeStatus, campaignType } = campaign;
  const { t } = useTranslation();

  const campaignStatusString = t(`campaignStatuses.${CampaignStatus[status]}`);

  const creativeStatusString = t(
    `creativeStatuses.${CreativeStatus[creativeStatus]}`
  );

  return (
    <Grid container alignItems="flex-start" justify="center" direction="column">
      <Grid item>
        <Typography variant="h5">{t('campaignInfoBox.details')}</Typography>
      </Grid>
      <Box mt={3} />
      <Box ml={3}>
        <Grid item container direction="column" alignItems="flex-start">
          <Grid xs={12} item>
            <Typography variant="subtitle1">
              {t('campaignInfoBox.status')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography>{campaignStatusString}</Typography>
          </Grid>
        </Grid>
        <Box mt={2} />

        <Grid item container direction="column" alignItems="flex-start">
          <Grid xs={12} item>
            <Typography variant="subtitle1">
              {t('campaignInfoBox.creativeStatus')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography>{creativeStatusString}</Typography>
          </Grid>
        </Grid>
        <Box mt={2} />

        <Grid item container direction="column" alignItems="flex-start">
          <Grid xs={12} item>
            <Typography variant="subtitle1">
              {t('campaignInfoBox.type')}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography>{capitalize(campaignType)}</Typography>
          </Grid>
        </Grid>
        <Box mt={2} />
      </Box>
    </Grid>
  );
};

export default memo(CampaignInfoBox);
