import React, { useCallback, memo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Grid,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Box,
  makeStyles,
  Divider,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Info } from '@material-ui/icons';
import { CampaignAgesForLinkedin } from '../../../../../../enums/campaign';
import AgeTargeting from './components/AgeTargeting';
import { toggleArrayItem } from '../../../../../../services/linkedin/state-helper';
import AudienceTargeting from './components/AudienceTargeting';
import GenderTargeting from './components/GenderTargeting';
import LandingPage from './components/LandingPage';
import LinkedinProfile from './components/LinkedinProfile';

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
});

const CampaignStep2 = (props) => {
  const {
    handleCampaignState,
    linkedinTargetGroupsState,
    linkedinCompanyTargetGroupsState,
    linkedinAges,
    gender,
    landingPage,
    idkLandingPageYet,
    linkedinProfile,
    idkLinkedinProfileYet,
    isFormSubmitted,
    isPdfView,
    refsMap,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const targetGroupsRef = useRef();
  const targetCompanyGroupsRef = useRef();
  const genderAgeRef = useRef();
  const landingPageRef = useRef();
  const linkedinPageRef = useRef();

  useEffect(() => {
    // eslint-disable-next-line dot-notation
    refsMap.current['target-groups'] = targetGroupsRef;
    refsMap.current['gender-age'] = genderAgeRef;
    refsMap.current['landing-page'] = landingPageRef;
    refsMap.current['linkedin-page'] = linkedinPageRef;
    refsMap.current['target-company-groups'] = targetCompanyGroupsRef;
  }, [refsMap]);

  const handleLandingPageChange = useCallback(
    (event) => {
      handleCampaignState({ landingPage: event.target.value });
    },
    [handleCampaignState]
  );

  const handleLinkedinProfileChange = useCallback(
    (event) => {
      handleCampaignState({ linkedinProfile: event.target.value });
    },
    [handleCampaignState]
  );

  const handleIdkLandingPageChange = useCallback(
    (e) => {
      handleCampaignState({ idkLandingPageYet: e.target.checked });
    },
    [handleCampaignState]
  );

  const handleIdkLinkedinProfileChange = useCallback(
    (e) => {
      handleCampaignState({ idkLinkedinProfileYet: e.target.checked });
    },
    [handleCampaignState]
  );

  const handleAgeChange = useCallback(
    (event) => {
      const { name, checked } = event.target;
      const updatedAges = [...linkedinAges];
      updatedAges[CampaignAgesForLinkedin[name]] = Number(checked);
      handleCampaignState({ linkedinAges: updatedAges });
    },
    [handleCampaignState, linkedinAges]
  );

  const handleGenderChange = useCallback(
    (event) => {
      const { name } = event.target;
      const updatedGender = toggleArrayItem(gender, name);
      handleCampaignState({ gender: updatedGender });
    },
    [handleCampaignState, gender]
  );

  const handleSelectCategory = useCallback(
    ({ categoryIndex }) => {
      const { isSelected, children } = linkedinTargetGroupsState[categoryIndex];
      const childrenState = children.map((item) => ({
        ...item,
        isSelected: !isSelected,
      }));
      const newState = [...linkedinTargetGroupsState];
      newState[categoryIndex] = {
        ...linkedinTargetGroupsState[categoryIndex],
        isSelected: !isSelected,
        children: childrenState,
      };
      handleCampaignState({ linkedinTargetGroupsState: newState });
    },
    [handleCampaignState, linkedinTargetGroupsState]
  );

  const handleSelectSubCategory = useCallback(
    ({ categoryIndex, subcategoryIndex }) => {
      const { children } = linkedinTargetGroupsState[categoryIndex];
      const newState = [...linkedinTargetGroupsState];
      newState[categoryIndex].children[subcategoryIndex] = {
        ...linkedinTargetGroupsState[categoryIndex].children[subcategoryIndex],
        isSelected: !children[subcategoryIndex].isSelected,
      };
      const stateIsSelected = children.some(({ isSelected }) => isSelected);
      newState[categoryIndex] = {
        ...linkedinTargetGroupsState[categoryIndex],
        isSelected: !!stateIsSelected,
        children,
      };
      handleCampaignState({ linkedinTargetGroupsState: newState });
    },
    [handleCampaignState, linkedinTargetGroupsState]
  );

  const handleSelectCompanyCategory = useCallback(
    ({ categoryIndex }) => {
      const { isSelected, children } =
        linkedinCompanyTargetGroupsState[categoryIndex];
      const childrenState = children.map((item) => ({
        ...item,
        isSelected: !isSelected,
      }));
      const newState = [...linkedinCompanyTargetGroupsState];
      newState[categoryIndex] = {
        ...linkedinCompanyTargetGroupsState[categoryIndex],
        isSelected: !isSelected,
        children: childrenState,
      };
      handleCampaignState({ linkedinCompanyTargetGroupsState: newState });
    },
    [handleCampaignState, linkedinCompanyTargetGroupsState]
  );

  const handleSelectCompanySubCategory = useCallback(
    ({ categoryIndex, subcategoryIndex }) => {
      const { children } = linkedinCompanyTargetGroupsState[categoryIndex];
      const newState = [...linkedinCompanyTargetGroupsState];
      newState[categoryIndex].children[subcategoryIndex] = {
        ...linkedinCompanyTargetGroupsState[categoryIndex].children[
          subcategoryIndex
        ],
        isSelected: !children[subcategoryIndex].isSelected,
      };
      const stateIsSelected = children.some(({ isSelected }) => isSelected);
      newState[categoryIndex] = {
        ...linkedinCompanyTargetGroupsState[categoryIndex],
        isSelected: !!stateIsSelected,
        children,
      };
      handleCampaignState({ linkedinCompanyTargetGroupsState: newState });
    },
    [handleCampaignState, linkedinCompanyTargetGroupsState]
  );

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{t('createCampaign.stepTwo')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {/* landing page */}
          <Grid ref={landingPageRef} item container spacing={2}>
            <LandingPage
              isFormSubmitted={isFormSubmitted}
              landingPage={landingPage}
              idkLandingPageYet={idkLandingPageYet}
              handleLandingPageChange={handleLandingPageChange}
              handleIdkLandingPageChange={handleIdkLandingPageChange}
            />
          </Grid>

          {/* linkedin company profile */}
          <Grid ref={linkedinPageRef} item container spacing={2}>
            <LinkedinProfile
              isFormSubmitted={isFormSubmitted}
              linkedinProfile={linkedinProfile}
              idkLinkedinProfileYet={idkLinkedinProfileYet}
              handleLinkedinProfileChange={handleLinkedinProfileChange}
              handleIdkLinkedinProfileChange={handleIdkLinkedinProfileChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Box my={4} width="100%">
              <Divider />
            </Box>
          </Grid>

          {/* targetGroups */}
          <Grid item container spacing={2}>
            <Grid ref={targetGroupsRef} item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.companyTargetGroups')}
                <Tooltip
                  title={t('tooltips.companyTargetGroups')}
                  className={classes.tooltip}
                >
                  <Info color="secondary" fontSize="inherit" />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AudienceTargeting
                onSelectCategory={handleSelectCategory}
                onSelectSubCategory={handleSelectSubCategory}
                categoriesState={linkedinTargetGroupsState}
                isPdfView={isPdfView}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box my={4} width="100%">
              <Divider />
            </Box>
          </Grid>

          {/* gender/age */}
          <Grid ref={genderAgeRef} container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Box mt={2} width="100%" />
            </Grid>
            <Grid item xs={12} md={4} className={classes.container}>
              <GenderTargeting
                isFormSubmitted={isFormSubmitted}
                gender={gender}
                handleGenderChange={handleGenderChange}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <AgeTargeting
                isFormSubmitted={isFormSubmitted}
                ages={linkedinAges}
                handleAgeChange={handleAgeChange}
              />
            </Grid>
            <Box mt={3} width="100%" />
          </Grid>

          <Grid item xs={12}>
            <Box my={4} width="100%">
              <Divider />
            </Box>
          </Grid>

          {/* targetCompanyGroups */}
          <Grid item container spacing={2}>
            <Grid ref={targetCompanyGroupsRef} item xs={12}>
              <Typography variant="body1">
                {t('createCampaign.userTargetGroups')}
                <Tooltip
                  title={t('tooltips.userTargetGroups')}
                  className={classes.tooltip}
                >
                  <Info color="secondary" fontSize="inherit" />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AudienceTargeting
                onSelectCategory={handleSelectCompanyCategory}
                onSelectSubCategory={handleSelectCompanySubCategory}
                categoriesState={linkedinCompanyTargetGroupsState}
              />
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(CampaignStep2);
