import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import AppRadioButton from '../../../../components/AppRadioButton';
import {
  CampaignSocialAdvertiserMaterialTypes,
  CampaignSocialPlatformTypes,
} from '../../../../enums/campaign';
import InstagramViewDetails from './components/InstagramViewDetails';
import FacebookViewDetails from './components/FacebookViewDetails';
import InstagramViewNotes from './components/InstagramViewNotes';
import FacebookViewNotes from './components/FacebookViewNotes';

const CampaignSocialAdvertisingMaterials = (props) => {
  const {
    socialPlatform = [],
    socialAdvertiserMaterialType,
    handleCampaignPricing,
    disabled,
    isPrint,
  } = props;
  const [selectedTab, setSelectedTab] = useState(
    CampaignSocialPlatformTypes.Instagram
  );
  const { t } = useTranslation();
  const isDisabledInstagram = !socialPlatform.includes(
    CampaignSocialPlatformTypes.Instagram
  );
  const isDisabledFacebook = !socialPlatform.includes(
    CampaignSocialPlatformTypes.Facebook
  );

  useEffect(() => {
    if (isDisabledInstagram) {
      setSelectedTab(CampaignSocialPlatformTypes.Facebook);
    }
    if (isDisabledFacebook) {
      setSelectedTab(CampaignSocialPlatformTypes.Instagram);
    }
    if (isDisabledInstagram && isDisabledFacebook) {
      setSelectedTab(undefined);
    }
  }, [socialPlatform]);

  const handleSocialMaterialChange = useCallback(
    (event) => {
      if (disabled) {
        return;
      }
      handleCampaignPricing({
        socialAdvertiserMaterialType: event.target.value,
      });
    },
    [handleCampaignPricing]
  );

  return (
    <Grid container>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AppRadioButton
            labelText="Feed Ad (Bild)"
            icon="photo_camera"
            name="geoType"
            checked={
              socialAdvertiserMaterialType ===
              CampaignSocialAdvertiserMaterialTypes.FeedStatic
            }
            value={CampaignSocialAdvertiserMaterialTypes.FeedStatic}
            onChange={handleSocialMaterialChange}
          />
        </Grid>
        <Grid item xs={6}>
          <AppRadioButton
            labelText="Feed Ad (Video)"
            icon="videocam"
            name="geoType"
            checked={
              socialAdvertiserMaterialType ===
              CampaignSocialAdvertiserMaterialTypes.FeedVideo
            }
            value={CampaignSocialAdvertiserMaterialTypes.FeedVideo}
            onChange={handleSocialMaterialChange}
          />
        </Grid>
        <Grid item xs={6}>
          <AppRadioButton
            labelText="Story Ad (Bild)"
            icon="photo_camera"
            name="geoType"
            checked={
              socialAdvertiserMaterialType ===
              CampaignSocialAdvertiserMaterialTypes.StoryStatic
            }
            value={CampaignSocialAdvertiserMaterialTypes.StoryStatic}
            onChange={handleSocialMaterialChange}
          />
        </Grid>
        <Grid item xs={6}>
          <AppRadioButton
            labelText="Story Ad (Video)"
            icon="videocam"
            name="geoType"
            checked={
              socialAdvertiserMaterialType ===
              CampaignSocialAdvertiserMaterialTypes.StoryVideo
            }
            value={CampaignSocialAdvertiserMaterialTypes.StoryVideo}
            onChange={handleSocialMaterialChange}
          />
        </Grid>
      </Grid>
      <Box mt={2} width="100%" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs
            variant="fullWidth"
            value={selectedTab}
            onChange={(e, value) => {
              setSelectedTab(value);
            }}
          >
            <Tab
              disabled={disabled || isDisabledInstagram}
              value={CampaignSocialPlatformTypes.Instagram}
              fullWidth
              label={t('socials.instagram.name')}
            />
            <Tab
              disabled={disabled || isDisabledFacebook}
              value={CampaignSocialPlatformTypes.Facebook}
              fullWidth
              label={t('socials.facebook.name')}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} />
          {selectedTab === CampaignSocialPlatformTypes.Instagram && (
            <Grid>
              <InstagramViewDetails
                socialAdvertiserMaterialType={socialAdvertiserMaterialType}
              />
            </Grid>
          )}
          {selectedTab === CampaignSocialPlatformTypes.Facebook && (
            <Grid>
              <FacebookViewDetails
                socialAdvertiserMaterialType={socialAdvertiserMaterialType}
              />
            </Grid>
          )}
          <Box p={2} />
        </Grid>
        {!isPrint && (
          <Grid item xs={12}>
            <Accordion variant="outlined">
              <AccordionSummary expandIcon={<Add />}>
                {t('socials.note')}
              </AccordionSummary>
              <AccordionDetails>
                {selectedTab === CampaignSocialPlatformTypes.Instagram && (
                  <Box>
                    <InstagramViewNotes
                      socialAdvertiserMaterialType={
                        socialAdvertiserMaterialType
                      }
                    />
                  </Box>
                )}
                {selectedTab === CampaignSocialPlatformTypes.Facebook && (
                  <Box>
                    <FacebookViewNotes
                      socialAdvertiserMaterialType={
                        socialAdvertiserMaterialType
                      }
                    />
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
            <Box p={2} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(CampaignSocialAdvertisingMaterials);
