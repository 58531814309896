import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Typography,
  Paper,
  Grid,
} from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { omit } from 'lodash';
import Breadcrumb from '../../components/Breadcrumb';
import { GET_USER } from '../../common/queries';
import { UPDATE_USER } from '../Users/queries';

const Settings = () => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(GET_USER);
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: ['getUser'],
  });

  const { register, handleSubmit, reset, errors } = useForm();

  useEffect(() => {
    reset(data?.user);
  }, [data, reset]);

  const onUserUpdate = async (updatedUser) => {
    const { id } = data?.user;
    let userInput = omit(
      updatedUser,
      'tableData',
      'margin',
      'id',
      '__typename'
    );
    userInput.password = userInput.password.trim();
    if (!userInput.password) {
      userInput = omit(userInput, 'password');
    }
    try {
      await updateUser({
        variables: {
          id,
          userInput,
        },
      });
      toast.success(t('toasts.userUpdated'));
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const onSubmit = (updatedUser) => {
    onUserUpdate(updatedUser);
  };

  const contactSupport = `mailto: campaignformula@twins-digital.com?Subject=AdUp%20-%20${data?.user.username}%20-%20Support Mail`;

  if (loading) {
    return <CircularProgress color="primary" size={20} />;
  }

  return (
    <Box mt={1}>
      <Breadcrumb>
        <Typography color="textPrimary">{t('settings.settings')}</Typography>
      </Breadcrumb>
      <Typography variant="h6">{t('settings.settings')}</Typography>
      <Paper component={Box} p={4} mt={3}>
        <form id="settings" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">
                {t('settings.username')}
              </Typography>
              <TextField
                required
                variant="outlined"
                placeholder={t('settings.username')}
                {...register('username', {
                  required: true,
                })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">{t('settings.email')}</Typography>
              <TextField
                required
                variant="outlined"
                placeholder={t('settings.email')}
                error={!!errors?.email}
                helperText={errors?.email?.message}
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: t('validationErrors.invalidEmailFormat'),
                  },
                })}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">
                {t('settings.invoice')}
              </Typography>
              <TextField
                required
                variant="outlined"
                name="invoiceAddress"
                placeholder={t('settings.invoice')}
                {...register('invoiceAddress', {
                  required: true,
                })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">
                {t('settings.password')}
              </Typography>
              <TextField
                variant="outlined"
                placeholder={t('settings.password')}
                {...register('password')}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={3} />
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={12} md={3}>
              <Button fullWidth color="secondary">
                <a
                  href={contactSupport}
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography color="secondary" component="span" gutterBottom>
                    {t('settings.contact')}
                  </Typography>
                </a>
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                form="settings"
              >
                {t('settings.save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default Settings;
