import React from 'react';
import { Grid, Typography, Chip } from '@material-ui/core';

const AudienceTargetingListItemSelected = (props) => {
  const { name, children, onRemoveSubCategory, categoryIndex } = props;

  return (
    <>
      <Grid item xs={12}>
        <Typography>{name}</Typography>
        {children.map(({ name: childName, subcategoryIndex, id }) => (
          <Chip
            color="primary"
            size="small"
            label={childName}
            key={id}
            onDelete={() =>
              onRemoveSubCategory({ categoryIndex, subcategoryIndex })
            }
            style={{ marginRight: 5, marginBottom: 5 }}
          />
        ))}
      </Grid>
    </>
  );
};

export default AudienceTargetingListItemSelected;
