import React, { useCallback, memo, useEffect, useRef } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';

import Intensity from '../../../../../../components/Intensity';
import LinkedinAdSpecs from './components/LinkedinAdSpecs';
import LinkedinBudgetInput from './components/LinkedinBudgetInput';

const CampaignStep4 = (props) => {
  const { t } = useTranslation();
  const {
    intensity,
    comment,
    linkedinGoal,
    handleCampaignState,
    budget,
    isFormSubmitted,
    budgetRecommendation,
    refsMap,
  } = props;

  const campaignBudgetRef = useRef();

  useEffect(() => {
    refsMap.current['campaign-budget'] = campaignBudgetRef;
  }, [refsMap]);

  const handleCommentChange = useCallback(
    (event) => {
      handleCampaignState({ comment: event.target.value });
    },
    [handleCampaignState]
  );

  const handleIntensityChange = useCallback(
    (value) => {
      handleCampaignState({ intensity: value });
    },
    [handleCampaignState]
  );

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{t('createCampaign.stepFour')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {/* intensity */}
          <Grid item xs={12}>
            <Intensity
              intensity={intensity}
              setIntensity={handleIntensityChange}
            />
            <Box my={4} width="100%">
              <Divider />
            </Box>
          </Grid>

          {/* Budget */}
          <Grid ref={campaignBudgetRef} item xs={12}>
            <Typography variant="body1">
              {t('createCampaign.budget')}
            </Typography>
            <Box mt={2} width="100%" />
            <LinkedinBudgetInput
              budget={budget}
              budgetRecommendation={budgetRecommendation}
              handleCampaignState={handleCampaignState}
              isFormSubmitted={isFormSubmitted}
            />
            <Box my={4} width="100%">
              <Divider />
            </Box>
          </Grid>

          {/* comment */}
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('createCampaign.comment')}
            </Typography>
            <Box my={2} width="100%">
              <TextField
                multiline
                InputProps={{
                  style: {
                    height: 'auto',
                  },
                }}
                rows={4}
                value={comment}
                onChange={handleCommentChange}
                placeholder={t('createCampaign.commentPlaceholder')}
                variant="outlined"
                fullWidth
              />
            </Box>
          </Grid>

          {/* linkedin ad specs */}
          <Grid item xs={12}>
            <LinkedinAdSpecs linkedinGoal={linkedinGoal} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(CampaignStep4);
