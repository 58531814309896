import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Toolbar,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Avatar,
  makeStyles,
  Box,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { logout } from '../../services/JWT-Auth';
import { GET_USER } from '../../common/queries';

const useStyles = makeStyles((theme) => ({
  logoutPopover: {
    padding: '12px 20px',
  },
  logoStyle: {
    maxWidth: 144,
    maxHeight: 56,
  },
  logo: {
    marginRight: 16,
    marginLeft: 16,
  },
  headerToolbar: {
    display: 'flex',
    [theme.breakpoints.up(600)]: {
      minHeight: 75,
    },
    [theme.breakpoints.up(1280)]: {
      maxWidth: 'none',
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { data, loading } = useQuery(GET_USER);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  if (loading || !data?.user) {
    return <CircularProgress color="primary" size={20} />;
  }

  const { logoUrl, username } = data?.user;
  const logo = logoUrl || `${process.env.PUBLIC_URL}/logo.png`;
  const initials = username.split(' ').reduce((acc, str) => {
    return acc + str[0];
  }, '');

  return (
    <AppBar position="sticky" color="inherit">
      <Toolbar
        style={{ padding: 0 }}
        component={Container}
        classes={{
          gutters: classes.headerToolbar,
          regular: classes.headerToolbar,
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs="auto" className={classes.logo}>
            <img src={logo} alt="logo" className={classes.logoStyle} />
          </Grid>
          <Grid id="breadcrumb" item xs />
          <Grid item xs="auto">
            <Grid container alignItems="center">
              <Grid item>
                <Avatar>{initials}</Avatar>
              </Grid>
              <Box mr={1} />
              <Grid item>
                <div>{username}</div>
              </Grid>
              <Grid item>
                <IconButton focusRipple={false} onClick={handleMenuOpen}>
                  <KeyboardArrowDown />
                </IconButton>
              </Grid>
              <Box mr={1} />
            </Grid>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              style={{ top: 41 }}
            >
              <MenuItem
                className={classes.logoutPopover}
                onClick={handleLogout}
              >
                {t('layout.logout')}
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
