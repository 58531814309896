import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';

import AppRadioButton from '../../../../../../components/AppRadioButton';
import { StatesIcon, ZipIcon } from '../../../../../../components/CustomIcons';
import { CampaignGeoTargetingType } from '../../../../../../enums/campaign';
import RegionSelector from '../../../../../../components/GeoTargeting/components/RegionSelector';
import ZipSelector from '../../../../../../components/GeoTargeting/components/ZipSelector';

const CampaignStep3 = (props) => {
  const { t } = useTranslation();
  const { geoTargetingType, geoRegionIds, geoZipCodes, onMapLoaded } = props;
  const [zipsPreset, setZipsPreset] = useState(null);
  const [regionsPreset, setRegionsPreset] = useState(null);

  useEffect(() => {
    if (geoRegionIds.length && !regionsPreset) {
      setRegionsPreset(geoRegionIds);
    }
    if (geoZipCodes.length && !zipsPreset) {
      setZipsPreset(geoZipCodes);
    }
  }, [geoRegionIds, geoZipCodes, zipsPreset, regionsPreset]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{t('createCampaign.stepThree')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <AppRadioButton
                labelText={t('geoTargeting.regionTitle')}
                icon={StatesIcon}
                name="geoType"
                checked={geoTargetingType === CampaignGeoTargetingType.Land}
                value={CampaignGeoTargetingType.Land}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AppRadioButton
                labelText={`${t('geoTargeting.zipTitle')}`}
                icon={ZipIcon}
                name="geoType"
                checked={geoTargetingType === CampaignGeoTargetingType.Zip}
                value={CampaignGeoTargetingType.Zip}
                tooltip={t('tooltips.zipTitle')}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Box mt={3} />
          </Grid>
          <Grid container>
            {geoTargetingType === CampaignGeoTargetingType.Land && (
              <RegionSelector
                onMapLoaded={onMapLoaded}
                regionsPreset={regionsPreset}
                disabled
              />
            )}
            {geoTargetingType === CampaignGeoTargetingType.Zip && (
              <ZipSelector
                onMapLoaded={onMapLoaded}
                zipsPreset={zipsPreset}
                disabled
              />
            )}
          </Grid>
          <Box mt={2} width="100%" />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CampaignStep3;
