import {
  CampaignAdvertisingType,
  CampaignCreatives,
  CampaignGenders,
  CampaignSocialAdvertiserMaterialTypes,
  CampaignTypes,
} from '../../../../enums/campaign';
import {
  daysCount,
  getBudgetRecommendation,
  getPricePerUser,
  getReach,
} from '../calculation';

const detailsRequiredFieldsNames = [
  'advertiser-select',
  'campaign-name',
  'goal',
  'platform',
  'date-picker',
  'date-picker',
];

const targetingRequiredFieldsNames = [
  'devices',
  'gender-age',
  'gender-age',
  'devices',
  'landing-page',
];

export const getDefaultGeoTargetingState = () => ({
  geoTargetingType: 0,
  reach: 0,
  geoZipCodes: [],
  geoRegionIds: [],
  geoRadiuses: [],
  geoPolygons: [],
  geoCategoriesIds: [],
  geoPolygonText: null,
  geoRadiusText: null,
  geoRadiusMeters: null,
});

const defaultCampaignFormValue = {
  campaignDetails: {
    type: CampaignTypes.Banner,
    goal: 0,
    isDraft: false,
    name: '',
    startDate: null,
    endDate: null,
    advertiser: '',
    socialPlatform: [],
    instagramProfile: '',
    facebookProfile: '',
  },
  campaignTargeting: {
    devices: ['tablet', 'mobile', 'desktop'],
    gender: [CampaignGenders.Male, CampaignGenders.Female],
    ages: new Array(6).fill(1),
    landingPage: '',
    idkLandingPageYet: false,
    targetGroups: [],
    websitesTargeting: undefined,
    websitesIncludeList: [],
  },
  geoTargeting: { ...getDefaultGeoTargetingState() },
  campaignPricing: {
    advertiserMaterial: 'own',
    advertiserMaterialType: undefined,
    intensity: 2,
    comment: '',
    budget: undefined,
    contactPersonEmail: undefined,
  },
};

export const campaignToFormState = (
  campaign,
  type = CampaignTypes.Banner,
  advertisers = []
) => {
  if (!campaign) {
    return {
      ...defaultCampaignFormValue,
      campaignDetails: {
        ...defaultCampaignFormValue.campaignDetails,
        goal: type !== CampaignTypes.Banner ? 0 : null,
        socialPlatform:
          type === CampaignTypes.Social ? ['instagram', 'facebook'] : [],
        advertisingType:
          type === CampaignTypes.Banner
            ? CampaignAdvertisingType.TKP
            : undefined,
        type,
      },
      campaignTargeting: {
        ...defaultCampaignFormValue.campaignTargeting,
        devices:
          type === CampaignTypes.Video
            ? ['tablet', 'mobile', 'desktop', 'smartTv']
            : ['tablet', 'mobile', 'desktop'],
        websitesTargeting: type === CampaignTypes.Banner ? 1 : undefined,
      },
      campaignPricing: {
        ...defaultCampaignFormValue.campaignPricing,
        socialAdvertiserMaterialType:
          type === CampaignTypes.Social
            ? CampaignSocialAdvertiserMaterialTypes.FeedStatic
            : '',
      },
    };
  }
  const {
    goal,
    isDraft,
    name,
    startDate,
    endDate,
    devices,
    gender,
    ages,
    landingPage,
    idkLandingPageYet,
    targetGroups,
    websitesTargeting,
    websitesIncludeCategorisedList,
    customWebsitesIncludeList,
    geoTargetingType,
    geoZipCodes,
    geoRegionIds,
    geoRadiuses,
    geoPolygons,
    creatives,
    creativesType,
    intensity,
    budget,
    comment,
    advertiserId,
    geoPolygonText,
    geoRadiusText,
    geoRadiusMeters,
    socialPlatform,
    facebookProfile,
    instagramProfile,
    socialAdvertiserMaterialType,
    geoCategoriesIds,
    advertisingType,
    contactPersonEmail,
  } = campaign;
  const advertiser = advertisers.find(({ id }) => id === advertiserId);
  return {
    campaignDetails: {
      type: campaign.campaignType || type,
      socialPlatform,
      facebookProfile,
      instagramProfile,
      goal,
      isDraft,
      name,
      startDate,
      endDate,
      advertiser,
      advertisingType,
    },
    campaignTargeting: {
      devices,
      gender,
      ages,
      landingPage,
      idkLandingPageYet,
      targetGroups,
      websitesTargeting,
      websitesIncludeList: websitesIncludeCategorisedList,
      customWebsitesIncludeList,
    },
    geoTargeting: {
      geoTargetingType,
      reach: 0,
      geoZipCodes,
      geoRegionIds,
      geoRadiuses,
      geoPolygons,
      geoPolygonText,
      geoRadiusText,
      geoRadiusMeters,
      geoCategoriesIds,
    },
    campaignPricing: {
      advertiserMaterial: creatives,
      advertiserMaterialType: creativesType,
      socialAdvertiserMaterialType,
      intensity,
      comment,
      budget,
      contactPersonEmail,
    },
  };
};

// validation helpers

const validateBudget = (budget, budgetRecommendation) => {
  if (!budget || budget < 2000) {
    return false;
  }
  if (budget > 100000) {
    return false;
  }
  return !(budgetRecommendation && budget > budgetRecommendation * 10);
};

export const checkIsFirstStepCompleted = (campaignDetails, type) => {
  const requiredFields = [
    !!campaignDetails.advertiser,
    !!campaignDetails.name,
    type === CampaignTypes.Banner ||
      !!campaignDetails.goal ||
      campaignDetails.goal === 0,
    type === CampaignTypes.Banner ||
      type === CampaignTypes.Video ||
      !!campaignDetails.socialPlatform.length,
    !!campaignDetails.startDate,
    !!campaignDetails.endDate,
  ];
  let errorFieldIndex;
  const isValid = requiredFields.every((field, index) => {
    errorFieldIndex = index;
    return field;
  });

  return {
    isValid,
    firstInvalidFieldName: detailsRequiredFieldsNames[errorFieldIndex],
  };
};

export const checkIsSecondStepCompleted = (campaignTargeting, type) => {
  const requiredFields = [
    !!campaignTargeting.devices.length,
    !!campaignTargeting.gender.length,
    campaignTargeting.ages.some((age) => age),
    type === CampaignTypes.Video ||
      type === CampaignTypes.Social ||
      !!campaignTargeting.websitesTargeting ||
      campaignTargeting.websitesTargeting === 0,
    campaignTargeting.landingPage || campaignTargeting.idkLandingPageYet,
  ];
  let errorFieldIndex;
  const isValid = requiredFields.every((field, index) => {
    errorFieldIndex = index;
    return field;
  });
  return {
    isValid,
    firstInvalidFieldName: targetingRequiredFieldsNames[errorFieldIndex],
  };
};

export const checkIsThirdStepCompleted = (geoTargeting) => {
  const requiredFields = [
    !!geoTargeting.geoZipCodes?.length,
    !!geoTargeting.geoRegionIds?.length,
    !!geoTargeting.geoRadiuses?.length,
    !!geoTargeting.geoPolygons?.length,
    !!geoTargeting.geoPolygonText?.length,
    !!geoTargeting.geoRadiusText?.length,
    !!geoTargeting.geoCategoriesIds?.length,
  ];
  return {
    isValid: requiredFields.some((field) => field),
    firstInvalidFieldName: 'geo-targeting',
  };
};

export const checkIsFourthStepCompleted = (
  campaignPricing,
  type,
  recommendedBudget
) => {
  const requiredFields = [
    !!campaignPricing.intensity,
    validateBudget(campaignPricing.budget, recommendedBudget),
    type === CampaignTypes.Social ||
      type === CampaignTypes.Video ||
      !!campaignPricing.advertiserMaterial,
    campaignPricing.advertiserMaterial !== CampaignCreatives.Order ||
      campaignPricing.advertiserMaterialType,
  ];
  return {
    isValid: requiredFields.every((field) => field),
    firstInvalidFieldName: 'campaign-budget',
  };
};

export const checkCampaignFormValidity = (formValue, agency, savedCampaign) => {
  const { campaignDetails, campaignTargeting, geoTargeting, campaignPricing } =
    formValue;

  const campaignGoal =
    campaignDetails.advertisingType || CampaignAdvertisingType.TKP;
  const calculatedPricePerUser = getPricePerUser({
    campaignValue: formValue,
    agency,
  });

  const savedPricePerUser = savedCampaign?.pricesPerUser;
  const pricePerUser = savedPricePerUser
    ? savedPricePerUser[campaignGoal]
    : calculatedPricePerUser;

  const reach = getReach(formValue);
  const days = daysCount(campaignDetails.startDate, campaignDetails.endDate);
  const recommendedBudget = getBudgetRecommendation(
    reach,
    campaignPricing.intensity,
    pricePerUser.price,
    days,
    campaignDetails.advertisingType
  );

  // check required fields
  const validationFields = [
    // campaignDetails
    checkIsFirstStepCompleted(campaignDetails, campaignDetails.type),

    // campaignTargeting,
    checkIsSecondStepCompleted(campaignTargeting, campaignDetails.type),

    // geoTargeting ?
    checkIsThirdStepCompleted(geoTargeting),

    // campaignPricing
    checkIsFourthStepCompleted(
      campaignPricing,
      campaignDetails.type,
      recommendedBudget
    ),
  ];

  let firstInvalidFieldName;
  const isValid = validationFields.every(
    ({ isValid: isFieldValid, firstInvalidFieldName: invalidFieldName }) => {
      firstInvalidFieldName = invalidFieldName;
      return isFieldValid;
    }
  );

  return { isValid, firstInvalidFieldName };
};

export const toggleArrayItem = (arr, item) => {
  const index = arr.indexOf(item);
  if (index === -1) {
    arr.push(item);
  } else {
    arr.splice(index, 1);
  }
  return [...arr];
};
