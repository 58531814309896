import React, { memo } from 'react';
import { FormControlLabel, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControlLabel-label': {
      marginBottom: '-3.5px',
    },
    '&.error .MuiFormControlLabel-label': {
      color: theme.palette.error.main,
    },
    '&.error .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },
  error: {},
}));

const AppLabeledCheckbox = (props) => {
  const { name, label, checked, value, error, onChange } = props;

  const classes = useStyles();

  return (
    <FormControlLabel
      className={classNames(classes.root, {
        error,
      })}
      control={
        <Checkbox
          color="primary"
          checked={checked}
          name={name}
          value={value}
          onChange={onChange}
        />
      }
      label={label}
    />
  );
};

export default memo(AppLabeledCheckbox);
