import gql from 'graphql-tag';

export const GET_ADVERTISERS = gql`
  query getAdvertisersCampaignsPage {
    advertisers {
      id
      name
      agencyId
    }
  }
`;

export const GET_USER = gql`
  query getUserCampaignsPage {
    user {
      email
      additionalContactField
      staticPrice {
        TKP
        CPC
      }
    }
  }
`;

export const GET_ALL_CAMPAIGNS = gql`
  query getCampaignsPage($filter: GetCampaignsFilterInput) {
    campaigns(filter: $filter) {
      campaigns {
        id
        name
        creativeStatus
        status
        reportLink
        sequence
        endDate
        campaignType
        contactPersonEmail
      }
      count
    }
  }
`;

export const GET_ALL_USERS = gql`
  query getCampaignUsers($usersFilter: UsersFilter) {
    users(usersFilter: $usersFilter) {
      users {
        id
        role
        username
      }
    }
  }
`;

export const REMOVE_CAMPAIGN = gql`
  mutation removeCampaign($id: ID!) {
    removeCampaign(campaignId: $id) {
      id
      name
    }
  }
`;

export const COPY_CAMPAIGN = gql`
  mutation copyCampaign($id: ID!) {
    copyCampaign(campaignId: $id) {
      id
    }
  }
`;
