import React from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Check } from '@material-ui/icons';

import WebsitesGeneratorListSelectedItem from './components/WebsitesGeneratorListSelectedItem';
import WebsitesGeneratorCustomListSelectedItem from './components/WebsitesGeneratorCustomListSelectedItem';

const WebsitesGeneratorListSelected = (props) => {
  const {
    checked,
    categories = [],
    onSelectCategory,
    onSelectCategoryWebsite,
    customWebsitesSelected,
    removeCustomWebsite,
  } = props;
  const { t } = useTranslation();
  return (
    <List disablePadding>
      <ListItem divider selected>
        <ListItemIcon>
          <Check style={{ color: checked ? green[500] : 'inherit' }} />
        </ListItemIcon>
        <ListItemText primary={t('websitesGenerator.selected')} />
      </ListItem>

      {categories.map((category) => (
        <WebsitesGeneratorListSelectedItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...category}
          onSelectCategory={onSelectCategory}
          onSelectCategoryWebsite={onSelectCategoryWebsite}
          key={category}
        />
      ))}

      {!!customWebsitesSelected?.length && (
        <WebsitesGeneratorCustomListSelectedItem
          removeCustomWebsite={removeCustomWebsite}
          websites={customWebsitesSelected}
        />
      )}
    </List>
  );
};

export default WebsitesGeneratorListSelected;
