import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  List,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Check, ChevronLeft, ExpandLess } from '@material-ui/icons';

const WebsitesCustomGeneratorListItem = (props) => {
  const {
    websites,
    onSelectCustomWebsite,
    handleCheckbox,
    checked,
    onSelectCustomWebsites,
  } = props;
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleCollapsed = () => setIsCollapsed(!isCollapsed);
  return (
    <>
      <ListItem divider button component="li" onClick={handleCollapsed}>
        <ListItemText primary={t('websitesGenerator.students')} />
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => {
              handleCheckbox();
              onSelectCustomWebsites(websites, checked);
            }}
          >
            <Check style={{ color: checked ? green[500] : 'inherit' }} />
          </IconButton>

          <IconButton onClick={handleCollapsed}>
            {isCollapsed ? <ExpandLess /> : <ChevronLeft />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      {isCollapsed && (
        <List dense disablePadding>
          {websites.map((website, index) => (
            <ListItem divider key={website.name}>
              <ListItemText secondary={website.name} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => onSelectCustomWebsite(website, index)}
                >
                  <Check
                    style={{ color: website.checked ? green[500] : 'inherit' }}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default WebsitesCustomGeneratorListItem;
