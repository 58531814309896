import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import { AppCase, AppSwitch } from '../../../../../../components/AppSwitchCase';
import { CampaignSocialAdvertiserMaterialTypes } from '../../../../../../enums/campaign';

const useStyles = makeStyles({
  wrapper: {
    minHeight: '450px',
  },
  img: {
    display: 'block',
    maxWidth: '200px',
    width: '100%',
  },
});

const InstagramViewDetails = (props) => {
  const {
    socialAdvertiserMaterialType = CampaignSocialAdvertiserMaterialTypes.FeedStatic,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const feedStaticSpecs = t('socials.instagram.feed.static.specs', {
    returnObjects: true,
  });
  const feedVideoSpecs = t('socials.instagram.feed.video.specs', {
    returnObjects: true,
  });
  const storyStaticSpecs = t('socials.instagram.story.static.specs', {
    returnObjects: true,
  });
  const storyVideoSpecs = t('socials.instagram.story.video.specs', {
    returnObjects: true,
  });

  return (
    <AppSwitch test={socialAdvertiserMaterialType}>
      <AppCase value={CampaignSocialAdvertiserMaterialTypes.FeedStatic}>
        <Grid container wrap="nowrap" spacing={3} className={classes.wrapper}>
          <Grid item xs={4} sm={3}>
            <img
              className={classes.img}
              src="/campaign/instagram-feed-static.png"
              alt="instagram FeedStatic"
            />
          </Grid>
          <Grid item xs={8} sm={9}>
            <Typography variant="h5" gutterBottom>
              {t('socials.instagram.feed.static.title')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('socials.instagram.feed.static.text')}
            </Typography>
            <Box m={3} />
            <Typography variant="h5" gutterBottom>
              {t('socials.instagram.feed.static.specsTitle')}
            </Typography>
            {feedStaticSpecs.map((i) => (
              <Typography variant="body2" key={i} gutterBottom>
                {i}
              </Typography>
            ))}
          </Grid>
        </Grid>
        {/* <h1>instagram FeedStatic</h1> */}
      </AppCase>
      <AppCase value={CampaignSocialAdvertiserMaterialTypes.FeedVideo}>
        <Grid container wrap="nowrap" spacing={3} className={classes.wrapper}>
          <Grid item xs={4} sm={3}>
            <img
              className={classes.img}
              src="/campaign/instagram-feed-video.png"
              alt="instagram FeedVideo"
            />
          </Grid>
          <Grid item xs={8} sm={9}>
            <Typography variant="h5" gutterBottom>
              {t('socials.instagram.feed.video.title')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('socials.instagram.feed.video.text')}
            </Typography>
            <Box m={3} />
            <Typography variant="h5" gutterBottom>
              {t('socials.instagram.feed.video.specsTitle')}
            </Typography>
            {feedVideoSpecs.map((i) => (
              <Typography variant="body2" key={i} gutterBottom>
                {i}
              </Typography>
            ))}
          </Grid>
        </Grid>
        {/* <h1>instagram FeedVideo</h1> */}
      </AppCase>
      <AppCase value={CampaignSocialAdvertiserMaterialTypes.StoryStatic}>
        <Grid container wrap="nowrap" spacing={3} className={classes.wrapper}>
          <Grid item xs={4} sm={3}>
            <img
              className={classes.img}
              src="/campaign/instagram-story-static.png"
              alt="instagram StoryStatic"
            />
          </Grid>
          <Grid item xs={8} sm={9}>
            <Typography variant="h5" gutterBottom>
              {t('socials.instagram.story.static.title')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('socials.instagram.story.static.text')}
            </Typography>
            <Box m={3} />
            <Typography variant="h5" gutterBottom>
              {t('socials.instagram.story.static.specsTitle')}
            </Typography>
            {storyStaticSpecs.map((i) => (
              <Typography variant="body2" key={i} gutterBottom>
                {i}
              </Typography>
            ))}
          </Grid>
        </Grid>
        {/* <h1>instagram StoryStatic</h1> */}
      </AppCase>
      <AppCase value={CampaignSocialAdvertiserMaterialTypes.StoryVideo}>
        <Grid container wrap="nowrap" spacing={3} className={classes.wrapper}>
          <Grid item xs={4} sm={3}>
            <img
              className={classes.img}
              src="/campaign/instagram-story-video.png"
              alt="instagram StoryVideo"
            />
          </Grid>
          <Grid item xs={8} sm={9}>
            <Typography variant="h5" gutterBottom>
              {t('socials.instagram.story.video.title')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('socials.instagram.story.video.text')}
            </Typography>
            <Box m={3} />
            <Typography variant="h5" gutterBottom>
              {t('socials.instagram.story.video.specsTitle')}
            </Typography>
            {storyVideoSpecs.map((i) => (
              <Typography variant="body2" key={i} gutterBottom>
                {i}
              </Typography>
            ))}
          </Grid>
        </Grid>
        {/* <h1>instagram StoryVideo</h1> */}
      </AppCase>
    </AppSwitch>
  );
};

export default InstagramViewDetails;
