import React, { memo } from 'react';
import { Grid, MenuItem, Select, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const GeoCategoryFilters = (props) => {
  const {
    onCategoryFilterChanged,
    onTextFilterChanged,
    textFilter,
    categoryFilter,
    allCategories,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={6}>
        <Select
          fullWidth
          onChange={(e) => {
            onCategoryFilterChanged(
              e.target.value === 'all' ? '' : e.target.value
            );
          }}
          variant="outlined"
          value={categoryFilter === '' ? 'all' : categoryFilter}
        >
          <MenuItem value="all" key="all">
            {t('geoTargeting.all')}
          </MenuItem>
          {allCategories?.map((category) => (
            <MenuItem value={category} key={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs>
        <TextField
          variant="outlined"
          fullWidth
          placeholder={t('geoTargeting.city/zip')}
          value={textFilter}
          onChange={(e) => {
            onTextFilterChanged(e.target.value);
          }}
        />
      </Grid>
    </>
  );
};

export default memo(GeoCategoryFilters);
