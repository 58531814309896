import React, { memo } from 'react';
import { FormGroup, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import AppLabeledCheckbox from '../../../../../../../../components/AppLabeledCheckbox';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControlLabel-root': {
      minWidth: '100px',
    },
  },
  error: {},
}));

const AgeTargeting = (props) => {
  const { handleAgeChange, ages, isFormSubmitted } = props;
  const error = isFormSubmitted && !ages.some((age) => age);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="body1" gutterBottom>
        {t('createCampaign.demographicTargeting')}
      </Typography>
      <FormGroup>
        <Grid
          container
          className={classNames(classes.root, {
            error,
          })}
        >
          <Grid item xs={12}>
            <AppLabeledCheckbox
              checked={Boolean(ages[0])}
              label="18-24"
              name="age1824"
              value={1}
              onChange={handleAgeChange}
              error={error}
            />
            <AppLabeledCheckbox
              checked={Boolean(ages[1])}
              label="25-34"
              name="age2534"
              value={1}
              onChange={handleAgeChange}
              error={error}
            />
            <AppLabeledCheckbox
              checked={Boolean(ages[2])}
              label="35-54"
              name="age3554"
              value={1}
              onChange={handleAgeChange}
              error={error}
            />
            <AppLabeledCheckbox
              checked={Boolean(ages[3])}
              label="55+"
              name="age55"
              value={1}
              onChange={handleAgeChange}
              error={error}
            />
          </Grid>
        </Grid>
      </FormGroup>
    </>
  );
};

export default memo(AgeTargeting);
