import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import Map from '../../../../components/GeoTargeting/components/Map';
import Polygon from '../../../../components/GeoTargeting/components/Polygon';
import {
  GET_ZIPS_BY_NAME,
  GET_ZIPS_POINTS,
  UPDATE_ZIP,
} from '../../../../components/GeoTargeting/components/ZipSelector/queries';
import SpinnerBackdrop from '../../../../components/BackdropSpinner';

const ZipCodesSettings = () => {
  const { refetch: fetchZip } = useQuery(GET_ZIPS_BY_NAME, { skip: true });
  const { refetch: fetchZipPoints } = useQuery(GET_ZIPS_POINTS, { skip: true });
  const [updateZip] = useMutation(UPDATE_ZIP);
  const [availableZip, setAvailableZip] = useState(undefined);
  const [optionalCoordinates, setOptionalCoordinates] = useState([]);
  const [zipId, setZipId] = useState(undefined);
  const [zipToUpdate, setZipToUpdate] = useState(undefined);
  const [selectedPointsArray, setSelectedPointsArray] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm();
  const {
    register: newZipRegister,
    handleSubmit: newZipSubmit,
    reset: resetNewZipForm,
  } = useForm();

  const uploadZip = async () => {
    try {
      setLoading(true);
      const selectedPoints = optionalCoordinates[selectedPointsArray].map(
        ([lng, lat]) => {
          return { lng, lat };
        }
      );
      const updatedZip = await updateZip({
        variables: {
          zipId,
          zipInput: { ...zipToUpdate, points: selectedPoints },
        },
      });
      setLoading(false);
      resetNewZipForm();
      setOptionalCoordinates([]);
      toast.success(`zip ${updatedZip.name} updated`);
    } catch ({ message }) {
      setLoading(false);
      toast.error(message);
    }
  };

  const onSubmit = async ({ zipName: name }) => {
    setAvailableZip(undefined);
    try {
      setLoading(true);
      const { data: zip } = await fetchZip({ name });
      if (!zip?.zipsByName.length) {
        setLoading(false);
        return toast.error('zip not found');
      }
      const { data: zipPoints } = await fetchZipPoints({
        ids: [zip.zipsByName[0].id],
      });
      if (!zipPoints?.zipsWithPoints.length) {
        return toast.error('zip not found');
      }
      setLoading(false);
      toast.success(`zip ${name} founded in DB`);
      setAvailableZip(zipPoints.zipsWithPoints[0]);
    } catch ({ message }) {
      setLoading(false);
      toast.error(message);
    }
    return false;
  };

  const onAddZipSubmit = async ({ zipName, population }) => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search.php?q=${zipName}&polygon_geojson=1&format=json`
      );
      const data = await response.json();
      const zip = data.length ? data[0] : {};
      const { data: zipByName } = await fetchZip({ name: zipName });
      const zipIdByName = zipByName?.zipsByName.length
        ? zipByName.zipsByName[0].id
        : undefined;
      setZipId(zipIdByName);
      const ResPolygonType = data.filter((res) => {
        const correctTypes = ['Polygon', 'MultiPolygon'];
        return res?.geojson && correctTypes.includes(res.geojson.type);
      });
      const coordinates = ResPolygonType.map((res) => {
        if (res.geojson.type === 'MultiPolygon') {
          return res.geojson.coordinates.sort(
            (a, b) => b[0].length - a[0].length
          )[0][0];
        }
        return res.geojson.coordinates[0];
      }).sort((a, b) => b.length - a.length);
      setOptionalCoordinates(coordinates);

      const newZipToUpdate = {
        lat: Number(zip.lat),
        lon: Number(zip.lon),
        region_name: zip.display_name.split(',')[1].trim(),
        name: zipName,
        d1: Number(zipName[0]),
        d2: Number(zipName[1]),
        d3: Number(zipName[2]),
        d4: Number(zipName[3]),
        d5: Number(zipName[4]),
        id: String(zip.place_id),
        population: Number(population),
        location: {
          type: 'Point',
          coordinates: [Number(zip.lon), Number(zip.lat)],
        },
      };
      setZipToUpdate(newZipToUpdate);
      setLoading(false);
    } catch ({ message }) {
      setLoading(false);
      toast.error(message);
    }
  };

  return (
    <>
      <SpinnerBackdrop open={loading} />
      <Paper component={Box} p={4} mt={3}>
        <form id="settings" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                check Zip availability
              </Typography>
              <TextField
                required
                variant="outlined"
                error={!!errors?.zipName}
                placeholder="zip name"
                {...register('zipName', {
                  required: true,
                })}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={3} />
          <Grid container spacing={3} justify="flex-start">
            <Grid item xs={12} md={3}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                form="settings"
              >
                Check
              </Button>
            </Grid>
          </Grid>
        </form>
        {availableZip && (
          <Grid container spacing={3} justify="flex-start">
            <Grid item xs={6} md={6}>
              <table>
                {Object.keys(availableZip).map((key) => {
                  if (key === 'points') return undefined;
                  return (
                    <tr key={key}>
                      <th>{key}</th>
                      <td>{String(availableZip[key])}</td>
                    </tr>
                  );
                })}
              </table>
            </Grid>
            <Grid item xs={6} md={6}>
              <Map
                center={{ lat: availableZip.lat, lng: availableZip.lon }}
                zoom={14}
                options={{
                  mapTypeControl: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                  disableDoubleClickZoom: true,
                  draggable: true,
                }}
              >
                <Polygon options={{}} path={availableZip.points} />
                ))
              </Map>
            </Grid>
          </Grid>
        )}
      </Paper>
      <Paper component={Box} p={4} mt={3}>
        <form id="settingsZipUpload" onSubmit={newZipSubmit(onAddZipSubmit)}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">add zip by name</Typography>
              <TextField
                required
                variant="outlined"
                placeholder="zip name"
                {...newZipRegister('zipName', {
                  required: true,
                })}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">add population</Typography>
              <TextField
                required
                variant="outlined"
                type="number"
                placeholder="population"
                {...newZipRegister('population', {
                  required: true,
                })}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={3} />
          {!!optionalCoordinates.length && (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {optionalCoordinates.map((coords, i) => {
                  return (
                    <Button
                      fullWidth
                      key={coords}
                      style={{ marginBottom: 5 }}
                      variant={
                        selectedPointsArray === i ? 'contained' : 'outlined'
                      }
                      color={
                        selectedPointsArray === i ? 'primary' : 'secondary'
                      }
                      onClick={() => {
                        setSelectedPointsArray(i);
                      }}
                      type="button"
                    >
                      #{i + 1} Available Points: {coords.length}
                    </Button>
                  );
                })}
              </Grid>
              {selectedPointsArray >= 0 && (
                <Grid item xs={6} md={6}>
                  <Map
                    center={{ lat: zipToUpdate.lat, lng: zipToUpdate.lon }}
                    zoom={14}
                    options={{
                      mapTypeControl: false,
                      streetViewControl: false,
                      fullscreenControl: false,
                      disableDoubleClickZoom: true,
                      draggable: true,
                    }}
                  >
                    <Polygon
                      options={{}}
                      path={optionalCoordinates[selectedPointsArray].map(
                        ([lng, lat]) => {
                          return { lng, lat };
                        }
                      )}
                    />
                    ))
                  </Map>
                </Grid>
              )}
            </Grid>
          )}
          <Box mt={3} />
          <Grid container spacing={3} justify="flex-start">
            <Grid item xs={4} md={4}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                form="settingsZipUpload"
              >
                get zip data
              </Button>
            </Grid>
            <Grid item xs={4} md={4}>
              <Button
                fullWidth
                color="primary"
                disabled={!selectedPointsArray && selectedPointsArray !== 0}
                variant="contained"
                onClick={uploadZip}
                form="settingsZipUpload"
              >
                upload/update zip
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default ZipCodesSettings;
