import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { LinkedinCampaignGoal } from '../../../../../../../../enums/campaign';
import {
  AppCase,
  AppSwitch,
} from '../../../../../../../../components/AppSwitchCase';

const useStyles = makeStyles({
  wrapper: {
    minHeight: '450px',
  },
  img: {
    display: 'block',
    maxWidth: '200px',
    width: '100%',
  },
});

const LinkedinAdSpecs = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { linkedinGoal } = props;

  const linkedinSpecs = t('linkedinCampaign.adSpecs', { returnObjects: true });
  const linkedinAdditionalInfo = t('linkedinCampaign.additionalInfo', {
    returnObjects: true,
  });

  return (
    <Grid container wrap="nowrap" spacing={3} className={classes.wrapper}>
      <AppSwitch test={linkedinGoal}>
        <AppCase value={LinkedinCampaignGoal.Leads}>
          <Grid container item xs={6} sm={6}>
            <Grid item md={12} lg={6}>
              <img
                className={classes.img}
                src="/campaign/linkedin-leadgen-1.png"
                alt="linkedin ads"
              />
            </Grid>
            <Grid item md={12} lg={6}>
              <img
                className={classes.img}
                src="/campaign/linkedin-leadgen-2.png"
                alt="linkedin ads"
              />
            </Grid>
          </Grid>
          <Grid item xs={8} sm={9}>
            <Typography variant="h5" gutterBottom>
              {t('linkedinCampaign.adSpecsTitle')}
            </Typography>
            {linkedinSpecs.map((i) => (
              <Typography variant="body2" key={i} gutterBottom>
                {i}
              </Typography>
            ))}
            <br />
            <Typography variant="h5" gutterBottom>
              {t('linkedinCampaign.additionalInfoTitle')}
            </Typography>
            {linkedinAdditionalInfo.map((i) => (
              <Typography variant="body2" key={i} gutterBottom>
                <li> {i}</li>
              </Typography>
            ))}
          </Grid>
        </AppCase>
        <AppCase value="default">
          <Grid item xs={4} sm={3}>
            <img
              className={classes.img}
              src="/campaign/linkedin-ad.png"
              alt="linkedin ads"
            />
          </Grid>
          <Grid item xs={8} sm={9}>
            <Typography variant="h5" gutterBottom>
              {t('linkedinCampaign.adSpecsTitle')}
            </Typography>
            {linkedinSpecs.map((i) => (
              <Typography variant="body2" key={i} gutterBottom>
                {i}
              </Typography>
            ))}
          </Grid>
        </AppCase>
      </AppSwitch>
    </Grid>
  );
};

export default memo(LinkedinAdSpecs);
