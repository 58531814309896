export const malesPercentage = 0.491869918699187;
export const femalesPercentage = 0.508130081300813;

export const devisesUsers = {
  desktop: {
    banner: 0.5347,
    video: 0.1695,
    social: 0.0315,
  },
  mobile: {
    banner: 0.421,
    video: 0.5602,
    social: 0.9185,
  },
  tablet: {
    banner: 0.0443,
    video: 0.1789,
    social: 0.05,
  },
  smartTv: {
    video: 0.1789,
  },
};

export const getDesktopUsers = (type) => {
  return devisesUsers.desktop[type] || devisesUsers.desktop.banner;
};

export const getMobileUsers = (type) => {
  return devisesUsers.mobile[type] || devisesUsers.desktop.mobile;
};

export const getTabletUsers = (type) => {
  return devisesUsers.tablet[type] || devisesUsers.desktop.tablet;
};

export const getSmartTv = (type) => {
  return devisesUsers.smartTv[type] || 0;
};

export const ageCategories = [
  0.09954484978490464, 0.14881957716787472, 0.14147556492966015,
  0.17490075140357841, 0.16973063472430489, 0.2655286219896772,
];

export const tgOverlap = [0.8, 0.6, 0.4, 0.15, 0.1];
export const minTgOverlap = 0.1;
