import React from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { turquiseColor, redColor, orangeColor, purpleColor } from '../../theme';

// todo user theme colors from pallete;
const StyledContainer = styled(ToastContainer).attrs({})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    background-color: ${purpleColor};
  }
  .Toastify__toast--error {
    background-color: ${redColor};
  }
  .Toastify__toast--warning {
    background-color: ${orangeColor};
  }
  .Toastify__toast--success {
    background-color: ${turquiseColor};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

const StyledToastContainer = () => <StyledContainer className="mt-5" />;

export default StyledToastContainer;
