import gql from 'graphql-tag';

export const GET_CAMPAIGN_PAGE_DATA = gql`
  query campaignViewPageData($id: ID) {
    advertisers {
      id
      name
      margin
      agencyId
    }
    user {
      username
      margin
      additionalContactField
      staticPrice {
        TKP
        CPC
      }
      id
    }
    campaign(id: $id) {
      advertiserId
      campaignType
      name
      goal
      ages
      comment
      devices
      startDate
      endDate
      gender
      targetGroups
      landingPage
      idkLandingPageYet
      websitesTargeting
      websitesIncludeCategorisedList {
        id
        websites
        category
      }
      customWebsitesIncludeList {
        name
        categories
        relevance
        isForStudent
        isForGraduate
        isForProspectiveStudents
        isForTrainee
        isForPupils
        isForForeignAffinity
        isAdsAvailable
      }
      agency {
        staticPrice {
          TKP
          CPC
        }
        username
        margin
        id
      }
      status
      budget
      creatives
      creativesType
      creativeStatus
      status
      reportLink
      intensity
      geoTargetingType
      geoZipCodes
      geoRegionIds
      geoCategoriesIds
      geoPolygonText
      geoRadiusText
      geoRadiusMeters
      geoPolygons {
        lat
        lng
      }
      geoRadiuses {
        lat
        lon
        radius
      }
      socialPlatform
      instagramProfile
      facebookProfile
      socialAdvertiserMaterialType
      advertisingType
      contactPersonEmail
      pricesPerUser
      pricing
      advertiserMargin
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign(
    $campaignInput: CreateCampaignInput!
    $additionalInfo: CampaignAdditionalInfoInput!
  ) {
    createCampaign(
      campaignInput: $campaignInput
      additionalInfo: $additionalInfo
    ) {
      id
      name
    }
  }
`;

export const UPDATE_CAMPAIGN_STATUSES = gql`
  mutation updateCampaignStatuses(
    $id: ID!
    $campaignInput: UpdateCampaignStatusesInput!
  ) {
    updateCampaignStatuses(campaignId: $id, campaignInput: $campaignInput) {
      status
      reportLink
      creativeStatus
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign(
    $campaignId: ID!
    $campaignInput: UpdateCampaignInput!
    $additionalInfo: CampaignAdditionalInfoInput!
  ) {
    updateCampaign(
      campaignId: $campaignId
      campaignInput: $campaignInput
      additionalInfo: $additionalInfo
    ) {
      id
      name
    }
  }
`;
