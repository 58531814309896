import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { CampaignStatus, CreativeStatus } from '../../../../enums/campaign';

const AdminCampaignActions = (props) => {
  const { onChange, report, creativeStatus, campaignStatus } = props;
  const [reportState, setReportState] = useState(report);
  const [statusState, setStatusState] = useState(campaignStatus);
  const [creativeStatusState, setCreativeStatusState] =
    useState(creativeStatus);
  const { t } = useTranslation();

  useEffect(() => {
    onChange({
      status: statusState,
      reportLink: reportState,
      creativeStatus: creativeStatusState,
    });
  }, [reportState, statusState, creativeStatusState]);

  const statuses = Object.values(CampaignStatus);
  const creativeStatuses = Object.values(CreativeStatus);

  const handleChangeReport = (e) => {
    const { value } = e.target;
    setReportState(value);
  };

  const handleChangeStatus = (e) => {
    const { value } = e.target;
    setStatusState(value);
  };

  const handleChangeCreativeStatus = (e) => {
    const { value } = e.target;
    setCreativeStatusState(value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="report"
          label={t('campaigns.report')}
          variant="outlined"
          value={reportState}
          onChange={handleChangeReport}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{t('campaigns.status')}</InputLabel>
          <Select
            onChange={handleChangeStatus}
            value={statusState}
            label={t('campaigns.status')}
            name="status"
          >
            {statuses.map((item) => (
              <MenuItem value={item} key={item}>
                {t(`campaignStatuses.${item}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{t('campaigns.creative_status')}</InputLabel>
          <Select
            onChange={handleChangeCreativeStatus}
            value={creativeStatusState}
            name="creative-status"
            label={t('campaigns.creative_status')}
          >
            {creativeStatuses.map((item) => (
              <MenuItem value={item} key={item}>
                {t(`creativeStatuses.${item}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default AdminCampaignActions;
