import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, Button, Typography } from '@material-ui/core';

import WebsitesGeneratorForm from '../WebsitesGeneratorForm';

const WebsitesGeneratorHeader = memo(({ count, onFormSubmit, onClearAll }) => {
  const { t } = useTranslation();
  return (
    <Box pl={3} pr={3}>
      <Divider />

      <Grid container wrap="nowrap">
        <Grid item xs={8}>
          <WebsitesGeneratorForm onFormSubmit={onFormSubmit} />
        </Grid>

        <Divider orientation="vertical" flexItem />

        <Grid item xs>
          <Grid container alignItems="center">
            <Grid item xs component={Box} pl={2}>
              <Typography variant="body2">
                {t('websitesGenerator.selectedWebsites', { count })}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={onClearAll}
                disabled={count === 0}
              >
                {t('websitesGenerator.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
});

export default WebsitesGeneratorHeader;
