/**
 * Mutates new URL search params
 */
function validateSearchAndPagination(newURLSearchParams) {
  if (newURLSearchParams.has('page')) {
    let page = Number(newURLSearchParams.get('page'));
    page = Number.isSafeInteger(page) ? Math.max(page, 0) : 0;
    newURLSearchParams.set('page', page);
  }

  if (newURLSearchParams.has('page_size')) {
    let pageSize = Number(newURLSearchParams.get('page_size'));
    pageSize =
      Number.isSafeInteger(pageSize) && [5, 10, 20].includes(pageSize)
        ? pageSize
        : 10;
    newURLSearchParams.set('page_size', pageSize);
  }

  if (newURLSearchParams.has('search') && newURLSearchParams.search === null)
    newURLSearchParams.delete('search');
}

/**
 * Accepts URLSearchParams of /users and validates it
 * @returns the same but validated URLSearchParams
 */
export function validateUsersQueryParams(newURLSearchParams) {
  validateSearchAndPagination(newURLSearchParams);
  return newURLSearchParams;
}

export function validateCampaignQueryParams(newURLSearchParams) {
  validateSearchAndPagination(newURLSearchParams);
  return newURLSearchParams;
}
