import React, { memo } from 'react';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const GeoDescriptionInput = (props) => {
  const { t } = useTranslation();
  return (
    <TextField
      {...props}
      fullWidth
      multiline
      variant="outlined"
      placeholder={t('geoTargeting.geoDescriptionLabel')}
      InputProps={{
        style: {
          height: 'auto',
        },
      }}
    />
  );
};

export default memo(GeoDescriptionInput);
