import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';

import GET_GEO_INFO_BY_IDS from './queries';
import downloadLinkedinExcel from '../../services/linkedin-excel-helper';

const LinkedinExcelGenerator = () => {
  const { campaign } = JSON.parse(localStorage.getItem('campaign-for-excel'));
  const { data: geoData, loading } = useQuery(GET_GEO_INFO_BY_IDS, {
    variables: {
      regionIds: campaign.geoRegionIds,
      zipIds: campaign.geoZipCodes,
    },
  });

  useEffect(() => {
    if (!loading && geoData) {
      downloadLinkedinExcel({ campaign, geoData });
    }
  }, [loading, geoData]);

  if (loading) {
    return <CircularProgress color="primary" size={20} />;
  }

  return <></>;
};

export default LinkedinExcelGenerator;
