import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { findIndex } from 'lodash';

import Breadcrumb from '../../../../components/Breadcrumb';
import SpinnerBackdrop from '../../../../components/BackdropSpinner';
import CampaignInfoBoxContainer from '../../../../components/CampaignInfoBoxContainer';
import CampaignStep1 from './components/CampaignStep1';
import CampaignStepper from './components/CampaignStepper';
import CampaignStep2 from './components/CampaignStep2';
import CampaignActions from './components/CampaignActions';
import CampaignStep3 from './components/CampaignStep3';
import CampaignStep4 from './components/CampaignStep4';
import { UPDATE_CAMPAIGN, GET_CAMPAIGN_PAGE_DATA } from './queries';
import { checkCampaignFormValidity } from '../../../../services/linkedin/state-helper';
import { CampaignGenders, CampaignTypes } from '../../../../enums/campaign';
import LinkedinCalculationInfoBox from './components/LinkedinCalculationInfoBox';
import {
  getEstimatedReach,
  getLinkedinBudgetRecommendation,
  getLinkedinCampaignPrice,
  linkedinCompanyTargetGroupsStateItems,
  linkedinTargetGroupsItems,
} from '../../../../services/linkedin/calculations';

const defaultLinkedinCampaignState = {
  advertiserId: '',
  name: '',
  linkedinGoal: 0,
  startDate: null,
  endDate: null,
  linkedinAges: new Array(4).fill(1),
  gender: [CampaignGenders.Male, CampaignGenders.Female],
  landingPage: '',
  idkLandingPageYet: false,
  linkedinProfile: '',
  idkLinkedinProfileYet: false,
  linkedinTargetGroups: [],
  linkedinTargetGroupsState: linkedinTargetGroupsItems,
  linkedinCompanyTargetGroupsState: linkedinCompanyTargetGroupsStateItems,
  geoTargetingType: 0,
  geoZipCodes: [],
  geoRegionIds: [],
  intensity: 2,
  budget: undefined,
  budgetRecommendation: undefined,
  comment: '',
  isDraft: false,
  campaignType: CampaignTypes.Linkedin,
  reach: 0,
  estimatedReach: 0,
  pricing: { TKP: 0 },
};

const EditLinkedinCampaign = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { campaignId } = useParams();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [campaignState, setCampaignState] = useState(
    defaultLinkedinCampaignState
  );

  const refsMap = useRef({});

  const { data: fetchCampaignData, loading: campaignLoading } = useQuery(
    GET_CAMPAIGN_PAGE_DATA,
    {
      variables: { id: campaignId },
      fetchPolicy: 'network-only',
    }
  );
  const [updateCampaign, { loading: creatingCampaignLoading }] =
    useMutation(UPDATE_CAMPAIGN);

  const {
    advertisers,
    user,
    campaign: loadedCampaign,
  } = fetchCampaignData || {};
  const isLoading = campaignLoading || creatingCampaignLoading;

  const handleCampaignState = useCallback(
    (payload) => {
      setCampaignState((formState) => {
        return { ...formState, ...payload };
      });
    },
    [setCampaignState]
  );

  useEffect(() => {
    const campaignData = loadedCampaign || defaultLinkedinCampaignState;
    const advertiser = advertisers?.find(
      ({ id }) => id === campaignData.advertiserId
    );
    const itemsSelected = campaignData.linkedinTargetGroups || [];
    const companyItemsSelected = campaignData.linkedinTargetCompanyGroups || [];

    const linkedinTargetGroupsState = linkedinTargetGroupsItems.map((item) => {
      const categoryIndex = findIndex(itemsSelected, (el) => item.id === el.id);
      if (categoryIndex >= 0) {
        item.isSelected = true;
        if (item.name === 'User input') {
          item.children = itemsSelected[categoryIndex].children.map(
            (child) => ({ ...child, isSelected: true })
          );
          return item;
        }
        item.children.forEach((child) => {
          const subCategoryIndex = findIndex(
            itemsSelected[categoryIndex].children,
            (elChild) => child.id === elChild.id
          );
          if (subCategoryIndex >= 0) {
            child.isSelected = true;
          }
        });
      }
      return item;
    });
    const linkedinCompanyTargetGroupsState =
      linkedinCompanyTargetGroupsStateItems.map((item) => {
        const categoryIndex = findIndex(
          companyItemsSelected,
          (el) => item.id === el.id
        );
        if (categoryIndex >= 0) {
          item.isSelected = true;
          if (item.name === 'User input') {
            item.children = companyItemsSelected[categoryIndex].children.map(
              (child) => ({ ...child, isSelected: true })
            );
            return item;
          }
          item.children.forEach((child) => {
            const subCategoryIndex = findIndex(
              companyItemsSelected[categoryIndex].children,
              (elChild) => child.id === elChild.id
            );
            if (subCategoryIndex >= 0) {
              child.isSelected = true;
            }
          });
        }
        return item;
      });
    handleCampaignState({
      ...campaignData,
      linkedinTargetGroupsState,
      linkedinCompanyTargetGroupsState,
      advertiser,
    });
  }, [loadedCampaign, handleCampaignState, advertisers]);

  const { isValid: isFormValid, firstInvalidFieldName } =
    checkCampaignFormValidity(campaignState);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);
    if (!checkCampaignFormValidity(campaignState).isValid) {
      const firstInvalidField =
        refsMap.current?.[firstInvalidFieldName].current;
      const invalidFieldRect = firstInvalidField?.getBoundingClientRect();
      // eslint-disable-next-line no-unused-expressions
      invalidFieldRect &&
        window.scrollTo({ top: window.scrollY + invalidFieldRect.top - 75 });
      toast.error(t('validationErrors.requiredCampaign'));
      return;
    }
    const linkedinTargetGroups = campaignState.linkedinTargetGroupsState.reduce(
      (acc, el) => {
        if (el.isSelected) {
          const children = el.children?.reduce((childAcc, childEl) => {
            if (childEl.isSelected)
              return [...childAcc, { name: childEl.name, id: childEl.id }];
            return childAcc;
          }, []);
          return [...acc, { name: el.name, id: el.id, children }];
        }
        return acc;
      },
      []
    );
    const linkedinTargetCompanyGroups =
      campaignState.linkedinCompanyTargetGroupsState.reduce((acc, el) => {
        if (el.isSelected) {
          const children = el.children?.reduce((childAcc, childEl) => {
            if (childEl.isSelected)
              return [...childAcc, { name: childEl.name, id: childEl.id }];
            return childAcc;
          }, []);
          return [...acc, { name: el.name, id: el.id, children }];
        }
        return acc;
      }, []);

    const newCampaign = {
      advertiserId: campaignState.advertiser.id,
      advertiserMargin: campaignState.advertiser.margin,
      name: campaignState.name,
      comment: campaignState.comment,
      intensity: campaignState.intensity,
      linkedinGoal: campaignState.linkedinGoal,
      campaignType: CampaignTypes.Linkedin,
      startDate: campaignState.startDate,
      endDate: campaignState.endDate,
      linkedinTargetGroups,
      linkedinTargetCompanyGroups,
      geoTargetingType: campaignState.geoTargetingType,
      geoZipCodes: campaignState.geoZipCodes,
      geoRegionIds: campaignState.geoRegionIds,
      budget: campaignState.budget,
      status: campaignState.isDraft ? 4 : 0, // 4-> 'Draft', 0 ->'Review'
      linkedinAges: campaignState.linkedinAges,
      gender: campaignState.gender,
      landingPage: campaignState.landingPage,
      idkLandingPageYet: campaignState.idkLandingPageYet,
      linkedinProfile: campaignState.linkedinProfile,
      idkLinkedinProfileYet: campaignState.idkLinkedinProfileYet,
      estimatedReach: campaignState.estimatedReach,
      budgetRecommendation: campaignState.budgetRecommendation,
      pricing: campaignState.pricing,
    };

    try {
      const advertiserTKP = Number(campaignState.pricing.TKP.toFixed(2));
      const agencyPrice =
        (advertiserTKP * 100) / (100 + campaignState.advertiser.margin);
      const agencyTKP = Number(agencyPrice.toFixed(2));
      await updateCampaign({
        variables: {
          campaignId,
          campaignInput: newCampaign,
          additionalInfo: {
            agencyTKP,
            advertiserTKP,
          },
        },
      });
      toast.success(t('toasts.campaignUpdated'));
      history('/campaigns');
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const handlePDFWindow = (params) => {
    localStorage.setItem('campaign-for-print', JSON.stringify(params));
    window.open('/campaign/linkedin-view-pdf-print', '_blank');
  };

  const handlePDFGeneration = () => {
    setIsFormSubmitted(true);
    if (!checkCampaignFormValidity(campaignState)) {
      toast.error(t('validationErrors.requiredCampaign'));
      return;
    }
    const params = {
      campaign: campaignState,
      advertisers,
    };
    handlePDFWindow(params);
  };

  const onPDFGenerationWithoutAdvertiserMargins = () => {
    setIsFormSubmitted(true);
    if (!checkCampaignFormValidity(campaignState)) {
      toast.error(t('validationErrors.requiredCampaign'));
      return;
    }
    const params = {
      campaign: campaignState,
      advertisers,
      isAgencyPrice: true,
    };
    handlePDFWindow(params);
  };

  const handleExcelGeneration = () => {
    const params = { campaign: campaignState };
    localStorage.setItem('campaign-for-excel', JSON.stringify(params));
    window.open('/campaign/linkedin-excel-generator', '_blank');
  };

  const {
    advertiser,
    name,
    linkedinGoal,
    startDate,
    endDate,
    linkedinTargetGroupsState,
    linkedinAges,
    gender,
    landingPage,
    idkLandingPageYet,
    linkedinProfile,
    idkLinkedinProfileYet,
    geoTargetingType,
    geoZipCodes,
    geoRegionIds,
    intensity,
    comment,
    budget,
    budgetRecommendation,
    estimatedReach,
    reach,
    pricing,
    linkedinCompanyTargetGroupsState,
  } = campaignState;

  useEffect(() => {
    const newEstimatedReach = getEstimatedReach({
      reach,
      linkedinAges,
      gender,
      linkedinTargetGroupsState,
    });
    handleCampaignState({ estimatedReach: newEstimatedReach });
  }, [
    reach,
    linkedinAges,
    gender,
    linkedinTargetGroupsState,
    handleCampaignState,
  ]);

  useEffect(() => {
    const newBudgetRecommendation = getLinkedinBudgetRecommendation({
      startDate,
      endDate,
      estimatedReach,
      intensity,
      advertiser,
      user,
      linkedinTargetGroupsState,
      linkedinCompanyTargetGroupsState,
      linkedinAges,
    });
    const newPricing = getLinkedinCampaignPrice({
      advertiser,
      user,
      linkedinTargetGroupsState,
      linkedinCompanyTargetGroupsState,
      linkedinAges,
    });
    handleCampaignState({
      budgetRecommendation: newBudgetRecommendation,
      pricing: newPricing,
    });
  }, [
    startDate,
    endDate,
    estimatedReach,
    intensity,
    advertiser,
    user,
    linkedinTargetGroupsState,
    linkedinCompanyTargetGroupsState,
    linkedinAges,
    handleCampaignState,
  ]);
  return (
    <Box mt={2} ml={2}>
      <Breadcrumb>
        <Link to="/campaigns" component={RouterLink}>
          {t('campaigns.campaigns')}
        </Link>
        <Typography color="textPrimary">
          {t('createCampaign.newCampaign')}
        </Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={isLoading} />
      <Box mt={2} />
      <Grid container wrap="nowrap" direction="row">
        <Grid
          style={{ maxWidth: '280px' }}
          item
          alignItems="flex-start"
          container
        >
          <CampaignInfoBoxContainer>
            {/* stepper */}
            <Grid item>
              <CampaignStepper
                headerText={t('createCampaign.newCampaign')}
                isFormSubmitted={isFormSubmitted}
                campaignState={campaignState}
              />
            </Grid>

            {/* campaign calculations */}
            <Grid item>
              <LinkedinCalculationInfoBox
                estimatedReach={estimatedReach}
                budgetRecommendation={budgetRecommendation}
                pricing={pricing}
                budget={budget}
              />
            </Grid>
          </CampaignInfoBoxContainer>
        </Grid>
        <Grid style={{ maxWidth: '1100px' }} item xs>
          <form>
            <CampaignStep1
              refsMap={refsMap}
              advertisers={advertisers}
              handleCampaignState={handleCampaignState}
              isFormSubmitted={isFormSubmitted}
              advertiser={advertiser}
              name={name}
              linkedinGoal={linkedinGoal}
              startDate={startDate}
              endDate={endDate}
            />
            <CampaignStep2
              refsMap={refsMap}
              linkedinTargetGroupsState={linkedinTargetGroupsState}
              linkedinCompanyTargetGroupsState={
                linkedinCompanyTargetGroupsState
              }
              linkedinAges={linkedinAges}
              gender={gender}
              landingPage={landingPage}
              idkLandingPageYet={idkLandingPageYet}
              linkedinProfile={linkedinProfile}
              idkLinkedinProfileYet={idkLinkedinProfileYet}
              handleCampaignState={handleCampaignState}
              isFormSubmitted={isFormSubmitted}
            />
            <CampaignStep3
              refsMap={refsMap}
              geoTargetingType={geoTargetingType}
              handleCampaignState={handleCampaignState}
              isFormSubmitted={isFormSubmitted}
              geoZipCodes={geoZipCodes}
              geoRegionIds={geoRegionIds}
            />
            <CampaignStep4
              refsMap={refsMap}
              intensity={intensity}
              comment={comment}
              budget={budget}
              budgetRecommendation={budgetRecommendation}
              linkedinGoal={linkedinGoal}
              isFormSubmitted={isFormSubmitted}
              handleCampaignState={handleCampaignState}
            />
            <Box mt={2} />
            <CampaignActions
              isFormValid={isFormValid}
              isFormSubmitted={isFormSubmitted}
              onSubmit={onSubmit}
              campaignState={campaignState}
              handleCampaignState={handleCampaignState}
              disabled={false} // TODO
              handlePDFGeneration={handlePDFGeneration}
              handleExcelGeneration={handleExcelGeneration}
              onPDFGenerationWithoutAdvertiserMargins={
                onPDFGenerationWithoutAdvertiserMargins
              }
            />
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditLinkedinCampaign;
