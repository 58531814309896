import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Grid,
  Divider,
  FormHelperText,
  Box,
} from '@material-ui/core';

import { concat, uniqBy, intersection, omit } from 'lodash';
import GET_WEBSITES from './queries';
import WebsitesGeneratorHeader from './components/WebsitesGeneratorHeader';
import WebsitesGeneratorList from './components/WebsitesGeneratorList';
import WebsitesGeneratorListSelected from './components/WebsitesGeneratorListSelected';
import WebsitesCustomGeneratorList from './components/WebsitesCustomGeneratorList';

const useStyles = makeStyles({
  item: { flexGrow: 1, overflowY: 'auto' },
  content: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    overflow: 'hidden',
  },
  customError: {
    position: 'static',
  },
});

const WebsitesGenerator = ({
  open,
  onClose,
  onEmitData,
  categories = [],
  customWebsitesPreset = [],
}) => {
  const { data } = useQuery(GET_WEBSITES);
  const [categoriesState, setCategoriesState] = useState([]);
  const [isPresetApplied, setIsPresetApplied] = useState(false);
  const [customFilter, setCustomFilter] = useState({});
  const [customWebsites, setCustomWebsites] = useState([]);
  const [customCategories, setCustomCategories] = useState([]);
  const [isCustomWebsitesChecked, setIsCustomWebsitesChecked] = useState(false);
  const handleCustomWebsitesCheckbox = () =>
    setIsCustomWebsitesChecked(!isCustomWebsitesChecked);
  const { t } = useTranslation();
  const classes = useStyles();
  const websites = data?.websites || [];
  const customWebsitesData = data?.customWebsites || [];
  const relevanceFilter = data?.relevanceFilter || [];

  useEffect(() => {
    if (!websites?.length) {
      return;
    }
    setCategoriesState(() => {
      const websitesIds = websites.map(({ id }) => id);
      const categoriesIds = categories.map(({ id }) => id);
      const categoriesSet = [...new Set([...websitesIds, ...categoriesIds])];
      return [
        ...categoriesSet.map((id) => {
          const [category] = websites.filter((i) => i.id === id);
          const [current] = categories.filter((i) => i.id === id);
          if (category) {
            const web = category.websites.map((name) => ({
              name,
              checked: current?.websites.some((i) => i === name),
            }));
            return {
              ...category,
              checked: !!web.filter(({ checked }) => checked).length,
              websites: web,
            };
          } else {
            const web = current.websites.map((name) => ({
              name,
              checked: true,
            }));
            const checked = !!web.length;
            return { ...current, checked, websites: web };
          }
        }),
      ];
    });
  }, [open, websites]);

  const initCustomWebsites = () => {
    const allWebsites = concat(
      ...customWebsitesData.map(
        ({ websites: mappedWebsites }) => mappedWebsites
      )
    );
    const uniqueAllWebsites = uniqBy(allWebsites, ({ name }) => name);
    const preparedUniqueWebsites = uniqueAllWebsites.map((website) => ({
      ...website,
      checked: false,
    }));
    const preparedCustomCategories = customWebsitesData.map(
      ({ category }) => category
    );
    setCustomWebsites(preparedUniqueWebsites);
    setCustomCategories(preparedCustomCategories);
    setIsCustomWebsitesChecked(false);
  };
  useEffect(() => {
    if (!customWebsitesData?.length) {
      return;
    }
    initCustomWebsites();
  }, [customWebsitesData]);

  const findCustomWebsiteIndexByName = (nameToSearch) => {
    return customWebsites.findIndex(({ name }) => name === nameToSearch);
  };

  useEffect(() => {
    if (
      !customWebsitesPreset?.length ||
      !customWebsites?.length ||
      isPresetApplied
    ) {
      return;
    }
    customWebsitesPreset.forEach((customWebsitePreset) => {
      const index = findCustomWebsiteIndexByName(customWebsitePreset.name);
      customWebsites[index].checked = true;
    });
    setCustomWebsites([...customWebsites]);
    setIsPresetApplied(true);
  }, [customWebsitesPreset, customWebsites, open]);
  const categoriesList = categoriesState.filter(({ id }) => id);
  useEffect(() => {
    if (!open) {
      setIsPresetApplied(false);
    }
  }, [open]);
  const categoriesListSelected = categoriesState
    .map((item) => ({
      ...item,
      total: item.websites.length,
      selected: item.websites?.filter(({ checked }) => checked).length,
      websites: item.websites?.filter(({ checked }) => checked) || [],
    }))
    .filter(({ checked }) => checked);

  const customWebsitesSelected = customWebsites.filter(
    ({ checked }) => checked
  );

  const count = () => {
    let categoriesCount = categoriesState.reduce(
      (a, { websites: categoriesWebsites }) => {
        return a + categoriesWebsites.filter(({ checked }) => checked).length;
      },
      0
    );
    if (customWebsitesSelected?.length) {
      categoriesCount += customWebsitesSelected?.length;
    }
    return categoriesCount;
  };

  const categoriesCount = count();

  const handleFormSubmit = ({ uncategorized }) => {
    const values = [...new Set(uncategorized.split(/[ ,]+/))].filter(Boolean);
    setCategoriesState((state) => {
      const [unCat] = state.filter(({ category }) => category === null);
      const uncatWeb = (unCat?.websites || []).filter(({ checked }) => checked);
      const uncatWebsites = [
        ...new Set([...uncatWeb.map(({ name }) => name), ...values]),
      ].map((name) => ({ name, checked: true }));
      return [
        ...state.filter(({ category }) => category),
        {
          category: null,
          checked: !!uncatWebsites.filter(({ checked }) => checked).length,
          websites: uncatWebsites,
        },
      ];
    });
  };

  const handleSelectCategory = ({ category }) => {
    setCategoriesState((state) => {
      const idx = state.findIndex((i) => i.category === category);
      const { checked, websites: stateWeb } = state[idx] || {};
      const web = stateWeb.map((item) => ({ ...item, checked: !checked }));
      Object.assign(state[idx], { checked: !checked, websites: web });
      return [...state];
    });
  };

  const handleSelectCategoryWebsite = ({ category, website }) => {
    setCategoriesState((state) => {
      const idx = state.findIndex((i) => i.category === category);
      const { websites: web } = state[idx] || {};
      const webIdx = web.findIndex((i) => i.name === website.name);
      Object.assign(web[webIdx], { checked: !web[webIdx].checked });
      const stateChecked = web.filter(({ checked }) => checked).length;
      Object.assign(state[idx], { checked: !!stateChecked, websites: web });
      return [...state];
    });
  };

  const onSelectCustomWebsite = (website) => {
    const newWebsite = { ...website, checked: !website.checked };
    const index = findCustomWebsiteIndexByName(newWebsite.name);
    customWebsites[index] = newWebsite;
    setCustomWebsites([...customWebsites]);
  };

  const onSelectCustomWebsites = (newWebsites, checked) => {
    newWebsites.forEach((newWebsite) => {
      const newWebsiteChanged = { ...newWebsite, checked: !checked };
      const index = findCustomWebsiteIndexByName(newWebsiteChanged.name);
      customWebsites[index] = newWebsiteChanged;
      setCustomWebsites([...customWebsites]);
    });
  };

  const handleClearAllCategories = () => {
    setCategoriesState((state) => {
      return [
        ...state.map((item) => {
          const web = item.websites.map((i) => ({ ...i, checked: false }));
          return { ...item, checked: false, websites: web };
        }),
      ];
    });
    initCustomWebsites();
  };

  const onCategoryChanged = (newCategories) => {
    setCustomFilter({
      ...customFilter,
      categories: newCategories.map((value) => value),
    });
  };
  const onTargetingChange = (targeting) => {
    setCustomFilter({
      ...customFilter,
      targeting: targeting.map(({ value }) => value),
    });
  };
  const onRelevanceChange = (relevance) => {
    setCustomFilter({
      ...customFilter,
      relevance,
    });
  };

  const handleEmitData = () => {
    const dataToEmit = {
      websitesIncludeList: categoriesListSelected.map((item) => {
        const web = item.websites.map(({ name }) => name);
        return { ...item, websites: web };
      }),
      customWebsitesIncludeList:
        customWebsitesSelected?.map((customWebsite) => {
          return omit(customWebsite, 'checked');
        }) || [],
    };
    onEmitData(dataToEmit);
    initCustomWebsites();
  };
  const filterCustomWebsites = () => {
    const { relevance, targeting, categories: newCategories } = customFilter;
    let toRender = customWebsites;
    if (relevance?.length) {
      toRender = toRender?.filter(
        (website) => intersection(website.relevance, relevance).length
      );
    }
    if (newCategories?.length) {
      toRender = toRender?.filter(
        (website) => intersection(website.categories, newCategories).length
      );
    }
    if (targeting?.length) {
      toRender = toRender?.filter(
        (website) => !targeting.find((currTargeting) => website[currTargeting])
      );
    }
    return toRender;
  };

  const removeCustomWebsite = (website) => {
    const newWebsite = { ...website, checked: false };
    const index = findCustomWebsiteIndexByName(website.name);
    customWebsites[index] = newWebsite;
    setCustomWebsites([...customWebsites]);
  };

  const hasCustomWebsites = !!customWebsites.length;

  const filteredCustomWebsites = filterCustomWebsites();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>{t('websitesGenerator.header')}</DialogTitle>
      <WebsitesGeneratorHeader
        count={categoriesCount}
        onFormSubmit={handleFormSubmit}
        onClearAll={handleClearAllCategories}
      />

      <DialogContent className={classes.content}>
        <Grid container wrap="nowrap">
          <Grid item xs={8} className={classes.item}>
            <Grid container direction="column">
              <WebsitesGeneratorList
                hasSubheader={hasCustomWebsites}
                categories={categoriesList}
                onSelectCategory={handleSelectCategory}
                onSelectCategoryWebsite={handleSelectCategoryWebsite}
              />
              <Box mt={3} />
              {hasCustomWebsites && (
                <WebsitesCustomGeneratorList
                  websites={filteredCustomWebsites}
                  categories={customCategories}
                  relevanceFilter={relevanceFilter}
                  onSelectCustomWebsite={onSelectCustomWebsite}
                  onTargetingChange={onTargetingChange}
                  onCategoryChanged={onCategoryChanged}
                  onRelevanceChange={onRelevanceChange}
                  handleCustomWebsitesCheckbox={handleCustomWebsitesCheckbox}
                  isCustomWebsitesChecked={isCustomWebsitesChecked}
                  onSelectCustomWebsites={onSelectCustomWebsites}
                />
              )}
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item className={classes.item}>
            <WebsitesGeneratorListSelected
              removeCustomWebsite={removeCustomWebsite}
              categories={categoriesListSelected}
              customWebsitesSelected={customWebsitesSelected}
              onSelectCategory={handleSelectCategory}
              onSelectCategoryWebsite={handleSelectCategoryWebsite}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {categoriesCount > 1 && categoriesCount < 30 && (
          <FormHelperText className={classes.customError} error>
            {t('validationErrors.requiredCategories')}
          </FormHelperText>
        )}

        <Button onClick={onClose}>{t('common.cancel')}</Button>

        <Button
          color="primary"
          variant="contained"
          disabled={categoriesCount < 30}
          onClick={handleEmitData}
        >
          {t('common.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WebsitesGenerator;
