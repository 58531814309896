import gql from 'graphql-tag';

export const GET_ALL_ADVERTISERS = gql`
  query getAdvertisers {
    advertisers {
      id
      name
      margin
      campaigns {
        id
        status
      }
    }
    user {
      disabledMargin
    }
  }
`;

export const GET_ADVERTISERS_CAMPAIGN = gql`
  query getCampaigns($filter: GetCampaignsFilterInput) {
    campaigns(filter: $filter) {
      id
      name
    }
  }
`;

export const CREATE_ADVERTISER = gql`
  mutation createAdvertiser($advertiserInput: CreateAdvertiserInput!) {
    createAdvertiser(advertiserInput: $advertiserInput) {
      id
      name
      margin
    }
  }
`;

export const UPDATE_ADVERTISER = gql`
  mutation updateAdvertiser(
    $id: ID!
    $advertiserInput: UpdateAdvertiserInput!
  ) {
    updateAdvertiser(advertiserId: $id, advertiserInput: $advertiserInput) {
      id
      name
      margin
    }
  }
`;

export const REMOVE_ADVERTISER = gql`
  mutation removeAdvertiser($id: ID!, $removeWithCampaigns: Boolean) {
    removeAdvertiser(
      advertiserId: $id
      removeWithCampaigns: $removeWithCampaigns
    ) {
      id
      name
      margin
    }
  }
`;
