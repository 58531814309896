import gql from 'graphql-tag';

export const GET_ZIPS_POINTS = gql`
  query getZipsPoints($ids: [ID!]!) {
    zipsWithPoints(ids: $ids) {
      name
      id
      lat
      lon
      population
      points
    }
  }
`;

export const GET_NEAREST_ZIPS = gql`
  query getNearestZips($search: CoordinatesSearchInput!) {
    nearestZips(search: $search) {
      id
    }
  }
`;

export const GET_ZIPS_BY_NAME = gql`
  query getZipsByName($name: String!) {
    zipsByName(name: $name) {
      name
      id
      lat
      lon
      population
    }
  }
`;

export const GET_ZIPS_IN_NAMES = gql`
  query getZipsInName($names: [String!]!) {
    zipsInNames(names: $names) {
      name
      id
      lat
      lon
      population
    }
  }
`;

export const GET_ZIPS_BY_IDS = gql`
  query getZipsByIds($ids: [String!]!) {
    zipsByIds(ids: $ids) {
      name
      id
      lat
      lon
      population
    }
  }
`;

export const UPDATE_ZIP = gql`
  mutation updateZip($zipId: ID, $zipInput: UpdateZipInput!) {
    updateZip(zipId: $zipId, zipInput: $zipInput) {
      name
      id
      lat
      lon
      population
    }
  }
`;
