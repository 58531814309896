import React, { memo } from 'react';
import { Polygon as GMPolygon } from '@react-google-maps/api';

const Polygon = memo((props) => {
  return (
    <GMPolygon
      options={{
        fillOpacity: 0.5,
        strokeOpacity: 0.6,
        strokeWeight: 2,
        strokeColor: 'green',
        fillColor: 'green',
      }}
      {...props}
    />
  );
});
export default Polygon;
