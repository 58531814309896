import { createTheme } from '@material-ui/core/styles';

export const baseColor = '#201C25';
export const purpleColor = '#322149';
export const turquiseColor = '#14A3AE';
export const orangeColor = '#F3CA40';
export const redColor = '#E0607E';
export const greenColor = '#84DCC6';
export const whiteColor = '#FFFFFF';
export const greyColor = '#8F8F8F';
export const lightGreyColor = '#E8E8E8';
export const lightGreyWithOpacityColor = '#F9F9FA';
export const whiteColorWithOpacity = 'rgba(255, 255, 255, 0.1)';

export default createTheme({
  palette: {
    primary: {
      main: turquiseColor,
    },
    secondary: {
      main: greyColor,
    },
    basic: {
      lightGrey: lightGreyColor,
      lightGreyOpacity: lightGreyWithOpacityColor,
      grey: greyColor,
      white: whiteColor,
    },
  },
  typography: {
    fontFamily: ['Spartan'],
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiTypography: {
      h4: {
        fontSize: 32,
        lineHeight: '42px',
        fontWeight: 600,
      },
      h5: {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '24px',
      },
      h6: {
        fontWeight: 600,
        fontSize: 24,
        lineHeight: '40px',
      },
      subtitle1: {
        fontSize: 18,
        lineHeight: '28px',
        color: greyColor,
      },
      subtitle2: {
        fontSize: 13,
        lineHeight: '24px',
      },
      body2: {
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 500,
      },
      caption: {
        fontSize: '0.875rem',
      },
    },
    MuiAppBar: {
      root: {
        zIndex: 1300,
      },
    },
    MuiPaper: {
      rounded: {
        border: '1px solid transparent',
        boxSizing: 'border-box',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
      },
      contained: {
        letterSpacing: 1,
        fontWeight: 'bold',
        padding: 12,
        boxShadow: 'none',
      },
      text: {
        padding: 12,
      },
      outlined: {
        padding: 11,
      },
      label: {
        display: 'contents',
      },
      textSecondary: {
        fontWeight: 'bold',
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: purpleColor,
        color: whiteColor,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: purpleColor,
        color: whiteColor,
        minWidth: 144,
      },
    },
    MuiList: {
      root: {
        cursor: 'pointer',
        width: '100%',
      },
    },
    MuiInputBase: {
      root: {
        height: '48px',
      },
    },
    MuiOutlinedInput: {
      inputAdornedEnd: {
        marginRight: '-46px',
        paddingRight: '52px',
      },
    },
    MuiAutocomplete: {
      endAdornment: {
        marginRight: -2,
      },
      inputRoot: {
        height: 'auto',
        '&[class*="MuiOutlinedInput-root"]': {
          paddingTop: 5,
          paddingBottom: 5,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'inherit',
        color: 'inherit',
      },
    },
    MuiListItemText: {
      root: {
        color: 'inherit',
      },
    },
    MuiIcon: {
      root: {
        color: greyColor,
      },
    },
    MuiTableSortLabel: {
      root: {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: '24px',
      },
    },
    MuiListSubheader: {
      sticky: {
        backgroundColor: whiteColor,
        borderBottom: '1px solid #E8E8E8',
      },
    },
    MuiTable: {
      root: {
        border: '1px solid #E8E8E8',
        borderSpacing: 0,
        borderRadius: 8,
        borderCollapse: 'unset',
        paddingRight: 24,
        paddingLeft: 24,
        '&:not(:first-child)': {
          border: 'none',
        },
      },
    },
    MuiTableRow: {
      root: {
        '&:last-of-type:not(:first-child) .MuiTableCell-root': {
          border: 'none',
        },
      },
    },
    MuiTablePagination: {
      root: {
        '&:last-child': {
          border: 'none',
        },
      },
    },
    MuiBreadcrumbs: {
      root: {
        color: greyColor,
      },
    },
    MuiIconButton: {
      root: {
        '&.Mui-disabled .MuiIconButton-label .MuiIcon-root': {
          color: lightGreyColor,
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: turquiseColor,
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        marginTop: 0,
        top: '100%',
        right: 0,
        left: 0,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.6875rem',
        whiteSpace: 'pre-wrap',
      },
    },
  },
});
