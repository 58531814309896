import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { invert } from 'lodash';

import {
  Typography,
  Grid,
  Box,
  Link,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { isAdmin } from '../../services/JWT-Auth';

import {
  CREATE_CAMPAIGN,
  GET_CAMPAIGN_PAGE_DATA,
  UPDATE_CAMPAIGN_STATUSES,
  UPDATE_CAMPAIGN,
} from './queries';

import AdminCampaignActions from './components/AdminCampaignActions';
import CampaignStepper from './components/CampaignStepper';
import CampaignDetails from './components/CampaignDetails';
import CampaignTargeting from './components/CampaignTargeting';
import CampaignInfoBox from './components/CampaignInfoBox';
import CampaignPricing from './components/CampaignPricing';
import GeoTargetingWidget from '../../components/GeoTargeting';
import CampaignActions from './components/CampaignActions';
import Breadcrumb from '../../components/Breadcrumb';
import {
  campaignToFormState,
  checkCampaignFormValidity,
  getDefaultGeoTargetingState,
} from './services/campaignStateHelper';
import {
  daysCount,
  getBudgetRecommendation,
  getPricePerUser,
  getPricesPerUser,
  getPricesWithMargin,
  getReach,
  isReachAvailable,
} from './services/calculation';
import SpinnerBackdrop from '../../components/BackdropSpinner';
import {
  CampaignAdvertisingType,
  CampaignCreatives,
  CampaignSocialAdvertiserMaterialTypes,
  CampaignStatus,
  CampaignTypes,
  CreativeStatus,
} from '../../enums/campaign';
import CampaignCalculationInfoBox from './components/CampaignCalculationInfoBox';
import CampaignInfoBoxContainer from './components/CampaignInfoBoxContainer';

const CreateViewEditCampaign = (props) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();
  const { disabled, type } = props;
  const { campaignId } = useParams();
  const isViewed = location.pathname.indexOf('/campaign/') > -1;
  const isCreate = location.pathname.indexOf('/create-campaign/') > -1;
  const isEdit = location.pathname.indexOf('/edit-campaign/') > -1;

  const refsMap = useRef({});

  const [formValue, setFormValue] = useState(
    campaignToFormState(undefined, type)
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [renderMaps, setRenderMaps] = useState(isCreate);

  const { data: fetchCampaignData, loading: campaignLoading } = useQuery(
    GET_CAMPAIGN_PAGE_DATA,
    {
      variables: { id: campaignId },
      fetchPolicy: 'network-only',
    }
  );

  const [createCampaign, { loading: creatingCampaignLoading }] =
    useMutation(CREATE_CAMPAIGN);
  const [updateCampaignStatuses] = useMutation(UPDATE_CAMPAIGN_STATUSES, {
    refetchQueries: ['getCampaignsPage', 'campaignViewPageData'],
  });
  const [updateCampaign, { loading: updateCampaignLoading }] =
    useMutation(UPDATE_CAMPAIGN);
  const { advertisers, campaign, user } = fetchCampaignData || {};

  const agency = campaign?.agency || fetchCampaignData?.user;
  const additionalContactField = fetchCampaignData?.user.additionalContactField;

  const userStaticPrice = user?.staticPrice;
  // get correct static price for view campaign as admin;
  const agencyStaticPrice = campaign?.agency?.staticPrice;
  const staticPrice = userStaticPrice || agencyStaticPrice;

  const report = campaign?.reportLink;
  const campaignStatus = CampaignStatus[campaign?.status];
  const creativeStatus = CreativeStatus[campaign?.creativeStatus];
  const [statusesToUpdate, setStatusesToUpdate] = useState(null);

  useEffect(() => {
    const campaignFormData = campaignToFormState(campaign, type, advertisers);
    setFormValue(campaignFormData);
    if (campaign) {
      setRenderMaps(true);
    }
  }, [campaign]);

  const isLoading =
    campaignLoading ||
    !formValue ||
    creatingCampaignLoading ||
    updateCampaignLoading;

  const handleCampaignDetails = useCallback(
    (payload) => {
      setFormValue((formState) => {
        const { campaignDetails } = formState;
        return {
          ...formState,
          campaignDetails: { ...campaignDetails, ...payload },
        };
      });
    },
    [setFormValue]
  );

  const handleCampaignTargeting = useCallback(
    (payload) => {
      setFormValue((formState) => {
        const { campaignTargeting } = formState;
        return {
          ...formState,
          campaignTargeting: { ...campaignTargeting, ...payload },
        };
      });
    },
    [setFormValue]
  );

  const handleCampaignPricing = useCallback(
    (payload) => {
      setFormValue((formState) => {
        const { campaignPricing } = formState;
        return {
          ...formState,
          campaignPricing: { ...campaignPricing, ...payload },
        };
      });
    },
    [setFormValue]
  );

  const handleCampaignGeoTargeting = useCallback(
    (payload) => {
      setFormValue((formState) => {
        return {
          ...formState,
          geoTargeting: { ...getDefaultGeoTargetingState(), ...payload },
        };
      });
    },
    [setFormValue]
  );

  const { campaignDetails, campaignTargeting, geoTargeting, campaignPricing } =
    formValue;

  const estimatedRange = getReach(formValue);
  const calculatedPricesWithMargin = getPricesWithMargin(
    campaignDetails?.advertiser?.margin,
    agency?.margin,
    campaignDetails.type,
    campaignDetails.advertisingType,
    campaignPricing.socialAdvertiserMaterialType
  );
  const pricing = campaign?.pricing || calculatedPricesWithMargin;

  const { isValid: isFormValid, firstInvalidFieldName } =
    checkCampaignFormValidity(formValue);

  const onSubmit = async (event) => {
    const campaignType = campaignDetails.type;
    const pricesPerUserToSave = getPricesPerUser({
      agency,
      campaignValue: formValue,
    });
    event.preventDefault();
    setIsFormSubmitted(true);
    if (!checkCampaignFormValidity(formValue, agency).isValid) {
      const firstInvalidField =
        refsMap.current?.[firstInvalidFieldName].current;
      const invalidFieldRect = firstInvalidField?.getBoundingClientRect();
      // eslint-disable-next-line no-unused-expressions
      invalidFieldRect &&
        window.scrollTo({ top: window.scrollY + invalidFieldRect.top - 75 });
      toast.error(t('validationErrors.requiredCampaign'));
      return;
    }
    const newCampaign = {
      advertiserId: campaignDetails.advertiser.id,
      ages: campaignTargeting.ages,
      budget: campaignPricing.budget,
      campaignType,
      comment: campaignPricing.comment,
      creativeStatus: 0,
      devices: campaignTargeting.devices,
      gender: campaignTargeting.gender,
      geoTargetingType: geoTargeting.geoTargetingType,
      geoRadiuses: geoTargeting.geoRadiuses,
      geoRegionIds: geoTargeting.geoRegionIds,
      geoCategoriesIds: geoTargeting.geoCategoriesIds,
      geoZipCodes: geoTargeting.geoZipCodes,
      geoPolygons: geoTargeting.geoPolygons,
      geoPolygonText: geoTargeting.geoPolygonText,
      geoRadiusText: geoTargeting.geoRadiusText,
      geoRadiusMeters: geoTargeting.geoRadiusMeters,
      goal: campaignDetails.goal,
      intensity: campaignPricing.intensity,
      landingPage: campaignTargeting.landingPage,
      idkLandingPageYet: campaignTargeting.idkLandingPageYet,
      name: campaignDetails.name,
      startDate: campaignDetails.startDate,
      endDate: campaignDetails.endDate,
      targetGroups: campaignTargeting.targetGroups,
      websitesIncludeList: campaignTargeting?.websitesIncludeList?.reduce(
        (acc, w) => [...acc, ...w.websites],
        []
      ),
      websitesTargeting:
        campaignDetails.type === CampaignTypes.Banner
          ? campaignTargeting.websitesTargeting
          : null,
      status: campaignDetails.isDraft ? 4 : 0, // 4-> 'Draft', 0 ->'Review'
      contactPersonEmail: campaignPricing.contactPersonEmail,
    };

    if (campaignType === CampaignTypes.Banner) {
      newCampaign.creativesType = campaignPricing.advertiserMaterialType;
      newCampaign.creatives = campaignPricing.advertiserMaterial;
      newCampaign.advertisingType = campaignDetails.advertisingType;
      // todo move map/reduce logic from submit;
      newCampaign.websitesIncludeCategorisedList =
        campaignTargeting.websitesIncludeList.map((w) => {
          return {
            id: w.id,
            category: w.category,
            websites: w.websites,
          };
        });
      newCampaign.customWebsitesIncludeList =
        campaignTargeting.customWebsitesIncludeList;
    }

    if (campaignType === CampaignTypes.Social) {
      newCampaign.socialPlatform = campaignDetails.socialPlatform;
      newCampaign.instagramProfile = campaignDetails.instagramProfile;
      newCampaign.facebookProfile = campaignDetails.facebookProfile;
      newCampaign.socialAdvertiserMaterialType =
        campaignPricing.socialAdvertiserMaterialType;
    }

    try {
      const campaignGoal =
        formValue.campaignDetails.advertisingType ||
        CampaignAdvertisingType.TKP;
      const calculatedPricePerUser = getPricePerUser({
        campaignValue: formValue,
        agency,
      });

      const savedPricePerUser = campaign?.pricesPerUser;
      const savedPrice = savedPricePerUser
        ? savedPricePerUser[campaignGoal]
        : calculatedPricePerUser;
      const pricePerUser = isEdit ? calculatedPricePerUser : savedPrice;

      // todo change naming for  *agencyTKP* value (it can be cpc as well)
      const agencyTKP = Number(
        (pricePerUser.viewPriceWithoutMargins * agency?.margin).toFixed(2)
      );

      const savedMargin =
        campaign?.advertiserMargin || campaignDetails?.advertiser?.margin;
      const advertiserMargin = isEdit
        ? campaignDetails?.advertiser?.margin
        : savedMargin;

      const advertiserTKP = Number(
        (agencyTKP * (1 + advertiserMargin / 100)).toFixed(2)
      );
      let uploadType = campaignType;
      if (campaignType === CampaignTypes.Social) {
        if (
          newCampaign.socialAdvertiserMaterialType ===
            CampaignSocialAdvertiserMaterialTypes.FeedStatic ||
          newCampaign.socialAdvertiserMaterialType ===
            CampaignSocialAdvertiserMaterialTypes.StoryStatic
        ) {
          uploadType = CampaignTypes.Banner;
        } else {
          uploadType = CampaignTypes.Video;
        }
      }
      if (isEdit && campaignId) {
        await updateCampaign({
          variables: {
            campaignId,
            campaignInput: {
              ...newCampaign,
              pricesPerUser: pricesPerUserToSave,
              pricing,
              advertiserMargin: campaignDetails.advertiser.margin,
              agencyId: agency.id,
            },
            additionalInfo: {
              agencyTKP,
              advertiserTKP,
            },
          },
        });
        toast.success(t('toasts.campaignUpdated'));
        if (
          campaignType === CampaignTypes.Social ||
          newCampaign.creatives === CampaignCreatives.Order ||
          newCampaign.creatives === CampaignCreatives.Later
        ) {
          history('/campaigns');
        } else {
          history(`/campaign/upload/${uploadType}/${campaignId}`);
        }
        return;
      }
      const res = await createCampaign({
        variables: {
          campaignInput: {
            ...newCampaign,
            pricesPerUser: pricesPerUserToSave,
            pricing,
            advertiserMargin: campaignDetails.advertiser.margin,
          },
          additionalInfo: {
            agencyTKP,
            advertiserTKP,
          },
        },
      });
      const { id } = res.data.createCampaign;
      toast.success(t('toasts.campaignCreated'));
      if (
        campaignType === CampaignTypes.Social ||
        newCampaign.creatives === CampaignCreatives.Order ||
        newCampaign.creatives === CampaignCreatives.Later
      ) {
        history('/campaigns');
      } else {
        history(`/campaign/upload/${uploadType}/${id}`);
      }
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const onCampaignStatusesChange = (newStatuses) => {
    const {
      status,
      reportLink,
      creativeStatus: newCreativeStatus,
    } = newStatuses;
    const statusesToSend = {
      status: Number(invert(CampaignStatus)[status]),
      creativeStatus: Number(invert(CreativeStatus)[newCreativeStatus]),
      reportLink,
    };
    setStatusesToUpdate(statusesToSend);
  };

  const onAdminSubmit = async () => {
    try {
      await updateCampaignStatuses({
        variables: {
          id: campaignId,
          campaignInput: statusesToUpdate,
        },
      });
      history(-1);
      toast.success(t('toasts.campaignUpdated'));
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const handlePDFWindow = (params) => {
    localStorage.setItem('campaign-for-print', JSON.stringify(params));
    window.open('/campaign/pdf-print', '_blank');
  };

  const handlePDFGeneration = () => {
    setIsFormSubmitted(true);
    if (!checkCampaignFormValidity(formValue, agency).isValid) {
      toast.error(t('validationErrors.requiredCampaign'));
      return;
    }
    const params = {
      savedCampaign: isEdit ? undefined : campaign,
      campaign: formValue,
      staticPrice,
      agency,
    };
    handlePDFWindow(params);
  };

  const handlePDFGenerationWithoutAdvertiserMargins = () => {
    setIsFormSubmitted(true);

    if (!checkCampaignFormValidity(formValue, agency).isValid) {
      toast.error(t('validationErrors.requiredCampaign'));
      return;
    }

    const params = {
      savedCampaign: isEdit ? undefined : campaign,
      campaign: { ...formValue },
      staticPrice,
      agency,
      priceWithoutAdvMargin: 'priceWithoutAdvMargin',
    };

    handlePDFWindow(params);
  };

  const handleExcelGeneration = () => {
    setIsFormSubmitted(true);
    if (!checkCampaignFormValidity(formValue, agency).isValid) {
      toast.error(t('validationErrors.requiredCampaign'));
      return;
    }
    const params = {
      campaign: formValue,
      agency,
      staticPrice,
      savedCampaign: isEdit ? undefined : campaign,
    };

    localStorage.setItem('campaign-for-excel', JSON.stringify(params));
    window.open('/campaign/excel-generator', '_blank');
  };

  // calculations section
  const campaignGoal =
    formValue.campaignDetails.advertisingType || CampaignAdvertisingType.TKP;

  const calculatedPricePerUser = getPricePerUser({
    campaignValue: formValue,
    agency,
  });
  const savedPricePerUser = campaign?.pricesPerUser;
  const savedPrice = savedPricePerUser
    ? savedPricePerUser[campaignGoal]
    : calculatedPricePerUser;
  const pricePerUser = isEdit ? calculatedPricePerUser : savedPrice;

  const reach = getReach(formValue);
  const days = daysCount(campaignDetails.startDate, campaignDetails.endDate);

  const budgetRecommendation = getBudgetRecommendation(
    reach,
    campaignPricing.intensity,
    pricePerUser.price,
    days,
    campaignDetails.advertisingType
  );

  return (
    <Box mt={2} ml={2}>
      <Breadcrumb>
        <Link to="/campaigns" component={RouterLink}>
          {t('campaigns.campaigns')}
        </Link>
        <Typography color="textPrimary">
          {!isLoading &&
            (campaign?.name || t(`createCampaign.newCampaign.${type}`))}
        </Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={isLoading} />
      <Box mt={2} />
      <Grid container wrap="nowrap" direction="row">
        <Grid
          style={{ maxWidth: '280px' }}
          item
          alignItems="flex-start"
          container
        >
          <CampaignInfoBoxContainer>
            {/* stepper */}
            {!isViewed && (
              <Grid item>
                <CampaignStepper
                  savedCampaign={isEdit ? undefined : campaign}
                  agency={agency}
                  headerText={t(`createCampaign.newCampaign.${type}`)}
                  campaignValue={formValue}
                  isFormSubmitted={isFormSubmitted}
                />
              </Grid>
            )}

            {/* campaign info */}
            {Boolean(isViewed && campaign) && (
              <Grid item>
                <CampaignInfoBox campaign={campaign} />
              </Grid>
            )}

            {/* campaign calculations */}
            <Grid item>
              <CampaignCalculationInfoBox
                isViewed={isViewed}
                type={campaignDetails.type}
                staticPrice={staticPrice}
                estimatedRange={estimatedRange}
                isReachAvailable={isReachAvailable(
                  geoTargeting.geoTargetingType
                )}
                pricePerUser={pricePerUser}
                budgetRecommendation={budgetRecommendation}
                budget={campaignPricing.budget}
                campaignGoal={campaignGoal}
              />
            </Grid>
          </CampaignInfoBoxContainer>
        </Grid>
        <Grid style={{ maxWidth: '1100px' }} item xs>
          <form>
            <CampaignDetails
              refsMap={refsMap}
              advertisers={advertisers}
              handleCampaignDetails={handleCampaignDetails}
              campaignDetails={campaignDetails}
              isFormSubmitted={isFormSubmitted}
              disabled={disabled}
              type={campaignDetails.type}
            />
            <CampaignTargeting
              refsMap={refsMap}
              handleCampaignTargeting={handleCampaignTargeting}
              campaignTargeting={campaignTargeting}
              campaignDetails={campaignDetails}
              isFormSubmitted={isFormSubmitted}
              disabled={disabled}
              pricing={pricing}
              campaignGoal={campaignGoal}
            />
            {renderMaps && (
              <GeoTargetingWidget
                refsMap={refsMap}
                pricing={pricing}
                onChange={handleCampaignGeoTargeting}
                geoTargeting={geoTargeting}
                isFormSubmitted={isFormSubmitted}
                type={campaignDetails.type}
                disabled={disabled}
                campaignGoal={campaignGoal}
              />
            )}

            <CampaignPricing
              additionalContactField={additionalContactField}
              socialPlatform={campaignDetails.socialPlatform}
              handleCampaignPricing={handleCampaignPricing}
              campaignPricing={campaignPricing}
              estimatedRange={estimatedRange}
              isReachAvailable={isReachAvailable(geoTargeting.geoTargetingType)}
              type={campaignDetails.type}
              pricesPerUser={pricePerUser}
              budgetRecommendation={budgetRecommendation}
              isFormSubmitted={isFormSubmitted}
              disabled={disabled}
              campaignGoal={campaignGoal}
              refsMap={refsMap}
            />
            {isAdmin() && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  id="admin-actions"
                  expandIcon={<ExpandMore />}
                >
                  <Typography variant="h6">Admin</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {campaign && (
                    <AdminCampaignActions
                      report={report}
                      campaignStatus={campaignStatus}
                      creativeStatus={creativeStatus}
                      onChange={onCampaignStatusesChange}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            )}
            <Box mt={2} />
            <CampaignActions
              isEditOrViewed={isEdit || isViewed}
              isViewed={isViewed}
              isFormValid={isFormValid}
              isFormSubmitted={isFormSubmitted}
              onSubmit={onSubmit}
              onAdminSubmit={onAdminSubmit}
              handleCampaignDetails={handleCampaignDetails}
              campaignDetails={campaignDetails}
              disabled={disabled}
              handlePDFGeneration={handlePDFGeneration}
              handleExcelGeneration={handleExcelGeneration}
              onPDFGenerationWithoutAdvertiserMargins={
                handlePDFGenerationWithoutAdvertiserMargins
              }
            />
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateViewEditCampaign;
